import React, { useState, useEffect, useRef } from "react";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Header } from "../../components/Header";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import "./DateRangePicker.css";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Menu,
  Button,
  Grid,
  Autocomplete,
  Card,
  Tabs,
  Tab,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";

import CallMadeIcon from "@mui/icons-material/CallMade";

import CallReceivedIcon from "@mui/icons-material/CallReceived";

import Pic1 from "../../images/phone_call.png";
import { Incoming } from "./Incoming";
import { Outgoing } from "./Outgoing";
import { useNavigate } from "react-router-dom";

import { useFetchAgentsMutation } from "../../services/agentApi";
import { useFetchClientOptionsMutation } from "../../services/clientApi";

import { isAllowed } from "../Functions";
// import { Typography } from "../Dashboard";
import {
  ImportContactsOutlined,
  ImportExportOutlined,
} from "@mui/icons-material";

export const daysBetween = (from_date, to_date) => {
  let date_1 = new Date(from_date);
  let date_2 = new Date(to_date);
  let difference = date_2.getTime() - date_1.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
};

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value == index && <Box>{children}</Box>}
    </div>
  );
};

export const secondsToHms = (d) => {
  if (!d) {
    return 0;
  }
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " h," : " h,") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " m, " : " m, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "0";

  // var hDisplay = h > 9 ? h : "0"+h;
  // var mDisplay = m > 9 ? m : "0"+m;
  // var sDisplay = s > 9 ? s : "0"+s;

  return hDisplay + mDisplay + sDisplay;

  // return hDisplay+":"+ mDisplay +":"+sDisplay;
};

export const timeConvert = (time) => {
  if (!time) {
    return null;
  }
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

export const convertToCSV = (objArray) => {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  // Add column names as the first row
  var header = Object.keys(array[0]).join(",");
  str += header + "\r\n";

  // Add data rows
  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";
      line += array[i][index];
    }
    str += line + "\r\n";
  }

  return str;
};

export const downloadCSV = (csv, filename) => {
  var csvFile;
  var downloadLink;

  csvFile = new Blob([csv], { type: "text/csv" });

  downloadLink = document.createElement("a");

  downloadLink.download = filename;

  downloadLink.href = window.URL.createObjectURL(csvFile);

  downloadLink.style.display = "none";

  document.body.appendChild(downloadLink);

  downloadLink.click();
};

export const CallRecord = () => {

  const [isExporting, setIsExporting] = useState(false);

  function handleExportClick(val) {
    setIsExporting(val);
  }

  const pageHeading = React.useRef(null);
  const fiterBar = React.useRef(null);
  const pagination = React.useRef(null);

  const role_id = localStorage.getItem("role_id");
  const is_superuser = localStorage.getItem("is_superuser");

  const handleClose = () => {
    setShowFilter(false);
  };

  console.log("page heading height", pageHeading?.current?.offsetHeight);

  const navigate = useNavigate();

  const childRef = useRef(null);

  const [value, setValue] = useState(0);

  const isAdmin = useSelector((state) => state.company.isAdmin);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [showFilter, setShowFilter] = useState(false);

  const [fetchAgents, agentLoader] = useFetchAgentsMutation();

  const [fetchClientOptions, clientLoader] = useFetchClientOptionsMutation();

  const isClientLoading = clientLoader.isLoading;
  const isAgentLoading = agentLoader.isLoading;

  const formatDate = (date, seprator) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();

    mm = mm < 10 ? "0" + mm : mm;
    dd = dd < 10 ? "0" + dd : dd;

    const formated_date = yyyy + seprator + mm + seprator + dd;

    return formated_date;
  };

  const date = new Date();

  const formated_date = formatDate(date, "-");

  const [dateRanges, onChange] = useState([formated_date, formated_date]);

  // console.log("formated date", formated_date);

  const [body, setBody] = useState({
    from_date: formated_date,
    to_date: formated_date,
    agent_number: "",
    customer_number: "",
    page: 1,
    per_page: 25,
    order_by: "datetime,Desc",
    duration_type: "secs",
    duration_min: undefined,
    filter_symbol: undefined,
    call_type: "all",
    // order: "desc",
    client_id: localStorage.getItem("client_id"),
  });
  const [client, setClient] = useState({
    id: localStorage.getItem("client_id"),
    name: localStorage.getItem("client_name"),
    did_number: localStorage.getItem("master_num"),
  });

  const [agents, setAgents] = useState([]);

  const [clients, setClients] = useState([]);

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const getAgents = async () => {
    try {
      const response = await fetchAgents({
        client_id: body.client_id,
      }).unwrap();

      if (response.status === true) {
        console.log("agent resposne", response);

        setAgents(response.agents);

        console.log("agent option", agents);
      }
    } catch (error) {
      console.log("agent error", error);
    }
  };

  const getClients = async () => {
    try {
      const response = await fetchClientOptions({ pagination: "no" }).unwrap();

      console.log("client resposne", response);

      if (response.status === true) {
        setClients(response.clients);

        console.log("clients option", clients);
      }
    } catch (error) {
      console.log("clients catch error", error);
    }
  };

  // useEffect(()=>{

  //   const calender = document.getElementsByClassName("react-calender");

  //   console.log("calender",calender[0])

  // },[])

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    setBody({ ...body, agent_number: "" });
    getAgents();
  }, [body.client_id]);

  if (!isAllowed("calls_access") && isAdmin != 1) {
    return navigate("/forbidden");
  }

  return (
    <>
      <Header title={"Calls Record"} />

      <Grid
        // spacing={1}
        container
        sx={{
          // p: 3,
          // pb: 1,
          borderWidth: "1px 0px",
          borderStyle: "solid",
          borderColor: "#F2F2F2",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
          mt: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item>
          <Box sx={{ pt: 1 }}>
            <Tabs
              centered
              value={value}
              onChange={handleTabs}
              textColor="primary"
              indicatorColor=""
            >
              {/* { isAllowed('incoming_call_access') &&  */}
              <Tab
                sx={{ textTransform: "none", fontWeight: "bold" }}
                label={
                  <>
                    {" "}
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "12px",
                        lineHeight: "15px",
                      }}
                    >
                      Incoming
                    </Typography>{" "}
                  </>
                }
                // icon={<CallReceivedIcon />}
              ></Tab>
              {/* } */}
              {/* { isAllowed('outgoing_call_access') && */}
              <Tab
                sx={{ textTransform: "none", fontWeight: "bold" }}
                label={
                  <>
                    {" "}
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "12px",
                        lineHeight: "15px",
                      }}
                    >
                      Outgoing
                    </Typography>{" "}
                  </>
                }
                // icon={<CallMadeIcon />}
              ></Tab>
              {/* } */}
            </Tabs>
          </Box>
        </Grid>

        <Grid sx={{}} item>
          <Grid
            sx={{ py: 2, pl: 2, display: "flex", justifyContent: "" }}
            container
            spacing={1}
          >
            <Grid item>
              {/* __________________ Order By */}
              <FormControl size="small" sx={{}}>
                <InputLabel
                  sx={{
                    // color: "primary.main"
                    fontWeight: "500",
                    fontSize: "11px",
                    lineHeight: "14px",
                  }}
                  id="demo-simple-select-label"
                >
                  Order By
                </InputLabel>
                <Select
                  size="small"
                  sx={{
                    // borderRadius: "40px",
                    // backgroundColor: "#FFF2F9",
                    // color: "primary.main",
                    border: 0,
                    fontWeight: "500",
                    fontSize: "11px",
                    lineHeight: "14px",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={body.order_by}
                  label="Order By"
                  onChange={(event) =>
                    setBody({ ...body, order_by: event.target.value })
                  }
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "14px",
                    }}
                    component={MenuItem}
                    value={"datetime,Desc"}
                  >
                    DateTime <ArrowUpwardIcon sx={{ fontSize: "9px" }} />
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "14px",
                    }}
                    component={MenuItem}
                    value={"duration,Desc"}
                  >
                    Duration <ArrowUpwardIcon sx={{ fontSize: "9px" }} />
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "14px",
                    }}
                    component={MenuItem}
                    value={"datetime,Asc"}
                  >
                    DateTime <ArrowDownwardIcon sx={{ fontSize: "9px" }} />
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "14px",
                    }}
                    component={MenuItem}
                    value={"duration,Asc"}
                  >
                    Duration <ArrowDownwardIcon sx={{ fontSize: "9px" }} />
                  </Typography>
                </Select>
              </FormControl>
            </Grid>
            <Grid style={{ paddingLeft: "0px" }} item>
              {/* __________________ Per Page */}
              {/* <Box sx={{}}> */}
              <FormControl size="small" sx={{ mx: 1 }}>
                <InputLabel
                  sx={{
                    // color: "primary.main"
                    fontWeight: "500",
                    fontSize: "11px",
                    lineHeight: "14px",
                  }}
                  id="demo-simple-select-label"
                >
                  Per Page
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={body.per_page}
                  label={"Per page"}
                  sx={{
                    // borderRadius: "40px",
                    // backgroundColor: "#FFF2F9",
                    // color: "primary.main",
                    border: 0,
                    fontWeight: "500",
                    fontSize: "11px",
                    lineHeight: "14px",
                    // mt: 0.5,
                  }}
                  onChange={(event) =>
                    setBody({ ...body, per_page: event.target.value })
                  }
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "14px",
                    }}
                    component={MenuItem}
                    value={10}
                  >
                    10
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "14px",
                    }}
                    component={MenuItem}
                    value={25}
                  >
                    25
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "14px",
                    }}
                    component={MenuItem}
                    value={50}
                  >
                    50
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "14px",
                    }}
                    component={MenuItem}
                    value={100}
                  >
                    100
                  </Typography>
                  {/* <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "14px",
                    }}
                    component={MenuItem}
                    value={1000}
                  >
                    1000
                  </Typography> */}
                </Select>
              </FormControl>
              {/* </Box> */}
            </Grid>
            <Grid style={{ paddingLeft: "0px" }} item>
              <Button
                // sx={{ color: "white" }}
                color={"primary"}
                size="small"
                variant={"contained"}
                sx={{
                  // color: "white",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "11px",
                  lineHeight: "14px",
                  padding: "8px 16px",
                  mr: 1,
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                Filter
              </Button>
            </Grid>
            <Grid style={{ paddingLeft: "0px" }} item>
              {/* {  isAllowed("export_cdr") || isAdmin == 1 ? */}
              <Button
                // sx={{ color: "white" }}
                color={"secondary"}
                size="small"
                variant="contained"
                sx={{
                  color: "white",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "11px",
                  lineHeight: "14px",
                  padding: "8px 16px",
                  mx: 1,
                }}
                my={{ xs: 1, sm: 1 }}
                onClick={() => {
                  childRef.current.exportCallRecords();
                }}
                disabled={isExporting}
              >
                {isExporting ? "Exporting..." : "Export"}
              </Button>
              {/* // : null} */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        keepMounted
        open={showFilter}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullScreen={fullScreen}
        fullWidth
        sx={
          {
            // boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
            // borderRadius: "10px",
            // width: "562px",
            // height: "310px",
            // position: "absolute",
            // left: "397px",
            // top: "86px",
          }
        }
      >
        <DialogTitle id="alert-dialog-title">
          {" "}
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "20px",
            }}
          >
            Filter
          </Typography>
        </DialogTitle>

        <DialogContent>
          <>
            <Grid
              spacing={2}
              container
              sx={{
                p: 3,
                pt: 1,
                // display: "flex",
                // justifyContent: "center",
                borderWidth: "1px 0px",
                borderStyle: "solid",
                borderColor: "#F2F2F2",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
                mt: 0,
                // mb:"15px",
              }}
            >
              {/* __________________ Client */}
              {isAllowed("search_any_client") ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{}}>
                  <Autocomplete
                    // fullWidth={false}
                    size="small"
                    value={client || ""}
                    onChange={(event, value) => {
                      console.log("client value", value);

                      setClient(value);

                      setBody({ ...body, client_id: value?.id });

                      // setBody({ ...body, agent_number: "" })
                    }}
                    disablePortal
                    id="clients"
                    options={clients}
                    loading={isClientLoading}
                    sx={{}}
                    renderInput={(params) => (
                      <TextField {...params} label="Client" />
                    )}
                    getOptionLabel={(option) =>
                      option.name + " " + option.did_number
                    }
                  />
                  {isClientLoading ? <LinearProgress /> : ""}
                </Grid>
              ) : null}
              {/* __________________ Start Date */}
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: " ",
                }}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  name="startDate"
                  label={
                    <>
                      <Typography
                        variant="span"
                        sx={{
                          // color: "primary.main"
                          fontWeight: "500",
                          fontSize: "11px",
                          lineHeight: "14px",
                        }}
                      >
                        Start Date
                      </Typography>
                    </>
                  }
                  type="date"
                  size="small"
                  inputProps={{
                    style: {
                      // width: "215px",
                    },
                  }}
                  value={body.from_date}
                  onChange={(e) => {
                    setBody({ ...body, from_date: e.target.value });
                  }}
                  variant="outlined"
                />
                {/* <Box sx={{ mt: 1 }}>
            <DateRangePicker
              className="react-daterange-picker"
              onChange={(value) => {
                console.log("dateranges", value);
                setBody({ ...body, from_date: value[0], to_date: value[1] });
              }}
              value={[body.from_date, body.to_date]}
            />
          </Box> */}
              </Grid>
              {/* __________________ End Date */}
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: " ",
                }}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <TextField
                  name="EndDate"
                  label={
                    <>
                      <Typography
                        variant="span"
                        sx={{
                          // color: "primary.main"
                          fontWeight: "500",
                          fontSize: "11px",
                          lineHeight: "14px",
                        }}
                      >
                        End Date
                      </Typography>
                    </>
                  }
                  type="date"
                  size="small"
                  value={body.to_date}
                  sx={{
                    width: "100%",
                  }}
                  // inputProps={{
                  //   style: {
                  //     width: "215px",
                  //   },
                  // }}
                  onChange={(e) => {
                    setBody({ ...body, to_date: e.target.value });
                  }}
                  variant="outlined"
                />
              </Grid>

              {/* __________________ Agent No */}
              {role_id != 6 && (
                <Grid
                  sx={{
                    display: "flex",
                    // justifyContent: "",
                  }}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <Autocomplete
                    fullWidth
                    freeSolo
                    size="small"
                    value={body.agent_number}
                    onChange={(event, value) => {
                      if (value?.label) {
                        setBody({ ...body, agent_number: value?.label });
                      } else {
                        setBody({ ...body, agent_number: value });
                      }
                    }}
                    disablePortal
                    id="agent"
                    options={agents}
                    sx={{}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => {
                          setBody({ ...body, agent_number: e.target.value });
                        }}
                        label={
                          <>
                            {" "}
                            <Typography
                              // variant="span"
                              sx={{
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: "11px",
                                lineHeight: "14px",
                                pt: 0.5,
                              }}
                            >
                              Agent Number
                            </Typography>{" "}
                          </>
                        }
                      />
                    )}
                  />
                  {isAgentLoading ? <LinearProgress /> : ""}
                </Grid>
              )}

              {/* __________________ Customer No */}
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: " ",
                }}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <TextField
                  size="small"
                  onChange={(e) => {
                    setBody({ ...body, customer_number: e.target.value });
                  }}
                  value={body.customer_number}
                  // sx={{mt:0.5,pb:1}}
                  sx={{
                    width: "100%",
                  }}
                  // inputProps={{
                  //   style: {
                  //     width: "215px",
                  //   },
                  // }}
                  label={
                    <>
                      {" "}
                      <Typography
                        sx={{
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "11px",
                          lineHeight: "14px",
                          color: "#767676",
                          // height:"50px",
                          pt: 0.5,
                        }}
                        // variant="span"
                      >
                        Client Number
                      </Typography>{" "}
                    </>
                  }
                />
                {/* </Box> */}
              </Grid>

              {/* Duration Type */}
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                // sx={{ display: "flex", justifyContent: "" }}
              >
                <FormControl size="small" sx={{}}>
                  <InputLabel
                    sx={{
                      // color: "primary.main"
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "14px",
                    }}
                    id="demo-simple-select-label"
                  >
                    Duration Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={body.duration_type || ""}
                    label={" Duration Type"}
                    sx={{
                      // borderRadius: "40px",
                      // backgroundColor: "#FFF2F9",
                      // color: "primary.main",
                      border: 0,
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "14px",
                      // width:"100%"
                      // mt: 0.5,
                    }}
                    onChange={(event) =>
                      setBody({ ...body, duration_type: event.target.value })
                    }
                  >
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "11px",
                        lineHeight: "14px",
                      }}
                      component={MenuItem}
                      value={"mins"}
                    >
                      Minutes
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "11px",
                        lineHeight: "14px",
                      }}
                      component={MenuItem}
                      value={"secs"}
                    >
                      Seconds
                    </Typography>
                  </Select>
                </FormControl>
              </Grid>

              {/* Duration */}
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                sx={{ display: "flex", justifyContent: "" }}
              >
                <TextField
                  size="small"
                  onChange={(e) => {
                    setBody({ ...body, duration_min: e.target.value });
                  }}
                  value={body.duration_min || ""}
                  inputProps={{
                    style: {
                      // width: "65px",
                      height: "17px",
                    },
                  }}
                  label={
                    <>
                      {" "}
                      <Typography
                        sx={{
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "11px",
                          lineHeight: "14px",
                          color: "#767676",
                          // height:"50px",
                          // pt: 0.5,
                        }}
                        // variant="span"
                      >
                        {body.duration_type == "mins" ? "Minutes" : "Seconds"}
                      </Typography>{" "}
                    </>
                  }
                />
                {/* </Box> */}
              </Grid>

              {/* Filter symbol */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                sx={{ display: "flex", justifyContent: "" }}
              >
                <FormControl>
                  {/* <FormLabel id="demo-radio-buttons-group-label">Duration Filter</FormLabel> */}
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    row
                    onClick={(e) => {
                      if (e.target.value == body.filter_symbol) {
                        console.log(
                          "if test radio button",
                          e.target.value == body.filter_symbol
                        );

                        setBody({
                          ...body,
                          filter_symbol: undefined,
                          duration_min: undefined,
                          // duration_type: "mins",
                        });
                      } else {
                        console.log(
                          "else test radio button",
                          e.target.value == body.filter_symbol
                        );

                        setBody({ ...body, filter_symbol: e.target.value });
                      }
                    }}
                    value={body.filter_symbol || ""}
                  >
                    <FormControlLabel value="<" control={<Radio />} label="<" />
                    <FormControlLabel value="=" control={<Radio />} label="=" />
                    <FormControlLabel value=">" control={<Radio />} label=">" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                // sx={{ display: "flex", justifyContent: "" }}
              >
                <FormControl size="small" sx={{}}>
                  <InputLabel
                    sx={{
                      // color: "primary.main"
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "14px",
                    }}
                    id="demo-simple-select-label"
                  >
                    Call Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={body.call_type || ""}
                    label={" Call Type"}
                    sx={{
                      // borderRadius: "40px",
                      // backgroundColor: "#FFF2F9",
                      // color: "primary.main",
                      border: 0,
                      fontWeight: "500",
                      fontSize: "11px",
                      lineHeight: "14px",
                      // width:"100%"
                      // mt: 0.5,
                    }}
                    onChange={(event) =>
                      setBody({ ...body, call_type: event.target.value })
                    }
                  >
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "11px",
                        lineHeight: "14px",
                      }}
                      component={MenuItem}
                      value={"all"}
                    >
                      All
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "11px",
                        lineHeight: "14px",
                      }}
                      component={MenuItem}
                      value={"answered"}
                    >
                      Answered/Connected
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "11px",
                        lineHeight: "14px",
                      }}
                      component={MenuItem}
                      value={"missed"}
                    >
                      Missed/Unanswered
                    </Typography>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              // color: "white",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "15px",
              padding: "8px 16px",
              gap: "19px",
              width: "98px",
              height: "31px",
            }}
            color={"primary"}
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            id="clearFilter"
            onClick={() => {
              setBody({
                from_date: formated_date,
                to_date: formated_date,
                agent_number: "",
                customer_number: "",
                page: 1,
                per_page: 25,
                order_by: "datetime,Desc",
                duration_min: undefined,
                filter_symbol: undefined,
                duration_type: "secs",
                call_type: "all",
                // order: "desc",
                client_id: localStorage.getItem("client_id"),
              });

              // childRef.current.childFunction1({
              //   from_date: formated_date,
              //   to_date: formated_date,
              //   agent_number: "",
              //   customer_number: "",
              //   page: 1,
              //   per_page: 25,
              //   order_by: "datetime,Desc",
              //   duration_min: undefined,
              //   filter_symbol: undefined,
              //   // order: "desc",
              //   client_id: localStorage.getItem("client_id"),
              // });
            }}
            // size="small"
            variant="outlined"
            color={"secondary"}
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "15px",
              color: "#B7BBBF",
              borderColor: "#B7BBBF",
              padding: "8px 16px",
              width: "98px",
              height: "31px",
              // ml: 1,
            }}
          >
            Clear
          </Button>
          <Button
            // sx={{ color: "white" }}
            color={"primary"}
            size="small"
            variant="contained"
            sx={{
              color: "white",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "15px",
              padding: "8px 16px",
              gap: "19px",
              width: "98px",
              height: "31px",
            }}
            onClick={() => {
              // alert(days(body.from_date, body.to_date));

              if (daysBetween(body.from_date, body.to_date) > 30) {
                alert("You cannot fetch more than 31 days data");
              } else {
                childRef.current.childFunction1(body);
                setShowFilter(false);
              }
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 0 }}>
          <Card
            sx={{
              width: "100%",
              height: "100%",
              boxShadow: "none",
              backgroundColor: "",
            }}
          >
            <Box>
              <TabPanel value={value} index={0}>
                {isAllowed("incoming_call_access") || isAdmin == 1 ? (
                  <Incoming
                    ref={childRef}
                    agents={agents}
                    secondsToHms={secondsToHms}
                    timeConvert={timeConvert}
                    body={body}
                    setBody={setBody}
                    handleExportClick={handleExportClick}
                  />
                ) : (
                  <Typography
                    sx={{ color: "error.main", textAlign: "center" }}
                    variant="h4"
                  >
                    Unauthorized Action !
                  </Typography>
                )}
              </TabPanel>

              <TabPanel value={value} index={1}>
                {isAllowed("outgoing_call_access") || isAdmin == 1 ? (
                  <Outgoing
                    ref={childRef}
                    secondsToHms={secondsToHms}
                    timeConvert={timeConvert}
                    body={body}
                    setBody={setBody}
                    handleExportClick={handleExportClick}
                  />
                ) : (
                  <Typography
                    sx={{ color: "error.main", textAlign: "center" }}
                    variant="h4"
                  >
                    Unauthorized Action !
                  </Typography>
                )}
              </TabPanel>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
