import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";


import {
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Header } from "../components/Header";

import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";

import { daysBetween } from "./call_record/CallRecord";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

// import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwardedOutlined";

import {
  useOutgoingTotalTalkTimeMutation,
  useTotalTalkTimeMutation,
  useFetchCallsStatsMutation,
  useTotalAndUniqueIncomingMutation,
  useTotalAndUniqueMissedMutation,
  useTotalAndUniqueAnsweredMutation,
  useTotalAndUniqueOutgoingMutation,
  useTotalAndUniqueConnectedMutation,
  useTotalAndUniqueUnansweredMutation,
  useTotalCallCountMutation,
  useTopIncomingAgentsMutation,
  useTopOutgoingAgentsMutation,
  useCallBackAnalyticsMutation,
} from "../services/dashboardApi";

import { secondsToHms } from "./call_record/CallRecord";

import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

import PhoneDisabledOutlinedIcon from "@mui/icons-material/PhoneDisabledOutlined";

import PhoneCallbackOutlinedIcon from "@mui/icons-material/PhoneCallbackOutlined";

import PhoneMissedOutlinedIcon from "@mui/icons-material/PhoneMissedOutlined";

import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import {
  Button,
  Grid,
  Autocomplete,
  Card,
  Tabs,
  Tab,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Typography,
} from "@mui/material";

import CallMadeIcon from "@mui/icons-material/CallMade";

import CallReceivedIcon from "@mui/icons-material/CallReceived";

import { useNavigate } from "react-router-dom";

import { useFetchAgentsMutation } from "../services/agentApi";

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  // fontWeight: "bolder",
  fontStyle: "normal",
}));

export const Dashboard = () => {
  const [fetchAgents, agentLoader] = useFetchAgentsMutation();
  const [fetchCallsStats, CallsStatsLoader] = useFetchCallsStatsMutation();

  const [totalAndUniqueIncoming, incomingLoader] =
    useTotalAndUniqueIncomingMutation();
  const [totalAndUniqueMissed, missedLoader] =
    useTotalAndUniqueMissedMutation();
  const [totalAndUniqueAnswered, answeredLoader] =
    useTotalAndUniqueAnsweredMutation();
  const [totalAndUniqueOutgoing, outgoingLoader] =
    useTotalAndUniqueOutgoingMutation();
  const [totalAndUniqueConnected, connectedLoader] =
    useTotalAndUniqueConnectedMutation();
  const [totalAndUniqueUnanswered, unansweredLoader] =
    useTotalAndUniqueUnansweredMutation();
  const [totalCallCount, totalCallLoader] = useTotalCallCountMutation();
  const [topIncomingAgents, incomingAgentsLoader] =
    useTopIncomingAgentsMutation();
  const [topOutgoingAgents, outgoingAgentsLoader] =
    useTopOutgoingAgentsMutation();
  const [CallBackAnalytics, callbackAnalyticsLoader] =
    useCallBackAnalyticsMutation();
  const [totalTalkTime, totalTalkTimeLoader] = useTotalTalkTimeMutation();
  const [outgoingTotalTalkTime, outgoingTotalTalkTimeLoader] =
    useOutgoingTotalTalkTimeMutation();

  const isAgentLoading = agentLoader.isLoading;
  const isCallsStatsLoading = CallsStatsLoader.isLoading;

  const navigate = useNavigate();

  const theme = useTheme();

  const callAllApis = (body) => {
    getTotalTalkTime(body);
    getOutgoingTotalTalkTime(body);
    fetchIncoming(body);
    fetchAnswered(body);
    fetchMissed(body);
    fetchOutgoing(body);
    fetchConnected(body);
    fetchUnanswered(body);
    fetchTotalCalls(body);
    fetchTopIncomingAgents(body);
    fetchTopOutgoingAgents(body);
    // fetchCallBackCalls(body)
  };

  const [value, setValue] = useState(0);

  const [incoming, setIncoming] = useState({
    incomingTotal: 0,
    incomingUnique: 0,
  });
  const [answered, setAnswered] = useState({
    answeredTotal: 0,
    answeredUnique: 0,
  });

  const [missed, setMissed] = useState({
    missedTotal: 0,
    missedUnique: 0,
  });

  const [outgoing, setOutgoing] = useState({
    outgoingTotal: 0,
    outgoingUnique: 0,
  });

  const [connected, setConnected] = useState({
    connectedTotal: 0,
    connectedUnique: 0,
  });

  const [unanswered, setUnanswered] = useState({
    unansweredTotal: 0,
    unansweredUnique: 0,
  });

  const [callBackCalls, setCallBackCalls] = useState({
    callBack: 0,
    neverCallBack: 0,
    averageCallBackTime: 0,
  });

  const [callTime, setCallTime] = useState({
    total: 0,
    average: 0,
  });

  const [outgoingCallTime, setOutgoingCallTime] = useState({
    total: 0,
    average: 0,
  });

  const [totalCalls, setTotalCalls] = useState(0);

  const [incomingTopAgents, setIncomingTopAgents] = useState([]);

  const [outgoingTopAgents, setOutgoingTopAgents] = useState([]);

  const [incomingError, setIncomingError] = useState(false);
  const [outgoingError, setOutgoingError] = useState(false);

  // const [data, setData] = useState({
  //   counts: {},
  //   incomingTotal: 0,
  //   incomingUnique: 0,
  //   answeredTotal: 0,
  //   answeredUnique: 0,
  //   missedTotal: 0,
  //   missedUnique: 0,
  //   outgoingTotal: 0,
  //   outgoingUnique: 0,
  //   connectedTotal: 0,
  //   connectedUnique: 0,
  //   unansweredTotal: 0,
  //   unansweredUnique: 0,
  //   totalCalls: 0,
  //   callBackCalls: 0,
  //   neverCallBackCalls: 0,
  //   averageCallBackTime: 0,
  //   incoming_top_agents: [],
  //   outgoing_top_agents: [],
  // });

  const AgentCard = ({ title, subTitle, count }) => {
    return (
      <Card
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.05)",
        }}
        variant="outlined"
      >
        <CardHeader
          subheader={
            <>
              {" "}
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                }}
                // variant="h6"
              >
                {subTitle}
              </Typography>
            </>
          }
          title={
            <>
              <Box>
                <Typography
                  sx={{
                    color: "#707070",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                  }}
                  variant="h6"
                >
                  {title}
                </Typography>
              </Box>
            </>
          }
        />
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {/* <PhoneInTalkOutlinedIcon fontSize="large" color="secondary" /> */}
            <Typography
              sx={{
                fontstyle: "normal",
                fontWeight: "400",
                fontSize: "25px",
                lineHeight: "32px",
              }}
              // variant="h4"
            >
              {count} Calls
            </Typography>
          </Box>
        </CardContent>
        <CardActions></CardActions>
      </Card>
    );
  };

  const LocalCard = ({ title, count, color, Icon, isLoading, fetchAgain }) => {
    return (
      <>
        <Card
          sx={{
            borderRadius: "10px",
            boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.05)",
            // height: '10vw',
          }}
          variant="outlined"
        >
          <CardHeader title={<></>} />
          <CardContent sx={{ p: 0 }}>
            <Grid container sx={{}}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={3}
                xl={3}
                item
                sx={{ pr: 2, pt: 2, textAlign: "center" }}
              >
                {
                  <Icon
                    sx={{
                      // Width :"42.92px",
                      // Height :"43px",
                      fontSize: "43px",
                      color: color,
                    }}
                  />
                }
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={9}
                xl={9}
                item
                sx={{
                  textAlign: {
                    xl: "left",
                    lg: "left",
                    sm: "center",
                    md: "center",
                    xs: "center",
                  },
                  pl: {
                    xl: 0,
                    lg: 0,
                    sm: 0,
                    md: 0,
                    xs: 0,
                  },
                }}
              >
                <Typography
                  // variant="h6"
                  sx={{
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "20px",
                    // display: "flex",
                    // alignItems: "center",
                    // color: "#000000",
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "500",
                    mt: 1,
                    fontSize: count?.length > 12 ? "25px" : "35px",
                    lineHeight: "44px",
                  }}
                  // variant="h3"
                >
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : count != null ? (
                    count
                  ) : (
                    <IconButton
                      onClick={() => {
                        fetchAgain(body);
                      }}
                    >
                      <GppBadOutlinedIcon color="error" />
                    </IconButton>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions></CardActions>
        </Card>
      </>
    );
  };

  const SubLocalCard = ({
    title,
    count,
    color,
    Icon,
    bl,
    bb,
    br,
    bt,
    isLoading,
    fetchAgain,
  }) => {
    return (
      <>
        <Card
          sx={{
            // borderRadius: "10px",
            // boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.05)",
            borderLeft: bl,
            borderBottom: bb,
            borderRight: br,
            borderTop: bt,
            borderRadius: 0,
          }}
          variant="outlined"
        >
          {/* <CardHeader
            title={
              <>
                <Box>
                  <Typography variant="h5">{title}</Typography>
                </Box>
              </>
            }
          /> */}
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  color: color,
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "25px",
                  lineHeight: "32px",
                }}
                // variant="h4"
              >
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : count != null ? (
                  count
                ) : (
                  <IconButton
                    onClick={() => {
                      fetchAgain(body);
                    }}
                  >
                    <GppBadOutlinedIcon color="error" />
                  </IconButton>
                )}
              </Typography>
              {/* <Icon fontSize={"large"} color={"black"} /> */}
            </Box>
          </CardContent>
          <CardActions>
            <Typography
              sx={{
                // fontFamily: "Plus Jakarta Sans",
                // fontStyle: "normal",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15px",
                color: "#848484",
              }}
              // variant="h6"
            >
              {title}
            </Typography>
          </CardActions>
        </Card>
      </>
    );
  };

  const [alert, setAlert] = useState({
    status: false,
    msg: "",
    type: "",
  });

  const formatDate = (date, seprator) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();

    mm = mm < 10 ? "0" + mm : mm;
    dd = dd < 10 ? "0" + dd : dd;

    const formated_date = yyyy + seprator + mm + seprator + dd;

    return formated_date;
  };

  const date = new Date();

  const formated_date = formatDate(date, "-");

  // console.log("formated date", formated_date);

  const [body, setBody] = useState({
    from_date: formated_date,
    to_date: formated_date,
    agent_number: "",
  });

  const [agents, setAgents] = useState([]);

  // const getCallsStats = async (body) => {
  //   try {
  //     const response = await fetchCallsStats(body).unwrap();

  //     console.log("Calls Stats response", response);

  //     if (response.status === true) {
  //       setData({ ...response.data });

  //       setAlert({
  //         status: false,
  //         msg: "",
  //         type: "",
  //       });

  //       // console.log("result", response.dial_lists);
  //     } else {
  //       setData({
  //         counts: {},
  //         incoming_top_agents: [],
  //         outgoing_top_agents: [],
  //       });

  //       setAlert({
  //         status: true,
  //         msg: "Oops Something went Wrong !",
  //         type: "error.main",
  //       });

  //       console.log("____false");
  //     }
  //   } catch (error) {
  //     console.log("error", error);

  //     setData({
  //       counts: {},
  //       incoming_top_agents: [],
  //       outgoing_top_agents: [],
  //     });

  //     setAlert({
  //       status: true,
  //       msg: "Oops Something went Wrong !",
  //       type: "error.main",
  //     });
  //   }
  // };

  // total talk time
  const getTotalTalkTime = async (body) => {
    try {
      const response = await totalTalkTime(body).unwrap();

      console.log("totalTalkTime response", response);

      if (response.status === true) {
        setCallTime({
          total: response.total_talk_time_secs,
          average: response.average_talk_time,
        });
      } else {
        setCallTime({ total: null, average: null });
        console.log("____false talktime");
      }
    } catch (error) {
      setCallTime({ total: null, average: null });

      console.log("error talktime", error);
    }
  };
  const getOutgoingTotalTalkTime = async (body) => {
    try {
      const response = await outgoingTotalTalkTime(body).unwrap();

      console.log("outgoingtotalTalkTime response", response);

      if (response.status === true) {
        setOutgoingCallTime({
          total: response.total_talk_time_secs,
          average: response.average_talk_time,
        });
      } else {
        setOutgoingCallTime({ total: null, average: null });
        console.log("____false talktime");
      }
    } catch (error) {
      setOutgoingCallTime({ total: null, average: null });

      console.log("error talktime", error);
    }
  };
  //incoming total and unique
  const fetchIncoming = async (body) => {
    try {
      const response = await totalAndUniqueIncoming(body).unwrap();

      console.log("totalAndUniqueIncoming response", response);

      if (response.status === true) {
        setIncoming({
          incomingTotal: response.incoming_calls_count,
          incomingUnique: response.unique_incoming_calls_count,
        });
      } else {
        setIncoming({ incomingTotal: null, incomingUnique: null });
        console.log("____false incoming");
      }
    } catch (error) {
      setIncoming({ incomingTotal: null, incomingUnique: null });

      console.log("error incoming", error);
    }
  };

  const fetchMissed = async (body) => {
    try {
      const response = await totalAndUniqueMissed(body).unwrap();

      console.log("totalAndUniqueMissed response", response);

      if (response.status === true) {
        setMissed({
          missedTotal: response.incoming_missed_calls_count,
          missedUnique: response.unique_missed_calls_count,
        });
      } else {
        setMissed({ missedTotal: null, missedUnique: null });

        console.log("____false missed");
      }
    } catch (error) {
      console.log("missed error", error);

      setMissed({ missedTotal: null, missedUnique: null });
    }
  };

  const fetchAnswered = async (body) => {
    try {
      const response = await totalAndUniqueAnswered(body).unwrap();

      console.log("totalAndUniqueAnswered response", response);

      if (response.status === true) {
        setAnswered({
          answeredTotal: response.incoming_answered_calls_count,
          answeredUnique: response.unique_incoming_answered_calls_count,
        });

        // console.log("result", response.dial_lists);
      } else {
        setAnswered({ answeredTotal: null, answeredUnique: null });
        console.log("____false answered");
      }
    } catch (error) {
      console.log("answered error", error);

      setAnswered({ answeredTotal: null, answeredUnique: null });
    }
  };
  const fetchOutgoing = async (body) => {
    try {
      const response = await totalAndUniqueOutgoing(body).unwrap();

      console.log("totalAndUniqueOutgoing response", response);

      if (response.status === true) {
        setOutgoing({
          outgoingTotal: response.outgoing_calls_count,
          outgoingUnique: response.unique_outgoing_calls_count,
        });

        // console.log("result", response.dial_lists);
      } else {
        setOutgoing({ outgoingTotal: null, outgoingUnique: null });
        console.log("____false answered");
      }
    } catch (error) {
      console.log("answered error", error);

      setOutgoing({ outgoingTotal: null, outgoingUnique: null });
    }
  };
  const fetchConnected = async (body) => {
    try {
      const response = await totalAndUniqueConnected(body).unwrap();

      console.log("totalAndUniqueConnected response", response);

      if (response.status === true) {
        setConnected({
          connectedTotal: response.outgoing_connected_calls_count,
          connectedUnique: response.unique_connected_outgoing_calls_count,
        });

        // console.log("result", response.dial_lists);
      } else {
        setConnected({ connectedTotal: null, connectedUnique: null });
        console.log("____false connected");
      }
    } catch (error) {
      console.log("connected error", error);

      setConnected({ connectedTotal: null, connectedUnique: null });
    }
  };

  const fetchUnanswered = async (body) => {
    try {
      const response = await totalAndUniqueUnanswered(body).unwrap();

      console.log("totalAndUniqueAnswered response", response);

      if (response.status === true) {
        setUnanswered({
          unansweredTotal: response.outgoing_unanswered_calls_count,
          unansweredUnique: response.unique_unanswered_outgoing_calls_count,
        });

        // console.log("result", response.dial_lists);
      } else {
        setUnanswered({ unansweredTotal: null, unansweredUnique: null });
        console.log("____false connected");
      }
    } catch (error) {
      console.log("connected error", error);

      setUnanswered({ unansweredTotal: null, unansweredUnique: null });
    }
  };
  const fetchTotalCalls = async (body) => {
    try {
      const response = await totalCallCount(body).unwrap();

      console.log("totalCallCount response", response);

      if (response.status === true) {
        setTotalCalls(response.total_calls_count);

        // console.log("result", response.dial_lists);
      } else {
        setTotalCalls(null);
        console.log("____false connected");
      }
    } catch (error) {
      console.log("connected error", error);

      setTotalCalls(null);
    }
  };

  const fetchCallBackCalls = async (body) => {
    try {
      const response = await CallBackAnalytics(body).unwrap();

      console.log("CallBackAnalytics response", response);

      if (response.status === true) {
        setCallBackCalls({
          callBack: response.call_back_calls_count,
          neverCallBack: response.never_called_back_count,
          averageCallBackTime: response.average_time_to_call_back,
        });

        // console.log("result", response.dial_lists);
      } else {
        setCallBackCalls({
          callBack: null,
          neverCallBack: null,
          averageCallBackTime: null,
        });
        console.log("____false callback");
      }
    } catch (error) {
      console.log("callback error", error);

      setCallBackCalls({
        callBack: null,
        neverCallBack: null,
        averageCallBackTime: null,
      });
    }
  };

  const fetchTopIncomingAgents = async (body) => {
    try {
      const response = await topIncomingAgents(body).unwrap();

      console.log("topIncomingAgents response", response);

      if (response.status === true) {
        setIncomingTopAgents([...response.incoming_top_agents]);
        setIncomingError(false);

        // console.log("result", response.dial_lists);
      } else {
        setIncomingTopAgents([]);
        setIncomingError(true);
        console.log("____false incomingtopagents");
      }
    } catch (error) {
      console.log("incomingtopagents error", error);

      setIncomingTopAgents([]);
      setIncomingError(true);
    }
  };

  const fetchTopOutgoingAgents = async (body) => {
    try {
      const response = await topOutgoingAgents(body).unwrap();

      console.log("topOutgoingAgents response", response);

      if (response.status === true) {
        setOutgoingTopAgents([...response.outgoing_top_agents]);
        setOutgoingError(false);

        // console.log("result", response.dial_lists);
      } else {
        setOutgoingTopAgents([]);
        setOutgoingError(true);
        console.log("____false outgoingtopagents");
      }
    } catch (error) {
      console.log("outgoingtopagents error", error);

      setOutgoingTopAgents([]);
      setOutgoingError(true);
    }
  };

  // function secondsToHms(d) {
  //   d = Number(d);
  //   var h = Math.floor(d / 3600);
  //   var m = Math.floor((d % 3600) / 60);
  //   var s = Math.floor((d % 3600) % 60);

  //   var hDisplay = h > 0 ? h + (h == 1 ? " h," : " h,") : "";
  //   var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
  //   var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "0";

  //   // var hDisplay = h > 9 ? h : "0"+h;
  //   // var mDisplay = m > 9 ? m : "0"+m;
  //   // var sDisplay = s > 9 ? s : "0"+s;

  //   return hDisplay + mDisplay + sDisplay;

  //   // return hDisplay+":"+ mDisplay +":"+sDisplay;
  // }

  const getAgents = async () => {
    try {
      const response = await fetchAgents({
        client_id: body.client_id,
      }).unwrap();

      if (response.status === true) {
        console.log("agent resposne", response);

        setAgents(response.agents);

        console.log("agent option", agents);
      }
    } catch (error) {
      console.log("agent error", error);
    }
  };

  const isAdmin=useSelector((state)=>state.company.isAdmin);


  useEffect(() => {
    setBody({ ...body, agent_number: "" });
    getAgents();
    // getCallsStats(body);
    callAllApis(body);
  }, []);

  const role_id=localStorage.getItem("role_id") ;

  if (
    role_id== 6 &&
    isAdmin != 1
  ) {
    return navigate("/call-record");
  }

  return (
    <>
      <Header title={"Dashboard"} />

      <Grid
        spacing={2}
        container
        sx={{
          p: 1,
          pt: 0.5,
          // borderBottom: 1,
          // borderColor: "divider"
          borderWidth: "1px 0px",
          borderStyle: "solid",
          borderColor: "#F2F2F2",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
          mt: 0,
        }}
      >
        <Grid item sx={{}}>
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "11px",
              pt: 1.5,
            }}
          >
            Filter by
          </Typography>
        </Grid>
        {/* __________________ Start Date */}
        <Grid item sx={{}}>
          <TextField
            name="startDate"
            label={
              <>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "11px",
                    lineHeight: "14px",
                    display: "flex",
                    alignItems: "center",
                    color: "#767676",
                  }}
                >
                  Start Date
                </Typography>
              </>
            }
            type="date"
            size="small"
            value={body.from_date}
            onChange={(e) => {
              setBody({ ...body, from_date: e.target.value });
            }}
            variant="outlined"
          />
        </Grid>
        {/* __________________ End Date */}
        <Grid item sx={{}}>
          <TextField
            name="EndDate"
            label={
              <>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "11px",
                    lineHeight: "14px",
                    display: "flex",
                    alignItems: "center",
                    color: "#767676",
                  }}
                >
                  End Date
                </Typography>
              </>
            }
            type="date"
            size="small"
            value={body.to_date}
            onChange={(e) => {
              setBody({ ...body, to_date: e.target.value });
            }}
            variant="outlined"
          />
        </Grid>

        {/* __________________ Agent No */}
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{}}>
          <Autocomplete
            // fullWidth={false}
            loading={isAgentLoading}
            size="small"
            value={body.agent_number}
            onChange={(event, value) =>
              setBody({ ...body, agent_number: value?.label })
            }
            disablePortal
            id="agent"
            options={agents}
            sx={{}}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "11px",
                        lineHeight: "14px",
                        display: "flex",
                        alignItems: "center",
                        color: "#767676",
                        pt: 0.5,
                      }}
                    >
                      <CallOutlinedIcon
                        sx={{ width: "12px", height: "12px", mr: 1 }}
                      />{" "}
                      Extension
                    </Typography>
                  </>
                }
              />
            )}
          />
          {isAgentLoading ? <LinearProgress /> : ""}
        </Grid>

        <Grid item>
          <Button
            disabled={isCallsStatsLoading}
            id="applyBtn"
            onClick={() => {
              if (daysBetween(body.from_date, body.to_date) > 30) {
                window.alert("You cannot fetch more than 31 days data");
              } else {
                callAllApis(body);
              }
            }}
            // size="large"
            variant="contained"
            color={"secondary"}
            sx={{
              color: "white",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "15px",
              p: 1.5,
            }}
          >
            Apply
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={isCallsStatsLoading}
            id="clearFilter"
            onClick={() => {
              setBody({
                from_date: formated_date,
                to_date: formated_date,
                agent_number: "",
              });
              callAllApis({
                from_date: formated_date,
                to_date: formated_date,
                agent_number: "",
              });
            }}
            // size="small"
            variant="outlined"
            color={"secondary"}
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "15px",
              color: "#B7BBBF",
              borderColor: "#B7BBBF",
              p: 1.5,
            }}
          >
            Clear Filter
          </Button>
        </Grid>
      </Grid>

      {isCallsStatsLoading ? <LinearProgress /> : ""}

      {/* Analytics___________________________________________________ */}

      {alert.status ? (
        <Typography
          sx={{ textAlign: "center", color: alert.type }}
          variant="h4"
        >
          {alert.msg}
        </Typography>
      ) : (
        <Grid container spacing={2} sx={{ p: 1 }}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} sx={{ mt: 0 }}>
            <LocalCard
              Icon={CallOutlinedIcon}
              title="Total Calls"
              count={totalCalls}
              isLoading={totalCallLoader.isLoading}
              color={"#0089ED"}
              fetchAgain={fetchTotalCalls}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} sx={{ mt: 0 }}>
            {/* <LocalCard
              Icon={PhoneCallbackOutlinedIcon}
              title="Callback Calls"
              count={callBackCalls.callBack}
              isLoading={callbackAnalyticsLoader.isLoading}
              color={"secondary.main"}
              fetchAgain={fetchCallBackCalls}
            /> */}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} sx={{ mt: 0 }}>
            {/* <LocalCard
              Icon={PhoneDisabledOutlinedIcon}
              title="Never Callback Calls"
              count={callBackCalls.neverCallBack}
              isLoading={callbackAnalyticsLoader.isLoading}
              color={"error.main"}
              fetchAgain={fetchCallBackCalls}
            /> */}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} sx={{ mt: 0 }}>
            {/* <LocalCard
              Icon={AccessTimeOutlinedIcon}
              title="Average Callback Time"
              fetchAgain={fetchCallBackCalls}
              count={secondsToHms(callBackCalls.averageCallBackTime)}
              isLoading={callbackAnalyticsLoader.isLoading}
              color={"error.main"}
            /> */}
          </Grid>

          {/* incoming call records */}
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ mt: 0 }}>
            <Card
              // sx={{ m: 2}}
              variant="outlined"
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.05)",
              }}
            >
              <CardHeader
                title={
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        //  justifyContent: "space-between"
                      }}
                    >
                      <CallOutlinedIcon
                        sx={{
                          background: "#EAFAD5",
                          borderRadius: "10px",
                          fontSize: "18px",
                        }}
                        // fontSize={"large"}
                        color={"secondary"}
                      />
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "20px",
                          pb: 1,
                          pl: 1,
                        }}
                        // variant="h5"
                      >
                        Incoming Calls
                      </Typography>
                    </Box>
                  </>
                }
              />
              <CardContent>
                <Grid container spacing={0} sx={{}}>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                    <SubLocalCard
                      title="Total"
                      count={incoming.incomingTotal}
                      isLoading={incomingLoader.isLoading}
                      fetchAgain={fetchIncoming}
                      color={""}
                      Icon={CallOutlinedIcon}
                      bl={0}
                      bt={0}
                      bb={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Unique Total"
                      count={incoming.incomingUnique}
                      isLoading={incomingLoader.isLoading}
                      fetchAgain={fetchIncoming}
                      color={""}
                      Icon={CallOutlinedIcon}
                      bl={0}
                      bt={0}
                      bb={0}
                      br={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Answered"
                      count={answered.answeredTotal}
                      isLoading={answeredLoader.isLoading}
                      fetchAgain={fetchAnswered}
                      color={""}
                      Icon={PhoneInTalkOutlinedIcon}
                      bl={0}
                      bb={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Unique Answered"
                      count={answered.answeredUnique}
                      isLoading={answeredLoader.isLoading}
                      fetchAgain={fetchAnswered}
                      color={""}
                      Icon={PhoneInTalkOutlinedIcon}
                      bl={0}
                      bb={0}
                      br={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Missed"
                      count={missed.missedTotal}
                      isLoading={missedLoader.isLoading}
                      fetchAgain={fetchMissed}
                      color={"error.main"}
                      Icon={PhoneMissedOutlinedIcon}
                      bl={0}
                      bb={0}
                      // br={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Unique Missed"
                      count={missed.missedUnique}
                      isLoading={missedLoader.isLoading}
                      fetchAgain={fetchMissed}
                      color={"error.main"}
                      Icon={PhoneMissedOutlinedIcon}
                      bl={0}
                      bb={0}
                      br={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Total Talk Time"
                      count={secondsToHms(callTime.total)}
                      isLoading={totalTalkTimeLoader.isLoading}
                      fetchAgain={getTotalTalkTime}
                      color={""}
                      Icon={PhoneMissedOutlinedIcon}
                      bl={0}
                      bb={0}
                      // br={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Average Talk Time"
                      count={secondsToHms(callTime.average)}
                      isLoading={totalTalkTimeLoader.isLoading}
                      fetchAgain={getTotalTalkTime}
                      color={""}
                      Icon={PhoneMissedOutlinedIcon}
                      bl={0}
                      bb={0}
                      br={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Answered %"
                      count={
                        incoming.incomingTotal == null ||
                        answered.answeredTotal == null
                          ? null
                          : incoming.incomingTotal == 0
                          ? 0
                          : Math.floor(
                              (answered.answeredTotal * 100) /
                                incoming.incomingTotal
                            ) + "%"
                      }
                      isLoading={answeredLoader.isLoading}
                      fetchAgain={fetchAnswered}
                      color={""}
                      Icon={PhoneInTalkOutlinedIcon}
                      bl={0}
                      bb={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Missed %"
                      count={
                        missed.missedTotal == null ||
                        incoming.incomingTotal == null
                          ? null
                          : incoming.incomingTotal == 0
                          ? 0
                          : Math.ceil(
                              (missed.missedTotal * 100) /
                                incoming.incomingTotal
                            ) + "%"
                      }
                      isLoading={
                        missedLoader.isLoading || incomingLoader.isLoading
                      }
                      fetchAgain={fetchMissed}
                      color={""}
                      Icon={PhoneInTalkOutlinedIcon}
                      bl={0}
                      bb={0}
                      br={0}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions></CardActions>
            </Card>
          </Grid>
          {/* outgoing call records */}
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ mt: 0 }}>
            <Card
              // sx={{ m: 2}}
              variant="outlined"
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.05)",
              }}
            >
              <CardHeader
                title={
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        // justifyContent: "space-between"
                      }}
                    >
                      <PhoneForwardedIcon
                        fontSize={"large"}
                        sx={{
                          color: "#0089ED",
                          background: "#CCE8FD",
                          borderRadius: "10px",
                          fontSize: "18px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "20px",
                          pb: 1,
                          pl: 1,
                        }}
                        // variant="h5"
                      >
                        Outgoing Calls
                      </Typography>
                    </Box>
                  </>
                }
              />
              <CardContent>
                <Grid container spacing={0} sx={{}}>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                    <SubLocalCard
                      title="Total"
                      count={outgoing.outgoingTotal}
                      isLoading={outgoingLoader.isLoading}
                      fetchAgain={fetchOutgoing}
                      color={""}
                      Icon={CallOutlinedIcon}
                      bl={0}
                      bt={0}
                      bb={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Unique Total"
                      count={outgoing.outgoingUnique}
                      isLoading={outgoingLoader.isLoading}
                      fetchAgain={fetchOutgoing}
                      color={""}
                      Icon={CallOutlinedIcon}
                      bl={0}
                      bt={0}
                      bb={0}
                      br={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Connected"
                      count={connected.connectedTotal}
                      isLoading={connectedLoader.isLoading}
                      fetchAgain={fetchConnected}
                      color={""}
                      Icon={PhoneInTalkOutlinedIcon}
                      bl={0}
                      bb={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Unique Connected"
                      count={connected.connectedUnique}
                      isLoading={connectedLoader.isLoading}
                      fetchAgain={fetchConnected}
                      color={""}
                      Icon={PhoneInTalkOutlinedIcon}
                      bl={0}
                      bb={0}
                      br={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Unanswered"
                      count={unanswered.unansweredTotal}
                      isLoading={unansweredLoader.isLoading}
                      fetchAgain={fetchUnanswered}
                      color={"error.main"}
                      Icon={PhoneMissedOutlinedIcon}
                      bl={0}
                      bb={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Unique Unanswered"
                      count={unanswered.unansweredUnique}
                      isLoading={unansweredLoader.isLoading}
                      fetchAgain={fetchUnanswered}
                      color={"error.main"}
                      Icon={PhoneMissedOutlinedIcon}
                      bl={0}
                      bb={0}
                      br={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Total Talk Time"
                      count={secondsToHms(outgoingCallTime.total)}
                      isLoading={outgoingTotalTalkTimeLoader.isLoading}
                      fetchAgain={getOutgoingTotalTalkTime}
                      color={""}
                      Icon={PhoneMissedOutlinedIcon}
                      bl={0}
                      bb={0}
                      // br={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Average Talk Time"
                      count={secondsToHms(outgoingCallTime.average)}
                      isLoading={outgoingTotalTalkTimeLoader.isLoading}
                      fetchAgain={getOutgoingTotalTalkTime}
                      color={""}
                      Icon={PhoneMissedOutlinedIcon}
                      bl={0}
                      bb={0}
                      br={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Connected %"
                      count={
                        connected.connectedTotal == null ||
                        outgoing.outgoingTotal == null
                          ? null
                          : outgoing.outgoingTotal == 0
                          ? 0
                          : Math.floor(
                              (connected.connectedTotal * 100) /
                                outgoing.outgoingTotal
                            ) + "%"
                      }
                      isLoading={
                        connectedLoader.isLoading || outgoingLoader.isLoading
                      }
                      fetchAgain={fetchConnected}
                      color={""}
                      Icon={PhoneInTalkOutlinedIcon}
                      bl={0}
                      bb={0}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Unique Connected %"
                      count={connected.connectedUnique!=null && outgoing.outgoingUnique!==null ? Math.ceil(connected.connectedUnique*100/outgoing.outgoingUnique)+"%": null }
                      isLoading={connectedLoader.isLoading || outgoingLoader.isLoading}
                      fetchAgain={fetchConnected}
                      color={""}
                      Icon={PhoneInTalkOutlinedIcon}
                      bl={0}
                      bb={0}
                      br={0}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Unanswered %"
                      count={
                        unanswered.unansweredTotal == null ||
                        outgoing.outgoingTotal == null
                          ? null
                          : outgoing.outgoingTotal == 0
                          ? 0
                          : Math.ceil(
                              (unanswered.unansweredTotal * 100) /
                                outgoing.outgoingTotal
                            ) + "%"
                      }
                      isLoading={
                        unansweredLoader.isLoading || outgoingLoader.isLoading
                      }
                      fetchAgain={fetchUnanswered}
                      color={""}
                      Icon={PhoneInTalkOutlinedIcon}
                      bl={0}
                      bb={0}
                      br={0}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4} lg={6} xl={6} sx={{ mt: 0 }}>
                    <SubLocalCard
                      title="Unique Unanswered %"
                      count={unanswered.unansweredUnique!=null && outgoing.outgoingUnique!==null ? Math.ceil(unanswered.unansweredUnique*100/outgoing.outgoingUnique)+"%": null }
                      isLoading={unansweredLoader.isLoading || outgoingLoader.isLoading}
                      fetchAgain={fetchUnanswered}
                      color={""}
                      Icon={PhoneInTalkOutlinedIcon}
                      bl={0}
                      bb={0}
                      br={0}
                    />
                  </Grid> */}
                </Grid>
              </CardContent>
              <CardActions></CardActions>
            </Card>
          </Grid>
          {/* incoming top agents */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            sx={{
              borderRadius: "10px",
              boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.05)",
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "20px",
                      }}
                    >
                      Incoming Agent Calls
                    </Typography>
                    <Typography
                      sx={
                        {
                          // fontWeight: "600",
                          // fontSize: "16px",
                          // lineHeight: "20px",
                        }
                      }
                      variant="span"
                    >
                      {incomingAgentsLoader.isLoading ? (
                        <CircularProgress size={20} />
                      ) : incomingError ? (
                        <IconButton
                          onClick={() => {
                            fetchTopIncomingAgents(body);
                          }}
                        >
                          <GppBadOutlinedIcon color="error" />
                        </IconButton>
                      ) : incomingTopAgents.length == 0 ? (
                        <Typography
                          variant="span"
                          sx={{ fontSize: "16px", color: "error.main" }}
                        >
                          No record
                        </Typography>
                      ) : null}
                    </Typography>
                  </Box>
                }
              />
              <CardContent>
                <TableContainer style={{ maxHeight: "400px" }}>
                  <Table size="small" stickyHeader aria-label="simple table">
                    <TableHead sx={{ backgroundColor: "" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12px",
                            lineHeight: "15px",
                            backgroundColor:
                              theme.palette.mode === "dark" ? "" : "#F9F9F9",
                            borderWidth: "1px 0px",
                            borderStyle: "solid",
                            borderColor: "#E1E1E1",
                          }}
                          align="center"
                        >
                          Agents
                        </TableCell>
                        <TableCell
                          sx={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12px",
                            lineHeight: "15px",
                            backgroundColor:
                              theme.palette.mode === "dark" ? "" : "#F9F9F9",
                            borderWidth: "1px 0px",
                            borderStyle: "solid",
                            borderColor: "#E1E1E1",
                          }}
                          align="center"
                        >
                          Calls
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {incomingTopAgents?.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              sx={{
                                fontWeight: "500",
                                fontSize: "12px",
                                lineHeight: "15px",
                              }}
                              align="center"
                            >
                              {item.agent_name}
                              <br></br>
                              {item.agent_no}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "500",
                                fontSize: "12px",
                                lineHeight: "15px",
                              }}
                              align="center"
                            >
                              {item.total_calls}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          {/* outgoing top agents */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            sx={{
              borderRadius: "10px",
              boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.05)",
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "20px",
                      }}
                    >
                      Outgoing Agent Calls
                    </Typography>
                    <Typography
                      sx={
                        {
                          // fontWeight: "600",
                          // fontSize: "16px",
                          // lineHeight: "20px",
                        }
                      }
                      variant="span"
                    >
                      {outgoingAgentsLoader.isLoading ? (
                        <CircularProgress size={20} />
                      ) : outgoingError ? (
                        <IconButton
                          onClick={() => {
                            fetchTopOutgoingAgents(body);
                          }}
                        >
                          <GppBadOutlinedIcon color="error" />
                        </IconButton>
                      ) : outgoingTopAgents.length == 0 ? (
                        <Typography
                          variant="span"
                          sx={{ fontSize: "16px", color: "error.main" }}
                        >
                          No record
                        </Typography>
                      ) : null}
                    </Typography>
                  </Box>
                }
              />
              <CardContent>
                <TableContainer style={{ maxHeight: "400px" }}>
                  <Table size="small" stickyHeader aria-label="simple table">
                    <TableHead sx={{}}>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12px",
                            lineHeight: "15px",
                            backgroundColor:
                              theme.palette.mode === "dark" ? "" : "#F9F9F9",
                            borderWidth: "1px 0px",
                            borderStyle: "solid",
                            borderColor: "#E1E1E1",
                          }}
                          align="center"
                        >
                          Agents
                        </TableCell>
                        <TableCell
                          sx={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12px",
                            lineHeight: "15px",
                            backgroundColor:
                              theme.palette.mode === "dark" ? "" : "#F9F9F9",
                            borderWidth: "1px 0px",
                            borderStyle: "solid",
                            borderColor: "#E1E1E1",
                          }}
                          align="center"
                        >
                          Calls
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {outgoingTopAgents?.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              sx={{
                                fontWeight: "500",
                                fontSize: "12px",
                                lineHeight: "15px",
                              }}
                              align="center"
                            >
                              {item.agent_name}
                              <br></br>
                              {item.agent_no}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "500",
                                fontSize: "12px",
                                lineHeight: "15px",
                              }}
                              align="center"
                            >
                              {item.total_calls}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};
