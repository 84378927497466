import * as React from "react";

import { styled, useTheme } from "@mui/material/styles";

import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import zonglogo from "../images/zonglogo.png";
import sidebarlogo from "../images/sidebarlogo.png";

import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MicIcon from "@mui/icons-material/Mic";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import QueueIcon from "@mui/icons-material/Queue";
import { ToastContainer, toast } from "react-toastify";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import TuneIcon from "@mui/icons-material/Tune";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ScheduleIcon from "@mui/icons-material/Schedule";
import GroupIcon from "@mui/icons-material/GroupOutlined";
import NumbersIcon from "@mui/icons-material/Numbers";
import AddBusinessIcon from "@mui/icons-material/AddBusinessOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccountsOutlined";
import PersonIcon from "@mui/icons-material/PersonOutlined";

import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";

import { isAllowed } from "../pages/Functions";

import { MyHeading } from "../components/MyHeading";

import LinearProgress from "@mui/material/LinearProgress";

import { Outlet } from "react-router-dom";

import { useLogoutUserMutation } from "../services/userAuthApi";

import { NavLink, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import CallIcon from "@mui/icons-material/Call";
import ExtensionIcon from "@mui/icons-material/Extension";
import SettingsIcon from "@mui/icons-material/Settings";
import CampaignIcon from "@mui/icons-material/Campaign";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MusicNoteOutlinedIcon from "@mui/icons-material/MusicNoteOutlined";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer(props) {
  const { children } = props;

  const role_id=localStorage.getItem("role_id")

  const [activeTab,setActiveTab]=React.useState(null)

  const isAdmin=useSelector((state)=>state.company.isAdmin);


  const company = useSelector((state) => state.company.company);

  console.log("company", company);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const navigate = useNavigate();

  const [logoutUser, isLoading] = useLogoutUserMutation();

  const authToken = localStorage.getItem("token");

  const userName = () => {
    const userName = localStorage.getItem("name");

    return userName;
  };
  const masterNumber = () => {
    const masterNumber = localStorage.getItem("master_num");

    return masterNumber;
  };

  const handleLogout = async () => {
    localStorage.clear();

    navigate("/login");
    // try {
    //   const res = await logoutUser(authToken).unwrap();

    //   console.log("logout resp", res);

    //   if (res.status === true) {
    //     // localStorage.removeItem("token");
    //     localStorage.clear();

    //     navigate("/login");
    //   } else {
    //     console.log("cant logout status is false");
    //   }
    // } catch (error) {
    //   console.log("cant logout", error);
    // }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [collapseOpen, setCollapseOpen] = React.useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* _____________________________ Header ____________________________________ */}
      {/* backgroundColor:"#F02794",backgroundImage: "linear-gradient(to right, #F02794 , #8DC63F)" */}
      {/* ,width: `calc(100% - ${theme.spacing(7)} + 1px)` */}



      {/* _____________________________ SideBar ____________________________________ */}

      {/* onMouseOver={()=>{setOpen(true)}} onMouseOut={()=>{setOpen(false)}} */}
      <Drawer   sx={{}} variant="permanent" open={open}>
          <Box sx={{
            display:"flex",
            m:"auto",
            mt:0,
            mb:0,
            // justifyContent:"center"
            }}  >
      <img  src={open ? zonglogo : sidebarlogo }></img>

      </Box>
        {/* <Divider /> */}
        {role_id == 1 &&
          isAllowed("management_access") && (
            // <Tooltip title="Management" placement="right">
            <List>
              <ListItemButton onClick={() => setCollapseOpen(!collapseOpen)}>
                <ListItemIcon sx={{ color: "" }}>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="Management" />
                {collapseOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {role_id == 1 &&
                    isAllowed("master_number_access") && (
                      <Tooltip title="Master Numbers" placement="right">
                        <ListItemButton
                          style={({ isActive }) => {
                            return {
                              color: isActive ? "#ED0080" : "",
                              fontWeight: "bold",
                              borderLeft: isActive ? "3px solid #ED0080" :"",
                            };
                          }}
                          to="master-numbers"
                          component={NavLink}
                          // sx={{ pl: 4 }}
                        >
                          <ListItemIcon sx={{ color: "inherit" }}>
                            <NumbersIcon />
                          </ListItemIcon>
                          <ListItemText primary="Master Numbers" />
                        </ListItemButton>
                      </Tooltip>
                    )}
                  {role_id == 1 &&
                    isAllowed("client_access") && (
                      <Tooltip title="Clients" placement="right">
                        <ListItemButton
                          style={({ isActive }) => {
                            return {
                              color: isActive ? "#ED0080" : "",
                              fontWeight: "bold",
                              borderLeft: isActive ? "3px solid #ED0080" :"",
                            };
                          }}
                          to="clients"
                          component={NavLink}
                          // sx={{ pl: 4 }}
                        >
                          <ListItemIcon sx={{ color: "inherit" }}>
                            <AddBusinessIcon />
                          </ListItemIcon>
                          <ListItemText primary="Clients" />
                        </ListItemButton>
                      </Tooltip>
                    )}
                  {role_id == 1 &&
                    isAllowed("user_access") && (
                      <Tooltip title="Users" placement="right">
                        <ListItemButton
                          style={({ isActive }) => {
                            return {
                              color: isActive ? "#ED0080" : "",
                              fontWeight: "bold",
                              borderLeft: isActive ? "3px solid #ED0080" :"",
                            };
                          }}
                          to="users"
                          component={NavLink}
                          // sx={{ pl: 4 }}
                        >
                          <ListItemIcon sx={{ color: "inherit" }}>
                            <PersonIcon />
                          </ListItemIcon>
                          <ListItemText primary="Users" />
                        </ListItemButton>
                      </Tooltip>
                    )}
                  {role_id == 1 &&
                    isAllowed("role_access") && (
                      <Tooltip title="Roles" placement="right">
                        <ListItemButton
                          style={({ isActive }) => {
                            return {
                              color: isActive ? "#ED0080" : "",
                              fontWeight: "bold",
                              borderLeft: isActive ? "3px solid #ED0080" :"",
                            };
                          }}
                          to="roles"
                          component={NavLink}
                          // sx={{ pl: 4 }}
                        >
                          <ListItemIcon sx={{ color: "inherit" }}>
                            <GroupIcon />
                          </ListItemIcon>
                          <ListItemText primary="Roles" />
                        </ListItemButton>
                      </Tooltip>
                    )}
                </List>
              </Collapse>
            </List>
            // </Tooltip>
          )}
        {/* <Divider /> */}
        <List sx={{}}>
          <Tooltip title="Dashboard" placement="right">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
              //   className={({ isActive }) =>{
              //   isActive ? activeLink : undefined
              //   }
              // }
                // disabled={(role_id == 6 || role_id == 5) &&  isAdmin != 1}
                disabled={!isAllowed("dashboard")}
                component={NavLink}
                to="/"
                style={({ isActive }) => {
                  return {
                    // backgroundColor: isActive ? "#8DC63F" : " ",
                    color: isActive ? "#ED0080" : "",
                    fontWeight: "bold",
                    borderLeft: isActive ? "3px solid #ED0080" :"",
                  };
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit",
                  }}
                >
                  <HomeOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </List>
        {/* <Divider /> */}
        <List sx={{}}>
          <Tooltip title="Call Records" placement="right">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                disabled={!isAllowed("calls_access")  }
                component={NavLink}
                to="call-record"
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#ED0080" : "",
                    fontWeight: "bold",
                    borderLeft: isActive ? "3px solid #ED0080" :"",
                  };
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit",
                  }}
                >
                  <CallOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Call Records"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
          <Tooltip title="Call Settings" placement="right">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                component={NavLink}
                to="call-setting"
                disabled={!isAllowed("call_setting_access")  }
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#ED0080" : "",
                    fontWeight: "bold",
                    borderLeft: isActive ? "3px solid #ED0080" :"",
                  };
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit",
                  }}
                >
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Call Settings"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </List>
        {/* <Divider /> */}
        <List>
          <Tooltip title="Extensions" placement="right">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                disabled={!isAllowed("dial_list_access")  }
                component={NavLink}
                to="extensions"
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#ED0080" : "",
                    fontWeight: "bold",
                    borderLeft: isActive ? "3px solid #ED0080" :"",
                  };
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit",
                  }}
                >
                  {/* <ExtensionIcon /> */}
                  <ExtensionOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Extensions"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
          <Tooltip title="Queues" placement="right">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                disabled={!isAllowed("queue_access")  }
                component={NavLink}
                to="queues"
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#ED0080" : "",
                    fontWeight: "bold",
                    borderLeft: isActive ? "3px solid #ED0080" :"",
                  };
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit",
                  }}
                >
                  <QueueOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Queues" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Tooltip>
          <Tooltip title="Music on Hold" placement="right">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                disabled={!isAllowed("music_on_hold_access")  }
                component={NavLink}
                to="music-on-hold"
                style={({ isActive }) => {
                  return {
                    fontWeight: "bold",
                    borderLeft: isActive ? "3px solid #ED0080" :"",
                    color: isActive ? "#ED0080" : "",

                  };
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit",
                  }}
                >
                  <HeadphonesOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Music on Hold"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
          <Tooltip title="System Sound" placement="right">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                disabled={!isAllowed("system_sound_access")  }
                component={NavLink}
                to="system-sounds"
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#ED0080" : "",
                    fontWeight: "bold",
                    borderLeft: isActive ? "3px solid #ED0080" :"",
                  };
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit",
                  }}
                >
                  <VolumeUpOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="System Sounds"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
          <Tooltip title="Announcements" placement="right">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                component={NavLink}
                disabled={!isAllowed("annoucement_access")  }
                to="announcements"
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#ED0080" : "",
                    fontWeight: "bold",
                    borderLeft: isActive ? "3px solid #ED0080" :"",
                  };
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit",
                  }}
                >
                  <CampaignOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Announcements"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>

          <Tooltip title="Time Conditions" placement="right">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                disabled={!isAllowed("time_condition_access")  }
                component={NavLink}
                to="time-conditions"
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#ED0080" : "",
                    fontWeight: "bold",
                    borderLeft: isActive ? "3px solid #ED0080" :"",
                  };
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit",
                  }}
                >
                  <ScheduleIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Time Conditions"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
          <Tooltip title="IVRs" placement="right">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                disabled={!isAllowed("ivr_access")  }
                component={NavLink}
                to="ivrs"
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#ED0080" : "",
                    fontWeight: "bold",
                    borderLeft: isActive ? "3px solid #ED0080" :"",
                  };
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit",
                  }}
                >
                  <MicNoneOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Ivrs" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Tooltip>

          <Tooltip title="Voicemail" placement="right">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                disabled={!isAllowed("voicemail_access")  }
                component={NavLink}
                to="voicemail"
                style={({ isActive }) => {
                  return {
                    color: isActive ? "#ED0080" : "",
                    fontWeight: "bold",
                    borderLeft: isActive ? "3px solid #ED0080" :"",
                  };
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit",
                  }}
                >
                  <VoicemailIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Voicemail"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </List>

      </Drawer>

      {/* _____________________________ Main ____________________________________ */}

      <Box
        component="main"
        sx={{ flexGrow: 1, width: `calc(100% - ${drawerWidth}px)`, }}
      >

        {/* <DrawerHeader /> */}
        {/* <h1>{props?.route?.head}</h1> */}

        {/* <MyHeading>{company.label ? company.label : "Please Select Company"} </MyHeading> */}

        {isLoading.isLoading ? <LinearProgress /> : ""}

        {/* {children} */}
        <Outlet />
      </Box>
    </Box>
  );
}
