import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";


import ReactAudioPlayer from "react-audio-player";

import { isAllowed } from "../Functions";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ReactComponent as IconEdit } from "../../images/editIcon.svg";
import { ReactComponent as IconDelete } from "../../images/deleteIcon.svg";
import { StyledTableCell } from "../call_setting/QueueList";

import { Header } from "../../components/Header";

import {
  useFetchIvrsMutation,
  useCreateIvrsMutation,
  useStoreIvrsMutation,
  useEditIvrsMutation,
  useUpdateIvrsMutation,
  useDeleteIvrsMutation,
} from "../../services/ivrApi";

import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import {
  TableRow,
  Box,
  IconButton,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Switch,
  LinearProgress,
  Typography,
  Button,
  Grid,
  Alert,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TableCell,
  Tooltip,
} from "@mui/material";

import { MyTable } from "../../components/MyTable";

import { StyledTableRow } from "../Style";

import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddBox from "../../components/AddBox";

import { QueueDestination } from "../../destinations/QueueDestination";
import { IvrDestination } from "../../destinations/IvrDestination";
import { DiallistDestination } from "../../destinations/DiallistDestination";
import { TimeConditionDestination } from "../../destinations/TimeConditionDestination";
import { AnnouncementDestination } from "../../destinations/AnnouncementDestination";
import { VoicemailDestination } from "../../destinations/VoicemailDestination";
import { useNavigate } from "react-router-dom";

export const Ivr = () => {
  const isAdmin=useSelector((state)=>state.company.isAdmin);


  const [fetchIvrs, isLoading] = useFetchIvrsMutation();
  const [createIvrs, createLoader] = useCreateIvrsMutation();
  const [storeIvrs, storeLoader] = useStoreIvrsMutation();
  const [editIvrs, editLoader] = useEditIvrsMutation();
  const [updateIvrs, updateLoader] = useUpdateIvrsMutation();
  const [deleteIvrs, deleteLoader] = useDeleteIvrsMutation();

  const isCreateLoading = createLoader.isLoading;
  const isStoreLoading = storeLoader.isLoading;
  const isEditLoading = editLoader.isLoading;
  const isUpdateLoading = updateLoader.isLoading;
  const isDeleteLoading = deleteLoader.isLoading;

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    totalRecords: 0,
    fromRecord: 0,
    toRecord: 0,
    totalPages: 0,
  });

  const [body, setBody] = useState({ page: 1, search: "" });

  const [fullScreen, setFullScreen] = useState(false);

  const [addAlert, setAddAlert] = useState({ status: false, msg: "" });

  const [open, setOpen] = React.useState(false);

  const [addErrors, setAddErrors] = useState({});

  const [title, setTitle] = useState("");

  const [editMode, setEditMode] = useState(false);

  const [sounds, setSounds] = useState([]);

  const [soundFile, setSoundFile] = useState({
    id: "",
    file_Name: "",
  });

  console.log("sound_file", soundFile);

  const [invaildSoundFile, setInvalidSoundFile] = useState({
    id: "",
    file_Name: "",
  });

  const [timeoutSoundFile, setTimeoutSoundFile] = useState({
    id: "",
    file_Name: "",
  });

  const handleClickOpen = () => {
    setTitle("Add New IVR");
    setEditMode(false);
    setOpen(true);
    // setaddFunction(()=>addQueue)
    // setaddLoading(isAddQueueLoading)
  };

  const handleEditOpen = () => {
    setTitle("Edit IVR");
    setEditMode(true);
    setOpen(true);
  };

  const getIvrs = async () => {
    try {
      const response = await fetchIvrs(body).unwrap();

      console.log("Ivr response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result ivrs", response.ivrs);

        setData(response.ivrs.data);

        setPagination({
          ...pagination,
          totalPages: response.ivrs.last_page,
          totalRecords: response.ivrs.total,
          fromRecord: response.ivrs.from,
          toRecord: response.ivrs.to,
          toRecord: response.ivrs.to,
        });

        if (response.ivrs.current_page > response.ivrs.last_page) {
          setBody({ ...body, page: 1 });

          console.log("current_page > last_page");
        }
      } else {
        console.log("____false", response);
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };

  const createIVR = async () => {
    try {
      const response = await createIvrs().unwrap();

      console.log("create ivr response", response);

      if (response.status === true) {
        setSounds(response.systemSoundsOptions);
      } else {
        console.log("____false");
      }
    } catch (error) {
      console.log("____catch", error);
    }
  };

  const storeIVR = async (values) => {
    try {
      const response = await storeIvrs(values).unwrap();

      console.log("ivr store response", response);

      if (response.status === true) {
        getIvrs();

        handleClose();

        toast.success("Record has been added successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);

      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const editIVR = async (id) => {
    try {
      const response = await editIvrs({ id: id }).unwrap();

      console.log("Ivr response", response);

      if (response.status === true) {
        formik.setFieldValue("id", response.ivr?.id);
        formik.setFieldValue("IVR_Name", response.ivr?.IVR_Name);

        formik.setFieldValue("IVR_Sound_ID", response.ivr?.IVR_Sound_ID);
        formik.setFieldValue(
          "Invalid_Sound_ID",
          response.ivr?.Invalid_Sound_ID
        );
        formik.setFieldValue(
          "Timeout_Sound_ID",
          response.ivr?.Timeout_Sound_ID
        );

        setSoundFile(response.ivr?.ivr_sound);
        setInvalidSoundFile(response.ivr?.invalid_sound);
        setTimeoutSoundFile(response.ivr?.timeout_sound);

        formik.setFieldValue("input_length", response.ivr?.DTMFs);
        formik.setFieldValue("input_timeout", response.ivr?.timer);

        formik.setFieldValue("auto_attendant", response.ivr?.auto_attendant);

        if (response.ivr?.TO_App != null) {
          formik.setFieldValue("useTimeDestination", 1);
          formik.setFieldValue("time_destination_type", response.ivr?.TO_App);
          formik.setFieldValue("time_destination_id", response.ivr?.TO_App_ID);

          const array = [...response.ivr?.ivr_data];

          // array.shift()

          console.log("array_test", array);

          // formik.setFieldValue("ivr_count", [...array]);

          for (var i = 0; i < array.length; i++) {
            console.log("i", i);

            formik.setFieldValue(
              `ivr_count[${i}][ivr_key_id]` + i,
              array[i]?.id
            );
            formik.setFieldValue(`ivr_count[${i}][ivr_key]`, array[i]?.DTMF);
            formik.setFieldValue(
              `ivr_count[${i}][ivr_option_name]`,
              array[i]?.DTMF_Name
            );
            formik.setFieldValue(
              `ivr_count[${i}][ivr_option_destination_type]`,
              array[i]?.Application
            );
            formik.setFieldValue(
              `ivr_count[${i}][ivr_option_destination_id]`,
              array[i]?.Application_ID
            );
          }
        } else {
          // formik.setFieldValue("ivr_count", response.ivr?.ivr_data);

          for (var i = 0; i < response.ivr?.ivr_data.length; i++) {
            console.log("i", i);

            formik.setFieldValue(
              `ivr_count[${i}][ivr_key_id]`,
              response.ivr?.ivr_data[i]?.id
            );
            formik.setFieldValue(
              `ivr_count[${i}][ivr_key]`,
              response.ivr?.ivr_data[i]?.DTMF
            );
            formik.setFieldValue(
              `ivr_count[${i}][ivr_option_name]`,
              response.ivr?.ivr_data[i]?.DTMF_Name
            );
            formik.setFieldValue(
              `ivr_count[${i}][ivr_option_destination_type]`,
              response.ivr?.ivr_data[i]?.Application
            );
            formik.setFieldValue(
              `ivr_count[${i}][ivr_option_destination_id]`,
              response.ivr?.ivr_data[i]?.Application_ID
            );
          }
        }

        handleEditOpen();
      } else {
        console.log("____false");
      }
    } catch (error) {
      console.log("____catch", error);
    }
  };

  const updateIVR = async (values) => {
    try {
      const response = await updateIvrs(values).unwrap();

      console.log("update Ivr response", response);

      if (response.status === true) {
        getIvrs();

        handleClose();

        toast.success("Record has been update successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);
      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add annonuce errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const deleteIVR = async (id) => {
    if (window.confirm("are you sure you want to delete this record?")) {
      try {
        const response = await deleteIvrs({ id: id }).unwrap();

        console.log("delete Ivr response", response);

        if (response.status === true) {
          getIvrs();
          toast.success("Record has been deleted successfully !");
        } else {
          console.log("____false", response);

          toast.error(response.message);
        }
      } catch (error) {
        console.log("____catch", error);

        toast.error("Oops something went wrong !");
      }
    }
  };

  const initialValues = {
    IVR_Name: "",
    ivr_count: [
      {
        ivr_key_id: null,

        ivr_key: "",

        ivr_option_name: "",

        ivr_option_destination_type: "",

        ivr_option_destination_id: "",
      },
    ],
    IVR_Sound_ID: null,
    Invalid_Sound_ID: null,
    Timeout_Sound_ID: null,
    input_length: null,
    input_timeout: null,
  };

  const onSubmit = (values, actions) => {
    console.log("form values", values);

    if (editMode) {
      updateIVR(values);
    } else {
      storeIVR(values);
    }
  };

  const validationSchema = Yup.object({
    IVR_Name: Yup.string()
      .required("Required !")
      .matches(
        /^[a-z0-9]+$/i,
        "The name must only contain letters or numbers."
      ),
    IVR_Sound_ID: Yup.number().nullable().required("Required !"),
    Invalid_Sound_ID: Yup.number().nullable().required("Required !"),
    Timeout_Sound_ID: Yup.number().nullable().required("Required !"),
    input_length: Yup.number().nullable().required("Required !"),
    input_timeout: Yup.number().nullable().required("Required !"),
    ivr_count: Yup.array().of(
      Yup.object().shape({
        ivr_key: Yup.number().required("Required !"),
        ivr_option_name: Yup.string().required("Required !"),
        ivr_option_destination_type: Yup.string().required("Required !"),
        ivr_option_destination_id: Yup.string().required("Required !"),
      })
    ),
    // ivr_key_0: Yup.number().required("Required !"),
    // ivr_option_name_0: Yup.string().required("Required !"),
    // ivr_option_destination_0_type: Yup.string().required("Required !"),
    // ivr_option_destination_0_id: Yup.string().required("Required !"),

    // ivr_option_destination_0_type: Yup.string()
    // .when(editMode, {
    //   is: false,
    //   then: Yup.string().required("Required !")
    // }),
    // ivr_option_destination_0_id: Yup.string()
    // .when(editMode, {
    //   is: false,
    //   then: Yup.string().required("Required !")
    // }),

    // 'auto_attendant' => 'sometimes|boolean',
    // 'useTimeDestination' => 'sometimes|boolean',
    // 'time_destination_type'=>'required_if:useTimeDestination,=,1',
    // 'time_destination_id'=>'required_with:time_destination_type',
    // 'ivr_count' => 'required|array',
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    // validateOnMount:true,
  });

  const checkFields = () => {
    formik.values.ivr_count.map((value, index) => {
      validationSchema[`ivr_key_${index}`] =
        Yup.number().required("Required !");

      // ivr_option_name_0 :Yup.string().required("Required !"),
      // ivr_option_destination_0_type :Yup.string().required("Required !"),
      // ivr_option_destination_0_id :Yup.number().required("Required !"),
    });

    // for(var i=0 ; i<formik?.values?.ivr_count){

    //   validationSchema[`ivr_key_${index}`]=Yup.number().required("Required !");

    // }
  };

  console.log("formik", formik);

  const handleClose = () => {
    setOpen(false);
    setAddErrors({});
    setAddAlert({ status: false, msg: "" });
    setSoundFile({ id: "", file_Name: "" });
    setInvalidSoundFile({ id: "", file_Name: "" });
    setTimeoutSoundFile({ id: "", file_Name: "" });

    formik.resetForm();
  };

  const destinations = [
    "Queue",
    "IVR",
    "DialList",
    "TimingCondition",
    "Announcement",
    "VoiceMail",
    "Hangup",
  ];

  const handleCheck = (e, name) => {
    const { checked } = e.target;

    if (checked) {
      formik.setFieldValue(name, 1);
    } else {
      formik.setFieldValue(name, 0);

      formik.setFieldValue("time_destination_type", "");
      formik.setFieldValue("time_destination_id", "");
    }
  };

  const [selectedDestination, setSelectedDestination] = useState("");

  useEffect(() => {
    getIvrs();
  }, [body.page]);

  useEffect(() => {
    createIVR();
  }, []);

  const columns = ["IVR Name", "Number of Options", "IVR Sound", "Action"];

  const navigate = useNavigate();

  if (!isAllowed("ivr_access")  ) {
    return navigate("/forbidden");
  }

  return (
    <>
      <Header title={"Ivrs"} />

      <MyTable
        // title="Ivrs"
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={getIvrs}
        handleClickOpen={handleClickOpen}
        isEditLoading={isEditLoading}
        addButton={isAllowed("ivr_create") || isAdmin == 1 ? true : false}
      >
        {data?.map((item, index) => {
          return (
            <TableRow hover key={index} sx={{}}>
              <StyledTableCell >{item.IVR_Name}</StyledTableCell>
              <StyledTableCell >
                {item.ivr_counter}
              </StyledTableCell>
              {/* <StyledTableCell >{item?.ivr_sound?.file_Name}</StyledTableCell> */}
              <StyledTableCell >
                <Box>{item?.ivr_sound?.file_Name}</Box>
                <ReactAudioPlayer
                  src={
                    item?.ivr_sound?.file_Path
                      ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL +
                        item?.ivr_sound?.file_Path.replace(".gsm", "mp3.mp3")
                      : ""
                  }
                  controls
                />
              </StyledTableCell>
              {/* <StyledTableCell >
              <Box>{item?.invalid_sound?.file_Name}</Box>
                <ReactAudioPlayer
                    src={item?.invalid_sound?.file_Path ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL+item?.invalid_sound?.file_Path.replace(".gsm", "mp3.mp3") : ""}
                    controls
                  />
              </StyledTableCell>
              <StyledTableCell >
              <Box>{item?.timeout_sound?.file_Name}</Box>
                <ReactAudioPlayer
                    src={item?.timeout_sound?.file_Path ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL+item?.timeout_sound?.file_Path.replace(".gsm", "mp3.mp3") : ""}
                    controls
                  />
              </StyledTableCell> */}

              <StyledTableCell >
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    // sx={{ m: 1 }}
                    color={"info"}
                    variant="contained"
                    onClick={() => editIVR(item.id)}
                    disabled={!isAllowed("ivr_edit")  }
                  >
                    <IconEdit />
                  </IconButton>
                </Tooltip>{" "}
                <Tooltip title="Delete">
                  <IconButton
                    disabled={!isAllowed("ivr_delete")  }
                    size="small"
                    // sx={{ m: 1 }}
                    color={"error"}
                    variant="contained"
                    onClick={() => deleteIVR(item.id)}
                  >
                    <IconDelete stroke="red" />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </MyTable>

      {/* ________Add Box___________________ */}
      <AddBox
        title={title}
        open={open}
        handleClose={handleClose}
        formik={formik}
        // fullScreen={true}
        fullWidth={true}
        style={{
          maxWidth: "100%",
          height: "100vh",
          // maxHeight: "100vh",
          boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
        }}
      >
        {addAlert.status ? (
          <Alert severity="error">{addAlert.msg}</Alert>
        ) : null}

        {isStoreLoading || (isUpdateLoading && <LinearProgress />)}

        <Grid sx={{}} container spacing={2}>
          <Grid xs={12} sm={12} md={3} lg={3} xl={3} item>
            <TextField
              error={
                (formik.touched.IVR_Name && formik.errors.IVR_Name) ||
                addErrors?.IVR_Name
              }
              sx={{
                width : "100%",
              }}
              // margin="normal"
              size="small"
              // sx={{m:1}}
              required
              label={

                <Typography
                variant="span"
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
                >
                  Ivr Name

                </Typography>
              }
              id="IVR_Name"
              name="IVR_Name"
              {...formik.getFieldProps("IVR_Name")}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.IVR_Name && formik.errors.IVR_Name ? (
              <Alert severity="error">{formik.errors.IVR_Name}</Alert>
            ) : null}
            {addErrors?.IVR_Name && (
              <Alert severity="error">{addErrors?.IVR_Name[0]}</Alert>
            )}
          </Grid>

          <Grid xs={12} sm={12} md={3} lg={3} xl={3} item>
            <FormControl size="small" fullWidth 
            // margin="normal"
            >
              <InputLabel sx={{}} id="cat-select-label">
              

<Typography
variant="span"
sx={{
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "16px",
}}
>
  Input Timeout

</Typography>

              </InputLabel>

              <Select
                labelId="cat-select-label"
                label="Input Timeout"
                id="input_timeout"
                name="input_timeout"
                {...formik.getFieldProps("input_timeout")}
                error={
                  (formik.touched.input_timeout &&
                    formik.errors.input_timeout) ||
                  addErrors?.input_timeout
                }
              >
                <MenuItem value="3">3 secs</MenuItem>
                <MenuItem value="6">6 secs</MenuItem>
                <MenuItem value="9">9 secs</MenuItem>
              </Select>
            </FormControl>
            {formik.touched.input_timeout && formik.errors.input_timeout ? (
              <Alert severity="error">{formik.errors.input_timeout}</Alert>
            ) : null}
            {addErrors?.input_timeout && (
              <Alert severity="error">{addErrors?.input_timeout[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={3} lg={3} xl={3} item>
            <FormControl size="small" fullWidth 
            // margin="normal"
            >
              <InputLabel sx={{}} id="cat-select-label">

<Typography
variant="span"
sx={{
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "16px",
}}
>
  Input Length

</Typography>
              </InputLabel>

              <Select
                labelId="cat-select-label"
                label="Input Lenght"
                id="input_length"
                name="input_length"
                // {...formik.getFieldProps("input_length")}
                error={
                  (formik.touched.input_length && formik.errors.input_length) ||
                  addErrors?.input_length
                }
                value={formik.values.input_length}
                onBlur={formik.handleBlur}
                onFocus={() => {
                  setAddErrors({});
                }}
                onChange={(e) => {
                  formik.setFieldValue("input_length", e.target.value);

                  if (e.target.value == 1 || e.target.value == 2) {
                    formik.setFieldValue("auto_attendant", 0);
                  }
                }}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
              </Select>
            </FormControl>
            {formik.touched.input_length && formik.errors.input_length ? (
              <Alert severity="error">{formik.errors.input_length}</Alert>
            ) : null}
            {addErrors?.input_length && (
              <Alert severity="error">{addErrors?.input_length[0]}</Alert>
            )}
          </Grid>

          {formik?.values?.input_length == 3 ||
          formik?.values?.input_length == 4 ? (
            <Grid xs={12} sm={12} md={3} lg={3} xl={3} item>
            <FormControl fullWidth >
              <FormGroup
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  pt:1,
                }}
                row
              >
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Auto Attendant
                </Typography>
                  <FormControlLabel
                    onChange={(e) => {
                      handleCheck(e, "auto_attendant");
                    }}
                    // label="Auto Attendant"
                    control={
                      <Switch
                        size="small"
                        checked={formik.values.auto_attendant == 1}
                        color="primary"
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          ) : null}

          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Autocomplete
              // disabled={editMode}
              id="sounFile"
              options={sounds}
              getOptionLabel={(option) => option.file_Name}
              // defaultValue={[moh[0]]}
              renderInput={(params) => (
                <TextField
                  error={
                    (formik.touched.IVR_Sound_ID &&
                      formik.errors.IVR_Sound_ID) ||
                    addErrors?.IVR_Sound_ID
                  }
                  {...params}
                  label={

                    <Typography
                    variant="span"
                    sx={{
                      fontWeight: 500,
                      fontSize: "13px",
                      lineHeight: "16px",
                    }}
                    >
                      Ivr Sound
    
                    </Typography>
                  }
                  // placeholder="Music on Hold"
                  {...formik.getFieldProps("IVR_Sound_ID")}
                />
              )}
              size="small"
              // margin="normal"
              // sx={{ width: "500px" }}
              onChange={(e, value) => {
                console.log("ivr sound value ", value);
                formik.setFieldValue("IVR_Sound_ID", value?.id);
                setSoundFile(value);
              }}
              value={soundFile || { id: "", file_Name: "" }}
              name="IVR_Sound_ID"
              // {...formik.getFieldProps("musiconhold_id")}
            />
            {formik.touched.IVR_Sound_ID && formik.errors.IVR_Sound_ID ? (
              <Alert severity="error">{formik.errors.IVR_Sound_ID}</Alert>
            ) : null}
            {addErrors?.IVR_Sound_ID && (
              <Alert severity="error">{addErrors?.IVR_Sound_ID[0]}</Alert>
            )}
            {isCreateLoading ? <LinearProgress /> : null}
          </Grid>

          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            {/* <Box sx={{fontWeight:"bolder"}} >IVR Sound</Box> */}
            <ReactAudioPlayer
              src={
                soundFile?.file_Path
                  ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL +
                    soundFile?.file_Path.replace(".gsm", "mp3.mp3")
                  : ""
              }
              controls
            />
          </Grid>

          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Autocomplete
              // disabled={editMode}
              id="Invalid_Sound_ID"
              options={sounds}
              getOptionLabel={(option) => option.file_Name}
              // defaultValue={[moh[0]]}
              renderInput={(params) => (
                <TextField
                  error={
                    (formik.touched.Invalid_Sound_ID &&
                      formik.errors.Invalid_Sound_ID) ||
                    addErrors?.Invalid_Sound_ID
                  }
                  {...params}
                  label={

                    <Typography
                    variant="span"
                    sx={{
                      fontWeight: 500,
                      fontSize: "13px",
                      lineHeight: "16px",
                    }}
                    >
                      Invaid Sound
    
                    </Typography>
                  }
                  // placeholder="Music on Hold"
                  {...formik.getFieldProps("Invalid_Sound_ID")}
                />
              )}
              size="small"
              // margin="normal"
              // sx={{ width: "500px" }}
              onChange={(e, value) => {
                console.log("Invalid_Sound_ID value ", value);
                formik.setFieldValue("Invalid_Sound_ID", value?.id);
                setInvalidSoundFile(value);
              }}
              value={invaildSoundFile || { id: "", file_Name: "" }}
              name="Invalid_Sound_ID"
              // {...formik.getFieldProps("musiconhold_id")}
            />
            {formik.touched.Invalid_Sound_ID &&
            formik.errors.Invalid_Sound_ID ? (
              <Alert severity="error">{formik.errors.Invalid_Sound_ID}</Alert>
            ) : null}
            {addErrors?.Invalid_Sound_ID && (
              <Alert severity="error">{addErrors?.Invalid_Sound_ID[0]}</Alert>
            )}
            {isCreateLoading ? <LinearProgress /> : null}
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            {/* <Box sx={{fontWeight:"bolder"}} >IVR Sound</Box> */}
            <ReactAudioPlayer
              src={
                invaildSoundFile?.file_Path
                  ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL +
                    invaildSoundFile?.file_Path.replace(".gsm", "mp3.mp3")
                  : ""
              }
              controls
            />
          </Grid>

          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Autocomplete
              // disabled={editMode}
              id="Timeout_Sound_ID"
              options={sounds}
              getOptionLabel={(option) => option.file_Name}
              // defaultValue={[moh[0]]}
              renderInput={(params) => (
                <TextField
                  error={
                    (formik.touched.Timeout_Sound_ID &&
                      formik.errors.Timeout_Sound_ID) ||
                    addErrors?.Timeout_Sound_ID
                  }
                  {...params}
                  label={

                    <Typography
                    variant="span"
                    sx={{
                      fontWeight: 500,
                      fontSize: "13px",
                      lineHeight: "16px",
                    }}
                    >
                      Timeout Sound
    
                    </Typography>
                  }
                  // placeholder="Music on Hold"
                  {...formik.getFieldProps("Timeout_Sound_ID")}
                />
              )}
              size="small"
              // margin="normal"
              // sx={{ width: "500px" }}
              onChange={(e, value) => {
                console.log("Timeout_Sound_ID value ", value);
                formik.setFieldValue("Timeout_Sound_ID", value?.id);
                setTimeoutSoundFile(value);
              }}
              value={timeoutSoundFile || { id: "", file_Name: "" }}
              name="Timeout_Sound_ID"
              // {...formik.getFieldProps("musiconhold_id")}
            />
            {formik.touched.Timeout_Sound_ID &&
            formik.errors.Timeout_Sound_ID ? (
              <Alert severity="error">{formik.errors.Timeout_Sound_ID}</Alert>
            ) : null}
            {addErrors?.Timeout_Sound_ID && (
              <Alert severity="error">{addErrors?.Timeout_Sound_ID[0]}</Alert>
            )}
            {isCreateLoading ? <LinearProgress /> : null}
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            {/* <Box sx={{fontWeight:"bolder"}} >IVR Sound</Box> */}
            <ReactAudioPlayer
              src={
                timeoutSoundFile?.file_Path
                  ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL +
                    timeoutSoundFile?.file_Path.replace(".gsm", "mp3.mp3")
                  : ""
              }
              controls
            />
          </Grid>







          <Grid sx={{}} xs={12} sm={12} md={4} lg={4} xl={4} item>
          <FormControl fullWidth >
              <FormGroup
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  pt:1
                }}
                row
              >
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Time Destination
                </Typography>
                <FormControlLabel
                  onChange={(e) => {
                    handleCheck(e, "useTimeDestination");
                  }}
                  // label="Time Destination"
                  control={
                    <Switch
                      size="small"
                      checked={formik.values.useTimeDestination == 1}
                      color="primary"
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>

          {formik.values?.useTimeDestination == 1 ?
          <>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{}}>
                  <Box sx={{ 
                    // mr: 1,
                     mb: 1 }}>
                    <Autocomplete
                      fullWidth={true}
                      size="small"
                      value={formik.values["time_destination_type"] || ""}
                      onChange={(event, value) => {
                        console.log("value", value);
                        formik.setFieldValue("time_destination_type", value);

                        formik.setFieldValue("time_destination_id", "");
                      }}
                      id={"time_destination_type"}
                      options={destinations}
                      renderInput={(params) => (
                        <TextField
                          {...formik.getFieldProps("time_destination_type")}
                          {...params}
                          label={

                            <Typography
                            variant="span"
                            sx={{
                              fontWeight: 500,
                              fontSize: "13px",
                              lineHeight: "16px",
                            }}
                            >
                              Destination Type
            
                            </Typography>
                          }
                          error={
                            (formik.touched["time_destination_type"] &&
                              formik.errors["time_destination_type"]) ||
                            addErrors["time_destination_type"]
                          }
                        />
                      )}
                      label="Destination Type"
                      name={"time_destination_type"}
                    />
                    {formik.touched["time_destination_type"] &&
                    formik.errors["time_destination_type"] ? (
                      <Alert severity="error">
                        {formik.errors["time_destination_type"]}
                      </Alert>
                    ) : null}
                    {addErrors["time_destination_type"] && (
                      <Alert severity="error">
                        {addErrors["time_destination_type"]?.[0]}
                      </Alert>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{}}>
                  <Box sx={{ 
                    // mr: 1
                   }}>
                    {formik.values[`time_destination_type`] == "Queue" && (
                      <QueueDestination
                        formik={formik}
                        addErrors={addErrors}
                        name={`time_destination_id`}
                      />
                    )}
                    {formik.values[`time_destination_type`] == "IVR" && (
                      <IvrDestination
                        formik={formik}
                        name={`time_destination_id`}
                        addErrors={addErrors}
                      />
                    )}
                    {formik.values[`time_destination_type`] == "DialList" && (
                      <DiallistDestination
                        formik={formik}
                        name={`time_destination_id`}
                        addErrors={addErrors}
                      />
                    )}
                    {formik.values[`time_destination_type`] ==
                      "TimingCondition" && (
                      <TimeConditionDestination
                        formik={formik}
                        name={`time_destination_id`}
                        addErrors={addErrors}
                      />
                    )}
                    {formik.values[`time_destination_type`] ==
                      "Announcement" && (
                      <AnnouncementDestination
                        formik={formik}
                        name={`time_destination_id`}
                        addErrors={addErrors}
                      />
                    )}
                    {formik.values[`time_destination_type`] == "Hangup" && (
                      <>
                        <TextField
                          size="small"
                          sx={{
                            width:"100%"
                          }}
                          name={`time_destination_id`}
                          {...formik.getFieldProps(`time_destination_id`)}
                          label={

                            <Typography
                            variant="span"
                            sx={{
                              fontWeight: 500,
                              fontSize: "13px",
                              lineHeight: "16px",
                            }}
                            >
                              Input Text
            
                            </Typography>
                          }
                          error={
                            (formik.touched[`time_destination_id`] &&
                              formik.errors[`time_destination_id`]) ||
                            addErrors[`time_destination_id`]
                          }
                        />
                        {formik.touched[`Invalid_Sound_ID`] &&
                        formik.errors[`time_destination_id`] ? (
                          <Alert severity="error">
                            {formik.errors[`time_destination_id`]}
                          </Alert>
                        ) : null}
                        {addErrors[`time_destination_id`] && (
                          <Alert severity="error">
                            {addErrors[`time_destination_id`]?.[0]}
                          </Alert>
                        )}
                      </>
                    )}

                    {formik.values[`time_destination_type`] == "VoiceMail" && (
                      <VoicemailDestination
                        formik={formik}
                        name={`time_destination_id`}
                        addErrors={addErrors}
                      />
                    )}
                  </Box>
                </Grid>

            </>
          : null }

          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <Grid spacing={2} container>
              {formik.values.ivr_count.map((option, index) => {
                return (
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    item
                    key={index}
                  >
                    <Grid spacing={2} container>
                      <Grid xs={12} sm={12} md={2} lg={2} xl={2} item>
                        <TextField
                          type={"number"}
                          InputProps={{ inputProps: { min: 0, max: 9 } }}
                          // sx={{ mr: 1 }}
                          sx={{width : "100%"}}
                          error={
                            (formik.touched?.ivr_count?.[index]?.["ivr_key"] &&
                              formik.errors?.ivr_count?.[index]?.["ivr_key"]) ||
                            addErrors[`ivr_key_${index}`]
                          }
                          size="small"
                          label={

                            <Typography
                            variant="span"
                            sx={{
                              fontWeight: 500,
                              fontSize: "13px",
                              lineHeight: "16px",
                            }}
                            >
                              Key
            
                            </Typography>
                          }
                          id={`ivr_count[${index}][ivr_key]`}
                          name={`ivr_count[${index}][ivr_key]`}
                          {...formik.getFieldProps(
                            `ivr_count[${index}][ivr_key]`
                          )}
                          onFocus={() => {
                            setAddErrors({});
                          }}
                        />
                        {formik.touched?.ivr_count?.[index]?.["ivr_key"] &&
                        formik.errors?.ivr_count?.[index]?.["ivr_key"] ? (
                          <Alert severity="error">
                            {formik.errors?.ivr_count?.[index]?.["ivr_key"]}
                          </Alert>
                        ) : null}
                        {addErrors[`ivr_key_${index}`] && (
                          <Alert severity="error">
                            {addErrors[`ivr_key_${index}`]?.[0]}
                          </Alert>
                        )}
                      </Grid>
                      <Grid xs={12} sm={12} md={3} lg={3} xl={3} item>
                        <TextField
                          // sx={{ mr: 1 }}
                          sx={{width : "100%"}}
                          error={
                            (formik.touched?.ivr_count?.[index]?.[
                              "ivr_option_name"
                            ] &&
                              formik.errors?.ivr_count?.[index]?.[
                                "ivr_option_name"
                              ]) ||
                            addErrors[`ivr_count[${index}][ivr_option_name]`]
                          }
                          // margin="normal"
                          size="small"
                          label={

                            <Typography
                            variant="span"
                            sx={{
                              fontWeight: 500,
                              fontSize: "13px",
                              lineHeight: "16px",
                            }}
                            >
                             Option Name
            
                            </Typography>
                          }
                          id={`ivr_count[${index}][ivr_option_name]`}
                          name={`ivr_count[${index}][ivr_option_name]`}
                          {...formik.getFieldProps(
                            `ivr_count[${index}][ivr_option_name]`
                          )}
                          onFocus={() => {
                            setAddErrors({});
                          }}
                        />
                        {formik.touched?.ivr_count?.[index]?.[
                          "ivr_option_name"
                        ] &&
                        formik.errors?.ivr_count?.[index]?.[
                          "ivr_option_name"
                        ] ? (
                          <Alert severity="error">
                            {
                              formik.errors?.ivr_count?.[index]?.[
                                "ivr_option_name"
                              ]
                            }
                          </Alert>
                        ) : null}
                        {addErrors[`ivr_count[${index}][ivr_option_name]`] && (
                          <Alert severity="error">
                            {
                              addErrors[
                                `ivr_count[${index}][ivr_option_name]`
                              ]?.[0]
                            }
                          </Alert>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{}}>
                        <Box 
                        // sx={{ mr: 1 }}
                        >
                          <Autocomplete
                            fullWidth={true}
                            size="small"
                            value={
                              formik.values.ivr_count[index]?.[
                                "ivr_option_destination_type"
                              ] || ""
                            }
                            onChange={(event, value) => {
                              console.log("value", value);
                              formik.setFieldValue(
                                `ivr_count[${index}][ivr_option_destination_type]`,
                                value
                              );

                              // formik.setFieldValue(
                              //   `ivr_count[${index}][ivr_option_destination_id]`,
                              //   ""
                              // );
                            }}
                            id={`ivr_count[${index}][ivr_option_destination_type]`}
                            options={destinations}
                            renderInput={(params) => (
                              <TextField
                                {...formik.getFieldProps(
                                  `ivr_count[${index}][ivr_option_destination_type]`
                                )}
                                {...params}
                                label={

                                  <Typography
                                  variant="span"
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "13px",
                                    lineHeight: "16px",
                                  }}
                                  >
                                    Destination Type
                  
                                  </Typography>
                                }
                                error={
                                  (formik.touched?.ivr_count?.[index]?.[
                                    "ivr_option_destination_type"
                                  ] &&
                                    formik.errors?.ivr_count?.[index]?.[
                                      "ivr_option_destination_type"
                                    ]) ||
                                  addErrors[
                                    `ivr_count[${index}][ivr_option_destination_type]`
                                  ]
                                }
                              />
                            )}
                            label="Destination Type"
                            name={`ivr_count[${index}][ivr_option_destination_type]`}
                          />
                          {formik.touched?.ivr_count?.[index]?.[
                            "ivr_option_destination_type"
                          ] &&
                          formik.errors?.ivr_count?.[index]?.[
                            "ivr_option_destination_type"
                          ] ? (
                            <Alert severity="error">
                              {
                                formik.errors?.ivr_count?.[index]?.[
                                  "ivr_option_destination_type"
                                ]
                              }
                            </Alert>
                          ) : null}
                          {addErrors[
                            `ivr_count[${index}][ivr_option_destination_type]`
                          ] && (
                            <Alert severity="error">
                              {
                                addErrors[
                                  `ivr_count[${index}][ivr_option_destination_type]`
                                ]?.[0]
                              }
                            </Alert>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{}}>
                        <Box 
                        // sx={{ mr: 1 }}
                        >
                          {formik.values.ivr_count[index][
                            `ivr_option_destination_type`
                          ] == "Queue" && (
                            <QueueDestination
                              formik={formik}
                              addErrors={addErrors}
                              name={`ivr_count[${index}][ivr_option_destination_id]`}
                              index={index}
                            />
                          )}
                          {formik.values.ivr_count[index][
                            `ivr_option_destination_type`
                          ] == "IVR" && (
                            <IvrDestination
                              formik={formik}
                              name={`ivr_count[${index}][ivr_option_destination_id]`}
                              addErrors={addErrors}
                              index={index}
                            />
                          )}
                          {formik.values.ivr_count[index][
                            `ivr_option_destination_type`
                          ] == "DialList" && (
                            <DiallistDestination
                              formik={formik}
                              name={`ivr_count[${index}][ivr_option_destination_id]`}
                              addErrors={addErrors}
                              index={index}
                            />
                          )}
                          {formik.values.ivr_count[index][
                            `ivr_option_destination_type`
                          ] == "TimingCondition" && (
                            <TimeConditionDestination
                              formik={formik}
                              name={`ivr_count[${index}][ivr_option_destination_id]`}
                              addErrors={addErrors}
                              index={index}
                            />
                          )}
                          {formik.values.ivr_count[index][
                            `ivr_option_destination_type`
                          ] == "Announcement" && (
                            <AnnouncementDestination
                              formik={formik}
                              name={`ivr_count[${index}][ivr_option_destination_id]`}
                              addErrors={addErrors}
                              index={index}
                            />
                          )}
                          {formik.values.ivr_count[index][
                            `ivr_option_destination_type`
                          ] == "Hangup" && (
                            <>
                              <TextField
                                sx={{
                                  width:"100%"
                                }}
                                size="small"
                                name={`ivr_count[${index}][ivr_option_destination_id]`}
                                {...formik.getFieldProps(
                                  `ivr_count[${index}][ivr_option_destination_id]`
                                )}
                                label={

                                  <Typography
                                  variant="span"
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "13px",
                                    lineHeight: "16px",
                                  }}
                                  >
                                    Input Text
                  
                                  </Typography>
                                }
                                error={
                                  (formik.touched?.ivr_count?.[index]?.[
                                    "ivr_option_destination_id"
                                  ] &&
                                    formik.errors?.ivr_count?.[index]?.[
                                      "ivr_option_destination_id"
                                    ]) ||
                                  addErrors[
                                    `ivr_count[${index}][ivr_option_destination_id]`
                                  ]
                                }
                              />
                              {formik.touched?.ivr_count?.[index]?.[
                                "ivr_option_destination_id"
                              ] &&
                              formik.errors?.ivr_count?.[index]?.[
                                "ivr_option_destination_id"
                              ] ? (
                                <Alert severity="error">
                                  {
                                    formik.errors?.ivr_count?.[index]?.[
                                      "ivr_option_destination_id"
                                    ]
                                  }
                                </Alert>
                              ) : null}
                              {addErrors[
                                `ivr_count[${index}][ivr_option_destination_id]`
                              ] && (
                                <Alert severity="error">
                                  {
                                    addErrors[
                                      `ivr_count[${index}][ivr_option_destination_id]`
                                    ]?.[0]
                                  }
                                </Alert>
                              )}
                            </>
                          )}

                          {formik.values.ivr_count[index][
                            `ivr_option_destination_type`
                          ] == "VoiceMail" && (
                            <VoicemailDestination
                              formik={formik}
                              name={`ivr_count[${index}][ivr_option_destination_id]`}
                              addErrors={addErrors}
                            />
                          )}
                        </Box>
                      </Grid>

                      <Grid xs={12} sm={12} md={1} lg={1} xl={1} item>
                        <Box sx={{ display: "flex" }}>
                          {formik.values.ivr_count.length > 1 ? (
                            <Button
                              size="small"
                              onClick={() => {
                                formik.values.ivr_count.splice(index, 1);

                                formik.setFieldValue(
                                  "ivr_count",
                                  formik.values.ivr_count
                                );
                              }}
                            >
                              <RemoveCircleIcon color="error" />
                            </Button>
                          ) : null}
                          {index == formik.values.ivr_count.length - 1 &&
                          index < 9 ? (
                            <Button
                              // disabled={editMode}
                              size="small"
                              onClick={() => {
                                formik.setFieldValue("ivr_count", [
                                  ...formik.values.ivr_count,
                                  "",
                                ]);
                              }}
                            >
                              <AddCircleIcon color="secondary" />
                            </Button>
                          ) : null}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </AddBox>
    </>
  );
};
