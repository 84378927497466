import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import { useFetchCallSettingMutation } from "../../services/callSettingApi";
import { useStoreCallSettingMutation } from "../../services/callSettingApi";
import { styled } from "@mui/material/styles";
import { CardActions, Card, CardHeader, CardContent } from "@mui/material";
import { QueueList } from "./QueueList";
import { DialList } from "./DialList";
import { TimeConditionList } from "./TimeConditionList";
import { IvrList } from "./IvrList";
import { VoicemailList } from "./VoicemailList";
import { AnnouncementList } from "./AnnouncementList";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Header } from "../../components/Header";

import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Checkbox,
  LinearProgress,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";

import { isAllowed } from "../Functions";
import { useNavigate } from "react-router-dom";

export const CallSetting = () => {
  const myRef = useRef(null);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [fetchCallSetting, isLoading] = useFetchCallSettingMutation();

  const [storeCallSetting] = useStoreCallSettingMutation();

  const [data, setData] = useState([]);

  const [edit, setEdit] = useState(false);

  const [alert, setAlert] = useState(false);

  const [selectedDestination, setSelectedDestination] = useState("");

  const [body, setBody] = useState({ entry_app_id: "", entry_app_type: "" });

  const executeScroll = () => {
    myRef.current.scrollIntoView();
    console.log("heeloo");
  };
  // run this function from an event handler or an effect to execute scroll

  const getCallSetting = async () => {
    try {
      const response = await fetchCallSetting().unwrap();

      console.log("call setting response", response);

      if (response.status === true) {
        setAlert(false);

        setBody(response.call_setting);
      } else {
        console.log("____false", response);
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };

  const saveCallSetting = async () => {
    try {
      const response = await storeCallSetting(body).unwrap();

      console.log("store call setting response", response);

      if (response.status === true) {
        setAlert(false);

        toast.success("Call setting has been updated !");
      } else {
        console.log("____false", response);

        toast.error("Can't update Call Setting !");

        // setData([]);
        // setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      // setData([]);
      // setAlert(true);
      toast.error("Can't update Call Setting !");
    }
  };

  useEffect(() => {
    getCallSetting();
  }, []);

  // const options = [
  //   {
  //     title: "queue",
  //     id: 1,
  //   },
  //   {
  //     title: "diallist",
  //     id: 2,
  //   },
  // ];

  // const defaultProps = {
  //   options: options,
  //   getOptionLabel: (option) => option.title,
  // };

  const destinations = [
    "Queue",
    "IVR",
    "DialList",
    "TimingCondition",
    "Announcement",
    "VoiceMail",
    "Hangup",
  ];

  const getSelectedDestination = (name) => {
    setSelectedDestination(name);
  };

  const saveChanges = () => {
    // if (window.confirm("Are you sure you want to change setting?")) {
    saveCallSetting();

    setEdit(false);
    // }
  };

  const [checked, setChecked] = React.useState(body.entry_app_type);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const isAdmin=useSelector((state)=>state.company.isAdmin);


  const navigate = useNavigate();

  if (!isAllowed("call_setting_access")  ) {
    return navigate("/forbidden");
  }

  return (
    <>
      <Header title={"Incoming Call Setting"} />

      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Card
            sx={{
              border: "1px solid #F2F2F2",
              boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.05)",
              borderRadius: "10px",
              m: 1,
              p: "25px",
            }}
            variant="outlined"
          >
            <CardHeader
              title={
                <>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "18px",
                      // pl:"25px",
                      // pt:"25px",
                    }}
                  >
                    Current Destination
                  </Typography>
                </>
              }
            />
            <CardContent>
              <Typography
                component="div"
                sx={{
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "30px",
                  color: "secondary.main",
                  // pl:"25px",
                  // pb:"25px",
                }}
              >
                {body.entry_app_type} :{" "}
                {body.entry_app_type == "Hangup"
                  ? body.entry_app_id
                  : selectedDestination}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Box sx={{ mt: "auto" }}>
            <Button
              sx={{ mx: 1, mb: 1 }}
              disabled={!isAllowed("call_setting_edit")  }
              onClick={() => {
                setEdit(true);
                setOpen(true);
              }}
              variant={"contained"}
              color="primary"
            >
              Change Destination
            </Button>
          </Box>
        </Grid>
      </Grid>

      {isLoading?.isLoading ? (
        <LinearProgress />
      ) : alert ? (
        <Box sx={{ mt: 1 }} display="flex" justifyContent="center">
          <Typography sx={{ color: "error.main", fontWeight: "bold" }}>
            Oops Something went wrong !
          </Typography>
        </Box>
      ) : (
        ""
      )}

      <Dialog
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          sx: {
            maxWidth: "100%",
            boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
            borderRadius: "10px",
            height: "100vh",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "20px",
            }}
          >
            Change Destination
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={0} sx={{}}>
            <Grid
              sx={{ borderRight: "1px solid #F2F2F2" }}
              item
              xs={12}
              sm={12}
              md={4}
              lg={2}
              xl={2}
            >
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {destinations?.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      onClick={() => {
                        setBody({
                          ...body,
                          entry_app_type: value,
                          entry_app_id: null,
                        });
                        setSelectedDestination("");
                      }}
                      sx={{
                        borderBottom: "1px solid #F2F2F2",
                        padding: "5px 13px 5px 17px",
                        gap: "77px",
                        background:
                          body.entry_app_type == value ? "#FBFBFB" : "",
                        color: body.entry_app_type == value ? "black" : "",
                        fontWeight:
                          body.entry_app_type == value ? "700" : "500",
                      }}
                      secondaryAction={
                        <ChevronRightOutlinedIcon
                          sx={{
                            color:
                              body.entry_app_type == value
                                ? "black"
                                : "#767676",
                          }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemText
                          id={labelId}
                          primary={
                            <>
                              <Typography
                                sx={{
                                  fontWeight:
                                    body.entry_app_type == value
                                      ? "700"
                                      : "500",
                                  fontSize: "12px",
                                  lineHeight: "15px",
                                  color:
                                    body.entry_app_type == value
                                      ? "black"
                                      : "#767676",
                                }}
                              >
                                {value}
                              </Typography>
                            </>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={10} xl={10}>
              {/* <Grid container spacing={2} sx={{}}> */}
              {body.entry_app_type == "Queue" ? (
                <QueueList
                  getSelectedDestination={getSelectedDestination}
                  // app_id={body.entry_app_id}
                  app={body}
                  setApp={setBody}
                />
              ) : body.entry_app_type == "TimingCondition" ? (
                <TimeConditionList
                  getSelectedDestination={getSelectedDestination}
                  // app_id={body.entry_app_id}
                  app={body}
                  setApp={setBody}
                />
              ) : body.entry_app_type == "DialList" ? (
                <DialList
                  getSelectedDestination={getSelectedDestination}
                  // app_id={body.entry_app_id}
                  app={body}
                  setApp={setBody}
                />
              ) : body.entry_app_type == "IVR" ? (
                <IvrList
                  getSelectedDestination={getSelectedDestination}
                  // app_id={body.entry_app_id}
                  app={body}
                  setApp={setBody}
                />
              ) : body.entry_app_type == "Announcement" ? (
                <AnnouncementList
                  getSelectedDestination={getSelectedDestination}
                  app={body}
                  setApp={setBody}
                />
              ) : body.entry_app_type == "VoiceMail" ? (
                <VoicemailList app={body} setApp={setBody} />
              ) : body.entry_app_type == "Hangup" ? (
                <TextField
                  label="Input Text"
                  value={body.entry_app_id || ""}
                  onChange={(e) =>
                    setBody({ ...body, entry_app_id: e.target.value })
                  }
                />
              ) : (
                ""
              )}
              {/* </Grid> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              // color: "white",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "15px",
              padding: "8px 16px",
              gap: "19px",
              width: "98px",
              height: "31px",
            }}
            color={"primary"}
            variant="outlined"
            // onClick={handleClose}
            onClick={() => {
              getCallSetting();
              setEdit(false);
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            color={"primary"}
            size="small"
            variant="contained"
            sx={{
              color: "white",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "15px",
              padding: "8px 16px",
              gap: "19px",
              width: "98px",
              height: "31px",
            }}
            disabled={
              body.entry_app_id == "" ||
              body.entry_app_id == null ||
              body.entry_app_type == null ||
              (!isAllowed("call_setting_edit")  )
            }
            onClick={() => {
              saveChanges();
              setEdit(false);
              handleClose();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
