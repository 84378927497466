import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {ReactComponent as IconSearch } from '../images/SearchIcon.svg'
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';


import { CardActions, Card, CardHeader, CardContent } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { NavLink } from "react-router-dom";

import {
  Paper,
  Typography,
  Box,
  LinearProgress,
  Button,
  TextField,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      "&.Mui-selected": {
        background: "#ED0080",
        color: "white",
        // borderRadius: '50%',
      },
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
  customTable: {
    tableBodyCell: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "15px",
      padding: "0",
    },
  },
}));

export const MyTable = (props) => {
  const {
    title,
    columns,
    children,
    data,
    alert,
    isLoading,
    pagination,
    body,
    setBody,
    fetch,
    addButton,
    handleClickOpen,
    isEditLoading,
    isSearch,
    isPagination,
  } = props;

  const classes = useStyles();

  const theme = useTheme();

  const HandleEnterSearch = () => {
    var input = document.getElementById("searchInput");
    input.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("searchBtn").click();
      }
    });
  };

  return (
    <>

      <Card sx={{ border: "0px" }} variant="outlined">
        <CardHeader
          sx={{ p: 1 }}
          title={
            <>
              <Box
                sx={{
                  display: { xl: "flex", lg: "flex", md: "flex" },
                  justifyContent: "space-between",
                }}
              >
                {title ? (
                  <>
                    <Box>{title}</Box>
                  </>
                ) : (
                  ""
                )}

                {isSearch !== false && (
                  <Box>
                    <TextField
                      id="searchInput"
                      size="small"
                      onChange={(e) => {
                        setBody({ ...body, search: e.target.value });
                        HandleEnterSearch();
                      }}
                      value={body?.search}
                      sx={{mt:0.5,mr:1}}
                      inputProps={{
                        style: {
                          height: "16px",
                        },
                      }}
                      label={
                        <>
                        <Box sx={{ display:"flex", }}>

                        <IconSearch />
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "11px",
                            lineHeight: "14px",
                            display: "flex",
                            alignItems: "center",
                            color: "#767676",
                            // pt: 0.5,
                            ml:0.5,
                          }}
                        >
                          Search
                        </Typography>
                        </Box>

                        </>
                      }
                    />
                    <Button
                      id="searchBtn"
                      onClick={() => {
                        fetch();
                      }}
                      size="small"
                      sx={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "12px",
                        lineHeight: "15px",
                        padding: "8px 16px",
                        gap: "19px",
                        // ml: 1,
                        borderRadius: "3px",
                      }}
                      variant="contained"
                    >
                      Search
                    </Button>
                  </Box>
                )}

                {addButton === false ? (
                  ""
                ) : (
                  <>
                    <Box>
                      <Button
                        onClick={handleClickOpen}
                        size="small"
                        variant="contained"
                        color={"secondary"}
                        sx={{
                          color: "white",
                          fontWeight: "600",
                          fontSize: "12px",
                          lineHeight: "15px",
                          padding: "8px 16px",
                          gap: "19px",
                        }}
                      >
                        Add New
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </>
          }
        />
        <CardContent sx={{ p: 0, m: 0 }}>
        {isLoading?.isLoading || isEditLoading ? (
        <LinearProgress />
      ) : data.length == 0 ? (
        <Box sx={{ mt: 1 }} display="flex" justifyContent="center">
          <Typography sx={{ color: "error.main", fontWeight: "bold" }}>
            {alert ? "Oops Something went wrong  !" : "No Record Found."}
          </Typography>
        </Box>
      ) : (
        ""
      )}
          <TableContainer
            // sx={{ maxHeight: "65vh" }}
            style={{maxHeight: 'calc(100vh - 215px)'}}

            // component={Paper}
          >
            <Table
              classes={{ root: classes.customTable }}
              size="small"
              stickyHeader
              aria-label="simple table"
            >
              <TableHead sx={{ }}>
                <TableRow>
                  {columns?.map((column, index) => {
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "12px",
                          lineHeight: "15px",
                          backgroundColor: theme.palette.mode === 'dark' ? "" :"#F9F9F9",
                          borderWidth: "1px 0px",
                          borderStyle: "solid",
                          borderColor: "#E1E1E1",
                        }}
                        // align="center"
                      >
                        {column}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>{children}</TableBody>
            </Table>
          </TableContainer>
          {isPagination != false && (
            // <CardActions>
            <Box container sx={{ display: "flex", justifyContent: "center" }}>

              <Box item spacing={2} sx={{ p: 1 }}>
                <Typography
                  sx={{
                    mb: 1,
                    textAlign: "center",
                  }}
                >
                  Showing {pagination.fromRecord}-{pagination.toRecord} out of{" "}
                  {pagination.totalRecords} records
                </Typography>
                <Pagination
                  id="pagination"
                  showFirstButton
                  showLastButton
                  boundaryCount={2}
                  onChange={(event, value) => {
                    setBody({ ...body, page: value });
                  }}
                  count={pagination.totalPages + 0}
                  defaultPage={body.page}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                  classes={{
                    root: classes.ul,
                  }}
                />
              </Box>
            </Box>
            // </CardActions>
          )}
        </CardContent>
      </Card>
    </>
  );
};
