import React, { useEffect, useState } from "react";

import { LinearProgress, Alert, Autocomplete, TextField, Typography } from "@mui/material";

import { useFetchAnnouncementMutation } from "../services/announcementApi";

export const AnnouncementDestination = (props) => {

  const { formik, name, addErrors,index } = props;

  const [fetchAnnouncement, fetchLoader] = useFetchAnnouncementMutation();

  const isFetchLoading = fetchLoader.isLoading;

  const [singleAnnouncement, setSingleAnnouncement] = useState({ file_Name:""});

  const [announcements, setAnnouncements] = useState([]);

  const fetchAnnounce = async () => {
    try {
      const response = await fetchAnnouncement({ pagination: "no" }).unwrap();

      console.log("announcement response", response);

      if (response.status === true) {

        console.log("announce destination result", response.announcement_sounds);

        setAnnouncements(response.announcement_sounds);

        const destination_id= name=='time_destination_id' ? formik.values[name] : formik.values?.ivr_count[index]?.["ivr_option_destination_id"];


        if(destination_id){

          response.announcement_sounds.forEach(element => {
    
            console.log("element",element)
            
            if(element["id"]==destination_id){
    
              setSingleAnnouncement(element)
    
            }
    
          });
    
        }

      } else {
        console.log("____announcement_sounds_destination_false");
      }
    } catch (error) {
      console.log("____catch_announcement_sounds_destination", error);
    }
  };

  useEffect(() => {
    fetchAnnounce();
  }, []);

  return (
    <>
      <Autocomplete
        openOnFocus
        disableClearable
        loading={isFetchLoading}
        getOptionLabel={(option) => option.file_Name}
        fullWidth={true}
        size="small"
        value={singleAnnouncement || { id: "", file_Name: ""}}
        onChange={(event, value) => {
          console.log("value", value);
          formik.setFieldValue(name, value.id);

          setSingleAnnouncement(value);
        }}
        id={name}
        options={announcements}
        renderInput={(params) => (
          <TextField
            {...formik.getFieldProps(name)}
            {...params}
            label={

              <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
              }}
              >
                Select Announcement

              </Typography>
            }
            error={
              (formik.touched?.ivr_count?.[index]?.[
                "ivr_option_destination_id"
              ] &&
                formik.errors?.ivr_count?.[index]?.[
                  "ivr_option_destination_id"
                ]) ||
              addErrors[name]
            }
          />
        )}
        label="Select Announcement"
        name={name}
      />
      {formik.touched?.ivr_count?.[index]?.["ivr_option_destination_id"] &&
      formik.errors?.ivr_count?.[index]?.["ivr_option_destination_id"] ? (
        <Alert severity="error">
          {formik.errors?.ivr_count?.[index]?.["ivr_option_destination_id"]}
        </Alert>
      ) : null}
      {addErrors[name] && (
        <Alert severity="error">
          {addErrors[name]?.[0]}
        </Alert>
      )}

      {isFetchLoading && <LinearProgress />}
    </>
  );
};
