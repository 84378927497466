import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { ReactComponent as IconSearch } from "../../images/SearchIcon.svg";
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';


import Divider from "@mui/material/Divider";
import ReactAudioPlayer from "react-audio-player";
import { ReactComponent as IconEdit } from "../../images/editIcon.svg";
import { ReactComponent as IconDelete } from "../../images/deleteIcon.svg";
import { StyledTableCell } from "../call_setting/QueueList";

import { makeStyles } from "@mui/styles";

import Pagination from "@mui/material/Pagination";
import {
  useFetchExtensionsMutation,
  useCreateExtensionsMutation,
  useStoreExtensionsMutation,
  useEditExtensionsMutation,
  useUpdateExtensionsMutation,
  useDeleteExtensionsMutation,
} from "../../services/extensionApi";
import { useExportMinutesMutation } from "../../services/exportMinuteApi";
import {
  CardActions,
  Card,
  CardHeader,
  CardContent,
  Switch,
} from "@mui/material";
import { useFetchClientOptionsMutation } from "../../services/clientApi";
import { Header } from "../../components/Header";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddBox from "../../components/AddBox";
import {
  Tooltip,
  Grid,
  Autocomplete,
  Paper,
  Typography,
  Box,
  LinearProgress,
  Button,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  TableCell,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { isAllowed } from "../Functions";
import CompanyChanger from "../../features/company/CompanyChanger";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      "&.Mui-selected": {
        background: "#ED0080",
        color: "white",
        // borderRadius: '50%',
      },
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
}));

export const Extension = () => {
  const isAdmin=useSelector((state)=>state.company.isAdmin);


  const classes = useStyles();
  const theme = useTheme();

  const [fetchClientOptions, clientLoader] = useFetchClientOptionsMutation();
  const isClientLoading = clientLoader.isLoading;

  const navigate = useNavigate();

  const [addButton, setAddButton] = useState(true);

  const [total_records, setTotalRecords] = useState(0);
  const [from_record, setFromRecord] = useState(0);
  const [to_record, setToRecord] = useState(0);
  const [total_pages, setTotalPages] = useState(0);
  const [alert, setAlert] = useState(false);
  const [exportAlert, setExportAlert] = useState({
    status: false,
    msg: "",
  });

  const handleChangePage = (event, value) => {
    setBody({ ...body, page: value });
  };

  const [body, setBody] = useState({ page: 1, search: "", client_id: "" });

  const [client, setClient] = useState({
    id: localStorage.getItem("client_id"),
    name: localStorage.getItem("client_name"),
    did_number: localStorage.getItem("master_num"),
  });

  const [clients, setClients] = useState([]);

  const [data, setData] = useState([]);

  const [isBucket, setIsBucket] = useState([0]);

  const [editIsBucket, setEditIsBucket] = useState([0]);

  const [offnet_minutes, setOffnetMinutes] = useState([0]);
  const [onnet_minutes, setOnnetMinutes] = useState([0]);

  const [fetchExtensions, isLoading] = useFetchExtensionsMutation();
  const [createExtensions, createLoader] = useCreateExtensionsMutation();
  const [storeExtensions, storeLoader] = useStoreExtensionsMutation();
  const [editExtensions, editLoader] = useEditExtensionsMutation();
  const [updateExtensions, updateLoader] = useUpdateExtensionsMutation();
  const [deleteExtensions, deleteLoader] = useDeleteExtensionsMutation();

  const isCreateLoading = createLoader.isLoading;
  const isStoreLoading = storeLoader.isLoading;
  const isEditLoading = editLoader.isLoading;
  const isUpdateLoading = updateLoader.isLoading;
  const isDeleteLoading = deleteLoader.isLoading;

  const [numbers, setNumbers] = useState([""]);

  const storeExt = async (values) => {
    try {
      const response = await storeExtensions(values).unwrap();

      console.log("store ext response", response);

      if (response.status === true) {
        fetchExt();

        handleClose();

        toast.success("Record has been added successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        toast.error(response.message);
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);

      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const createExt = async () => {
    try {
      const response = await createExtensions().unwrap();

      console.log("create extensions response", response);

      if (response.status === true) {
        setSounds(response.callbackSounds);
      } else {
        console.log("____false");
      }
    } catch (error) {
      console.log("____catch", error);
    }
  };

  const updateExt = async (values) => {
    try {
      const response = await updateExtensions(values).unwrap();

      console.log("update extension response", response);

      if (response.status === true) {
        fetchExt();

        handleClose();

        toast.success("Record has been update successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);
      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add annonuce errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const deleteExt = async (id) => {
    if (window.confirm("are you sure you want to delete this record?")) {
      try {
        const response = await deleteExtensions({ id: id }).unwrap();

        console.log("delete Extension response", response);

        if (response.status === true) {
          fetchExt();
          toast.success("Record has been deleted successfully !");
        } else {
          console.log("____false", response);

          toast.error(response.message);
        }
      } catch (error) {
        console.log("____catch", error);

        toast.error("Oops something went wrong !");
      }
    }
  };

  const [exportMinutes, isMinLoading] = useExportMinutesMutation();

  const [addAlert, setAddAlert] = useState({ status: false, msg: "" });

  const [open, setOpen] = React.useState(false);

  const [addErrors, setAddErrors] = useState({});

  const [title, setTitle] = useState("");

  const [editMode, setEditMode] = useState(false);

  const [sounds, setSounds] = useState([]);

  const [singleSystemSound, setSingleSystemSound] = useState({
    id: "",
    file_Name: "",
  });

  const handleClickOpen = () => {
    setTitle("Add New Extension");
    setEditMode(false);
    setOpen(true);
    // setaddFunction(()=>addQueue)
    // setaddLoading(isAddQueueLoading)
  };

  const handleEditOpen = () => {
    setTitle("Edit Extension");
    setEditMode(true);
    setOpen(true);
  };

  const initialValues = {
    List_Name: "",
    ext_code: null,
    phNumbers: [""],
    incomingrecording: 0,
    outgoingcall: 0,
    outgoingrecording: 0,
    callbackdetection: 0,
    exten_call: 0,
    // onnet_minutes: null,
    // offnet_minutes: null,
    // credit_limit: null,
    // sound: null,
  };

  const onSubmit = (values, actions) => {
    console.log("form values", values);

    if (editMode) {
      updateExt(values);
    } else {
      storeExt(values);
    }
  };

  const validationSchema = Yup.object({
    List_Name: Yup.string().required("Required !"),
    ext_code: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(3, "Must be minimum 3 digits")
      .max(4, "Must be maximum 4 digits")
      .nullable()
      .required("Required !"),
    phNumbers: Yup.array().min(1, "Phone Number field is required"),
    Num_1: Yup.number().required("Required !"),
    Num_2: Yup.number(),
    Num_3: Yup.number(),
    Num_4: Yup.number(),
    Num_5: Yup.number(),
    onnet_minutes: Yup.number(),
    offnet_minutes: Yup.number()
      .nullable()
      .optional()
      .test(
        "offnet_minutes",
        "You can add only 100 or 500 minutes at a time.",
        (offnet_minutes) =>
          offnet_minutes == null ||
          offnet_minutes == 100 ||
          offnet_minutes == 500
      ),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    // validateOnMount:true,
  });

  console.log("formik", formik);

  const handleClose = () => {
    setOpen(false);
    setAddErrors({});
    setAddAlert({ status: false, msg: "" });
    setSingleSystemSound({ id: "", file_Name: "" });
    formik.resetForm();
  };

  const exportMins = async () => {
    try {
      const body = {
        master_number: localStorage.getItem("master_num").substring(0, 11),
      };

      console.log("export api body", body);

      const response = await exportMinutes(body).unwrap();

      console.log("Extension response", response);

      if (response.status === true) {
        setExportAlert(false);

        // window.location.replace(response.file);
        window.open(response.file, "_blank");

        // <Link to={response.file} target="_blank" rel="noopener noreferrer" />
      } else {
        setExportAlert({ status: true, msg: response.error });

        console.log(response.message);
      }

      setTimeout(() => {
        setExportAlert({ status: false, msg: "" });
      }, 3000);
    } catch (error) {
      console.log("error", error);

      setExportAlert({
        status: true,
        msg: "Oops ! there is an error in exporting file !",
      });

      setTimeout(() => {
        setExportAlert({ status: false, msg: "" });
      }, 3000);
    }
  };

  const fetchExt = async () => {
    try {
      const response = await fetchExtensions(body).unwrap();

      console.log("Extension response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result", response.dial_lists);

        setData(response.dial_lists.data);

        // setData(response.dial_lists);

        setIsBucket(response.is_bucket);

        // console.log('columns',columns)

        // if(response.is_bucket==1 || response.is_bucket==2 ){

        // setColumns([...columns, 'Remaining Minutes','Utilized Minutes'])

        // console.log('newcolumns',columns)

        // }

        setTotalPages(response.dial_lists.last_page);

        setTotalRecords(response.dial_lists.total);

        setFromRecord(response.dial_lists.from);

        setToRecord(response.dial_lists.to);

        console.log("current_page", response.dial_lists.current_page);
        console.log("last_page", response.dial_lists.last_page);

        if (response.dial_lists.current_page > response.dial_lists.last_page) {
          setBody({ ...body, page: 1 });

          console.log("hello");
        }
      } else {
        setData([]);
        setAlert(true);
        console.log("____false", data);
      }
    } catch (error) {
      console.log("error", error);
      setData([]);
      setAlert(true);
      console.log("____catch", data);
    }
  };

  const editExt = async (id) => {
    console.log("ext id", id);

    try {
      const response = await editExtensions({ id: id }).unwrap();

      console.log("Edit Extension response", response);

      if (response.status === true) {
        formik.setFieldValue("id", id);

        formik.setFieldValue("List_Name", response.dial_list?.List_Name);
        formik.setFieldValue("ext_code", response.dial_list?.ext_code);
        formik.setFieldValue("Num_1", response.dial_list?.Num_1);
        formik.setFieldValue("incomingrecording", response.dial_list?.rec_flag);
        formik.setFieldValue(
          "outgoingrecording",
          response.dial_list?.outgoing_recording
        );
        formik.setFieldValue(
          "callbackdetection",
          response.dial_list?.cb_detection
        );
        formik.setFieldValue("outgoingcall", response.dial_list?.outgoing_call);
        formik.setFieldValue("exten_call", response.dial_list?.exten_call);
        formik.setFieldValue(
          "sound",
          response.dial_list?.dial_list_acl?.Sound_File
        );

        formik.setFieldValue("designation", response.dial_list?.designation);
        formik.setFieldValue("department", response.dial_list?.department);

        setSingleSystemSound(response.dial_list?.dial_list_acl?.callback_sound);

        setEditIsBucket(response.is_bucket);

        setOffnetMinutes(response?.dial_list?.offnet_minutes);
        setOnnetMinutes(response?.dial_list?.onnet_minutes);

        handleEditOpen();
      } else {
        console.log("____false", response);
      }
    } catch (error) {
      console.log("____catch", error);
    }
  };

  const getClients = async () => {
    try {
      const response = await fetchClientOptions({ pagination: "no" }).unwrap();

      console.log("client resposne", response);

      if (response.status === true) {
        setClients(response.clients);

        console.log("clients option", clients);
      }
    } catch (error) {
      console.log("clients catch error", error);
    }
  };

  useEffect(() => {
    fetchExt();
  }, [body.page, body.client_id]);

  useEffect(() => {
    getClients();
    createExt();
  }, []);

  const handleCheck = (e, name) => {
    const { checked } = e.target;

    if (checked) {
      formik.setFieldValue(name, 1);
    } else {
      formik.setFieldValue(name, 0);

      if (name == "outgoingcall") {
        formik.setFieldValue("outgoingrecording", 0);
        formik.setFieldValue("callbackdetection", 0);
        formik.setFieldValue("outgoingrecording", 0);

        formik.setFieldValue("sound", null);

        setSingleSystemSound({ id: "", file_Name: "" });
      } else if (name == "callbackdetection") {
        formik.setFieldValue("sound", null);

        setSingleSystemSound({ id: "", file_Name: "" });
      }
    }
  };

  const role_id=localStorage.getItem("role_id")
  const is_superuser=localStorage.getItem("is_superuser")

  // useEffect(() => {

  //   formik.setFieldValue("outgoingrecording",0)
  //   formik.setFieldValue("callbackdetection",0)
  //   formik.setFieldValue("outgoingrecording",0)

  //   formik.setFieldValue("sound",null)

  //   setSingleSystemSound({id:"",file_Name:""})

  // }, [formik.values.outgoingcall]);

  if (!isAllowed("dial_list_access")  ) {
    return navigate("/forbidden");
  }

  const HandleEnterSearch = () => {
    var input = document.getElementById("searchInput");
    input.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("searchBtn").click();
      }
    });
  };

  return (
    <>
      <Header title={"Extensions"} />

      {/* <CompanyChanger/> */}

      {/* __________________ Client */}
      { isAllowed("search_any_client") ? (
        <Paper spacing={2} sx={{ m: 2 }}>
          <Autocomplete
            // fullWidth={false}
            size="small"
            value={client || ""}
            onChange={(event, value) => {
              console.log("client value", value);

              setClient(value);

              setBody({ ...body, client_id: value?.id });

              // setBody({ ...body, agent_number: "" })
            }}
            disablePortal
            id="clients"
            options={clients}
            loading={isClientLoading}
            getOptionLabel={(option) => option.name + " " + option.did_number}
            sx={{}}
            renderInput={(params) => <TextField {...params} label="Client" />}
          />

          {isClientLoading ? <LinearProgress /> : null}
        </Paper>
      ) : null}

            {/* _______________________________Table */}
      <Card sx={{ border: "0px" }} variant="outlined">
        <CardHeader
          sx={{ p: 1 }}
          title={
            <>
              <Box
                sx={{
                  display: { xl: "flex", lg: "flex", md: "flex" },
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <TextField
                    id="searchInput"
                    size="small"
                    onChange={(e) => {
                      setBody({ ...body, search: e.target.value });
                      HandleEnterSearch();
                    }}
                    value={body.search}
                    sx={{ mt: 0.5 ,mr:1}}
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    label={
                      <>
                        <Box sx={{ display: "flex" }}>
                          <IconSearch />
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "11px",
                              lineHeight: "14px",
                              display: "flex",
                              alignItems: "center",
                              color: "#767676",
                              // pt: 0.5,
                              ml: 0.5,
                            }}
                          >
                            Search
                          </Typography>
                        </Box>
                      </>
                    }
                  />
                  <Button
                    id="searchBtn"
                    onClick={() => {
                      fetchExt();
                    }}
                    size="small"
                    sx={{
                      color: "white",
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "15px",
                      padding: "8px 16px",
                      gap: "19px",
                      // ml: 1,
                      borderRadius: "3px",
                    }}
                    variant="contained"
                  >
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      exportMins();
                    }}
                    size="small"
                    sx={{
                      color: "white",
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "15px",
                      padding: "8px 16px",
                      gap: "19px",
                      ml: 1,
                      borderRadius: "3px",
                      display: isBucket == 0 ? "none" : "",
                    }}
                    variant="contained"
                    disabled={isMinLoading.isLoading}
                  >
                    Export
                  </Button>
                  {exportAlert.status ? (
                    <Alert severity={"error"}>{exportAlert.msg}</Alert>
                  ) : (
                    ""
                  )}
                </Box>
                <Box>
                  {!isAllowed("dial_list_create")   ? (
                    ""
                  ) : (
                    <>
                      <Button
                        onClick={handleClickOpen}
                        size="small"
                        variant="contained"
                        color={"secondary"}
                        sx={{
                          color: "white",
                          fontWeight: "600",
                          fontSize: "12px",
                          lineHeight: "15px",
                          padding: "8px 16px",
                          gap: "19px",
                        }}
                      >
                        Add New
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </>
          }
        />
        <CardContent sx={{ p: 0, m: 0 }}>
          {isEditLoading ?? <LinearProgress />}
          {isLoading?.isLoading ? (
            <LinearProgress />
          ) : data.length == 0 ? (
            <Box display="flex" justifyContent="center">
              <Typography sx={{ color: "error.main", fontWeight: "bold" }}>
                {alert ? "Oops Something went wrong  !" : "No Record Found."}
              </Typography>
            </Box>
          ) : (
            ""
          )}
          <TableContainer style={{ maxHeight: "calc(100vh - 215px)" }}>
            <Table size="small" stickyHeader aria-label="simple table">
              <TableHead sx={{ }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "15px",
                      backgroundColor: theme.palette.mode === 'dark' ? "" :"#F9F9F9",
                      borderWidth: "1px 0px",
                      borderStyle: "solid",
                      borderColor: "#E1E1E1",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "15px",
                      backgroundColor: theme.palette.mode === 'dark' ? "" :"#F9F9F9",
                      borderWidth: "1px 0px",
                      borderStyle: "solid",
                      borderColor: "#E1E1E1",
                    }}
                  >
                    Code
                  </TableCell>
                  <TableCell
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "15px",
                      backgroundColor: theme.palette.mode === 'dark' ? "" :"#F9F9F9",
                      borderWidth: "1px 0px",
                      borderStyle: "solid",
                      borderColor: "#E1E1E1",
                    }}
                  >
                    Primary Number
                  </TableCell>
                  <TableCell
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "15px",
                      backgroundColor: theme.palette.mode === 'dark' ? "" :"#F9F9F9",
                      borderWidth: "1px 0px",
                      borderStyle: "solid",
                      borderColor: "#E1E1E1",
                      display: isBucket == 0 ? "none" : "",
                    }}
                    // align="center"
                  >
                    Remaining Minutes
                  </TableCell>
                  <TableCell
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "15px",
                      backgroundColor: theme.palette.mode === 'dark' ? "" :"#F9F9F9",
                      borderWidth: "1px 0px",
                      borderStyle: "solid",
                      borderColor: "#E1E1E1",
                      display: isBucket == 0 ? "none" : "",
                    }}
                    // align="center"
                  >
                    Utilized Minutes
                  </TableCell>
                  <TableCell
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "15px",
                      backgroundColor: theme.palette.mode === 'dark' ? "" :"#F9F9F9",
                      borderWidth: "1px 0px",
                      borderStyle: "solid",
                      borderColor: "#E1E1E1",
                      display: isBucket == 2 ? "" : "none",
                    }}
                    // align="center"
                  >
                    Designation
                  </TableCell>
                  <TableCell
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "15px",
                      backgroundColor: theme.palette.mode === 'dark' ? "" :"#F9F9F9",
                      borderWidth: "1px 0px",
                      borderStyle: "solid",
                      borderColor: "#E1E1E1",
                      display: isBucket == 2 ? "" : "none",
                    }}
                    // align="center"
                  >
                    Department
                  </TableCell>
                  <TableCell
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "15px",
                      backgroundColor: theme.palette.mode === 'dark' ? "" :"#F9F9F9",
                      borderWidth: "1px 0px",
                      borderStyle: "solid",
                      borderColor: "#E1E1E1",
                      // display: isBucket == 2 ? "" : "none",
                    }}
                    // align="center"
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((ext, index) => {
                  return (
                    <TableRow hover key={index} sx={{}}>
                      {/* <StyledTableCell >{ext.id}</StyledTableCell> */}
                      <StyledTableCell>{ext.List_Name}</StyledTableCell>
                      <StyledTableCell>{ext.ext_code}</StyledTableCell>
                      <StyledTableCell>{ext.Num_1}</StyledTableCell>
                      <StyledTableCell
                        sx={{ display: isBucket == 0 ? "none" : "" }}
                        // align="center"
                      >
                        <Typography
                          sx={{
                            display: isBucket == 1 ? "block" : "none",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "15px",
                          }}
                        >
                          Onnet : {Math.floor(ext.onnet_minutes / 60)}
                          <br />
                          Offnet : {Math.floor(ext.offnet_minutes / 60)}
                          <br />
                        </Typography>
                        <Typography
                          sx={{
                            display: isBucket == 2 ? "block" : "none",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "15px",
                          }}
                        >
                          All Net : {Math.floor(ext.offnet_minutes / 60)}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ display: isBucket == 0 ? "none" : "" }}
                        // align="center"
                      >
                        <Typography
                          sx={{
                            display: isBucket == 1 ? "block" : "none",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "15px",
                          }}
                        >
                          Onnet : {Math.floor(ext.utilized_onnet / 60)}
                          <br />
                          Offnet : {Math.floor(ext.utilized_offnet / 60)}
                          <br />
                        </Typography>
                        <Typography
                          sx={{
                            display: isBucket == 2 ? "block" : "none",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "15px",
                          }}
                        >
                          All Net : {Math.floor(ext.utilized_offnet / 60)}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ display: isBucket == 2 ? "" : "none" }}
                        // align="center"
                      >
                        {ext.designation}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ display: isBucket == 2 ? "" : "none" }}
                        // align="center"
                      >
                        {ext.department}
                      </StyledTableCell>
                      {/* <StyledTableCell
                        sx={{ display: isBucket == 2 ? "" : "none" }}
                        align="center"
                      >
                        <Button
                          onClick={() =>
                            navigate(`/edit-extension/${ext.id}`, {
                              replace: true,
                            })
                          }
                          size="small"
                          variant="contained"
                          color={"info"}
                        >
                          Edit
                        </Button>
                      </StyledTableCell> */}
                      <StyledTableCell>
                        <Tooltip title="Edit">
                          <IconButton
                            disabled={
                              !isAllowed("dial_list_edit")  
                            }
                            size="small"
                            // sx={{ m: 1 }}
                            color={"info"}
                            variant="contained"
                            onClick={() => editExt(ext.id)}
                          >
                            <IconEdit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            disabled={
                              !isAllowed("dial_list_delete")  
                            }
                            size="small"
                            // sx={{ m: 1 }}
                            color={"error"}
                            variant="contained"
                            onClick={() => deleteExt(ext.id)}
                          >
                            <IconDelete stroke="red" />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box container sx={{ display: "flex", justifyContent: "center" }}>
            <Box item spacing={2} sx={{ p: 1 }}>
              <Typography sx={{ mb: 1, textAlign: "center" }}>
                Showing {from_record}-{to_record} out of {total_records} records
              </Typography>

              <Pagination
                id="pagination"
                showFirstButton
                showLastButton
                boundaryCount={2}
                onChange={handleChangePage}
                count={total_pages + 0}
                defaultPage={body.page}
                color="primary"
                variant="outlined"
                shape="rounded"
                classes={{
                  root: classes.ul,
                }}
              />
            </Box>
          </Box>
        </CardContent>
        <CardActions></CardActions>
      </Card>

      {/* _____________________________________________________________________________ */}

      {/* ________Add Box___________________ */}
      <AddBox
        title={title}
        open={open}
        handleClose={handleClose}
        formik={formik}
        fullScreen={false}
        fullWidth={true}
        style={{
          width: "562px",
          boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
          maxHeight:"100vh",
        }}
      >
        {addAlert.status ? (
          <Alert severity="error">{addAlert.msg}</Alert>
        ) : null}

        {isStoreLoading || (isUpdateLoading && <LinearProgress />)}

        <Grid container spacing={1}>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <TextField
              error={
                (formik.touched.List_Name && formik.errors.List_Name) ||
                addErrors?.List_Name
              }
              // margin="normal"
              size="small"
              // sx={{m:1}}
              required
              sx={{ width: "100%" }}
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Name
                </Typography>
              }
              id="List_Name"
              name="List_Name"
              {...formik.getFieldProps("List_Name")}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.List_Name && formik.errors.List_Name ? (
              <Alert severity="error">{formik.errors.List_Name}</Alert>
            ) : null}
            {addErrors?.List_Name && (
              <Alert severity="error">{addErrors?.List_Name[0]}</Alert>
            )}
          </Grid>

          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <TextField
              disabled={!isAllowed("dial_list_create")}
              error={
                (formik.touched.ext_code && formik.errors.ext_code) ||
                addErrors?.ext_code
              }
              // margin="normal"
              size="small"
              value={formik.values.ext_code || ""}
              sx={{ width: "100%" }}
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Extension code
                </Typography>
              }
              id="ext_code"
              name="ext_code"
              {...formik.getFieldProps("ext_code")}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.ext_code && formik.errors.ext_code ? (
              <Alert severity="error">{formik.errors.ext_code}</Alert>
            ) : null}
            {addErrors?.ext_code && (
              <Alert severity="error">{addErrors?.ext_code[0]}</Alert>
            )}
          </Grid>
          <Grid spacing={1} sx={{pl:1, mt:1}} container>
            {formik.values.phNumbers.map((number, index) => {
              return (
                <Grid xs={12} sm={12} md={6} lg={6} xl={6} item key={index}>
                  <TextField
                    disabled={editMode}
                    helperText={
                      index == 0 ? "This will be your primary number" : ""
                    }
                    error={
                      (formik.touched[`Num_${index + 1}`] &&
                        formik.errors[`Num_${index + 1}`]) ||
                      addErrors[`Num_${index + 1}`]
                    }
                    // margin="normal"
                    size="small"
                    sx={{ width: "100%" }}
                    label={
                      <Typography
                        variant="span"
                        sx={{
                          fontWeight: 500,
                          fontSize: "13px",
                          lineHeight: "16px",
                        }}
                      >
                        {`Number ${index + 1}`}
                      </Typography>
                    }
                    id={`Num_${[index + 1]}`}
                    name={`Num_${[index + 1]}`}
                    {...formik.getFieldProps(`Num_${[index + 1]}`)}
                    onFocus={() => {
                      setAddErrors({});
                    }}
                    InputProps={{
                      endAdornment: !editMode && (
                        <InputAdornment position="end">
                          {index > 0 &&
                          index == formik.values.phNumbers.length - 1 ? (
                            <IconButton
                              onClick={() =>
                                formik.setFieldValue(
                                  "phNumbers",
                                  formik.values.phNumbers.slice(0, -1)
                                )
                              }
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          ) : null}
                          {index == formik.values.phNumbers.length - 1 &&
                          index < 4 ? (
                            <IconButton
                              onClick={() => {
                                formik.setFieldValue("phNumbers", [
                                  ...formik.values.phNumbers,
                                  "",
                                ]);
                              }}
                            >
                              <AddCircleIcon />
                            </IconButton>
                          ) : null}
                        </InputAdornment>
                      ),
                    }}
                  />

                  {formik.touched[`Num_${index + 1}`] &&
                  formik?.errors[`Num_${index + 1}`] ? (
                    <Alert severity="error">
                      {formik.errors[`Num_${index + 1}`]}
                    </Alert>
                  ) : null}
                  {addErrors[`Num_${index + 1}`] && (
                    <Alert severity="error">
                      {addErrors[`Num_${index + 1}`]?.[0]}
                    </Alert>
                  )}
                </Grid>
              );
            })}
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <TextField
              error={
                (formik.touched.designation && formik.errors.designation) ||
                addErrors?.designation
              }
              // margin="normal"
              size="small"
              value={formik.values.designation || ""}
              sx={{ width: "100%" }}
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Designation
                </Typography>
              }
              id="designation"
              name="designation"
              {...formik.getFieldProps("designation")}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.designation && formik.errors.designation ? (
              <Alert severity="error">{formik.errors.designation}</Alert>
            ) : null}
            {addErrors?.designation && (
              <Alert severity="error">{addErrors?.designation[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <TextField
              error={
                (formik.touched.department && formik.errors.department) ||
                addErrors?.department
              }
              // margin="normal"
              size="small"
              value={formik.values.department || ""}
              sx={{ width: "100%" }}
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Department
                </Typography>
              }
              id="department"
              name="department"
              {...formik.getFieldProps("department")}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.department && formik.errors.department ? (
              <Alert severity="error">{formik.errors.department}</Alert>
            ) : null}
            {addErrors?.department && (
              <Alert severity="error">{addErrors?.department[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <FormControl fullWidth
            // margin="normal"
            >
              <FormGroup
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                row
              >
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Incoming Recording
                </Typography>
                <FormControlLabel
                  onChange={(e) => {
                    handleCheck(e, "incomingrecording");
                  }}
                  // sx={{width:"100%"}}
                  // label={

                  // }
                  control={
                    <Switch
                      disabled={!isAllowed("dial_list_create")}
                      size="small"
                      checked={formik.values.incomingrecording == 1}
                      color="primary"
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <FormControl fullWidth 
            // margin="normal"
            >
            <FormGroup
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                row
              >
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Ext to Ext Call
                </Typography>
                <FormControlLabel
                  onChange={(e) => {
                    handleCheck(e, "exten_call");
                  }}
                  // label="Ext to Ext Call"
                  control={
                    <Switch
                      disabled={!isAllowed("dial_list_create")}
                      size="small"
                      checked={formik.values.exten_call == 1}
                      color="primary"
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <Divider>        <Typography
            sx={{
              // fontWeight: "700",
              fontSize: "16px",
              lineHeight: "20px",
            }}
          >
            Outgoing
          </Typography></Divider>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <FormControl fullWidth
            // margin="normal"
            >
            <FormGroup
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                row
              >
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Outgoing Call
                </Typography>
                <FormControlLabel
                  onChange={(e) => {
                    handleCheck(e, "outgoingcall");
                  }}
                  // label="Outgoing Call"
                  control={
                    <Switch
                      disabled={!isAllowed("dial_list_create")}
                      size="small"
                      checked={formik.values.outgoingcall == 1}
                      color="primary"
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>
          {formik.values.outgoingcall == 1 && (
            <>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <FormControl fullWidth
                // margin="normal"
                >
                <FormGroup
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                row
              >
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Outgoing Recording
                </Typography>
                    <FormControlLabel
                      onChange={(e) => {
                        handleCheck(e, "outgoingrecording");
                      }}
                      // label="Outgoing Recording"
                      control={
                        <Switch
                          disabled={!isAllowed("dial_list_create")}
                          size="small"
                          checked={formik.values.outgoingrecording == 1}
                          color="primary"
                        />
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <FormControl fullWidth
                // margin="normal"
                >
                <FormGroup
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                row
              >
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  CallBack Detection
                </Typography>

                    <FormControlLabel
                      onChange={(e) => {
                        handleCheck(e, "callbackdetection");
                      }}
                      // label="Callback Detection"
                      control={
                        <Switch
                          disabled={!isAllowed("dial_list_create")}
                          size="small"
                          checked={formik.values.callbackdetection == 1}
                          color="primary"
                        />
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              
              {formik.values.callbackdetection == 1 && (
                <>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                    <Autocomplete
                      disabled={!isAllowed("dial_list_create")}
                      id="sounds"
                      options={sounds}
                      getOptionLabel={(option) => option.file_Name}
                      renderInput={(params) => (
                        <TextField
                          error={
                            (formik.touched.sound && formik.errors.sound) ||
                            addErrors?.sound
                          }
                          {...params}
                          sx={{width:"100%"}}
                          label={
            
                            <Typography
                            variant="span"
                            sx={{
                              fontWeight: 500,
                              fontSize: "13px",
                              lineHeight: "16px",
                            }}
                            >
                             Sound
            
                            </Typography>
                          }
                          {...formik.getFieldProps("sound")}
                        />
                      )}
                      size="small"
                      // margin="normal"
                      onChange={(e, value) => {
                        console.log("sound value ", value);
                        formik.setFieldValue("sound", value?.id);
                        setSingleSystemSound(value);
                      }}
                      value={singleSystemSound || { id: "", file_Name: "" }}
                      name="sound"
                    />
                    {formik.touched.sound && formik.errors.sound ? (
                      <Alert severity="error">{formik.errors.sound}</Alert>
                    ) : null}
                    {addErrors?.sound && (
                      <Alert severity="error">{addErrors?.sound[0]}</Alert>
                    )}
                    {isCreateLoading ? <LinearProgress /> : null}
                  </Grid>
                 { singleSystemSound?.file_Path ?
                  <Grid sx={{display:"flex",justifyContent:"center"}} xs={12} sm={12} md={12} lg={12} xl={12} item>
                    <ReactAudioPlayer
                      src={
                        singleSystemSound?.file_Path
                          ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL +
                            singleSystemSound?.file_Path.replace(
                              ".gsm",
                              "mp3.mp3"
                            )
                          : ""
                      }
                      controls
                    />
                  </Grid>
                :null}
                </>
              )}
            </>
          )}
          {editMode && editIsBucket !== 0 && (
            <>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <Divider>Bucket Minutes</Divider>
              </Grid>

              <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                <TextField
                  // margin="normal"
                  size="small"
                  label={
                    isBucket == 2
                      ? "Current Allnet Minutes"
                      : "Current Offnet Minutes"
                  }
                  id="offnet_minutes"
                  name="offnet_minutes"
                  value={Math.floor(offnet_minutes / 60)}
                  disabled
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                <TextField
                  disabled={!isAllowed("add_offnet_minutes")  }
                  helperText={"Must be 100 or 500"}
                  error={
                    (formik.touched.offnet_minutes &&
                      formik.errors.offnet_minutes) ||
                    addErrors?.offnet_minutes
                  }
                  // margin="normal"
                  size="small"
                  value={formik.values.offnet_minutes || 0}
                  label={
                    isBucket == 2 ? "Add Allnet Minutes" : "Add Offnet Minutes"
                  }
                  id="offnet_minutes"
                  name="offnet_minutes"
                  {...formik.getFieldProps("offnet_minutes")}
                  onFocus={() => {
                    setAddErrors({});
                  }}
                />
                {formik.touched.offnet_minutes &&
                formik.errors.offnet_minutes ? (
                  <Alert severity="error">{formik.errors.offnet_minutes}</Alert>
                ) : null}
                {addErrors?.offnet_minutes && (
                  <Alert severity="error">{addErrors?.offnet_minutes[0]}</Alert>
                )}
              </Grid>

              <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                <TextField
                  // margin="normal"
                  size="small"
                  label={"Current Onnet Minutes"}
                  id="onnet_minutes"
                  name="onnet_minutes"
                  value={Math.floor(onnet_minutes / 60)}
                  disabled
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                <TextField
                  disabled={!isAllowed("add_onnet_minutes")  }
                  error={
                    (formik.touched.onnet_minutes &&
                      formik.errors.onnet_minutes) ||
                    addErrors?.onnet_minutes
                  }
                  // margin="normal"
                  size="small"
                  value={formik.values.onnet_minutes || 0}
                  label="Add Onnet Minutes"
                  id="onnet_minutes"
                  name="onnet_minutes"
                  {...formik.getFieldProps("onnet_minutes")}
                  onFocus={() => {
                    setAddErrors({});
                  }}
                />
                {formik.touched.onnet_minutes && formik.errors.onnet_minutes ? (
                  <Alert severity="error">{formik.errors.onnet_minutes}</Alert>
                ) : null}
                {addErrors?.onnet_minutes && (
                  <Alert severity="error">{addErrors?.onnet_minutes[0]}</Alert>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </AddBox>
    </>
  );
};
