import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useSelector } from "react-redux";

import { convertToCSV, downloadCSV } from "./CallRecord";

import Pagination from "@mui/material/Pagination";
import ReactAudioPlayer from "react-audio-player";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { toast } from "react-toastify";
// import { Typography } from "../Dashboard";
import { makeStyles } from "@mui/styles";
// import { AudioCard, VideoCard } from "material-ui-player";
// import AudioPlayer from 'material-ui-audio-player';
// import { PaginationItem } from '@mui/material';

// import ClickAwayListener from "react-click-away-listener";

import {
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
} from "@mui/material";

import { useFetchIncomingCallsMutation } from "../../services/incomingCallApi";
import { isAllowed } from "../Functions";
import { StyledTableCell } from "../call_setting/QueueList";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import Moment from "react-moment";
import RecordingField from "./RecordingField";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      "&.Mui-selected": {
        background: "#ED0080",
        color: "white",
        // borderRadius: '50%',
      },
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
}));

export const Incoming = forwardRef((props, ref) => {
  const isAdmin = useSelector((state) => state.company.isAdmin);

  const {
    body,
    setBody,
    timeConvert,
    secondsToHms,
    agents,
    handleExportClick,
  } = props;

  // const useStyles = makeStyles(() => ({
  //   ul: {
  //     '& .MuiPaginationItem-root': {
  //        '&.Mui-selected': {
  //          background: 'red',
  //          color: 'white',
  //          // borderRadius: '50%',
  //        },
  //     },

  //   },
  // }));

  const classes = useStyles();
  const theme = useTheme();

  const [total_records, setTotalRecords] = useState(0);
  const [from_record, setFromRecord] = useState(0);
  const [to_record, setToRecord] = useState(0);
  const [total_pages, setTotalPages] = useState(0);
  const [alert, setAlert] = useState(false);
  const [wcAlert, setWcAlert] = useState(false);
  const [wcMessage, setWcMessage] = useState("");
  const [data, setData] = useState([]);

  const [fetchIncomingCalls, isLoading] = useFetchIncomingCallsMutation();

  // console.log("isLoading", isLoading);
  // console.log("isSuccess", isLoading.isSuccess);
  // console.log("isError", isLoading.isError);
  // console.log("data", isLoading.data);

  // console.log("testing",fetchIncomingCalls)

  const handleChangePage = (event, value) => {
    setBody({ ...body, page: value });
  };

  // const handleClickAway = () => {
  //   console.log("Maybe close the popup");

  //   // const audio=document.getElementById(e.target.id)

  //   // audio.pause()
  // };

  const changeDateformat = (date) => {};

  useImperativeHandle(ref, () => ({
    childFunction1(body) {
      fetchCalls({ ...body, export_incoming: 0 });
      console.log("child function 1 called");
    },

    exportCallRecords() {
      exportCsvApi(body);

      // if (data.length == 0) {
      //   toast.error("No data found to export !");
      // } else {
      //   const pData = refinedData(data);
      //   const csv = convertToCSV(pData);
      //   downloadCSV(
      //     csv,
      //     "incoming" +
      //       from_record +
      //       "-" +
      //       to_record +
      //       "_" +
      //       total_records +
      //       ".csv"
      //   );
      // }

      // fetchCalls({ ...body, export_incoming: 1 });

      // // setBody({...body,export_incoming:0})

      // console.log("incoming export call records called !");
    },
  }));

  const exportCsvApi = (body) => {
    handleExportClick(true);

    toast.info(
      "Exporting... This may take a few seconds or minutes depending on the size of the data you are exporting, please be patient.",
      {
        autoClose: false,
      }
    );

    const req = { ...body, export_incoming: 1 };

    const url = process.env.REACT_APP_BASE_URL + "search-incoming-calls";

    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(req),
    };

    fetch(url, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "incoming.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);

        toast.success("Data exported successfully !", {
          autoClose: false,
        });
      })
      .catch((error) => {
        toast.error("Oops there is an error in export, please try later");
        console.error("Error:", error);
      })
      .finally(() => {
        handleExportClick(false);
      });
  };

  function refinedData(tasks) {
    let columns = [
      // 'Company_id',
      "Client_no",
      "Destination",
      "Ivr_Options",
      "Duration",
      "Duration_In_Seconds",
      "Date",
      "Time",
      "Status",
      "Recording",
    ];
    let newData = [];

    tasks.forEach((task) => {
      let row = {};
      // row['company_id'] = task.client_id;
      row["Client_no"] = task.cids;
      row["Destination"] = getDestination(task, agents);
      row["Ivr_Options"] = task.ivr_dtmf ?? "N/A";
      row["Duration"] = task.duration;
      row["Date"] = task.date;
      row["Time"] = task.time;
      row["Status"] = task?.Last_App_Data?.includes("-ANSWER-")
        ? "Answer"
        : "Missed";
      row["Recording"] = task?.Last_App_Data?.includes("-ANSWER-")
        ? task?.Recd_File_Path?.replace(".gsm", ".mp3")
        : "N/A";

      newData.push(row);
    });

    return newData;
  }

  const fetchCalls = async (body) => {
    try {
      const response = await fetchIncomingCalls(body).unwrap();

      console.log("incoming call response", response);

      if (response.status === true) {
        if (response.file) {
          window.open(response.file, "_blank");
        } else {
          setAlert(false);

          console.log("result", response.search_result);

          setData(response.search_result.data);

          setTotalPages(response.search_result.last_page);

          setTotalRecords(response.search_result.total);

          setFromRecord(response.search_result.from);

          setToRecord(response.search_result.to);

          console.log("current_page", response.search_result.current_page);
          console.log("last_page", response.search_result.last_page);

          if (
            response.search_result.current_page >
            response.search_result.last_page
          ) {
            setBody({ ...body, page: 1 });

            console.log("hello");
          }
        }
      } else {
        toast.error(response.message);

        setData([]);
        // setAlert(true);
        console.log("____", data);
      }
    } catch (error) {
      console.log("error", error);
      setData([]);
      setAlert(true);
      console.log("____", data);
    }
  };

  useEffect(() => {
    fetchCalls(body);
    console.log("body", body);
  }, [body.page, body.per_page, body.order_by]);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const getDestination = (call, agents) => {
    if (call.Last_App == "DialList") {
      const agent = agents.find((item) => item.id === call.Last_App_ID);

      if (agent) {
        // return agent.name == agent.label ? (
        return agent.label;
        // ) : (
        //   <>
        //     <div>{agent.name}</div>
        //     <div>{agent.label}</div>
        //   </>
        // );
      } else {
        return "Extension Not Found";
      }
    } else if (call.Last_App == "Queue") {
      if (call.Last_App_Data.includes("-ANSWER-")) {
        // const agent = agents.find((item) => item.label === call.agent_no);

        // if (agent) {
        //   return agent.name == agent.label ? (
        //     agent.label
        //   ) : (
        //     <>
        //       <div>{agent.name}</div>
        //       <div>{agent.label}</div>
        //     </>
        //   );
        // } else {
        return call.agent_no;
        // }
      } else {
        return "Queue";
      }
    } else if (call.Last_App == "Call-Back Detector") {
      let agent_no = call.Last_App_Data.substring(0, 11);

      // const agent = agents.find((item) => item.label === agent_no);

      // if (agent) {
      //   // return agent.name == agent.label ? (
      //     agent.label
      //   // ) : (
      //   //   <>
      //   //     <div>{agent.name}</div>
      //   //     <div>{agent.label}</div>
      //   //   </>
      //   // );
      // } else {
      return agent_no;
      // }
    } else if (call.Last_App == "Message-Box") {
      return "Voicemail";
    } else {
      return "IVR";
    }
  };

  return (
    <>
      {isLoading?.isLoading ? (
        <LinearProgress />
      ) : data.length == 0 ? (
        <Box display="flex" justifyContent="center">
          <Typography sx={{ color: "error.main", fontWeight: "bold" }}>
            {alert
              ? "Oops Something went wrong,Please change fitler and try to fetch minimum days record  !"
              : "No Record Found. Change filter and then try!"}
          </Typography>
        </Box>
      ) : (
        ""
      )}
      {wcAlert && (
        <Box display="flex" justifyContent="center">
          <Typography sx={{ color: "error.main", fontWeight: "bold" }}>
            {wcMessage}
          </Typography>
        </Box>
      )}
      {/* <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          borderRadius: "16px",
          boxShadow: 5,
          p: 3,
          m: 3,
        }}
      > */}
      {/* <MyHeading>Incoming Calls</MyHeading> */}

      {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            fetchCalls();
          }}
        >
          Apply Filter
        </Button>
      </Box> */}

      <TableContainer
        style={{ maxHeight: "calc(100vh - 210px)" }}
        // sx={{ border: 1, borderColor: "divider" }}
        // component={Paper}
      >
        <Table size="small" stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                fontWeight: "bold",
                backgroundColor: theme.palette.mode === "dark" ? "" : "#F9F9F9",
                borderWidth: "1px 0px",
                borderStyle: "solid",
                borderColor: "#E1E1E1",
                // zIndex: "0",
              }}
            >
              {/* <TableCell sx={{ fontWeight: "bold" }} >
                #
              </TableCell> */}
              <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "" : "#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
                // align="center"
              >
                Client #
              </TableCell>
              <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "" : "#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
                // align="center"
              >
                Destination
              </TableCell>
              <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "" : "#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
                // align="center"
              >
                Ivr Options
              </TableCell>
              {/* <TableCell sx={{ fontWeight: "bold" }} >
                Destination 2
              </TableCell> */}
              {/* <TableCell sx={{ fontWeight: "bold" }} >
                Agent #
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }} >
                Last APP
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }} >
                Last APP Data
              </TableCell> */}
              <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "" : "#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
                // align="center"
              >
                Duration
              </TableCell>
              {/* <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor: theme.palette.mode === 'dark' ? "" :"#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
                // align="center"
              >
                Wait Time
              </TableCell> */}
              <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "" : "#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
                // align="center"
              >
                Date
              </TableCell>
              <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "" : "#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
                // align="center"
              >
                Time
              </TableCell>
              <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "" : "#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
                // align="center"
              >
                Status
              </TableCell>
              {(isAllowed("incoming_call_recording") || isAdmin == 1) && (
                <TableCell
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    backgroundColor:
                      theme.palette.mode === "dark" ? "" : "#F9F9F9",
                    borderWidth: "1px 0px",
                    borderStyle: "solid",
                    borderColor: "#E1E1E1",
                    // zIndex: "0",
                  }}
                  // align="center"
                >
                  Recording
                </TableCell>
              )}
              {(isAllowed("call_feedback") || isAdmin == 1) && (
                <TableCell
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    backgroundColor:
                      theme.palette.mode === "dark" ? "" : "#F9F9F9",
                    borderWidth: "1px 0px",
                    borderStyle: "solid",
                    borderColor: "#E1E1E1",
                    // zIndex: "0",
                  }}
                  // align="center"
                >
                  Feedback
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((call, index) => {
              return (
                <TableRow hover key={index}>
                  <StyledTableCell>{"0" + call.cids}</StyledTableCell>
                  <StyledTableCell>
                    {getDestination(call, agents)}
                  </StyledTableCell>
                  <StyledTableCell>{call?.ivr_dtmf}</StyledTableCell>

                  <StyledTableCell>{call.duration}</StyledTableCell>

                  <StyledTableCell>
                    {call.date ? call.date : null}
                  </StyledTableCell>
                  <StyledTableCell>
                    {call.time ? call.time : null}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      fontWeight: "bold",
                      color: call?.Last_App_Data?.includes("-ANSWER-")
                        ? "success.main"
                        : "error.main",
                    }}
                  >
                    {call?.Last_App_Data?.includes("-ANSWER-") ? (
                      <Typography
                        sx={{
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "15px",
                          backgroundColor:
                            theme.palette.mode === "dark" ? "" : "#F9F9F9",
                          color: "#678E31",
                          borderRadius: "40px",
                          px: 2,
                          py: 1,
                          textAlign: "center",
                        }}
                      >
                        Answered
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "15px",
                          backgroundColor:
                            theme.palette.mode === "dark" ? "" : "#F9F9F9",
                          color: "#E43131",
                          borderRadius: "40px",
                          px: 2,
                          py: 1,
                          textAlign: "center",
                        }}
                      >
                        Missed
                      </Typography>
                    )}
                  </StyledTableCell>
                  {(isAllowed("incoming_call_recording") || isAdmin == 1) && (
                    <TableCell
                      sx={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "12px",
                        lineHeight: "15px",
                      }}
                      // align="center"
                    >
                      {call?.Last_App_Data?.includes("-ANSWER-") ? 
                      /* // <ReactAudioPlayer
                      //   class="audios"
                      //   id={index + "audio"}
                      //   // onPlay={(e) => {
                      //   //   console.log(
                      //   //     "query",
                      //   //     document.querySelector(".audios")
                      //   //   );
                      //   // }}
                      //   src={
                      //     call?.Last_App_Data?.includes("-ANSWER-")
                      //       ? call?.Recd_File_Path?.replace(".gsm", ".mp3")
                      //       : ""
                      //   }
                      //   controls
                      // />
                      // :
                    // "N/A"*/
                      <audio
                      className="audios"
                      id={index + "audio"}
                      src={
                            call?.Last_App_Data?.includes("-ANSWER-")
                              ? call?.Recd_File_Path?.replace(".gsm", ".mp3")
                              : ""
                          }
                      controls
                      />:
                      "N/A" 
                      }

                      {/* <RecordingField
                        setWcAlert={setWcAlert}
                        setWcMessage={setWcMessage}
                        call={call}
                        status={"Incoming"}
                      /> */}
                    </TableCell>
                  )}
                  {(isAllowed("call_feedback") || isAdmin == 1) && (
                    <StyledTableCell>{call.feedback}</StyledTableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box container sx={{ display: "flex", justifyContent: "center" }}>
        {/* <Box item spacing={2} sx={{ m: 1 }}>
        </Box> */}
        <Box item spacing={2} sx={{ p: 1 }}>
          <Typography
            sx={{
              mb: 1,
              textAlign: "center",
            }}
          >
            Showing {from_record}-{to_record} out of {total_records} records
          </Typography>

          <Pagination
            // shape="rounded"
            id="pagination"
            //  size="small"
            showFirstButton
            showLastButton
            boundaryCount={2}
            onChange={handleChangePage}
            count={total_pages + 0}
            defaultPage={body.page}
            // shape="rounded"
            color="primary"
            variant="outlined"
            shape="rounded"
            classes={{
              root: classes.ul,
            }}
          />
        </Box>
      </Box>
      {/* <Box container sx={{ display: "flex", justifyContent: "center" }}>

        </Box> */}
      {/* </Grid> */}
    </>
  );
});
