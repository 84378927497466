import React, { useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Header } from "../../components/Header";
import {
  useFetchTimeConditionMutation,
  useStoreTimeConditionMutation,
  useEditTimeConditionMutation,
  useUpdateTimeConditionMutation,
  useDeleteTimeConditionMutation,
} from "../../services/timeConditionApi";
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as IconEdit } from "../../images/editIcon.svg";
import { ReactComponent as IconDelete } from "../../images/deleteIcon.svg";
import { StyledTableCell } from "../call_setting/QueueList";

import { StyledTableRow } from "../Style";
import { MyTable } from "../../components/MyTable";
import { useSelector } from "react-redux";


import {
  Divider,
  LinearProgress,
  Typography,
  Button,
  Grid,
  Alert,
  Box,
  Tabs,
  Tab,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TableRow,
  Tooltip,
  IconButton,
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

import { isAllowed } from "../Functions";

import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddBox from "../../components/AddBox";

import { QueueList } from "../call_setting/QueueList";
import { DialList } from "../call_setting/DialList";
import { TimeConditionList } from "../call_setting/TimeConditionList";
import { IvrList } from "../call_setting/IvrList";
import { VoicemailList } from "../call_setting/VoicemailList";
import { AnnouncementList } from "../call_setting/AnnouncementList";
import { useNavigate } from "react-router-dom";

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value == index && <Box>{children}</Box>}
    </div>
  );
};

export const TimeCondition = () => {
  const isAdmin=useSelector((state)=>state.company.isAdmin);


  const [fetchTimeCondition, isLoading] = useFetchTimeConditionMutation();
  const [storeTimeCondition, storeLoader] = useStoreTimeConditionMutation();
  const [editTimeCondition, editLoader] = useEditTimeConditionMutation();
  const [updateTimeCondition, updateLoader] = useUpdateTimeConditionMutation();
  const [deleteTimeCondition, deleteLoader] = useDeleteTimeConditionMutation();

  const isStoreLoading = storeLoader.isLoading;
  const isEditLoading = editLoader.isLoading;
  const isUpdateLoading = updateLoader.isLoading;
  const isDeleteLoading = deleteLoader.isLoading;

  const [fullScreen, setFullScreen] = useState(false);

  const mediumViewport = useMediaQuery('(min-width:768px)');
  
  const [app, setApp] = useState({ entry_app_id: "" });

  const [addAlert, setAddAlert] = useState({ status: false, msg: "" });

  const [open, setOpen] = React.useState(false);

  const [addErrors, setAddErrors] = useState({});

  const [title, setTitle] = useState("");

  const [editMode, setEditMode] = useState(false);

  const handleClickOpen = () => {
    setTitle("Add New Time Condition");
    setEditMode(false);
    setOpen(true);
    // setaddFunction(()=>addQueue)
    // setaddLoading(isAddQueueLoading)
  };

  const handleEditOpen = () => {
    setTitle("Edit Time Condition");
    setEditMode(true);
    setOpen(true);
  };

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    totalRecords: 0,
    fromRecord: 0,
    toRecord: 0,
    totalPages: 0,
  });

  const [body, setBody] = useState({ page: 1, search: "" });

  const fetchTC = async () => {
    try {
      const response = await fetchTimeCondition(body).unwrap();

      console.log("Time Condition response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result", response.time_conditions);

        setData(response.time_conditions.data);

        setPagination({
          ...pagination,
          totalPages: response.time_conditions.last_page,
          totalRecords: response.time_conditions.total,
          fromRecord: response.time_conditions.from,
          toRecord: response.time_conditions.to,
          toRecord: response.time_conditions.to,
        });

        if (
          response.time_conditions.current_page >
          response.time_conditions.last_page
        ) {
          setBody({ ...body, page: 1 });

          console.log("current_page > last_page");
        }
      } else {
        console.log("____false");
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };

  const storeTC = async (values) => {
    try {
      const response = await storeTimeCondition(values).unwrap();

      console.log("time condition response", response);

      if (response.status === true) {
        fetchTC();

        handleClose();

        toast.success("Record has been added successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);

      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const editTC = async (id) => {
    try {
      const response = await editTimeCondition({ id: id }).unwrap();

      console.log("edit time condition", response);

      if (response.status === true) {
        formik.setFieldValue("id", response.time_condition?.id);
        formik.setFieldValue("tc_name", response.time_condition?.TC_Name);

        formik.setFieldValue(
          "tc_matched_type",
          response.time_condition?.DEST_true_App
        );
        formik.setFieldValue(
          "tc_matched_id",
          response.time_condition?.DEST_true_ID
        );

        formik.setFieldValue(
          "tc_notMatched_type",
          response.time_condition?.DEST_false_App
        );
        formik.setFieldValue(
          "tc_notMatched_id",
          response.time_condition?.DEST_false_ID
        );

        const times = splitStr(response.time_condition?.Time_Range, ",", 0);
        const days = splitStr(response.time_condition?.Time_Range, ",", 1);

        formik.setFieldValue("start_day", splitStr(days, "-", 0));
        formik.setFieldValue("end_day", splitStr(days, "-", 1));

        formik.setFieldValue("start_time", splitStr(times, "-", 0));
        formik.setFieldValue("end_time", splitStr(times, "-", 1));

        handleEditOpen();
      } else {
        console.log("____false");
      }
    } catch (error) {
      console.log("____catch", error);
    }
  };

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const [value, setValue] = useState(0);

  const updateTC = async (values) => {
    try {
      const response = await updateTimeCondition(values).unwrap();

      console.log("update time condition response", response);

      if (response.status === true) {
        fetchTC();

        handleClose();

        toast.success("Record has been update successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);
      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add annonuce errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const deleteTC = async (id) => {
    if (window.confirm("are you sure you want to delete this record?")) {
      try {
        const response = await deleteTimeCondition({ id: id }).unwrap();

        console.log("delete Time Condition response", response);

        if (response.status === true) {
          fetchTC();
          toast.success("Record has been deleted successfully !");
        } else {
          console.log("____false", response);

          toast.error(response.message);
        }
      } catch (error) {
        console.log("____catch", error);

        toast.error("Oops something went wrong !");
      }
    }
  };

  const initialValues = {
    tc_name: "",
    start_day: "",
    end_day: "",
    start_time: "",
    end_time: "",
    tc_matched_type: "",
    tc_matched_id: "",
    tc_notMatched_type: "",
    tc_notMatched_id: "",
  };

  const onSubmit = (values, actions) => {
    console.log("form values", values);

    if (editMode) {
      updateTC(values);
    } else {
      storeTC(values);
    }
  };

  const validationSchema = Yup.object({
    tc_name: Yup.string().required("Required !"),
    start_day: Yup.string().required("Required !"),
    end_day: Yup.string().required("Required !"),
    start_time: Yup.string().required("Required !"),
    end_time: Yup.string().required("Required !"),
    tc_matched_type: Yup.string().required("Required !"),
    tc_matched_id: Yup.string().required("Required !"),
    tc_notMatched_type: Yup.string().required("Required !"),
    tc_notMatched_id: Yup.string().required("Required !"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    // validateOnMount:true,
  });

  console.log("formik", formik);

  const handleClose = () => {
    setOpen(false);
    setAddErrors({});
    setAddAlert({ status: false, msg: "" });
    formik.resetForm();
    setApp({ ...app, entry_app_id: "" });
  };

  const destinations = [
    "Queue",
    "IVR",
    "DialList",
    "TimingCondition",
    "Announcement",
    "VoiceMail",
    "Hangup",
  ];

  const [selectedDestination, setSelectedDestination] = useState("");

  const getSelectedDestination = (name) => {
    setSelectedDestination(name);
  };

  useEffect(() => {
    fetchTC();
  }, [body.page]);

  const splitStr = (string, symbol, index) => {
    const str = string.split(symbol);

    // console.log('split string',str)

    return str[index];
  };

  const columns = ["Name", "Day Range", "Time Range", "Action"];

  const navigate = useNavigate();

  if (!isAllowed("time_condition_access")  ) {
    return navigate("/forbidden");
  }

  return (
    <>
      <Header title={"Time Conditions"} />

      <MyTable
        // title="Time Conditions"
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={fetchTC}
        handleClickOpen={handleClickOpen}
        isEditLoading={isEditLoading}
        addButton={
          isAllowed("time_condition_create") || isAdmin == 1 ? true : false
        }
      >
        {data?.map((item, index) => {
          return (
            <TableRow hover key={index} sx={{}}>
              <StyledTableCell>{item.TC_Name}</StyledTableCell>
              <StyledTableCell>
                {splitStr(item.Time_Range, ",", 1)}
              </StyledTableCell>
              <StyledTableCell>
                {splitStr(item.Time_Range, ",", 0)}
              </StyledTableCell>

              <StyledTableCell>
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    // sx={{ m: 1 }}
                    color={"info"}
                    variant="contained"
                    onClick={() => editTC(item.id)}
                    disabled={!isAllowed("time_condition_edit")  }
                  >
                    <IconEdit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    size="small"
                    // sx={{ m: 1 }}
                    color={"error"}
                    variant="contained"
                    onClick={() => deleteTC(item.id)}
                    disabled={
                      !isAllowed("time_condition_delete")  
                    }
                  >
                    <IconDelete stroke="red" />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </MyTable>

      {/* ________Add Box___________________ */}
      <AddBox
        title={title}
        open={open}
        handleClose={handleClose}
        formik={formik}
        // fullScreen={true}
        fullWidth={true}
        style={{
          maxWidth: "100%",
          // height: "560px",
          height: "100vh",
          boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
        }}
      >
        {addAlert.status ? (
          <Alert severity="error">{addAlert.msg}</Alert>
        ) : null}

        {isStoreLoading || (isUpdateLoading && <LinearProgress />)}

        <Grid sx={{ mt: 1 }} container spacing={1}>
          <Grid xs={12} sm={12} md={4} lg={4} xl={4} item>
            <TextField
              sx={{ width: "100%" }}
              error={
                (formik.touched.tc_name && formik.errors.tc_name) ||
                addErrors?.name
              }
              // margin="normal"
              size="small"
              // sx={{m:1}}
              required
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Time Condition Name
                </Typography>
              }
              id="tc_name"
              name="tc_name"
              {...formik.getFieldProps("tc_name")}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              // onBlur={formik.handleBlur}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.tc_name && formik.errors.tc_name ? (
              <Alert severity="error">{formik.errors.tc_name}</Alert>
            ) : null}
            {addErrors?.tc_name && (
              <Alert severity="error">{addErrors?.tc_name[0]}</Alert>
            )}
          </Grid>

          <Grid xs={12} sm={12} md={4} lg={2} xl={2} item>
            <FormControl
              size="small"
              fullWidth
              // margin="normal"
            >
              <InputLabel
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
                id="cat-select-label"
              >
                Start Day
              </InputLabel>

              <Select
                labelId="cat-select-label"
                label="Start Day"
                id="start_day"
                name="start_day"
                {...formik.getFieldProps("start_day")}
                error={
                  (formik.touched.start_day && formik.errors.start_day) ||
                  addErrors?.start_day
                }
              >
                <MenuItem value="Sun">Sunday</MenuItem>
                <MenuItem value="Mon">Monday</MenuItem>
                <MenuItem value="Tue">Tuesday</MenuItem>
                <MenuItem value="Wed">Wednesday</MenuItem>
                <MenuItem value="Thu">Thursday</MenuItem>
                <MenuItem value="Fri">Friday</MenuItem>
                <MenuItem value="Sat">Saturday</MenuItem>
              </Select>
            </FormControl>
            {formik.touched.start_day && formik.errors.start_day ? (
              <Alert severity="error">{formik.errors.start_day}</Alert>
            ) : null}
            {addErrors?.start_day && (
              <Alert severity="error">{addErrors?.start_day[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={2} xl={2} item>
            <FormControl
              size="small"
              fullWidth
              // margin="normal"
            >
              <InputLabel
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
                id="cat-select-label"
              >
                End Day
              </InputLabel>

              <Select
                labelId="cat-select-label"
                label="End Day"
                id="end_day"
                name="end_day"
                {...formik.getFieldProps("end_day")}
                error={
                  (formik.touched.end_day && formik.errors.end_day) ||
                  addErrors?.end_day
                }
              >
                <MenuItem value="Sun">Sunday</MenuItem>
                <MenuItem value="Mon">Monday</MenuItem>
                <MenuItem value="Tue">Tuesday</MenuItem>
                <MenuItem value="Wed">Wednesday</MenuItem>
                <MenuItem value="Thu">Thursday</MenuItem>
                <MenuItem value="Fri">Friday</MenuItem>
                <MenuItem value="Sat">Saturday</MenuItem>
              </Select>
            </FormControl>
            {formik.touched.end_day && formik.errors.end_day ? (
              <Alert severity="error">{formik.errors.end_day}</Alert>
            ) : null}
            {addErrors?.end_day && (
              <Alert severity="error">{addErrors?.end_day[0]}</Alert>
            )}
          </Grid>
          {/*Start Time*/}
          <Grid xs={12} sm={12} md={4} lg={2} xl={2} item>
            <TextField
              sx={{ width: "100%" }}
              error={
                (formik.touched.start_time && formik.errors.start_time) ||
                addErrors?.start_time
              }
              // margin="normal"
              name="start_time"
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Start Time
                </Typography>
              }
              type="time"
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formik.values.start_time}
              onChange={(e) => {
                formik.setFieldValue("start_time", e.target.value);
              }}
            />
            {formik.touched.start_time && formik.errors.start_time ? (
              <Alert severity="error">{formik.errors.start_time}</Alert>
            ) : null}
            {addErrors?.start_time && (
              <Alert severity="error">{addErrors?.start_time[0]}</Alert>
            )}
          </Grid>
          {/*End Time */}
          <Grid xs={12} sm={12} md={4} lg={2} xl={2} item>
            <TextField
              sx={{ width: "100%" }}
              error={
                (formik.touched.end_time && formik.errors.end_time) ||
                addErrors?.end_time
              }
              // margin="normal"
              name="end_time"
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  End Time
                </Typography>
              }
              type="time"
              size="small"
              variant="outlined"
              value={formik.values.end_time}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                formik.setFieldValue("end_time", e.target.value);
              }}
            />
          </Grid>

          <Grid item>
            <Box sx={{}}>
              <Tabs
                centered
                value={value}
                onChange={handleTabs}
                textColor="primary"
                indicatorColor="primary"
                orientation={mediumViewport ?  "horizontal" :"vertical"} 
              >
                <Tab
                  sx={{ textTransform: "none", fontWeight: "bold" }}
                  label={
                    <>
                      <Typography
                        sx={{
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "12px",
                          lineHeight: "15px",
                        }}
                      >
                        Destination When Time Matches
                      </Typography>
                    </>
                  }
                ></Tab>
                <Tab
                  sx={{ textTransform: "none", fontWeight: "bold" }}
                  label={
                    <>
                      {" "}
                      <Typography
                        sx={{
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "12px",
                          lineHeight: "15px",
                        }}
                      >
                        Destination When Time Doesn't Match
                      </Typography>
                    </>
                  }
                ></Tab>
              </Tabs>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 0 }}>
            <Card
              sx={{
                width: "100%",
                height: "100%",
                boxShadow: "none",
                backgroundColor: "",
              }}
            >
              <Box>
                <TabPanel value={value} index={0}>
                  <Grid container spacing={1}>
                    <Grid
                      sx={{ borderRight: "1px solid #F2F2F2" }}
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={2}
                      xl={2}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "14px",
                          lineHeight: "17.64px",
                          pl: 3,
                        }}
                      >
                        Select Destination Type
                      </Typography>
                      <List
                        dense
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        {destinations?.map((value) => {
                          const labelId = `checkbox-list-secondary-label-${value}`;
                          return (
                            <ListItem
                              key={value}
                              onClick={(event) => {
                                console.log("value", value);
                                formik.setFieldValue("tc_matched_type", value);

                                // formik.setFieldValue("destination_type_id", "");

                                // setApp({ ...app, entry_app_id: "" });
                              }}
                              sx={{
                                borderBottom: "1px solid #F2F2F2",
                                padding: "5px 13px 5px 17px",
                                gap: "160px",
                                background:
                                  formik?.values?.tc_matched_type == value
                                    ? "#FBFBFB"
                                    : "",
                                color:
                                  formik?.values?.tc_matched_type == value
                                    ? "black"
                                    : "",
                                fontWeight:
                                  formik?.values?.tc_matched_type == value
                                    ? "700"
                                    : "500",
                              }}
                              secondaryAction={
                                <ChevronRightOutlinedIcon
                                  sx={{
                                    color:
                                      formik?.values?.tc_matched_type == value
                                        ? "black"
                                        : "#767676",
                                  }}
                                />
                              }
                              disablePadding
                            >
                              <ListItemButton>
                                <ListItemText
                                  id={labelId}
                                  primary={
                                    <>
                                      <Typography
                                        sx={{
                                          fontWeight:
                                            formik?.values?.tc_matched_type ==
                                            value
                                              ? "700"
                                              : "500",
                                          fontSize: "12px",
                                          lineHeight: "15px",
                                          color:
                                            formik?.values?.tc_matched_type ==
                                            value
                                              ? "black"
                                              : "#767676",
                                        }}
                                      >
                                        {value}
                                      </Typography>
                                    </>
                                  }
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} lg={10} xl={10} sx={{}}>
                      {addErrors?.tc_matched_id && (
                        <Alert severity="error">
                          {addErrors?.tc_matched_id[0]}
                        </Alert>
                      )}

                      {formik.values.tc_matched_type == "Queue" ? (
                        <QueueList
                          app_id={app.entry_app_id}
                          app={app}
                          setApp={setApp}
                          getSelectedDestination={getSelectedDestination}
                          formik={formik}
                          type={"tc_matched"}
                        />
                      ) : formik.values.tc_matched_type == "TimingCondition" ? (
                        <TimeConditionList
                          getSelectedDestination={getSelectedDestination}
                          app_id={app.entry_app_id}
                          app={app}
                          setApp={setApp}
                          formik={formik}
                          type={"tc_matched"}
                        />
                      ) : formik.values.tc_matched_type == "DialList" ? (
                        <DialList
                          getSelectedDestination={getSelectedDestination}
                          app_id={app.entry_app_id}
                          app={app}
                          setApp={setApp}
                          formik={formik}
                          type={"tc_matched"}
                        />
                      ) : formik.values.tc_matched_type == "IVR" ? (
                        <IvrList
                          getSelectedDestination={getSelectedDestination}
                          app_id={app.entry_app_id}
                          app={app}
                          setApp={setApp}
                          formik={formik}
                          type={"tc_matched"}
                        />
                      ) : formik.values.tc_matched_type == "Announcement" ? (
                        <AnnouncementList
                          getSelectedDestination={getSelectedDestination}
                          app_id={app.entry_app_id}
                          app={app}
                          setApp={setApp}
                          formik={formik}
                          type={"tc_matched"}
                        />
                      ) : formik.values.tc_matched_type == "VoiceMail" ? (
                        <VoicemailList
                          app_id={app.entry_app_id}
                          app={app}
                          setApp={setApp}
                          getSelectedDestination={getSelectedDestination}
                          formik={formik}
                          type={"tc_matched"}
                        />
                      ) : formik.values.tc_matched_type == "Hangup" ? (
                        <TextField
                          size="small"
                          label="Input Text"
                          value={formik.values.tc_matched_id || ""}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "tc_matched_id",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        ""
                      )}
                      {/* </Grid> */}
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <Grid container spacing={1}>
                    <Grid
                      sx={{ borderRight: "1px solid #F2F2F2" }}
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={2}
                      xl={2}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "14px",
                          lineHeight: "17.64px",
                          pl: 3,
                        }}
                      >
                        Select Destination Type
                      </Typography>
                      <List
                        dense
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        {destinations?.map((value) => {
                          const labelId = `checkbox-list-secondary-label-${value}`;
                          return (
                            <ListItem
                              key={value}
                              onClick={(event) => {
                                console.log("value", value);
                                formik.setFieldValue(
                                  "tc_notMatched_type",
                                  value
                                );

                                // formik.setFieldValue("destination_type_id", "");

                                // setApp({ ...app, entry_app_id: "" });
                              }}
                              sx={{
                                borderBottom: "1px solid #F2F2F2",
                                padding: "5px 13px 5px 17px",
                                gap: "160px",
                                background:
                                  formik?.values?.tc_notMatched_type == value
                                    ? "#FBFBFB"
                                    : "",
                                color:
                                  formik?.values?.tc_notMatched_type == value
                                    ? "black"
                                    : "",
                                fontWeight:
                                  formik?.values?.tc_notMatched_type == value
                                    ? "700"
                                    : "500",
                              }}
                              secondaryAction={
                                <ChevronRightOutlinedIcon
                                  sx={{
                                    color:
                                      formik?.values?.tc_notMatched_type ==
                                      value
                                        ? "black"
                                        : "#767676",
                                  }}
                                />
                              }
                              disablePadding
                            >
                              <ListItemButton>
                                <ListItemText
                                  id={labelId}
                                  primary={
                                    <>
                                      <Typography
                                        sx={{
                                          fontWeight:
                                            formik?.values
                                              ?.tc_notMatched_type == value
                                              ? "700"
                                              : "500",
                                          fontSize: "12px",
                                          lineHeight: "15px",
                                          color:
                                            formik?.values
                                              ?.tc_notMatched_type == value
                                              ? "black"
                                              : "#767676",
                                        }}
                                      >
                                        {value}
                                      </Typography>
                                    </>
                                  }
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                    {/* <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                    <FormControl size="small" fullWidth margin="normal">
                      <InputLabel sx={{}}>Destination</InputLabel>

                      <Select
                        labelId="cat-select-label"
                        label="Destination"
                        id="tc_notMatched_type"
                        name="tc_notMatched_type"
                        {...formik.getFieldProps("tc_notMatched_type")}
                        error={
                          (formik.touched.tc_notMatched_type &&
                            formik.errors.tc_notMatched_type) ||
                          addErrors?.tc_notMatched_type
                        }
                      >
                        <MenuItem value="Queue">Queue</MenuItem>
                        <MenuItem value="DialList">DialList</MenuItem>
                        <MenuItem value="IVR">IVR</MenuItem>
                        <MenuItem value="TimingCondition">
                          TimingCondition
                        </MenuItem>
                        <MenuItem value="Announcement">Announcement</MenuItem>
                        <MenuItem value="VoiceMail">VoiceMail</MenuItem>
                        <MenuItem value="Hangup">Hangup</MenuItem>
                      </Select>
                      <FormHelperText>
                        Destination When Time Not Matches
                      </FormHelperText>
                    </FormControl>
                    {formik.touched.tc_notMatched_type &&
                    formik.errors.tc_notMatched_type ? (
                      <Alert severity="error">
                        {formik.errors.tc_notMatched_type}
                      </Alert>
                    ) : null}
                    {addErrors?.tc_notMatched_type && (
                      <Alert severity="error">
                        {addErrors?.tc_notMatched_type[0]}
                      </Alert>
                    )}
                  </Grid> */}
                    <Grid item xs={12} sm={12} md={8} lg={10} xl={10} sx={{}}>
                      {addErrors?.tc_notMatched_id && (
                        <Alert severity="error">
                          {addErrors?.tc_notMatched_id[0]}
                        </Alert>
                      )}

                      {formik.values.tc_notMatched_type == "Queue" ? (
                        <QueueList
                          app_id={app.entry_app_id}
                          app={app}
                          setApp={setApp}
                          getSelectedDestination={getSelectedDestination}
                          formik={formik}
                          type={"tc_notMatched"}
                        />
                      ) : formik.values.tc_notMatched_type ==
                        "TimingCondition" ? (
                        <TimeConditionList
                          getSelectedDestination={getSelectedDestination}
                          app_id={app.entry_app_id}
                          app={app}
                          setApp={setApp}
                          formik={formik}
                          type={"tc_notMatched"}
                        />
                      ) : formik.values.tc_notMatched_type == "DialList" ? (
                        <DialList
                          getSelectedDestination={getSelectedDestination}
                          app_id={app.entry_app_id}
                          app={app}
                          setApp={setApp}
                          formik={formik}
                          type={"tc_notMatched"}
                        />
                      ) : formik.values.tc_notMatched_type == "IVR" ? (
                        <IvrList
                          getSelectedDestination={getSelectedDestination}
                          app_id={app.entry_app_id}
                          app={app}
                          setApp={setApp}
                          formik={formik}
                          type={"tc_notMatched"}
                        />
                      ) : formik.values.tc_notMatched_type == "Announcement" ? (
                        <AnnouncementList
                          getSelectedDestination={getSelectedDestination}
                          app_id={app.entry_app_id}
                          app={app}
                          setApp={setApp}
                          formik={formik}
                          type={"tc_notMatched"}
                        />
                      ) : formik.values.tc_notMatched_type == "VoiceMail" ? (
                        <VoicemailList
                          app_id={app.entry_app_id}
                          app={app}
                          setApp={setApp}
                          getSelectedDestination={getSelectedDestination}
                          formik={formik}
                          type={"tc_notMatched"}
                        />
                      ) : formik.values.tc_notMatched_type == "Hangup" ? (
                        <TextField
                          size="small"
                          label="Input Text"
                          value={formik.values.tc_notMatched_id || ""}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "tc_notMatched_id",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        ""
                      )}
                      {/* </Grid> */}
                    </Grid>
                  </Grid>
                </TabPanel>
              </Box>
            </Card>
          </Grid>

          {/* <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <Divider>Destination When Time Matches</Divider>
          </Grid> */}

          {/* 
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <Divider>Destination When Time Doesn't Match</Divider>
          </Grid> */}
        </Grid>
      </AddBox>
    </>
  );
};
