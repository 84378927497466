import React, { useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  useFetchClientsMutation,
  useCreateClientsMutation,
  useStoreClientsMutation,
  useEditClientsMutation,
  useUpdateClientsMutation,
  useDeleteClientsMutation,
} from "../../../services/admin/adminClientApi";
import { Header } from "../../../components/Header";
import { styled } from "@mui/material/styles";
import { ReactComponent as IconEdit } from "../../../images/editIcon.svg";
import { ReactComponent as IconDelete } from "../../../images/deleteIcon.svg";
import { StyledTableCell } from "../../call_setting/QueueList";

import {
  FormControlLabel,
  FormGroup,
  FormControl,
  Autocomplete,
  Stack,
  Input,
  Typography,
  Button,
  TableRow,
  Alert,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Switch,
  Tooltip,
  IconButton,
} from "@mui/material";
import { MyTable } from "../../../components/MyTable";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { StyledTableRow } from "../../Style";
import AddBox from "../../../components/AddBox";
import { isAllowed } from "../../Functions";
import { useNavigate } from "react-router-dom";

export const Client = () => {
  const role_id = localStorage.getItem("role_id");

  const [fetchClients, fetchLoader] = useFetchClientsMutation();
  const [createClients, createLoader] = useCreateClientsMutation();
  const [storeClients, storeLoader] = useStoreClientsMutation();
  const [editClients, editLoader] = useEditClientsMutation();
  const [updateClients, updateLoader] = useUpdateClientsMutation();
  const [deleteClients, deleteLoader] = useDeleteClientsMutation();

  const isFetchLoading = fetchLoader;
  const isCreateLoading = createLoader.isLoading;
  const isStoreLoading = storeLoader.isLoading;
  const isEditLoading = editLoader.isLoading;
  const isUpdateLoading = updateLoader.isLoading;
  const isDeleteLoading = deleteLoader.isLoading;

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    totalRecords: 0,
    fromRecord: 0,
    toRecord: 0,
    totalPages: 0,
  });

  const [body, setBody] = useState({ page: 1, search: "" });

  const [addAlert, setAddAlert] = useState({ status: false, msg: "" });

  const [open, setOpen] = React.useState(false);

  const [addErrors, setAddErrors] = useState({});

  const [title, setTitle] = useState("");

  const [editMode, setEditMode] = useState(false);

  const handleClickOpen = () => {
    setTitle("Add new Client");
    setEditMode(false);
    setOpen(true);
  };

  const Input = styled("input")({
    display: "none",
  });

  const getClients = async () => {
    try {
      const response = await fetchClients(body).unwrap();

      console.log("client response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result", response.clients);

        setData(response.clients.data);

        setPagination({
          ...pagination,
          totalPages: response.clients.last_page,
          totalRecords: response.clients.total,
          fromRecord: response.clients.from,
          toRecord: response.clients.to,
          toRecord: response.clients.to,
        });

        if (response.clients.current_page > response.clients.last_page) {
          setBody({ ...body, page: 1 });

          console.log("current_page > last_page");
        }
      } else {
        console.log("____false");
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };

  useEffect(() => {
    getClients();
  }, [body.page]);

  const [masterNumbers, setMasterNumbers] = useState([]);
  const [singleMasterNumber, setSingleMasterNumber] = useState({
    id: null,
    did: "",
    client_id: null,
  });

  const handleFileChange = (e) => {
    let file = e.target.files[0];

    console.log("logo", file);

    formik.setFieldValue("logo", file);

    console.log("value file", formik.values.logo);
  };

  const createClient = async () => {
    try {
      const response = await createClients().unwrap();

      console.log("create client response", response);

      if (response.status === true) {
        setMasterNumbers(response.did_numbers);
      } else {
        console.log("____false");
      }
    } catch (error) {
      console.log("____catch", error);
    }
  };

  useEffect(() => {
    createClient();
  }, []);

  const storeClient = async (values) => {
    try {
      const response = await storeClients(values).unwrap();

      console.log("store client response", response);

      if (response.status === true) {
        getClients();

        createClient();

        handleClose();

        toast.success("Record has been added successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);

      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const editClient = async (id) => {
    try {
      const response = await editClients({ id: id }).unwrap();

      console.log("edit clients response", response);

      if (response.status === true) {
        formik.setFieldValue("id", response.client?.id);
        formik.setFieldValue("name", response.client?.name);
        formik.setFieldValue("email", response.client?.email_address);
        formik.setFieldValue("call_limit", response.client?.call_limit);
        formik.setFieldValue(
          "maximum_extensions",
          response.client?.maximum_extensions
        );
        formik.setFieldValue("phone", response.client?.phone);
        formik.setFieldValue("status", response.client?.status);
        formik.setFieldValue("did_number", response.client?.did_number);
        formik.setFieldValue("did_number_id", response?.did_number?.id);

        setSingleMasterNumber(response?.did_number);

        handleEditOpen();
      } else {
        console.log("____false");
      }
    } catch (error) {
      console.log("____catch", error);
    }
  };

  const handleEditOpen = () => {
    setTitle("Edit Client");
    setEditMode(true);
    setOpen(true);
  };

  const updateClient = async (values) => {
    try {
      const response = await updateClients(values).unwrap();

      console.log("update user response", response);

      if (response.status === true) {
        getClients();

        createClient();

        handleClose();

        toast.success("Record has been update successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);
      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add annonuce errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const deleteClient = async (id) => {
    if (window.confirm("are you sure you want to delete this record?")) {
      try {
        const response = await deleteClients({ id: id }).unwrap();

        console.log("delete client response", response);

        if (response.status === true) {
          getClients();

          createClient();

          toast.success("Record has been deleted successfully !");
        } else {
          console.log("____false", response);

          toast.error(response.message);
        }
      } catch (error) {
        console.log("____catch", error);

        toast.error("Oops something went wrong !");
      }
    }
  };

  const initialValues = {
    name: "",
    did_number_id: null,
    phone: null,
    email: "",
    status: 0,
    call_limit: null,
    maximum_extensions: null,
    // logo: null,
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid Email Format").required("Required !"),
    name: Yup.string().required("Required !"),
    // .matches(
    //   /^[a-z0-9]+$/i,
    //   "The user name must only contain letters or numbers."
    // ),
    phone: Yup.number().nullable().required("Required !"),

    did_number_id: Yup.number().nullable().required("Required !"),
    call_limit: Yup.number().nullable().required("Required !"),
    maximum_extensions: Yup.number().nullable().required("Required !"),
    status: Yup.number().nullable().required("Required !"),
    // logo: Yup.mixed()
    //   .required("Photo Required !")
    //   .nullable()
    //   .test(
    //     "fileSize",
    //     "File should be less than 10mb",
    //     (logo) =>{ logo && checkIfFileSizeBig(logo?.size); }
    //   )
    //   .test(
    //     "fileType",
    //     "Unsupported File Format, Allowed .jpg , .jpeg , .png",
    //     (logo) =>
    //       logo && ["image/jpg", "image/jpeg", "image/png"].includes(logo?.type)
    //   ),
  });

  const onSubmit = (values, actions) => {
    console.log("form values", values);

    const form_data = new FormData();

    form_data.append("id", formik.values.id);
    form_data.append("name", formik.values.name);
    form_data.append("email", formik.values.email);
    form_data.append("did_number_id", formik.values.did_number_id);
    form_data.append("did_number", formik.values.did_number);
    form_data.append("phone", formik.values.phone);
    form_data.append("call_limit", formik.values.call_limit);
    form_data.append("maximum_extensions", formik.values.maximum_extensions);
    form_data.append("status", formik.values.status);
    // form_data.append("logo",formik.values.logo);

    if (editMode) {
      updateClient(form_data);
    } else {
      storeClient(form_data);
    }
  };

  const checkIfFileSizeBig = (fileSize) => {
    let valid = true;
    const size = fileSize / 1024 / 1024;
    if (size > 10) {
      valid = false;
    }
    return valid;
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    // validateOnMount:true,
    // validateOnBlur: false,
    // validateOnChange:false,
    // validate,
  });

  console.log("formik", formik);

  const handleClose = () => {
    setOpen(false);
    setAddErrors({});
    setAddAlert({ status: false, msg: "" });

    setSingleMasterNumber({ id: null, client_id: null, did: "" });
    formik.resetForm();
  };

  const columns = [
    "Name",
    "Master Number",
    "Call Limit",
    "Max Ext",
    "Status",
    "Action",
  ];

  const handleCheck = (e, name) => {
    const { checked } = e.target;

    if (checked) {
      formik.setFieldValue(name, 1);
    } else {
      formik.setFieldValue(name, 0);
    }
  };

  const navigate = useNavigate();

  if (!isAllowed("client_access") || role_id != 1) {
    return navigate("/forbidden");
  }

  return (
    <>
      <Header title={"Clients"} />

      <MyTable
        // title="Clients"
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isFetchLoading}
        isEditLoading={isEditLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={getClients}
        handleClickOpen={handleClickOpen}
        addButton={isAllowed("client_create") && role_id == 1 ? true : false}
      >
        {data?.map((item, index) => {
          return (
            <TableRow hover key={index}>
              <StyledTableCell>{item.name}</StyledTableCell>
              <StyledTableCell>{item.did_number}</StyledTableCell>
              <StyledTableCell>{item.call_limit}</StyledTableCell>
              <StyledTableCell>{item.maximum_extensions}</StyledTableCell>

              <StyledTableCell
                sx={{ color: item.status == 0 ? "error.main" : "success.main" }}
                align="center"
              >
                {item.status == 0 ? "Inactive" : "Active"}
              </StyledTableCell>

              <StyledTableCell>
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    sx={{ m: 1 }}
                    color={"info"}
                    variant="contained"
                    disabled={!isAllowed("client_edit") || role_id != 1}
                    onClick={() => editClient(item.id)}
                  >
                    <IconEdit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    disabled={!isAllowed("client_delete") || role_id != 1}
                    size="small"
                    // sx={{ m: 1 }}
                    color={"error"}
                    variant="contained"
                    onClick={() => deleteClient(item.id)}
                  >
                    <IconDelete stroke="red" />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </MyTable>

      {/* ________Add Box___________________ */}
      <AddBox
        title={title}
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        addLoading={isStoreLoading}
        formik={formik}
        fullWidth={false}
        style={{
          // width: "333px",
          boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
        }}
      >
        {addAlert.status ? (
          <Alert severity="error">{addAlert.msg}</Alert>
        ) : null}

        {(isStoreLoading || isUpdateLoading) && <LinearProgress />}

        <Grid container spacing={1}>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <TextField
              // disabled={editMode}
              size="small"
              error={
                (formik.touched.name && formik.errors.name) || addErrors?.name
              }
              // margin="normal"
              sx={{ width: "100%" }}
              required
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Name
                </Typography>
              }
              id="name"
              name="name"
              {...formik.getFieldProps("name")}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              // onBlur={formik.handleBlur}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.name && formik.errors.name ? (
              <Alert severity="error">{formik.errors.name}</Alert>
            ) : null}
            {addErrors?.name && (
              <Alert severity="error">{addErrors?.name[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <TextField
              error={
                (formik.touched.email && formik.errors.email) ||
                addErrors?.email
              }
              type="email"
              // margin="normal"
              sx={{ width: "100%" }}
              required
              id="email"
              name="email"
              size="small"
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Email
                </Typography>
              }
              // minRows={4}
              {...formik.getFieldProps("email")}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.email && formik.errors.email ? (
              <Alert severity="error">{formik.errors.email}</Alert>
            ) : null}
            {addErrors?.email && (
              <Alert severity="error">{addErrors?.email[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <TextField
              size="small"
              error={
                (formik.touched.call_limit && formik.errors.call_limit) ||
                addErrors?.call_limit
              }
              // margin="normal"
              sx={{ width: "100%" }}
              required
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Call Limit
                </Typography>
              }
              id="call_limit"
              name="call_limit"
              {...formik.getFieldProps("call_limit")}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              // onBlur={formik.handleBlur}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.call_limit && formik.errors.call_limit ? (
              <Alert severity="error">{formik.errors.call_limit}</Alert>
            ) : null}
            {addErrors?.call_limit && (
              <Alert severity="error">{addErrors?.call_limit[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <TextField
              size="small"
              error={
                (formik.touched.maximum_extensions &&
                  formik.errors.maximum_extensions) ||
                addErrors?.maximum_extensions
              }
              // margin="normal"
              sx={{ width: "100%" }}
              required
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Maximum Extension
                </Typography>
              }
              id="maximum_extensions"
              name="maximum_extensions"
              {...formik.getFieldProps("maximum_extensions")}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              // onBlur={formik.handleBlur}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.maximum_extensions &&
            formik.errors.maximum_extensions ? (
              <Alert severity="error">{formik.errors.maximum_extensions}</Alert>
            ) : null}
            {addErrors?.maximum_extensions && (
              <Alert severity="error">{addErrors?.maximum_extensions[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <TextField
              error={
                (formik.touched.phone && formik.errors.phone) ||
                addErrors?.phone
              }
              type="text"
              // margin="normal"
              sx={{ width: "100%" }}
              required
              id="phone"
              name="phone"
              size="small"
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Phone
                </Typography>
              }
              // minRows={4}
              {...formik.getFieldProps("phone")}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <Alert severity="error">{formik.errors.phone}</Alert>
            ) : null}
            {addErrors?.phone && (
              <Alert severity="error">{addErrors?.phone[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <Autocomplete
              // disabled={editMode}
              // disableCloseOnSelect={true}
              filterSelectedOptions={true}
              id="masterNumbers"
              options={masterNumbers}
              getOptionLabel={(option) => option.did}
              renderInput={(params) => (
                <TextField
                  error={
                    (formik.touched.did_number_id &&
                      formik.errors.did_number_id) ||
                    addErrors?.did_number_id
                  }
                  {...params}
                  label={
                    <Typography
                      variant="span"
                      sx={{
                        fontWeight: 500,
                        fontSize: "13px",
                        lineHeight: "16px",
                      }}
                    >
                      Master Number
                    </Typography>
                  }
                  // placeholder="Music on Hold"
                  {...formik.getFieldProps("did_number_id")}
                />
              )}
              size="small"
              // margin="normal"
              sx={{ width: "100%" }}
              defaultValue={singleMasterNumber}
              onChange={(e, value) => {
                console.log("master number value ", value);
                formik.setFieldValue("did_number_id", value?.id);
                formik.setFieldValue("did_number", value?.did);
                setSingleMasterNumber(value);
              }}
              value={
                singleMasterNumber || { id: null, client_id: null, did: "" }
              }
              name="did_number_id"
              // {...formik.getFieldProps("musiconhold_id")}
            />
            {formik.touched.did_number_id && formik.errors.did_number_id ? (
              <Alert severity="error">{formik.errors.did_number_id}</Alert>
            ) : null}
            {addErrors?.did_number_id && (
              <Alert severity="error">{addErrors?.did_number_id[0]}</Alert>
            )}
          </Grid>
          {/* <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <Stack direction={"row"} alignItems="center" spacing={4}>
              <label htmlFor="logo">
                <Input
                  // {...formik.getFieldProps("sound_file")}
                  onChange={(e) => {
                    handleFileChange(e);

                    //  console.log("formik file name",formik.values.sound_file.name)
                    // console.log(
                    //   "sound file size",
                    //   formik.values.sound_file.size / 1024 / 1024
                    // );

                    //  console.log({
                    //   fileName: formik.values.sound_file.name,
                    //   type: formik.values.sound_file.type,
                    //   size: `${formik.values.sound_file.size} bytes`
                    // })
                  }}
                  accept=".jpg,.jpeg,.png"
                  name="logo"
                  id="logo"
                  type="file"
                />

                <Button
                  size="small"
                  color={formik.errors.logo ? "error" : "success"}
                  component="span"
                  variant="contained"
                >
                  Upload Logo
                </Button>
              </label>
            </Stack>
            {formik.errors.logo ? (
              <Alert severity="error">{formik.errors.logo}</Alert>
            ) : null}
            {addErrors?.logo && (
              <Alert severity="error">{addErrors?.logo[0]}</Alert>
            )}
          </Grid> */}
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <FormControl fullWidth margin="normal">
              <FormGroup
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                row
              >
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Active
                </Typography>
                <FormControlLabel
                  onChange={(e) => {
                    handleCheck(e, "status");
                  }}
                  // label="Active"
                  control={
                    <Switch
                      size="small"
                      checked={formik.values.status == 1}
                      color="primary"
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </AddBox>
    </>
  );
};
