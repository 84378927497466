import React from "react";

import { MyHeading } from "./MyHeading";

import { Box } from "@mui/material";

export const MyCard = (props) => {
  const { children, Heading } = props;

  return (
    <>
      <Box
        py={{ xs: 2, sm: 3, md: 5, lg: 5, xl: 5 }}
        // px={{ xs: 2, sm: 3, md: 7.4, lg: 7.4, xl: 7.4 }}
        px={{xs:1.4, sm:2.4, md:7.4 , lg : 7.4 ,xl: 7.4}}

        sx={{
          m: 2,
          display: "flex",
          borderRadius: "16px",
          boxShadow: 5,
          flexDirection: "column",
        }}
      >
        <MyHeading>{Heading}</MyHeading>

        {children}
      </Box>
    </>
  );
};
