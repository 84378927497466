export const isAllowed=(permission)=>{

    const user_permissions=JSON.parse(localStorage.getItem("user_permissions"))


    if(user_permissions.includes(permission)){


        return true

    }else{


        return false
    }
}