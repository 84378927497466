import React, { useEffect, useState } from "react";
import { useFetchRolePermissionsMutation } from "../../../services/admin/permissionApi";

import { ReactComponent as IconEdit } from "../../../images/editIcon.svg";
import { ReactComponent as IconDelete } from "../../../images/deleteIcon.svg";
import { StyledTableCell } from "../../call_setting/QueueList";

import {
  FormControlLabel,
  FormGroup,
  FormControl,
  Autocomplete,
  Stack,
  Input,
  Typography,
  Button,
  TableCell,
  TableRow,
  Alert,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Switch,
  Box,
} from "@mui/material";
import { MyTable } from "../../../components/MyTable";
import { StyledTableRow } from "../../Style";
import { Navigate, NavLink, useParams } from "react-router-dom";
import { Header } from "../../../components/Header";

export const RolePermission = () => {
  const [fetchRolePermissions, fetchLoader] = useFetchRolePermissionsMutation();

  const isFetchLoading = fetchLoader;

  const { role_id } = useParams();

  const [body, setBody] = useState({ role_id: role_id, search: "" });

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [role, setRole] = useState();

  const getRolePermissions = async (body) => {
    try {
      const response = await fetchRolePermissions(body).unwrap();

      console.log("role permission response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result role permissions", response.role);

        setData(response.role.permission);

        setRole(response.role.title);
      } else {
        console.log("____false");
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };

  useEffect(() => {
    getRolePermissions(body);
  }, []);

  const columns = [
    "Title",
    // "Action",
  ];

  return (
    <>
      <Header title="Role Permissions" />
      <Box sx={{ p: 1 }}>
        <Button
          // onClick={handleClickOpen}
          component={NavLink}
          to="/Roles"
          size="small"
          variant="outlined"
          color={"primary"}
          sx={{
            // color: "white",
            fontWeight: "600",
            fontSize: "12px",
            lineHeight: "15px",
            padding: "8px 16px",
            gap: "19px",
          }}
        >
          Go Back
        </Button>
      </Box>
      <MyTable
        title={
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "20px",
              // zIndex: 1,
            }}
          >{`${role} Permissions`}</Typography>
        }
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isFetchLoading}
        fetch={getRolePermissions}
        isSearch={false}
        isPagination={false}
        addButton={false}
      >
        {data?.map((item, index) => {
          return (
            <TableRow hover key={index} sx={{}}>
              <StyledTableCell>{item.title}</StyledTableCell>
            </TableRow>
          );
        })}
      </MyTable>
    </>
  );
};
