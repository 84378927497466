import React, { useEffect, useState } from "react";
import { useFetchTimeConditionMutation } from "../../services/timeConditionApi";
import { styled } from "@mui/material/styles";
import { Typography, Button, Checkbox } from "@mui/material";

import { TableCell, TableRow } from "@mui/material";
import { MyTable } from "../../components/MyTable";
import { StyledTableCell } from "./QueueList";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";

import { timeConvert } from "../call_record/CallRecord";
import { StyledTableRow } from "./QueueList";

export const TimeConditionList = (props) => {
  const { getSelectedDestination, app, setApp, formik, type } = props;

  const [fetchTimeCondition, isLoading] = useFetchTimeConditionMutation();

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    totalRecords: 0,
    fromRecord: 0,
    toRecord: 0,
    totalPages: 0,
  });

  const [body, setBody] = useState({ page: 1, search: "" });

  const fetchTC = async () => {
    try {
      const response = await fetchTimeCondition(body).unwrap();

      console.log("Time Condition response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result", response.time_conditions);

        setData(response.time_conditions.data);

        setPagination({
          ...pagination,
          totalPages: response.time_conditions.last_page,
          totalRecords: response.time_conditions.total,
          fromRecord: response.time_conditions.from,
          toRecord: response.time_conditions.to,
          toRecord: response.time_conditions.to,
        });

        if (
          response.time_conditions.current_page >
          response.time_conditions.last_page
        ) {
          setBody({ ...body, page: 1 });

          console.log("current_page > last_page");
        }
      } else {
        console.log("____false");
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };

  useEffect(() => {
    fetchTC();
  }, [body.page]);

  const splitStr = (string, symbol, index) => {
    const str = string.split(symbol);

    // console.log('split string',str)

    return str[index];
  };

  const columns = ["Selected", "Name", "Day Range", "Time Range"];

  const setDestId = (id) => {
    if (formik && type == "announcement") {
      formik.setFieldValue("destination_type_id", id);
      // setApp({ ...app,entry_app_id : id });
    } else if (formik && type == "tc_matched") {
      formik.setFieldValue("tc_matched_id", id);
      // setApp({ ...app,entry_app_id : id });
    } else if (formik && type == "tc_notMatched") {
      formik.setFieldValue("tc_notMatched_id", id);
      // setApp({ ...app,entry_app_id : id });
    } else if (!formik) {
      setApp({ ...app, entry_app_id: id });
    }

    // console.log('app',app)
  };

  const isSelected = (id) => {
    if (formik && type == "announcement") {
      return formik?.values?.destination_type_id == id;
    } else if (formik && type == "tc_matched") {
      return formik?.values?.tc_matched_id == id;
    } else if (formik && type == "tc_notMatched") {
      return formik?.values?.tc_notMatched_id == id;
    } else if (!formik) {
      return app.entry_app_id == id;
    }

    // console.log('app',app)
  };

  return (
    <>
      <MyTable
        title={
          <>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              Select Time Condition
            </Typography>
          </>
        }
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={fetchTC}
        addButton={false}
      >
        {data?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {app.entry_app_id == item.id
                ? getSelectedDestination(item.TC_Name)
                : ""}

              {/* <StyledTableRow
                onClick={ ()=>setDestId(item.id)}
                selected={ isSelected(item.id)}
                hover={ !(isSelected(item.id))}
                sx={{}}
              > */}
              <TableRow hover>
                <StyledTableCell
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "15px",
                  }}
                  // align="center"
                >
                  <Checkbox
                    sx={{ p: 0 }}
                    icon={<RadioButtonUncheckedOutlinedIcon />}
                    checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                    onClick={() => setDestId(item.id)}
                    checked={isSelected(item.id)}
                  />
                </StyledTableCell>
                <TableCell >{item.TC_Name}</TableCell>
                <TableCell >
                  {splitStr(item.Time_Range, ",", 1)}
                </TableCell>
                <TableCell >
                  {splitStr(item.Time_Range, ",", 0)}
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </MyTable>
    </>
  );
};
