import React from "react";
import { Navigate,useLocation } from "react-router-dom";

export const Protected = (props) => {
  const { Component } = props;

  const token = localStorage.getItem("token");
  const role_id= localStorage.getItem("role_id");
  const must_change_password = localStorage.getItem("must_change_password");

  const location=useLocation()

  if (!token) {
    return <Navigate to="login" replace={true} />;
  }

  // console.log('url_path',location.pathname)

  if (must_change_password == 1 && location.pathname!='/change-password') {
    return <Navigate to="change-password" replace={true} />;
  }

  // useEffect(()=>{

  //     const token =localStorage.getItem('token');

  //     if(!token){

  //         navigate('login')

  //     }

  // },[])

  return <>{<Component />}</>;
};
