import React, { useState, useEffect } from "react";
import { Header } from "../../components/Header";
import {
  TextField,
  Button,
  Box,
  Alert,
  Stack,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  Avatar,
  Divider
} from "@mui/material";

import { useUploadLogoMutation } from "../../services/userAuthApi";

import { styled } from "@mui/material/styles";

import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

export const EditCompanyInfo = () => {
  const Input = styled("input")({
    display: "none",
  });

  const [uploadLogo , uploadLogoLoader] = useUploadLogoMutation();

  const [img, setImg] = useState(localStorage.getItem("profile_photo"));

  const [blob , setBlob]=useState(null);

  const handleFileChange = async (e) => {

    let file = e.target.files[0];

    setFile(file);

    setImg(URL.createObjectURL(file));

    console.log("logo file after", file);

  };



  const [file, setFile] = useState(null);

  const handleSubmitForm=async (e)=>{

    e.preventDefault()

    const form_data= new FormData(e.currentTarget)

    form_data.append('logo',file,file.name);
      

    try {
        const res = await uploadLogo(form_data).unwrap();
  
        console.log("upload logo resp", res);
  
        if (res.status === true) {


          localStorage.setItem("profile_photo", res.client.logo_url);

          window.location.reload(false);

          setFile(null)


        } else if (res.status === false) {

          toast.error(res?.message && "Oops Something Went Wrong");


        }
      } catch (error) {
        console.log("catch error", error);
  
        if (error?.status == 400) {

          toast.error("Oops Something Went Wrong !");
          
        } else if (error?.status == 403) {

          toast.error("Unauthorized Action !");
         
        } else {
          
          toast.error("Oops Something Went Wrong !");
        }
      }

  }

  return (
    <>
      {/* _________________________ Header_____________________________________ */}

      {/* <Header title={"Upload Logo"} /> */}

      {/* {profileEditLoader.isLoading ? <LinearProgress /> : null} */}

      {/* {alert.status ? <Alert severity={alert.type}>{alert.msg}</Alert> : ""} */}

      {/* <Grid container justifyContent="center" sx={{ mt: 1 }}> */}
        <Grid
          sx={{
            boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.05)",
            borderRadius: "20px",
          }}
          item
          xl={4}
          lg={4}
          md={4}
          sm={8}
          xs={12}
        >

          <Box sx={{display:"flex",justifyContent:"center"}}>
          <Avatar  sx={{ width: 56, height: 56 }} alt="photo" src={img} />

          </Box>
          <Box
            onSubmit={handleSubmitForm}
            component="form"
            id="profile-form"
            noValidate
            sx={{
              py: 1.4,
              display: "flex",
              flexDirection: "column",
            }}
            px={{ xs: 1.4, sm: 2.4, md: 7.4, lg: 7.4 }}
          >

              <Box sx={{display:"flex",justifyContent:"center"}}>
            <Stack direction={"row"} alignItems="center" spacing={4}>
              <label htmlFor="logo">
                <Input
                  onChange={(e) => {

                    handleFileChange(e)
                  }}
                  accept="image/*"
                  name="logo"
                  id="logo"
                  type="file"
                />
                
                <Button
                  size="small"
                  component="span"
                  variant="outlined"
                  // color="secondary"
                  // sx={{ color: "white}}
                >
                  { file ? file.name : "Upload Logo"}
                </Button>
              </label>
            </Stack>
              </Box>

            <Box
              textAlign="Center"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Button
              disabled={uploadLogoLoader.isLoading || file==null}
                size="large"
                sx={{
                  mt: 3,
                  mb: 2,
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
                type="submit"
                variant="contained"
              >
                Update Logo
              </Button>

              {/* {addAlert.status ? (
                <Alert severity={addAlert.type}>{addAlert.msg}</Alert>
              ) : (
                ""
              )} */}
            </Box>
          </Box>
          {/* <Divider/> */}

          {/* <Box sx={{display:"flex",justifyContent:"center"}}>
          <Button
                // style={({ isActive }) => {
                //   return {
                //     backgroundColor: isActive ? "#8DC63F" : " ",
                //     color: isActive ? "white" : "black",
                //   };
                // }}
                component={NavLink}
                to="/sendPasswordResetEmail"
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  color: "",
                  display: "block",
                }}
              >
                Send Password Reset Email
          </Button>
          </Box> */}
        </Grid>
      {/* </Grid> */}
    </>
  );
};
