


import React, { useEffect, useState } from "react";
import { useFetchExtensionsMutation } from "../../services/extensionApi";
import { Typography,Button ,Checkbox} from "@mui/material";
import { styled } from "@mui/material/styles";
import { StyledTableRow } from "./QueueList";
import { StyledTableCell } from "./QueueList";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";



import {
    TableCell,
    TableRow,
  } from "@mui/material";
import { MyTable } from "../../components/MyTable";


export const DialList = (props) => {

    const {getSelectedDestination,app,setApp,formik,type }=props

  const [fetchExtensions,isLoading] = useFetchExtensionsMutation();

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [isBucket, setIsBucket] = useState([0]);

  const [pagination , setPagination ]=useState({

    totalRecords:0,
    fromRecord:0,
    toRecord :0,
    totalPages:0,

  });

  const [body,setBody]=useState({page:1,search:''})
  

  const fetchExt = async () => {
    try {
      const response = await fetchExtensions(body).unwrap();

      console.log("Extension response", response);

      if (response.status === true) {
        
        setAlert(false);

        console.log("result", response.dial_lists);

        setData(response.dial_lists.data);

        // setData(response.dial_lists);

        setIsBucket(response.is_bucket);


        setPagination({

            ...pagination,
            totalPages : response.dial_lists.last_page,
            totalRecords : response.dial_lists.total,
            fromRecord : response.dial_lists.from,
            toRecord : response.dial_lists.to,
            toRecord : response.dial_lists.to,
          });


        console.log("current_page", response.dial_lists.current_page);
        console.log("last_page", response.dial_lists.last_page);

        if (response.dial_lists.current_page > response.dial_lists.last_page) {
          setBody({...body,page:1});

          console.log("hello");
        }
      } else {
        setData([]);
        setAlert(true);
        console.log("____false", data);
      }
    } catch (error) {
      console.log("error", error);
      setData([]);
      setAlert(true);
      console.log("____catch", data);
    }
  };

  useEffect(() => {
    fetchExt();
  }, [body.page]);

  const columns =['Selected','Name','Code','Primary Number'];
  const columns2 =['Selected','Name','Code','Primary Number',"Remaining Minutes","Utilized Minutes"];

  const setDestId=(id)=>{


    if(formik && type=="announcement" ){
    
      (formik.setFieldValue('destination_type_id',id ) );
      // setApp({ ...app,entry_app_id : id });

    }
    else if(formik && type=="tc_matched"){

      (formik.setFieldValue('tc_matched_id',id));
      // setApp({ ...app,entry_app_id : id });


    }

    else if(formik && type=="tc_notMatched"){
    
      (formik.setFieldValue('tc_notMatched_id',id));
      // setApp({ ...app,entry_app_id : id });

    }else if(!formik){

      setApp({ ...app,entry_app_id : id });

    }

    // console.log('app',app)

  }

  const isSelected=(id)=>{


    if(formik && type=="announcement" ){
    

      return formik?.values?.destination_type_id==id

    }
    else if(formik && type=="tc_matched"){


      return formik?.values?.tc_matched_id==id



    }

    else if(formik && type=="tc_notMatched"){
      
    
      return formik?.values?.tc_notMatched_id==id

    }else if(!formik){


      return app.entry_app_id==id

    }

    // console.log('app',app)

  }


  return (
    <>

      <MyTable
      
        title={<>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "18px",
          }}
        >
          Select Extension
        </Typography></>}
        columns={isBucket==0 ? columns : columns2}
        data={data}
        alert={alert}
        isLoading={isLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={fetchExt}
        addButton={false}

      >

      {data?.map((ext, index) => {


          return (
            <React.Fragment  key={index} >

            {app.entry_app_id==ext.id ? getSelectedDestination(ext.List_Name) : ""}

            {/* <StyledTableRow onClick={ ()=>setDestId(ext.id)} selected={ isSelected(ext.id)} hover={ !(isSelected(ext.id))} sx={{}}> */}
            <TableRow hover>

<StyledTableCell sx={{
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "12px",
          lineHeight: "15px",
        }} >
      <Checkbox
        sx={{p:0}}
        icon={<RadioButtonUncheckedOutlinedIcon />}
        checkedIcon={<RadioButtonCheckedOutlinedIcon />}
        onClick={() => setDestId(ext.id)}
        checked={isSelected(ext.id)}
      />
    </StyledTableCell>

                    <TableCell >{ext.List_Name}</TableCell>
                    <TableCell >{ext.ext_code}</TableCell>
                    <TableCell >{ext.Num_1}</TableCell>
                    <TableCell
                      sx={{ display: isBucket == 0 ? "none" : "" }}
                      // align="center"
                    >
                      <Typography
                        sx={{ display: isBucket == 1 ? "block" : "none" }}
                      >
                        Onnet : {Math.floor(ext.onnet_minutes / 60)}
                        <br />
                        Offnet : {Math.floor(ext.offnet_minutes / 60)}
                        <br />
                      </Typography>
                      <Typography
                        sx={{ display: isBucket == 2 ? "block" : "none" }}
                      >
                        All Net : {Math.floor(ext.offnet_minutes / 60)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ display: isBucket == 0 ? "none" : "" }}
                      // align="center"
                    >
                      <Typography
                        sx={{ display: isBucket == 1 ? "block" : "none" }}
                      >
                        Onnet : {Math.floor(ext.utilized_onnet / 60)}
                        <br />
                        Offnet : {Math.floor(ext.utilized_offnet / 60)}
                        <br />
                      </Typography>
                      <Typography
                        sx={{ display: isBucket == 2 ? "block" : "none" }}
                      >
                        All Net : {Math.floor(ext.utilized_offnet / 60)}
                      </Typography>
                    </TableCell>
                    {/* <TableCell
                      sx={{ display: isBucket == 2 ? "" : "none" }}
                      align="center"
                    >
                      <Button onClick={()=>navigate(`/edit-extension/${ext.id}`, { replace:true }) } size="small" variant="contained" color={"info"}>Edit</Button>

                    </TableCell> */}
                    {/* <TableCell >Edit Delete</TableCell> */}
                    </TableRow>
              </React.Fragment >);
        })}

      </MyTable>


    </>
  );
};
