import React, { useEffect, useState } from "react";
import { useFetchRolesMutation } from "../../../services/admin/roleApi";
import {
  useUpdateRolePermissionsMutation,
  useFetchRolePermissionsMutation,
} from "../../../services/admin/permissionApi";

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ReactComponent as IconEdit } from "../../../images/editIcon.svg";
import { ReactComponent as IconDelete } from "../../../images/deleteIcon.svg";
import { StyledTableCell } from "../../call_setting/QueueList";

import { Header } from "../../../components/Header";

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { useFetchAllPermissionsMutation } from "../../../services/admin/permissionApi";

import { styled } from "@mui/material/styles";

import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import AddBox from "../../../components/AddBox";

import {
  Tooltip,
  IconButton,
  FormControlLabel,
  FormGroup,
  FormControl,
  Autocomplete,
  Stack,
  Input,
  Typography,
  Button,
  TableCell,
  TableRow,
  Alert,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Switch,
} from "@mui/material";
import { MyTable } from "../../../components/MyTable";
import { StyledTableRow } from "../../Style";
import { NavLink, useNavigate } from "react-router-dom";
import { isAllowed } from "../../Functions";

export const Role = () => {
  const navigate = useNavigate();

  const role_id=localStorage.getItem("role_id")

  const [fetchAllPermissions, permissionsLoader] =
    useFetchAllPermissionsMutation();

  const [permissions, setPermissions] = useState([]);

  const [filteredPermissions, setFilteredPermissions] = useState([]);

  const [userPermissions, setUserPermissions] = useState([]);

  const getPermissions = async () => {
    try {
      const response = await fetchAllPermissions().unwrap();

      console.log("fetch permission response", response);

      if (response.status === true) {
        console.log("result", response.permissions);

        setPermissions(response.permissions);
      } else {
        console.log("____permission_false");
      }
    } catch (error) {
      console.log("____permission_catch", error);
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const [fetchRolePermissions, rolePermissionLoader] =
    useFetchRolePermissionsMutation();

  
  const filterOptions=(options,selectedOptions) => {

    const newOptions = [];

    options.forEach((element) => {
      const found = selectedOptions.some(
        (el) => el.id === element["id"]
      );

      if (!found) newOptions.push(element);
    });

    setFilteredPermissions(newOptions);
  }

  const getRolePermissions = async (role_id) => {
    try {
      const response = await fetchRolePermissions({
        role_id: role_id,
      }).unwrap();

      console.log("role permission response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result role permissions", response.role);

        // setUserPermissions(response.role.permission);

        formik.setFieldValue("permissions", response.role.permission);

        filterOptions(permissions, response.role.permission)
      } else {
        console.log("____false");
        // setData([]);
        // setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      // setData([]);
      // setAlert(true);
    }
  };

  const isPermissionsLoading = permissionsLoader.isLoading;

  const [fetchRoles, fetchLoader] = useFetchRolesMutation();
  const [updateRolePermissions, storeLoader] =
    useUpdateRolePermissionsMutation();

  const isFetchLoading = fetchLoader;
  const isStoreLoading = storeLoader.isLoading;

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [open, setOpen] = React.useState(false);

  const [addAlert, setAddAlert] = useState({ status: false, msg: "" });

  const [addErrors, setAddErrors] = useState({});

  const initialValues = {
    permissions: [],
    role_id: null,
  };

  const onSubmit = (values, actions) => {
    console.log("form values", values);

    updatePerm(values);
  };

  const validationSchema = Yup.object({
    permissions: Yup.array(),
    role_id: Yup.number().required(),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const updatePerm = async (body) => {
    try {
      const response = await updateRolePermissions(body).unwrap();

      console.log("update role permissions response", response);

      if (response.status === true) {
        getRoles();

        handleClose();

        toast.success("Record has been update successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);
      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add role perm errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const handleClickOpen = async (e, role_id) => {
    formik.setFieldValue("role_id", role_id);
    await getRolePermissions(role_id);

    setOpen(true);
  };

  console.log("formik", formik);

  const handleClose = () => {
    setOpen(false);
    setAddErrors({});
    setAddAlert({ status: false, msg: "" });
  };

  const getRoles = async () => {
    try {
      const response = await fetchRoles().unwrap();

      console.log("role response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result", response.roles);

        setData(response.roles);
      } else {
        console.log("____false");
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const columns = ["Role", "Action"];

  // const navigate=useNavigate()

  if (!isAllowed("role_access") || role_id != 1) {
    return navigate("/forbidden");
  }

  return (
    <>

<Header title={"Roles"} />


      <MyTable
        // title="Roles"
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isFetchLoading}
        fetch={getRoles}
        isSearch={false}
        isPagination={false}
        addButton={false}
      >
        {data?.map((item, index) => {
          return (
            <TableRow hover key={index} sx={{}}>
              <StyledTableCell >{item.title}</StyledTableCell>

              <StyledTableCell >
              <Tooltip title="View Permissions">
                        <IconButton      
                  disabled={
                    !isAllowed("role_permission_view") ||
                    role_id != 1
                  }
                  onClick={() =>
                    navigate(`/role-permissions/${item.id}`, {
                      replace: true,
                    })
                  }
                  // component={NavLink}
                  // to={`role-permissions/${item.id}`}
                  size="small"
                  // sx={{ m: 1 }}
                  color={"secondary"}
                  variant="contained"
                >
                  <VisibilityOutlinedIcon fontSize="15px" />
                </IconButton>
                </Tooltip>
                <Tooltip title="Edit Permissions">
                        <IconButton       
                  disabled={
                    !isAllowed("role_permission_edit") ||
                    role_id != 1
                  }
                  onClick={(e) => handleClickOpen(e, item.id)}
                  size="small"
                  // sx={{ m: 1 }}
                  color={"primary"}
                  variant="contained"
                >
                  <IconEdit/>
                        </IconButton>
                        </Tooltip>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </MyTable>

      <AddBox
        title={"Edit Permission"}
        open={open}
        handleClose={handleClose}
        formik={formik}
        fullWidth
      >
        {addAlert.status ? (
          <Alert severity="error">{addAlert.msg}</Alert>
        ) : null}

        {isStoreLoading && <LinearProgress />}

        <Grid container spacing={1}>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <Autocomplete
              disableCloseOnSelect={true}
              filterSelectedOptions={true}
              multiple
              id="permissions"
              options={filteredPermissions || []}
              getOptionLabel={(option) => option.title}
              defaultValue={formik.values.permissions}
              // filterOptions={(options) => {
              //   const newOptions = [];

              //   options.forEach((element) => {
              //     const found = formik.values.permissions.some(
              //       (el) => el.id === element["id"]
              //     );

              //     if (!found) newOptions.push(element);
              //   });

              //   return newOptions;
              // }}
              renderInput={(params) => (
                <TextField
                  error={
                    (formik.touched.permissions && formik.errors.permissions) ||
                    addErrors?.permissions
                  }
                  {...params}
                  label="Permissions"
                  // placeholder="Music on Hold"
                  {...formik.getFieldProps("permissions")}
                />
              )}
              size="small"
              margin="normal"
              name="permissions"
              onChange={(e, value) => {
                console.log("perm value ", value);
                formik.setFieldValue("permissions", value);
                filterOptions(permissions, value)
              }}
            />
            {formik.touched.permissions && formik.errors.permissions ? (
              <Alert severity="error">{formik.errors.permissions}</Alert>
            ) : null}
            {addErrors?.permissions && (
              <Alert severity="error">{addErrors?.permissions[0]}</Alert>
            )}
          </Grid>
        </Grid>
      </AddBox>
    </>
  );
};
