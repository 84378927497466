import React from 'react'

import { Box , Typography } from "@mui/material";

export const MyHeading = (Props) => {

    const { children }=Props
  return (
    <>

        <Box
            display="flex"
            justifyContent="center"
            sx={{ p: 1,m : 1, backgroundColor: "primary.main" }}
        >
            <Typography
              variant="h5"
              component="div"
              sx={{ color: "white", fontWeight: "bold" }}
            >
              {children}
            </Typography>
        </Box>

    </>
  )
}
