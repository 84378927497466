import React, { useState, useEffect } from "react";

import { Grid, Card, Typography, Tabs, Tab, Box, Avatar } from "@mui/material";

import Pic1 from "../../images/Group22.png";
import TopLeft from "../../images/topleft.png";
import BottomRight from "../../images/bottomright.png";
import LockIcon from '@mui/icons-material/Lock';
import logo from "../../images/product_logo.png";
import Login from "./Login";
import Register from "./Register";
import { useNavigate, Navigate } from "react-router-dom";

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value == index && <Box>{children}</Box>}
    </div>
  );
};

export const LoginReg = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState(0);

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const token = localStorage.getItem("token");
  const role_id= localStorage.getItem("role_id");

  if (token) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <>
      {/* <Box  > */}
        <img style={{
        position:"fixed",
        zIndex: -1,
        top:0,
        left:0,
        height:"20vh",
        }} src={TopLeft}></img>
      {/* </Box> */}
      <Grid container  sx={{height:"100vh"}}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            // backgroundImage : `url(${Pic1})`,
            // backgroundRepeat : 'no-repeat',
            // backgroundSize : 'cover',
            // backgroundPosition : 'center',
            // p: "auto",
            // p: 12,
            m:"auto",
            display: {
              xl: "flex",
              lg: "flex",
              md: "flex",
              sm: "none",
              xs: "none",
            },
            justifyContent:"center",
          }}
        >
          <img style={{}} src={Pic1} alt="image" width="436.39px" height="472.63px" ></img>
        </Grid>
        <Grid  item xs={12} sm={12} md={6} lg={6} xl={6} sx={{m:"auto", justifyContent:"center", }}>
          <Login />
        </Grid>
      </Grid>
      {/* <Box sx={{display:"flex",justifyContent:"end"}} >
      <Box sx={{
        position:"fixed",
        // position: "absolute",
        // top: 0,
        // left: 0,
        // bottom : -4,
        zIndex: -1,
    }} > */}
        <img style={{
        position:"fixed",
        // position: "absolute",
        // top: 0,
        // left: 0,
        bottom : 0,
        right:0,
        zIndex: -1,
        height:"20vh",
    }} src={BottomRight}></img>
      {/* </Box>
      </Box> */}
    </>
  );
};
