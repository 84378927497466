import React, { useEffect, useState } from "react";
import { useFetchIvrsMutation } from "../../services/ivrApi";
import { StyledTableRow } from "./QueueList";
import { StyledTableCell } from "./QueueList";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";

import {
    TableCell,
    TableRow,
    Typography,
    Checkbox
  } from "@mui/material";

import { MyTable } from "../../components/MyTable";

export const IvrList = (props) => {

    const {getSelectedDestination,app,setApp,formik,type }=props

  const [fetchIvrs,isLoading] = useFetchIvrsMutation();

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [pagination , setPagination ]=useState({

    totalRecords:0,
    fromRecord:0,
    toRecord :0,
    totalPages:0,

  });

  const [body,setBody]=useState({page:1,search:''})

  
//   const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     "&:nth-of-type(odd)": {
//       backgroundColor: theme.palette.action.hover,
//     },
//     // hide last border
//     "&:last-child td, &:last-child th": {
//       border: 0,
//     },
//   }));

  const getIvrs = async () => {
    
    try {
      const response = await fetchIvrs(body).unwrap();

      console.log("Ivr response", response);

      if (response.status === true) {

        setAlert(false);

        console.log("result ivrs", response.ivrs);
        
        setData(response.ivrs.data);

        setPagination({

          ...pagination,
          totalPages : response.ivrs.last_page,
          totalRecords : response.ivrs.total,
          fromRecord : response.ivrs.from,
          toRecord : response.ivrs.to,
          toRecord : response.ivrs.to,
        });

        if (response.ivrs.current_page > response.ivrs.last_page) {

          setBody({...body,page:1});

          console.log("current_page > last_page");
        }

      } else {

        console.log("____false",response);
        setData([]);
        setAlert(true);

      }
    } catch (error) {

      console.log("____catch",error);
      setData([]);
      setAlert(true);

    }
  };

  useEffect(() => {
    getIvrs();
  }, [body.page]);

  const columns =['Selected','IVR Name','Number of Options','IVR Sounds'];

  const setDestId=(id)=>{


    if(formik && type=="announcement" ){
    
      (formik.setFieldValue('destination_type_id',id ) );
      // setApp({ ...app,entry_app_id : id });

    }
    else if(formik && type=="tc_matched"){

      (formik.setFieldValue('tc_matched_id',id));
      // setApp({ ...app,entry_app_id : id });


    }

    else if(formik && type=="tc_notMatched"){
    
      (formik.setFieldValue('tc_notMatched_id',id));
      // setApp({ ...app,entry_app_id : id });

    }else if(!formik){

      setApp({ ...app,entry_app_id : id });

    }

    // console.log('app',app)

  }

  const isSelected=(id)=>{


    if(formik && type=="announcement" ){
    

      return formik?.values?.destination_type_id==id

    }
    else if(formik && type=="tc_matched"){


      return formik?.values?.tc_matched_id==id



    }

    else if(formik && type=="tc_notMatched"){
      
    
      return formik?.values?.tc_notMatched_id==id

    }else if(!formik){


      return app.entry_app_id==id

    }

    // console.log('app',app)

  }


  return (
    <>

      <MyTable
      
      title={<>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "18px",
          }}
        >
          Select Ivr
        </Typography></>}
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={getIvrs}
        addButton={false}


      >

      {data?.map((item, index) => {
          return (
            <React.Fragment  key={index} >
            

            {app.entry_app_id==item.id ? getSelectedDestination(item.IVR_Name) : ""}

            {/* <StyledTableRow onClick={ ()=>setDestId(item.id)} selected={ isSelected(item.id)} hover={ !(isSelected(item.id))} sx={{}}> */}
            <TableRow hover>

            <StyledTableCell sx={{
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "15px",
                      
                    }}
                    // style={{
                    //   paddingY:"0px",
                    // }}
                    // align="center"
                    >
                  <Checkbox
                    sx={{p:0}}
                    icon={<RadioButtonUncheckedOutlinedIcon />}
                    checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                    onClick={() => setDestId(item.id)}
                    checked={isSelected(item.id)}
                  />
                </StyledTableCell>
                <StyledTableCell >{item.IVR_Name}</StyledTableCell>
              <StyledTableCell >{item.ivr_counter}</StyledTableCell>
              <StyledTableCell >{item?.ivr_sound?.file_Name}</StyledTableCell>
            </TableRow>
            </React.Fragment>
            );
        })}

      </MyTable>


    </>
  );
};
