import React, { useState } from "react";
import { MyHeading } from "../../components/MyHeading";

import { useSearchExtMutation } from "../../services/searchExtensionApi";
import { ToastContainer, toast } from "react-toastify";

import {
  TextField,
  Button,
  Box,
  Alert,
  Stack,
  Paper,
  Grid,
} from "@mui/material";

import { MyButton } from "../../components/MyButton";

import { NavLink, useNavigate } from "react-router-dom";
import { MyCard } from "../../components/MyCard";

export const SearchExtension = () => {
  const [searchExt, isLoading] = useSearchExtMutation();

  const [alert, setAlert] = useState({
    status: false,
    msg: "",
    type: "",
  });

  const [body, setBody] = useState({ extension_number: "" });

  const [company, setCompany] = useState({id:"",name:"",did_number : ""});

  const getCompanyInfo = async (e) => {
    e.preventDefault();
    try {
      const response = await searchExt(body).unwrap();

      console.log("client resposne", response);

      if (response.status === true) {
        setCompany(response.info.client);

        console.log("Company info", company);
      } else if (response.status === false) {
        toast.success("No record found!");
      }
    } catch (error) {
      console.log("company catch error", error);

      toast.error("OOps something went wrong !");
    }
  };

  return (
    <>
      <Grid container justifyContent="center" sx={{ mt: 5 }}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <MyCard Heading="Search Extension">
            <Box
              component={"form"}
              onSubmit={getCompanyInfo}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <TextField
                margin="normal"
                sx={{}}
                required
                value={body.extension_number || ""}
                onChange={(e) =>
                  setBody({ ...body, extension_number: e.target.value })
                }
                id="extension_number"
                name="extension_number"
                label="Extension Number"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />

              <Box
                textAlign="Center"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Button
                  variant="contained"
                  disabled={
                    body.extension_number.length < 11 ||
                    body.extension_number.indexOf(" ") >= 0
                  }
                  type="submit"
                >
                  Get Company Information
                </Button>
                {/* 
              {alert.status ? (
                <Alert severity={alert.type}>{alert.msg}</Alert>
              ) : (
                ""
              )} */}
              </Box>
            </Box>
          </MyCard>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" sx={{ mt: 5 ,display : company?.name =="" && company?.did_number=="" ? "none" : ""  }}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <MyHeading>Company Name : {company?.name} </MyHeading>
          <MyHeading>Master Number : {company?.did_number} </MyHeading>
        </Grid>
      </Grid>
    </>
  );
};
