import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { setCompany,setMustChangePassword } from "../company/companySlice";
import { MyCard } from "../../components/MyCard";

import {
  Grid,
  Autocomplete,
  Paper,
  Typography,
  Box,
  LinearProgress,
  Button,
  TextField,
} from "@mui/material";

import { useFetchClientOptionsMutation } from "../../services/clientApi";

function CompanyChanger() {
  const [fetchClientOptions, clientLoader] = useFetchClientOptionsMutation();

  const isClientLoading = clientLoader.isLoading;

  const [companies, setCompanies] = useState([]);

  const company = useSelector((state) => state.company.company);
  const user = useSelector((state) => state.company.user);

  const dispatch = useDispatch();

  const getCompanies = async () => {
    try {
      const response = await fetchClientOptions().unwrap();

      console.log("client resposne", response);

      if (response.status === true) {
        setCompanies(response.clients);

        console.log("compaines option", companies);
      }
    } catch (error) {
      console.log("clients catch error", error);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <>
      <Grid container justifyContent="center" sx={{ mt: 5 }}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <MyCard Heading="Select Company">
            <Paper spacing={2} sx={{ m: 2 }}>
              <Autocomplete
                // fullWidth={false}
                size="small"
                value={company || ""}
                onChange={(event, value) => {
                  console.log("company value", value);

                  dispatch(
                    setCompany(value)
                  );
                  // dispatch(
                  //   setMustChangePassword(1)
                  // );

                  //   setClient(value);

                  //   setBody({ ...body, client_id: value?.id });

                  // setBody({ ...body, agent_number: "" })
                }}
                loading={isClientLoading}
                disablePortal
                id="Company"
                options={companies}
                getOptionLabel={(option) => option.name + " " + option.did_number}
                sx={{}}
                renderInput={(params) => (
                  <TextField {...params} label="Select Company" />
                )}
              />

              {isClientLoading ? <LinearProgress /> : null}
            </Paper>

            {/* must_change_password {user?.must_change_password} */}
          </MyCard>
        </Grid>
      </Grid>
    </>
  );
}

export default CompanyChanger;
