import React, { useEffect, useState } from "react";
import { useFetchVoicemailMutation } from "../../services/voicemailApi";
import { useStoreVoicemailMutation } from "../../services/voicemailApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Header } from "../../components/Header";
import { useSelector } from "react-redux";

import {
  Grid,
  TextField,
  Autocomplete,
  Button,
  Typography,
} from "@mui/material";

import ReactAudioPlayer from "react-audio-player";

import { CardActions, Card, CardHeader, CardContent } from "@mui/material";

import { isAllowed } from "../Functions";
import { useNavigate } from "react-router-dom";

export const VoiceMail = () => {
  const [fetchVoicemail] = useFetchVoicemailMutation();
  const [storeVoicemail, isLoading] = useStoreVoicemailMutation();

  const loading = isLoading.isLoading;

  const [alert, setAlert] = useState(false);

  const [body, setBody] = useState([]);

  const [sounds, setSounds] = useState([]);

  const getVoicemail = async () => {
    try {
      const response = await fetchVoicemail().unwrap();

      console.log("voicemail responses", response);

      if (response.status === true) {
        setAlert(false);

        setBody(response.voicemail);

        setSounds(response.voice_sounds);

        console.log("voicemail", response.voicemail);
        console.log("voice_sounds", response.voice_sounds[0]);
        console.log("body", body);
        console.log("sounds", sounds);
      } else {
        console.log("____false", response);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setAlert(true);
    }
  };
  const createVoicemail = async () => {
    try {
      const response = await storeVoicemail(body).unwrap();

      console.log("voicemail respons", response);

      if (response.status === true) {
        setAlert(false);

        toast.success("Voicemail settings have been updated !");
      } else {
        console.log("____false", response);
        setAlert(true);
        toast.error(
          "Oops something went wrong ! cant update voicemail settings ! "
        );
      }
    } catch (error) {
      console.log("____catch", error);
      toast.error(
        "Oops something went wrong ! cant update voicemail settings ! "
      );
      setAlert(true);
    }
  };

  useEffect(() => {
    getVoicemail();
  }, []);

  const navigate=useNavigate()

   const isAdmin=useSelector((state)=>state.company.isAdmin);


  if(!isAllowed('voicemail_access')){

    return navigate("/forbidden")
  
  }
  console.log("system sound",body?.destination_sound)

  return (
    <>

<Header title={"Voicemail Settings"} />

      <Card sx={{ m: 2 }} variant="outlined">
        <CardHeader
          title={<><Typography  sx={{
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "20px",
          }}>Select Sound Files</Typography></>}
        />
        <CardContent>

        <Grid container spacing={2} sx={{ }}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{}}>
          <Autocomplete
            fullWidth={false}
            size="small"
            onChange={(event, value) => {
              // alert('hello')
              console.log("value", value);
              setBody({
                ...body,
                destination_sound: value,
                record_message: value?.id,
              });

              console.log("body", body);
            }}
            value={body?.destination_sound || ""}
            disablePortal
            id="record_message"
            options={sounds}
            sx={{}}
            renderInput={(params) => (
              <TextField {...params} label="Destination Sound" />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3} item>
            <ReactAudioPlayer
                    src={body?.destination_sound?.file_Path ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL+body?.destination_sound?.file_Path.replace(".gsm", "mp3.mp3") : ""}
                    controls
                  />
          </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{}}>
          <Autocomplete
            fullWidth={false}
            size="small"
            value={body?.msg_option_sound || ""}
            onChange={(event, value) => {
              // alert('hello')
              console.log("value", value);
              setBody({
                ...body,
                msg_option_sound: value,
                record_options: value?.id,
              });

              console.log("body", body);
            }}
            disablePortal
            id="msg_option_sound"
            options={sounds}
            sx={{}}
            renderInput={(params) => (
              <TextField {...params} label="Message Option Sound" />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3} item>
            <ReactAudioPlayer
                    src={body?.msg_option_sound?.file_Path ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL+body?.msg_option_sound?.file_Path.replace(".gsm", "mp3.mp3") : ""}
                    controls
                  />
          </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{}}>
          <Autocomplete
            fullWidth={false}
            size="small"
            value={body?.timeout_sound || ""}
            onChange={(event, value) => {
              // alert('hello')
              console.log("value", value);
              setBody({
                ...body,
                timeout_sound: value,
                t_sound_file: value?.id,
              });

              console.log("body", body);
            }}
            disablePortal
            id="t_sound_file"
            options={sounds || []}
            sx={{}}
            renderInput={(params) => (
              <TextField {...params} label="Options Timeout Sound" />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3} item>
            <ReactAudioPlayer
                    src={body?.timeout_sound?.file_Path ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL+body?.timeout_sound?.file_Path.replace(".gsm", "mp3.mp3") : ""}
                    controls
                  />
          </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{}}>
          <Autocomplete
            fullWidth={false}
            size="small"
            value={body?.invalid_sound || ""}
            onChange={(event, value) => {
              // alert('hello')
              console.log("value", value);
              setBody({
                ...body,
                invalid_sound: value,
                i_sound_file: value?.id,
              });

              console.log("body", body);
            }}
            disablePortal
            id="i_sound_file"
            options={sounds}
            sx={{}}
            renderInput={(params) => (
              <TextField {...params} label="Invalid Option Sound" />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3} item>
            <ReactAudioPlayer
                    src={body?.invalid_sound?.file_Path ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL+body?.invalid_sound?.file_Path.replace(".gsm", "mp3.mp3") : ""}
                    controls
                  />
          </Grid>
      </Grid>

        </CardContent>
        <CardActions>

        <Button
            disabled={loading || (!isAllowed('voicemail_edit'))}
            onClick={() => createVoicemail()}
            variant="contained"
            color={"secondary"}
            sx={{color:"white", fontWeight:"bolder" }}
          >
            Save
          </Button>
 
        </CardActions>
      </Card>

    </>
  );
};
