import { Card, Typography,CardContent,CardHeader,CardActions,Box } from "@mui/material";
import axios from "axios";
import React,{useState,useEffect} from "react";
import { Header } from "../components/Header";

export const Welcome = () => {
  const [advice, setAdvice] = useState("");

  const fetchAdvice = () => {
    axios
      .get("https://api.adviceslip.com/advice")
      .then((response) => {
        const { advice } = response.data.slip;

        console.log("advice", advice);

        setAdvice(advice)
      })

      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(()=>{

    // fetchAdvice()

  },[])
  return (
    <>
      <Header title={"Welcome"} />

      <Typography
        sx={{ 
          textAlign:"center"
               }}
        variant="h4"
      >
        Welcome to the Zong Cap !
      </Typography>



      {/* <Card sx={{ m:1 }} variant="outlined">
        <CardHeader
          title={
            <>
                  <Typography
        sx={{        }}
        variant="h4"
      >
        Quote of the Day
      </Typography>

            </>
          }
        />
        <CardContent sx={{}}>

        <Typography
        sx={{        }}
        variant="h5"
      >
        {advice}
      </Typography>

        </CardContent>
      </Card> */}
    </>
  );
};
