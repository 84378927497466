import React, { useEffect, useState } from "react";
import { useFetchQueuesMutation } from "../../services/queueApi";
import { styled } from "@mui/material/styles";
import { Typography, Button, Checkbox } from "@mui/material";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";

import { TableCell, TableRow } from "@mui/material";
import { MyTable } from "../../components/MyTable";

import '../../style.css'

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  ".MuiTableRow-hover": {
    backgroundColor: "blue",
  },
  "&.Mui-selected": {
    backgroundColor: "#8DC63F",
    color: "yellow",
    "&:hover": {
      backgroundColor: "#8DC63F",
    },
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  

  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "15px",
  // padding:"0px",

}));

export const QueueList = (props) => {
  const { getSelectedDestination, app, setApp, formik, type } = props;

  const [fetchQueues, isLoading] = useFetchQueuesMutation();

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    totalRecords: 0,
    fromRecord: 0,
    toRecord: 0,
    totalPages: 0,
  });

  const [body, setBody] = useState({ page: 1, search: "" });

  const fetchQ = async () => {
    try {
      const response = await fetchQueues(body).unwrap();

      console.log("Queue response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result", response.queues);

        setData(response.queues.data);

        setPagination({
          ...pagination,
          totalPages: response.queues.last_page,
          totalRecords: response.queues.total,
          fromRecord: response.queues.from,
          toRecord: response.queues.to,
          toRecord: response.queues.to,
        });

        if (response.queues.current_page > response.queues.last_page) {
          setBody({ ...body, page: 1 });

          console.log("current_page > last_page");
        }
      } else {
        console.log("____false");
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };

  useEffect(() => {
    fetchQ();
  }, [body.page]);

  const setDestId = (id) => {
    if (formik && type == "announcement") {
      formik.setFieldValue("destination_type_id", id);
      // setApp({ ...app,entry_app_id : id });
    } else if (formik && type == "tc_matched") {
      formik.setFieldValue("tc_matched_id", id);
      // setApp({ ...app,entry_app_id : id });
    } else if (formik && type == "tc_notMatched") {
      formik.setFieldValue("tc_notMatched_id", id);
      // setApp({ ...app,entry_app_id : id });
    } else if (!formik) {
      setApp({ ...app, entry_app_id: id });
    }

    // console.log('app',app)
  };

  const isSelected = (id) => {
    if (formik && type == "announcement") {
      return formik?.values?.destination_type_id == id;
    } else if (formik && type == "tc_matched") {
      return formik?.values?.tc_matched_id == id;
    } else if (formik && type == "tc_notMatched") {
      return formik?.values?.tc_notMatched_id == id;
    } else if (!formik) {
      return app.entry_app_id == id;
    }

    // console.log('app',app)
  };

  const [selectedRow, setSelectedRow] = useState(null);

  const columns = ["Selected", "Queue Name", "Strategy", "Member"];

  return (
    <>
      {/* <Typography variant="h4" >{selectedRow}</Typography> */}

      <MyTable
        title={
          <>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              Select Queue
            </Typography>
          </>
        }
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={fetchQ}
        addButton={false}
      >
        {data?.map((queue, index) => {
          return (
            <React.Fragment key={index}>
              {/* {app.entry_app_id==queue.id && setSelectedRow(queue.queue_name)} */}

              {app.entry_app_id == queue.id
                ? getSelectedDestination(queue.queue_name)
                : ""}

              {/* <StyledTableRow onClick={ ()=>setDestId(queue.id)} selected={ isSelected(queue.id)} hover={ !(isSelected(queue.id))} sx={{}}> */}
              <TableRow hover>
                <StyledTableCell sx={{
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "15px",
                    }} >
                  <Checkbox
                    sx={{p:0}}
                    icon={<RadioButtonUncheckedOutlinedIcon />}
                    checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                    onClick={() => setDestId(queue.id)}
                    checked={isSelected(queue.id)}
                  />
                </StyledTableCell>
                <StyledTableCell  >{queue.queue_name}</StyledTableCell>
                <StyledTableCell  >{queue.strategy}</StyledTableCell>
                <StyledTableCell >
                  {queue.queue_member?.map((member, index) => {
                    return (
                      <Typography sx={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "12px",
                        lineHeight: "15px",
                      }} key={index}>
                        {"Name: " +
                          member.membername +
                          " | Number : " +
                          member.member_number}
                      </Typography>
                    );
                  })}
                </StyledTableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </MyTable>
    </>
  );
};
