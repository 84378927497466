import React, { useEffect, useState } from "react";

import ReactAudioPlayer from "react-audio-player";

import {ReactComponent as IconEdit } from '../../images/editIcon.svg'
import {ReactComponent as IconDelete } from '../../images/deleteIcon.svg'
import { StyledTableCell } from "../call_setting/QueueList";
import { useSelector } from "react-redux";



import {
  useFetchQueuesMutation,
  useCreateQueueMutation,
  useStoreQueueMutation,
  useEditQueueMutation,
  useUpdateQueueMutation,
  useDeleteQueueMutation,
} from "../../services/queueApi";
import { styled } from "@mui/material/styles";
import {
  LinearProgress,
  TableCell,
  TableRow,
  Typography,
  Button,
  Alert,
  Grid,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import { MyTable } from "../../components/MyTable";
import { Header } from "../../components/Header";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddBox from "../../components/AddBox";
import { StyledTableRow } from "../Style";
import { useNavigate } from "react-router-dom";

import { isAllowed } from "../Functions";

export const Queue = () => {
  const isAdmin=useSelector((state)=>state.company.isAdmin);


  const [createQueue, createLoader] = useCreateQueueMutation();
  const [editQueue, editLoader] = useEditQueueMutation();
  const [updateQueue, updateLoader] = useUpdateQueueMutation();
  const [deleteQueue, deleteLoader] = useDeleteQueueMutation();

  const isCreateLoading = createLoader.isLoading;
  const isEditLoading = editLoader.isLoading;
  const isUpdateLoading = updateLoader.isLoading;
  const isDeleteLoading = deleteLoader.isLoading;

  const [extensions, setExtensions] = useState([]);

  const [filteredExtensions, setFilteredExtensions] = useState([]);

  const [moh, setMoh] = useState([]);

  const [singleMOH, setSingleMOH] = useState({ id: "", file_Name: "" });

  const [addAlert, setAddAlert] = useState({ status: false, msg: "" });

  const [open, setOpen] = React.useState(false);

  const [addErrors, setAddErrors] = useState({});

  const addQueue = async (values) => {
    // return window.alert("add q")

    setAddErrors({});
    setAddAlert({ status: false, msg: "" });

    try {
      const response = await storeQueue(values).unwrap();

      console.log("add queue response", response);

      if (response.status === true) {
        fetchQ();

        handleClose();

        toast.success("Queue has been added successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false", response);
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);

      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const filterOptions = (options, selectedOptions) => {
    var newOptions = [];

    options.forEach((element) => {
      const found = selectedOptions.some((el) => el.Num_1 === element["Num_1"]);

      if (!found) newOptions.push(element);
    });

    setFilteredExtensions(newOptions);
  };

  const editQ = async (queue_id) => {
    console.log("queue id", queue_id);

    try {
      const response = await editQueue({ id: queue_id }).unwrap();

      console.log("Edit Queue response", response);

      if (response.status === true) {
        formik.setFieldValue("queue_name", response.queue?.queue_name);
        formik.setFieldValue("strategy", response.queue?.strategy);

        console.log("queue members", response.queue?.queue_member);

        console.log("queue system sound", response.queue?.system_sound);

        formik.setFieldValue(
          "musiconhold_id",
          response.queue?.system_sound?.id
        );

        setSingleMOH(response.queue?.system_sound);

        // formik.setFieldValue('members',response.queue?.queue_member)
        formik.setFieldValue("members", response.queueMembers);

        formik.setFieldValue("id", queue_id);

        filterOptions(extensions, response.queueMembers);

        console.log("filtered extensions", filteredExtensions);

        handleEditOpen();
      } else {
        console.log("____false", response);
      }
    } catch (error) {
      console.log("____catch", error);
    }
  };

  const updateQ = async (values) => {
    // return window.alert("update q")

    setAddErrors({});
    setAddAlert({ status: false, msg: "" });

    try {
      const response = await updateQueue(values).unwrap();

      console.log("update queue response", response);

      if (response.status === true) {
        fetchQ();

        handleClose();

        toast.success("Queue has been update successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false", response);
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);

      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const deleteQ = async (queue_id) => {
    console.log("queue id", queue_id);

    if (window.confirm("are you sure you want to delete this record?")) {
      try {
        const response = await deleteQueue({ id: queue_id }).unwrap();

        console.log("delete Queue response", response);

        if (response.status === true) {
          fetchQ();
          toast.success("Record has been deleted successfully !");
        } else {
          console.log("____false", response);

          toast.error(response.message);
        }
      } catch (error) {
        console.log("____catch", error);

        toast.error("Oops something went wrong !");
      }
    }
  };

  const initialValues = {
    queue_name: "",
    strategy: "",
    musiconhold_id: null,
    members: [],
  };

  const onSubmit = (values, actions) => {
    console.log("form values", values);

    if (editMode) {
      updateQ(values);
    } else {
      addQueue(values);
    }
  };

  const validationSchema = Yup.object({
    queue_name: Yup.string()
      .required("Required !")
      .matches(
        /^[a-z0-9]+$/i,
        "The name must only contain letters or numbers."
      ),
    strategy: Yup.string().required("Required !"),
    musiconhold_id: Yup.number().nullable(),
    members: Yup.array()
      .min(1, "Members field is required")
      .max(25, "You can select maximum 5 members."),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    // validateOnMount:true,
    // validate,
  });

  console.log("formik", formik);

  const handleClose = () => {
    setOpen(false);
    setAddErrors({});
    setAddAlert({ status: false, msg: "" });

    setSingleMOH({ id: "", file_Name: "" });
    formik.resetForm();
  };

  const createQ = async () => {
    try {
      const response = await createQueue().unwrap();

      console.log("create Queue response", response);

      if (response.status === true) {
        // setAlert(false);

        setMoh(response.mohSounds);
        setExtensions(response.agents);
      } else {
        console.log("____false", response);
        // setData([]);
        // setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      // setData([]);
      // setAlert(true);
    }
  };

  useEffect(() => {
    createQ();
  }, []);

  const [fetchQueues, isLoading] = useFetchQueuesMutation();

  const [storeQueue, addQueueLoader] = useStoreQueueMutation();

  const isStoreLoading = addQueueLoader.isLoading;

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    totalRecords: 0,
    fromRecord: 0,
    toRecord: 0,
    totalPages: 0,
  });

  const [body, setBody] = useState({ page: 1, search: "" });

  const fetchQ = async () => {
    try {
      const response = await fetchQueues(body).unwrap();

      console.log("Queue response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result", response.queues);

        setData(response.queues.data);

        setPagination({
          ...pagination,
          totalPages: response.queues.last_page,
          totalRecords: response.queues.total,
          fromRecord: response.queues.from,
          toRecord: response.queues.to,
          toRecord: response.queues.to,
        });

        if (response.queues.current_page > response.queues.last_page) {
          setBody({ ...body, page: 1 });

          console.log("current_page > last_page");
        }
      } else {
        console.log("____false");
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };
  useEffect(() => {
    fetchQ();
  }, [body.page]);

  const columns = ["Queue Name", "Member", "Strategy", "Action"];

  const [title, setTitle] = useState("");

  const handleClickOpen = () => {
    setTitle("Add New Queue");
    setEditMode(false);
    setOpen(true);
    // setaddFunction(()=>addQueue)
    // setaddLoading(isAddQueueLoading)
  };

  const [editMode, setEditMode] = useState(false);

  const handleEditOpen = () => {
    setTitle("Edit Queue");
    setEditMode(true);
    setOpen(true);
  };

  const navigate = useNavigate();

  if (!isAllowed("queue_access")  ) {
    return navigate("/forbidden");
  }

  return (
    <>
    {/* _________________________ Header_____________________________________ */}

    <Header title={"Queues"} />

    {/* __________________________________Table_____________________________ */}

      <MyTable
        // title="Queues"
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={fetchQ}
        handleClickOpen={handleClickOpen}
        isEditLoading={isEditLoading}
        addButton={isAllowed("queue_create") || isAdmin == 1 ? true : false}
      >
        {data?.map((queue, index) => {
          return (
            <TableRow hover key={index} sx={{p:0}}>
              <StyledTableCell >{queue.queue_name}</StyledTableCell>
              <StyledTableCell>
                {queue.queue_member?.map((member, index) => {
                  return (
                    <Typography
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "15px",
                    
                    }}
                    key={index}>
                      {"Name: " +
                        member.membername +
                        " | Number : " +
                        member.member_number}
                    </Typography>
                  );
                })}
              </StyledTableCell>
              <StyledTableCell >{queue.strategy}</StyledTableCell>
              {/* <TableCell >{queue?.system_sound?.file_Name}</TableCell> */}
              <StyledTableCell >
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    // sx={{ p: 0 }}
                    variant="contained"
                    onClick={() => editQ(queue.id)}
                    disabled={!isAllowed("queue_edit")  }
                  >
                    <IconEdit   />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    size="small"
                    // sx={{ p: 0 }}
                    color={"error"}
                    variant="contained"
                    onClick={() => deleteQ(queue.id)}
                    disabled={!isAllowed("queue_delete")  }
                  >
                    <IconDelete stroke="red" />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </MyTable>

      {/* ________Add Box___________________ */}
      <AddBox
        title={title}
        open={open}
        handleClose={handleClose}
        formik={formik}
        fullWidth={true}
        style={{
          width: "562px",
          boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
      }}
      >
        {addAlert.status ? (
          <Alert severity="error">{addAlert.msg}</Alert>
        ) : null}

        {isStoreLoading || (isUpdateLoading && <LinearProgress />)}

        <Grid container spacing={1}>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <TextField
              error={
                (formik.touched.queue_name && formik.errors.queue_name) ||
                addErrors?.name
              }
              // margin="normal"
              size="small"
              required
              sx={{width:"100%"}}
              label={

                <Typography
                variant="span"
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
                >
                  Name

                </Typography>
              }
              id="queue_name"
              name="queue_name"
              {...formik.getFieldProps("queue_name")}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              // onBlur={formik.handleBlur}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.queue_name && formik.errors.queue_name ? (
              <Alert severity="error">{formik.errors.queue_name}</Alert>
            ) : null}
            {addErrors?.queue_name && (
              <Alert severity="error">{addErrors?.queue_name[0]}</Alert>
            )}
          </Grid>

          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <FormControl size="small" fullWidth
            // margin="normal"
            >
              <InputLabel sx={{}} id="cat-select-label">
              <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                  >
                    Strategy
  
                  </Typography>
              </InputLabel>

              <Select
                labelId="cat-select-label"
                label="Strategy"
                id="strategy"
                name="strategy"
                {...formik.getFieldProps("strategy")}
                error={
                  (formik.touched.strategy && formik.errors.strategy) ||
                  addErrors?.strategy
                }
              >
                <MenuItem                   sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }} value="linear">Sequential</MenuItem>
                <MenuItem                   sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }} value="ringall">Ring All</MenuItem>
                <MenuItem                   sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }} value="rrmemory">Sequential with memory</MenuItem>
              </Select>
            </FormControl>
            {formik.touched.strategy && formik.errors.strategy ? (
              <Alert severity="error">{formik.errors.strategy}</Alert>
            ) : null}
            {addErrors?.strategy && (
              <Alert severity="error">{addErrors?.strategy[0]}</Alert>
            )}
          </Grid>

          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <Autocomplete
              id="moh"
              options={moh}
              getOptionLabel={(option) => option.file_Name}
              // defaultValue={[moh[0]]}
              renderInput={(params) => (
                <TextField
                  error={
                    (formik.touched.musiconhold_id &&
                      formik.errors.musiconhold_id) ||
                    addErrors?.musiconhold_id
                  }
                  {...params}
                  sx={{width:"100%"}}
                  label={
    
                    <Typography
                    variant="span"
                    sx={{
                      fontWeight: 500,
                      fontSize: "13px",
                      lineHeight: "16px",
                    }}
                    >
                      Music On Hold
    
                    </Typography>
                  }
                  // placeholder="Music on Hold"
                  {...formik.getFieldProps("musiconhold_id")}
                />
              )}
              size="small"
              // margin="normal"
              // sx={{ width: "500px" }}
              onChange={(e, value) => {
                console.log("moh value ", value);
                formik.setFieldValue("musiconhold_id", value?.id);
                setSingleMOH(value);
              }}
              value={singleMOH || { id: "", file_Name: "" }}
              name="musiconhold_id"
              // {...formik.getFieldProps("musiconhold_id")}
            />
            {formik.touched.musiconhold_id && formik.errors.musiconhold_id ? (
              <Alert severity="error">{formik.errors.musiconhold_id}</Alert>
            ) : null}
            {addErrors?.musiconhold_id && (
              <Alert severity="error">{addErrors?.musiconhold_id[0]}</Alert>
            )}
          </Grid>

          {singleMOH?.file_Path ? <Grid sx={{display:"flex",justifyContent:"center"}}  xs={12} sm={12} md={12} lg={12} xl={12} item>
            <ReactAudioPlayer
              src={
                singleMOH?.file_Path
                  ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL +
                    singleMOH?.file_Path.replace(".gsm", "mp3.mp3")
                  : ""
              }
              controls
            />
          </Grid> :null }
          

          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <Autocomplete
              disableCloseOnSelect={true}
              filterSelectedOptions={true}
              multiple
              id="members"
              options={(editMode ? filteredExtensions : extensions) || []}
              getOptionLabel={(option) => option.List_Name + " " + option.Num_1}
              defaultValue={[...formik.values.members]}
              // filterOptions={(options) => {

              //     const newOptions=[];

              //     options.forEach(element => {

              //       const found = formik.values.members.some((el) => el.id === element["id"]);

              //       if (!found) newOptions.push(element);

              //     });

              //     return newOptions;

              // }}
              renderInput={(params) => (
                <TextField
                  error={
                    (formik.touched.members && formik.errors.members) ||
                    addErrors?.members
                  }
                  {...params}
                  // sx={{width:"100%"}}
                  label={
    
                    <Typography
                    variant="span"
                    sx={{
                      fontWeight: 500,
                      fontSize: "13px",
                      lineHeight: "16px",
                    }}
                    >
                      Members
    
                    </Typography>
                  }
                  // placeholder="Music on Hold"
                  {...formik.getFieldProps("members")}
                />
              )}
              size="small"
              // margin="normal"
              name="members"
              // sx={{ width: "500px" }}
              onChange={(e, value) => {
                console.log("ext value ", value);
                formik.setFieldValue("members", value);
                // setSingleMember([...singleMember,value]);
                filterOptions(extensions, value);
              }}
              // value={singleMember || {id:'',List_Name:'',Num_1:''}}
              // {...formik.getFieldProps("musiconhold_id")}
            />
            {formik.touched.members && formik.errors.members ? (
              <Alert severity="error">{formik.errors.members}</Alert>
            ) : null}
            {addErrors?.members && (
              <Alert severity="error">{addErrors?.members[0]}</Alert>
            )}
          </Grid>
        </Grid>
      </AddBox>
    </>
  );
};
