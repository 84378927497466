import { configureStore } from '@reduxjs/toolkit'

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';


// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { userAuthApi } from '../services/userAuthApi'
import { incomingCallApi } from '../services/incomingCallApi'
import { outgoingCallApi } from '../services/outgoingCallApi'
import { agentApi } from '../services/agentApi'
import { clientApi } from '../services/clientApi'
import { extensionApi } from '../services/extensionApi'
import { exportMinuteApi } from '../services/exportMinuteApi'
import { queueApi } from '../services/queueApi'
import { ivrApi } from '../services/ivrApi'
import { mohApi } from '../services/mohApi'
import { callSettingApi } from '../services/callSettingApi'
import { voicemailApi } from '../services/voicemailApi'
import { timeConditionApi } from '../services/timeConditionApi'
import { announcementApi } from '../services/announcementApi'
import { systemSoundApi } from '../services/systemSoundApi'
import { searchExtensionApi } from '../services/searchExtensionApi'
/////______________admin
import { masterNumberApi } from '../services/admin/masterNumberApi'
import { adminClientApi } from '../services/admin/adminClientApi'
import { userApi } from '../services/admin/userApi'
import { roleApi } from '../services/admin/roleApi'
import { permissionApi } from '../services/admin/permissionApi'
import { dashboardApi } from '../services/dashboardApi'

import companyReducer from '../features/company/companySlice'
import { callOrignateApi } from '../services/callOrignateApi';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["company","user","permissions","isAdmin"]
}

const persistedReducer = persistReducer(persistConfig, companyReducer)


export const store = configureStore({
  reducer: {

    company : persistedReducer,

    // Add the generated reducer as a specific top-level slice
    [userAuthApi.reducerPath]: userAuthApi.reducer,
    [incomingCallApi.reducerPath]: incomingCallApi.reducer,
    [outgoingCallApi.reducerPath]: outgoingCallApi.reducer,
    [agentApi.reducerPath]: agentApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [extensionApi.reducerPath]: extensionApi.reducer,
    [exportMinuteApi.reducerPath]: exportMinuteApi.reducer,
    [queueApi.reducerPath]: queueApi.reducer,
    [ivrApi.reducerPath]: ivrApi.reducer,
    [mohApi.reducerPath]: mohApi.reducer,
    [callSettingApi.reducerPath]: callSettingApi.reducer,
    [voicemailApi.reducerPath]: voicemailApi.reducer,
    [timeConditionApi.reducerPath]: timeConditionApi.reducer,
    [announcementApi.reducerPath]: announcementApi.reducer,
    [systemSoundApi.reducerPath]: systemSoundApi.reducer,
    [searchExtensionApi.reducerPath]: searchExtensionApi.reducer,
    //__________________________Admin
    [masterNumberApi.reducerPath]: masterNumberApi.reducer,
    [adminClientApi.reducerPath]: adminClientApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [permissionApi.reducerPath]: permissionApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [callOrignateApi.reducerPath]: callOrignateApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userAuthApi.middleware,
      // incomingCallApi.middleware,
      // outgoingCallApi.middleware,
      // agentApi.middleware,
      // clientApi.middleware,
      // extensionApi.middleware,
      // exportMinuteApi.middleware,
      // queueApi.middleware,
      // ivrApi.middleware,
      // mohApi.middleware,
      // callSettingApi.middleware,
      // voicemailApi.middleware,
      // timeConditionApi.middleware,
      // announcementApi.middleware,
      // systemSoundApi.middleware,
      // searchExtensionApi.middleware,
      // masterNumberApi.middleware,
      // adminClientApi.middleware,
      // userApi.middleware,
      // roleApi.middleware,
      // permissionApi.middleware,
      // dashboardApi.middleware
      ),
})

export const persistor = persistStore(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)