import React, { useState,useEffect } from 'react'
import { SearchExtension } from "./admin/SearchExtension";

import { ChangePassword } from "./auth/ChangePassword";

import CompanyChanger from "../features/company/CompanyChanger";


import {Grid,Card,Typography,Tabs,Tab,Box,Avatar,
} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { Header } from '../components/Header';
import { isAllowed } from './Functions';

const TabPanel= (props)=>{

  const {children,value,index}=props

  return <div role='tabpanel' hidden = {value !== index}>

      {
          value==index && (

              <Box>{children}</Box>
          )
      }


  </div>
}


const Setting = () => {

  const [value,setValue]= useState(0);

  const handleTabs= (event,newValue)=>{

      setValue(newValue);
  }

  const navigate=useNavigate()

  if(!isAllowed("admin_setting")){

    return navigate("/forbidden")
  
  }

  return (
    <>
      {/* <ChangePassword/> */}

      {/* <CompanyChanger />

      <SearchExtension /> */}
      <Header title="Settings" />

      <Grid container justifyContent="center" sx={{ height: "90vh" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
          <Card sx={{ width: "100%", height: "100%" }}>

            <Box>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  centered
                  value={value}
                  onChange={handleTabs}
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab
                    sx={{ textTransform: "none", fontWeight: "bold" }}
                    label="Search Extension"
                  ></Tab>
                  {/* <Tab  sx={{textTransform: 'none' , fontWeight :'bold'}} label='Change Company'></Tab> */}
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                {" "}
                <SearchExtension />{" "}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {" "}
                <CompanyChanger />{" "}
              </TabPanel>
            </Box>
          </Card>
        </Grid>


      </Grid>
    </>
  );
};

export default Setting;
