import React, { useEffect, useState } from "react";
import {
  useFetchUsersMutation,
  useCreateUsersMutation,
  useStoreUsersMutation,
  useEditUsersMutation,
  useUpdateUsersMutation,
  useDeleteUsersMutation,
} from "../../../services/admin/userApi";

import { useSelector, useDispatch } from "react-redux";
import { setCompany,setUser,setPermissions,setIsAdmin } from "../../../features/company/companySlice";


import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Header } from "../../../components/Header";
import { ReactComponent as IconEdit } from "../../../images/editIcon.svg";
import { ReactComponent as IconDelete } from "../../../images/deleteIcon.svg";
import { StyledTableCell } from "../../call_setting/QueueList";

import { useLoginAsAdminMutation } from "../../../services/userAuthApi";

import { useFetchAllPermissionsMutation } from "../../../services/admin/permissionApi";
import { styled } from "@mui/material/styles";

import {
  FormControlLabel,
  FormGroup,
  FormControl,
  Autocomplete,
  Stack,
  Input,
  Typography,
  Button,
  TableCell,
  TableRow,
  Alert,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Switch,
  Tooltip,
  IconButton,
} from "@mui/material";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { MyTable } from "../../../components/MyTable";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddBox from "../../../components/AddBox";
import { isAllowed } from "../../Functions";
import { useNavigate } from "react-router-dom";

export const User = () => {

  const role_id=localStorage.getItem("role_id")

  const dispatch = useDispatch();
  const [fetchUsers, fetchLoader] = useFetchUsersMutation();
  const [createUsers, createLoader] = useCreateUsersMutation();
  const [storeUsers, storeLoader] = useStoreUsersMutation();
  const [editUsers, editLoader] = useEditUsersMutation();
  const [updateUsers, updateLoader] = useUpdateUsersMutation();
  const [deleteUsers, deleteLoader] = useDeleteUsersMutation();

  const [loginAsAdmin, loginLoader] = useLoginAsAdminMutation();

  const [fetchAllPermissions, permissionsLoader] =
    useFetchAllPermissionsMutation();

  const isPermissionsLoading = permissionsLoader.isLoading;

  const isFetchLoading = fetchLoader;
  const isCreateLoading = createLoader.isLoading;
  const isStoreLoading = storeLoader.isLoading;
  const isEditLoading = editLoader.isLoading;
  const isUpdateLoading = updateLoader.isLoading;
  const isDeleteLoading = deleteLoader.isLoading;

  const isLoginLoading = loginLoader.isLoading;

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    totalRecords: 0,
    fromRecord: 0,
    toRecord: 0,
    totalPages: 0,
  });

  const [body, setBody] = useState({ page: 1, search: "" });

  const [addAlert, setAddAlert] = useState({ status: false, msg: "" });

  const [open, setOpen] = React.useState(false);

  const [addErrors, setAddErrors] = useState({});

  const [title, setTitle] = useState("");

  const [editMode, setEditMode] = useState(false);

  const handleClickOpen = () => {
    setTitle("Add new User");
    setEditMode(false);
    setValidationSchema(validate);
    setOpen(true);
  };

  const Input = styled("input")({
    display: "none",
  });

  const getUsers = async () => {
    try {
      const response = await fetchUsers(body).unwrap();

      console.log("user response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result", response.users);

        setData(response.users.data);

        setPagination({
          ...pagination,
          totalPages: response.users.last_page,
          totalRecords: response.users.total,
          fromRecord: response.users.from,
          toRecord: response.users.to,
          toRecord: response.users.to,
        });

        if (response.users.current_page > response.users.last_page) {
          setBody({ ...body, page: 1 });

          console.log("current_page > last_page");
        }
      } else {
        console.log("____false");
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };

  useEffect(() => {
    getUsers();
  }, [body.page]);

  const [roles, setRoles] = useState([]);
  const [clients, setClients] = useState([]);
  const [singleClient, setSingleClient] = useState({
    id: "",
    name: "",
    did_number: "",
  });

  const handleFileChange = (e) => {
    let file = e.target.files[0];

    console.log("photo", file);

    formik.setFieldValue("photo", file);

    console.log("value file", formik.values.photo);
  };

  const createUser = async () => {
    try {
      const response = await createUsers().unwrap();

      console.log("create user response", response);

      if (response.status === true) {
        setRoles(response.roles);
        setClients(response.clients);
      } else {
        console.log("____false");
      }
    } catch (error) {
      console.log("____catch", error);
    }
  };

  useEffect(() => {
    createUser();
  }, []);

  const storeUser = async (values) => {
    try {
      const response = await storeUsers(values).unwrap();

      console.log("store user response", response);

      if (response.status === true) {
        getUsers();

        handleClose();

        toast.success("Record has been added successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);

      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const [userPermissions, setUserPermissions] = useState([]);

  const filterOptions = (options, selectedOptions) => {
    var newOptions = [];

    options.forEach((element) => {
      const found = selectedOptions.some((el) => el.id === element["id"]);

      if (!found) newOptions.push(element);
    });

    setFilteredPermissions(newOptions);
  };

  const editUser = async (id) => {
    try {
      const response = await editUsers({ id: id }).unwrap();

      console.log("edit users response", response);

      if (response.status === true) {
        formik.setFieldValue("id", response.user?.id);
        formik.setFieldValue("name", response.user?.name);
        formik.setFieldValue("user_name", response.user?.user_name);
        formik.setFieldValue("email", response.user?.email);
        formik.setFieldValue("phone", response.user?.phone);
        // formik.setFieldValue('password',response.user?.password)
        // formik.setFieldValue('password_confirmation',response.user?.password_confirmation)
        formik.setFieldValue("role_id", response.user?.role_id);
        console.log("role id", formik.values.role_id);
        console.log("response role id", response.user.role_id);
        formik.setFieldValue("client_id", response.user?.client_id);
        formik.setFieldValue("status", response.user?.status);

        setSingleClient(response.user?.client);

        formik.setFieldValue("permissions", response.user?.permission);

        setUserPermissions(response.user?.permission);

        console.log("user Permissions", userPermissions);

        filterOptions(permissions, response.user?.permission);

        handleEditOpen();
      } else {
        console.log("____false");
      }
    } catch (error) {
      console.log("____catch", error);
    }
  };

  const handleEditOpen = () => {
    setTitle("Edit User");
    setEditMode(true);
    setValidationSchema(editValidation);
    setOpen(true);
  };

  const editValidation = Yup.object({
    name: Yup.string().required("Required !"),
    email: Yup.string().email("Invalid Email Format").required("Required !"),
    user_name: Yup.string()
      .required("Required !")
      .matches(
        /^[a-z0-9]+$/i,
        "The user name must only contain letters or numbers."
      ),
    phone: Yup.number().nullable().required("Required !"),
    password: Yup.string(),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    client_id: Yup.number().nullable().required("Required !"),
    role_id: Yup.number().nullable().required("Required !"),
    status: Yup.number().nullable().required("Required !"),
    // photo: Yup.mixed()
    //   .required("Photo Required !")
    //   .nullable()
    //   .test(
    //     "fileSize",
    //     "File should be less than 10mb",
    //     (photo) =>{ photo && checkIfFileSizeBig(photo?.size); }
    //   )
    //   .test(
    //     "fileType",
    //     "Unsupported File Format, Allowed .jpg , .jpeg , .png",
    //     (photo) =>
    //       photo && ["image/jpg", "image/jpeg", "image/png"].includes(photo?.type)
    //   ),
  });

  const updateUser = async (values) => {
    try {
      const response = await updateUsers(values).unwrap();

      console.log("update user response", response);

      if (response.status === true) {
        getUsers();

        handleClose();

        toast.success("Record has been update successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);
      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add annonuce errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const deleteUser = async (id) => {
    if (window.confirm("are you sure you want to delete this record?")) {
      try {
        const response = await deleteUsers({ id: id }).unwrap();

        console.log("delete user response", response);

        if (response.status === true) {
          getUsers();
          toast.success("Record has been deleted successfully !");
        } else {
          console.log("____false", response);

          toast.error(response.message);
        }
      } catch (error) {
        console.log("____catch", error);

        toast.error("Oops something went wrong !");
      }
    }
  };

  const login = async (user_id) => {
    try {
      const response = await loginAsAdmin({ user_id: user_id }).unwrap();

      console.log("login as admin response", response);

      if (response.status === true) {
        localStorage.clear();

        localStorage.setItem("token", response.token);
        localStorage.setItem("client_id", response.user.client_id);
        localStorage.setItem("user_id", response.user.id);
        localStorage.setItem("client_name", response.user.client.name);
        localStorage.setItem("role_id", response.user.role_id);

        localStorage.setItem("master_num", response.user.client.did_number);
        
        // dispatch(
        //   setCompany(response?.user?.client)
        // );

        // dispatch(
        //   setPermissions(response.permissions)
        // );

        // dispatch(
        //   setUser(response?.user)
        // );

        // dispatch(
        //   setIsAdmin(1)
        // );

        localStorage.setItem("name", response.user.name);
        
        if(response.user.client.logo==null){

          localStorage.setItem("profile_photo", response.user.profile_photo_url);

        }else{

          localStorage.setItem("profile_photo", response.user.client.logo_url);

        }

        localStorage.setItem(
          "user_permissions",
          JSON.stringify(response.permissions)
        );

        // localStorage.setItem("isAdmin", 1);

        navigate("/welcome");

        window.location.reload();
      } else {
        console.log("____false", response);

        toast.error(response.message);
      }
    } catch (error) {
      console.log("____catch admin login error", error);

      toast.error("Oops something went wrong !");
    }
  };

  const initialValues = {
    name: "",
    user_name: "",
    password: "",
    password_confirmation: "",
    client_id: null,
    role_id: null,
    phone: null,
    email: "",
    // photo: null,
    status: 0,
  };

  const onSubmit = (values, actions) => {
    console.log("form values", values);

    const form_data = new FormData();

    form_data.append("id", formik.values.id);
    form_data.append("name", formik.values.name);
    form_data.append("user_name", formik.values.user_name);
    form_data.append("password", formik.values.password);
    form_data.append(
      "password_confirmation",
      formik.values.password_confirmation
    );
    form_data.append("client_id", formik.values.client_id);
    form_data.append("role_id", formik.values.role_id);
    form_data.append("phone", formik.values.phone);
    form_data.append("email", formik.values.email);
    // form_data.append("photo",formik.values.photo);
    form_data.append("status", formik.values.status);

    if (editMode) {
      form_data.append("permissions", [...formik.values.permissions]);

      updateUser(values);
    } else {
      storeUser(form_data);
    }
  };

  const checkIfFileSizeBig = (fileSize) => {
    let valid = true;
    const size = fileSize / 1024 / 1024;
    if (size > 10) {
      valid = false;
    }
    return valid;
  };

  const validate = Yup.object({
    name: Yup.string().required("Required !"),
    email: Yup.string().email("Invalid Email Format").required("Required !"),
    user_name: Yup.string()
      .required("Required !")
      .matches(
        /^[a-z0-9]+$/i,
        "The user name must only contain letters or numbers."
      ),
    phone: Yup.number().nullable().required("Required !"),
    password: Yup.string().required("Required !"),
    password_confirmation: Yup.string()
      .required("Required !")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    client_id: Yup.number().nullable().required("Required !"),
    role_id: Yup.number().nullable().required("Required !"),
    status: Yup.number().nullable().required("Required !"),
    // photo: Yup.mixed()
    //   .required("Photo Required !")
    //   .nullable()
    //   .test(
    //     "fileSize",
    //     "File should be less than 10mb",
    //     (photo) =>{ photo && checkIfFileSizeBig(photo?.size); }
    //   )
    //   .test(
    //     "fileType",
    //     "Unsupported File Format, Allowed .jpg , .jpeg , .png",
    //     (photo) =>
    //       photo && ["image/jpg", "image/jpeg", "image/png"].includes(photo?.type)
    //   ),
  });

  const [validationSchema, setValidationSchema] = useState(validate);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    // validateOnMount:true,
    // validateOnBlur: false,
    // validateOnChange:false,
    // validate,
  });

  console.log("formik", formik);

  const handleClose = () => {
    setOpen(false);
    setAddErrors({});
    setAddAlert({ status: false, msg: "" });

    setSingleClient({ id: "", name: "", did_number: "" });
    formik.resetForm();
  };

  const columns = [
    "Name",
    "User Name",
    "Company",
    "Role",
    "Email",
    "Phone #",
    "Status",
    "Action",
  ];

  const handleCheck = (e, name) => {
    const { checked } = e.target;

    if (checked) {
      formik.setFieldValue(name, 1);
    } else {
      formik.setFieldValue(name, 0);
    }
  };

  const [permissions, setPermissions] = useState([]);
  const [filteredPermissions, setFilteredPermissions] = useState([]);

  const getPermissions = async () => {
    try {
      const response = await fetchAllPermissions().unwrap();

      console.log("fetch permission response", response);

      if (response.status === true) {
        console.log("result", response.permissions);

        setPermissions(response.permissions);
      } else {
        console.log("____permission_false");
      }
    } catch (error) {
      console.log("____permission_catch", error);
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const navigate = useNavigate();

  if (!isAllowed("user_access") || role_id != 1) {
    return navigate("/forbidden");
  }

  return (
    <>
      <Header title={"Users"} />

      <MyTable
        // title="Users"
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isFetchLoading}
        isEditLoading={isEditLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={getUsers}
        handleClickOpen={handleClickOpen}
        addButton={
          isAllowed("user_create") && role_id == 1
            ? true
            : false
        }
      >
        {data?.map((item, index) => {
          return (
            <TableRow hover key={index} sx={{}}>
              <StyledTableCell >{item.name}</StyledTableCell>
              <StyledTableCell >{item.user_name}</StyledTableCell>
              <StyledTableCell >{item.client?.name}</StyledTableCell>
              <StyledTableCell >{item.role?.title}</StyledTableCell>
              <StyledTableCell >{item.email}</StyledTableCell>
              <StyledTableCell >{item.phone}</StyledTableCell>

              <StyledTableCell
                sx={{ color: item.status == 0 ? "error.main" : "success.main" }}
                align="center"
              >
                {item.status == 0 ? "Inactive" : "Active"}
              </StyledTableCell>

              <StyledTableCell >
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    // sx={{ m: 1 }}
                    color={"info"}
                    variant="contained"
                    disabled={
                      !isAllowed("user_edit") ||
                      role_id != 1
                    }
                    onClick={() => editUser(item.id)}
                  >
                    <IconEdit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    disabled={
                      !isAllowed("user_delete") ||
                      role_id != 1 ||
                      item.is_superuser == 1
                    }
                    size="small"
                    // sx={{ m: 1 }}
                    color={"error"}
                    variant="contained"
                    onClick={() => deleteUser(item.id)}
                  >
                    <IconDelete stroke="red" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Login">
                  <IconButton
                    disabled={
                      isLoginLoading ||
                      role_id != 1 ||
                      item.is_superuser == 1
                    }
                    size="small"
                    // sx={{ m: 1 }}
                    color={"secondary"}
                    variant="contained"
                    onClick={() => login(item.id)}
                  >
                    <VpnKeyOutlinedIcon fontSize="15px" />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </MyTable>

      {/* ________Add Box___________________ */}
      <AddBox
        title={title}
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        // addFunction={createSystemSound}
        addLoading={isStoreLoading}
        formik={formik}
        fullWidth={false}
        style={{
          // width: "100%",
          boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
      }}
      >
        {addAlert.status ? (
          <Alert severity="error">{addAlert.msg}</Alert>
        ) : null}

        {(isStoreLoading || isUpdateLoading) && <LinearProgress />}

        <Grid xs={12} sm={12} md={12} lg={6} xl={6} item></Grid>

        <Grid container spacing={1}>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <TextField
              size="small"
              error={
                (formik.touched.name && formik.errors.name) || addErrors?.name
              }
              // margin="normal"
              sx={{ width: "100%" }}

              required
              label={

                <Typography
                variant="span"
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
                >
                  Name

                </Typography>
              }
              id="name"
              name="name"
              {...formik.getFieldProps("name")}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              // onBlur={formik.handleBlur}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.name && formik.errors.name ? (
              <Alert severity="error">{formik.errors.name}</Alert>
            ) : null}
            {addErrors?.name && (
              <Alert severity="error">{addErrors?.name[0]}</Alert>
            )}
          </Grid>

          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <TextField
              size="small"
              error={
                (formik.touched.user_name && formik.errors.user_name) ||
                addErrors?.user_name
              }
              // margin="normal"
              sx={{ width: "100%" }}

              required
              label={

                <Typography
                variant="span"
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
                >
                  User Name

                </Typography>
              }
              id="user_name"
              name="user_name"
              {...formik.getFieldProps("user_name")}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              // onBlur={formik.handleBlur}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.user_name && formik.errors.user_name ? (
              <Alert severity="error">{formik.errors.user_name}</Alert>
            ) : null}
            {addErrors?.user_name && (
              <Alert severity="error">{addErrors?.user_name[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <TextField
              error={
                (formik.touched.password && formik.errors.password) ||
                addErrors?.password
              }
              type="password"
              // margin="normal"
              sx={{ width: "100%" }}

              required
              id="password"
              name="password"
              size="small"
              label={

                <Typography
                variant="span"
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
                >
                  Password

                </Typography>
              }
              // minRows={4}
              {...formik.getFieldProps("password")}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.password && formik.errors.password ? (
              <Alert severity="error">{formik.errors.password}</Alert>
            ) : null}
            {addErrors?.password && (
              <Alert severity="error">{addErrors?.password[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <TextField
              error={
                (formik.touched.password_confirmation &&
                  formik.errors.password_confirmation) ||
                addErrors?.password_confirmation
              }
              type="password"
              // margin="normal"
              sx={{ width: "100%" }}

              required
              id="password_confirmation"
              name="password_confirmation"
              size="small"
              label={

                <Typography
                variant="span"
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
                >
                  Confirm Password

                </Typography>
              }
              // minRows={4}
              {...formik.getFieldProps("password_confirmation")}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.password_confirmation &&
            formik.errors.password_confirmation ? (
              <Alert severity="error">
                {formik.errors.password_confirmation}
              </Alert>
            ) : null}
            {addErrors?.password_confirmation && (
              <Alert severity="error">
                {addErrors?.password_confirmation[0]}
              </Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <TextField
              error={
                (formik.touched.email && formik.errors.email) ||
                addErrors?.email
              }
              type="email"
              // margin="normal"
              sx={{ width: "100%" }}

              required
              id="email"
              name="email"
              size="small"
              label={

                <Typography
                variant="span"
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
                >
                  Email

                </Typography>
              }
              // minRows={4}
              {...formik.getFieldProps("email")}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.email && formik.errors.email ? (
              <Alert severity="error">{formik.errors.email}</Alert>
            ) : null}
            {addErrors?.email && (
              <Alert severity="error">{addErrors?.email[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <TextField
              error={
                (formik.touched.phone && formik.errors.phone) ||
                addErrors?.phone
              }
              type="text"
              // margin="normal"
              sx={{ width: "100%" }}

              required
              id="phone"
              name="phone"
              size="small"
              label={

                <Typography
                variant="span"
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
                >
                  Phone

                </Typography>
              }
              // minRows={4}
              {...formik.getFieldProps("phone")}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <Alert severity="error">{formik.errors.phone}</Alert>
            ) : null}
            {addErrors?.phone && (
              <Alert severity="error">{addErrors?.phone[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <FormControl size="small" fullWidth>
              <InputLabel sx={{}} id="cat-select-label">

<Typography
variant="span"
sx={{
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "16px",
}}
>
  Role

</Typography>
              </InputLabel>

              <Select
                labelId="cat-select-label"
                label={

                  <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                  >
                    Role
  
                  </Typography>
                }
                id="role_id"
                name="role_id"
                {...formik.getFieldProps("role_id")}
                error={
                  (formik.touched.role_id && formik.errors.role_id) ||
                  addErrors?.role_id
                }
              >
                {roles.map((item, index) => {
                  return (
                    <MenuItem
                      /* selected={formik.values.role_id==item.id}*/ key={index}
                      value={item.id}
                    >
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {formik.touched.category_id && formik.errors.category_id ? (
              <Alert severity="error">{formik.errors.category_id}</Alert>
            ) : null}
            {addErrors?.category_id && (
              <Alert severity="error">{addErrors?.category_id[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <Autocomplete
              disableCloseOnSelect={true}
              filterSelectedOptions={true}
              id="clients"
              options={clients}
              getOptionLabel={(option) => option.name + " " + option.did_number}
              renderInput={(params) => (
                <TextField
                  error={
                    (formik.touched.client_id && formik.errors.client_id) ||
                    addErrors?.client_id
                  }
                  {...params}
                  label={

                    <Typography
                    variant="span"
                    sx={{
                      fontWeight: 500,
                      fontSize: "13px",
                      lineHeight: "16px",
                    }}
                    >
                      Clients
    
                    </Typography>
                  }
                  // placeholder="Music on Hold"
                  {...formik.getFieldProps("client_id")}
                />
              )}
              size="small"
              // margin="normal"
              // sx={{ width: "500px" }}
              defaultValue={singleClient}
              onChange={(e, value) => {
                console.log("client company value ", value);
                formik.setFieldValue("client_id", value?.id);
                setSingleClient(value);
              }}
              // value={singleClient || {id:'',name:'',did_number:""}}
              name="client_id"
              // {...formik.getFieldProps("musiconhold_id")}
            />
            {formik.touched.client_id && formik.errors.client_id ? (
              <Alert severity="error">{formik.errors.client_id}</Alert>
            ) : null}
            {addErrors?.client_id && (
              <Alert severity="error">{addErrors?.client_id[0]}</Alert>
            )}
          </Grid>
          {/* <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <Stack direction={"row"} alignItems="center" spacing={4}>
              <label htmlFor="photo">
                <Input
                  // {...formik.getFieldProps("sound_file")}
                  onChange={(e) => {
                    handleFileChange(e);

                    //  console.log("formik file name",formik.values.sound_file.name)
                    // console.log(
                    //   "sound file size",
                    //   formik.values.sound_file.size / 1024 / 1024
                    // );

                    //  console.log({
                    //   fileName: formik.values.sound_file.name,
                    //   type: formik.values.sound_file.type,
                    //   size: `${formik.values.sound_file.size} bytes`
                    // })
                  }}
                  accept=".jpg,.jpeg,.png"
                  name="photo"
                  id="photo"
                  type="file"
                />

                <Button
                  size="small"
                  color={formik.errors.photo ? "error" : "success"}
                  component="span"
                  variant="contained"
                >
                  Upload Photo
                </Button>
              </label>
            </Stack>
            {formik.errors.photo ? (
              <Alert severity="error">{formik.errors.photo}</Alert>
            ) : null}
            {addErrors?.photo && (
              <Alert severity="error">{addErrors?.photo[0]}</Alert>
            )}
          </Grid> */}
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
          <FormControl fullWidth margin="normal">
              <FormGroup
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                row
              >
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Active
                </Typography>
                <FormControlLabel
                  onChange={(e) => {
                    handleCheck(e, "status");
                  }}
                  // label="Active"
                  control={
                    <Switch
                      size="small"
                      checked={formik.values.status == 1}
                      color="primary"
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>

          {editMode ? (
            <Grid sx={{ mt: 1 }} xs={12} sm={12} md={12} lg={12} xl={12} item>
              <Autocomplete
                // disablePortal={true}
                disableCloseOnSelect={true}
                filterSelectedOptions={true}
                multiple
                id="permissions"
                options={filteredPermissions || []}
                getOptionLabel={(option) => option.title}
                defaultValue={[...userPermissions]}
                // filterOptions={(options) => {
                //   const newOptions = [];

                //   options.forEach((element) => {
                //     const found = userPermissions.some(
                //       (el) => el.id === element["id"]
                //     );

                //     if (!found) newOptions.push(element);
                //   });

                //   return newOptions;
                // }}

                renderInput={(params) => (
                  <TextField
                    error={
                      (formik.touched.permissions &&
                        formik.errors.permissions) ||
                      addErrors?.permissions
                    }
                    {...params}
                    label="Permissions"
                    // placeholder="Music on Hold"
                    {...formik.getFieldProps("permissions")}
                  />
                )}
                size="small"
                // margin="normal"
                sx={{ width: "100%" }}
s
                name="permissions"
                // sx={{ width: "500px" }}
                onChange={(e, value) => {
                  console.log("perm value ", value);
                  formik.setFieldValue("permissions", value);
                  // setSingleMember([...singleMember,value]);
                  filterOptions(permissions, value);
                }}
                // value={singleMember || {id:'',List_Name:'',Num_1:''}}
                // {...formik.getFieldProps("musiconhold_id")}
              />
              {formik.touched.permissions && formik.errors.permissions ? (
                <Alert severity="error">{formik.errors.permissions}</Alert>
              ) : null}
              {addErrors?.permissions && (
                <Alert severity="error">{addErrors?.permissions[0]}</Alert>
              )}
            </Grid>
          ) : null}
        </Grid>
      </AddBox>
    </>
  );
};
