import React, { useRef, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Alert,
  Stack,
  Paper,
  LinearProgress,
  Typography,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import {
  useLoginUserMutation,
  useVerifyCaptchaMutation,
} from "../../services/userAuthApi";
import { MyButton } from "../../components/MyButton";
import LockIcon from "@mui/icons-material/Lock";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import LoginLogo from "../../images/zonglogo.png";
import { ReactComponent as UsernameIcon } from "../../images/username.svg";
import { ReactComponent as PasswordIcon } from "../../images/lock.svg";
import ReCAPTCHA from "react-google-recaptcha";

import { useSelector, useDispatch } from "react-redux";
import {
  setCompany,
  setUser,
  setPermissions,
  setIsAdmin,
} from "../../features/company/companySlice";

import { IconButton, InputLabel, Input, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();

  const [alert, setAlert] = useState({
    status: false,
    msg: "",
    type: "",
  });

  const [values, setValues] = React.useState({
    password: "",
    user_name: "",
    showPassword: false,
  });

  const [recaptcha, setreCaptcha] = useState(false);

  const navigate = useNavigate();
  const captchaRef = useRef(null);

  const [loginUser, isLoading] = useLoginUserMutation();
  const [verifyCaptcha,reCaptchLoading] = useVerifyCaptchaMutation();

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleUserNameChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  async function handleLogin() {
    const captchaValue = captchaRef.current.getValue();

    if (!captchaValue) {
      displayError("Please verify the reCAPTCHA!");
      return;
    }

    try {
      const captchaResponse = await verifyCaptcha({ captchaValue }).unwrap();
      let recaptcha_res=false;
      if (captchaResponse.success) {
        recaptcha_res= true

        const data = {
          user_name: values.user_name,
          password: values.password,
          captcha_response:captchaResponse.success
        };

        if (data.user_name && data.password && recaptcha_res) {
          try {
            const loginResponse = await loginUser(data).unwrap();
            handleLoginResponse(loginResponse);
          } catch (error) {
            handleLoginError(error);
          }
        } else {
          displayError("All fields are required");
        }
      } else {
        displayError("Invalid reCAPTCHA!");
      }
    } catch (error) {
      displayError("Invalid reCAPTCHA!");
    }
  }
  function handleLoginResponse(res) {
    console.log("res", res);
    console.log("status", res.status);
    console.log("msg", res.message);

    if (res.status === true) {
      console.log("login res", res);

      localStorage.setItem("token", res.token);
          localStorage.setItem("client_id", res.user.client_id);

          localStorage.setItem("user_id", res.user.id);

          localStorage.setItem(
            "must_change_password",
            res.user.must_change_password
          );

          if (res.user.is_superuser == 1) {
            // localStorage.setItem("isAdmin", 1);
          } else {
            // localStorage.setItem("isAdmin", 0);
          }
          if (res.user.is_superuser == 1) {

            dispatch(setIsAdmin(1));
          }else{

            dispatch(setIsAdmin(0));
          }

          // localStorage.setItem("is_superuser", res.user.is_superuser);

          localStorage.setItem("client_name", res.user.client.name);
          localStorage.setItem("role_id", res.user.role_id);

          localStorage.setItem("master_num", res.user.client.did_number);
          dispatch(
            setCompany(res.user.client)
          );
          dispatch(
            setUser(res.user)
          );
          dispatch(
            setPermissions(res.permissions)
          );

          localStorage.setItem("name", res.user.name);

          if(res.user.client.logo==null){

            localStorage.setItem("profile_photo", res.user.profile_photo_url);

          }else{

            localStorage.setItem("profile_photo", res.user.client.logo_url);

          }

          localStorage.setItem(
            "user_permissions",
            JSON.stringify(res.permissions)
          );

          if (res.user.role_id == 6 || res.user.role_id == 5) {
            navigate("/call-record");
          } else {
            navigate("/welcome");
          }
    } else if (res.status === false) {
      displayError(res.message);
    } else {
      displayError("Something went wrong");
    }
    captchaRef.current.reset();
  }

  function handleLoginError(error) {
    console.error("Error during login:", error);
    const errorMessage = error?.data?.message || "Something went wrong!";
    displayError(errorMessage);
  }

  function displayError(message) {
    setAlert({
      status: true,
      msg: message,
      type: "error",
    });
    captchaRef.current.reset();
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    handleLogin();
    // const form_data = new FormData(e.currentTarget);

    // const data = {
    //   user_name: values.user_name,
    //   password: values.password,
    // };

    // if (data.user_name && data.password && recaptcha) {
    //   // console.log('data',data)

    //   // document.getElementById('login-form').reset()

    //   try {
    //     const res = await loginUser(data).unwrap();

    //     console.log("res", res);

    //     console.log("status", res.status);

    //     console.log("msg", res.message);

    //     if (res.status === true) {
    //       console.log("login ressponse", res);

    //       localStorage.setItem("token", res.token);
    //       localStorage.setItem("client_id", res.user.client_id);

    //       localStorage.setItem("user_id", res.user.id);

    //       localStorage.setItem(
    //         "must_change_password",
    //         res.user.must_change_password
    //       );

    //       if (res.user.is_superuser == 1) {
    //         // localStorage.setItem("isAdmin", 1);
    //       } else {
    //         // localStorage.setItem("isAdmin", 0);
    //       }
    //       if (res.user.is_superuser == 1) {
    //         dispatch(setIsAdmin(1));
    //       } else {
    //         dispatch(setIsAdmin(0));
    //       }

    //       // localStorage.setItem("is_superuser", res.user.is_superuser);

    //       localStorage.setItem("client_name", res.user.client.name);
    //       localStorage.setItem("role_id", res.user.role_id);

    //       localStorage.setItem("master_num", res.user.client.did_number);
    //       dispatch(setCompany(res.user.client));
    //       dispatch(setUser(res.user));
    //       dispatch(setPermissions(res.permissions));

    //       localStorage.setItem("name", res.user.name);

    //       if (res.user.client.logo == null) {
    //         localStorage.setItem("profile_photo", res.user.profile_photo_url);
    //       } else {
    //         localStorage.setItem("profile_photo", res.user.client.logo_url);
    //       }

    //       localStorage.setItem(
    //         "user_permissions",
    //         JSON.stringify(res.permissions)
    //       );

    //       if (res.user.role_id == 6 || res.user.role_id == 5) {
    //         navigate("/call-record");
    //       } else {
    //         navigate("/welcome");
    //       }
    //     } else if (res.status === false) {
    //       setAlert({
    //         status: true,
    //         msg: res.message,
    //         type: "error",
    //       });
    //     } else {
    //       setAlert({
    //         status: true,
    //         msg: "Something went wrong",
    //         type: "error",
    //       });
    //     }
    //   } catch (error) {
    //     console.log("error", error);

    //     // console.log('error data',error.data.error['0'])

    //     setAlert({
    //       status: true,
    //       msg: error?.data?.message
    //         ? error?.data?.message
    //         : "Something went wrong !",
    //       type: "error",
    //     });
    //   }
    // } else {
    //   console.log("all fields are required");

    //   setAlert({
    //     status: true,
    //     msg: "All fields are required",
    //     type: "error",
    //   });
    // }
  };

  return (
    <>
      {isLoading.isLoading ? <LinearProgress /> : ""}

      {/* <Stack direction="column" spacing={5} > */}
      <Box
        onSubmit={handleSubmit}
        component="form"
        id="login-form"
        noValidate
        sx={{
          // m: 2,
          // mt:3,
          py: 9.4,
          display: "flex",
          // borderRadius: "16px",
          // boxShadow: 5,
          flexDirection: "column",
        }}
        px={{ xs: 1.4, sm: 2.4, md: 7.4, lg: 17, xl: 17 }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={LoginLogo}></img>
        </Box>
        <Box sx={{ py: 3 }}>
          <Typography
            sx={{
              // display: open ? "none" : "",
              fontWeight: 500,
              fontSize: "16px",
              textDecoration: "none",
              textAlign: "center",
            }}
          >
            Login
          </Typography>
        </Box>
        <TextField
          margin="normal"
          sx={{}}
          required
          id="user_name"
          name="user_name"
          label={
            <>
              <UsernameIcon />{" "}
              <Typography
                variant="span"
                sx={{ fontWeight: 500, fontSize: "13px" }}
              >
                User Name / Agent No{" "}
              </Typography>
            </>
          }
          value={values.user_name}
          onChange={handleUserNameChange("user_name")}
        />

        {/* <TextField
          margin="normal"
          sx={{}}
          required
          id="pass"
          name="pass"
          label="Password"
          type="password"
        /> */}

        <TextField
          id="pass"
          name="pass"
          margin="normal"
          label={
            <>
              <PasswordIcon />{" "}
              <Typography
                sx={{ fontWeight: 500, fontSize: "13px" }}
                variant="span"
              >
                Password
              </Typography>{" "}
            </>
          }
          sx={{}}
          type={values.showPassword ? "text" : "password"}
          onChange={handlePasswordChange("password")}
          value={values.password}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Typography
          sx={{
            textDecoration: "none",
            color: "primary.main",
            // display: "block",
            textAlign: "right",
            fontWeight: 500,
            fontSize: "16px",
          }}
          component={NavLink}
          to="/sendPasswordResetEmail"
        >
          Forgot Password?
        </Typography>

        <Box
          textAlign="Center"
          sx={{ display: "flex", flexDirection: "column", pt: 3 }}
        >
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={captchaRef}
          />
        </Box>
        <Box
          textAlign="Center"
          sx={{ display: "flex", flexDirection: "column", pt: 3 }}
        >
          <Button
            disabled={
              reCaptchLoading.isLoading||
              isLoading.isLoading ||
              values.user_name === "" ||
              values.password === ""
            }
            size="large"
            sx={{
              // mt: 3,
              // mb: 2,
              // borderRadius: "16px",
              textTransform: "none",
              fontWeight: 500,
              fontSize: "13px",
            }}
            type="submit"
            variant="contained"
          >
            Login
          </Button>

          {/* <MyButton type="submit" text="Login" / > */}

          {alert.status ? <Alert severity={alert.type}>{alert.msg}</Alert> : ""}
        </Box>
      </Box>
      {/* </Stack> */}
    </>
  );
};

export default Login;
