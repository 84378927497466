import React, { useEffect, useState } from "react";

import { Alert, Autocomplete, TextField, LinearProgress,Typography } from "@mui/material";

import { useFetchQueuesMutation } from "../services/queueApi";

export const QueueDestination = (props) => {
  const { formik, addErrors, name, index } = props;

  const [fetchQueues, fetchLoader] = useFetchQueuesMutation();

  const isFetchLoading = fetchLoader.isLoading;

  const [singleQueue, setSingleQueue] = useState({ id: "", queue_name: "" });

  const [queues, setQueues] = useState([]);

  const fetchQ = async () => {
    try {
      const response = await fetchQueues({ pagination: "no" }).unwrap();

      console.log("Queue destination response", response);

      if (response.status === true) {
        console.log("queue destination result", response.queues);

        setQueues(response.queues);

        // if(name=='time_destination_id'){


        //   var destination_id=formik.values[name];

        // }else{

        // var destination_id =
        //   formik.values?.ivr_count[index]?.["ivr_option_destination_id"];

        // }

        const destination_id= name=='time_destination_id' ? formik.values[name] : formik.values?.ivr_count[index]?.["ivr_option_destination_id"];

        if (destination_id) {
          response.queues.forEach((element) => {
            // console.log("element", element);
            // console.log("valllll", destination_id);

            if (element["id"] == destination_id) {
              setSingleQueue(element);
            }
          });
        }
      } else {
        console.log("____queue_destination_false");
      }
    } catch (error) {
      console.log("____catch_queue_destination", error);
    }
  };

  useEffect(() => {
    fetchQ();
  }, []);

  return (
    <>
      <Autocomplete
        openOnFocus
        disableClearable
        loading={isFetchLoading}
        getOptionLabel={(option) => option.queue_name}
        fullWidth={true}
        size="small"
        value={singleQueue || { id: "", queue_name: "" }}
        onChange={(event, value) => {
          console.log("value", value);
          formik.setFieldValue(name, value.id);

          setSingleQueue(value);
        }}
        id={name}
        options={queues}
        renderInput={(params) => (
          <TextField
            {...formik.getFieldProps(name)}
            {...params}
            label={

              <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
              }}
              >
                Select Queue

              </Typography>
            }
            error={
              (formik.touched?.ivr_count?.[index]?.[
                "ivr_option_destination_id"
              ] &&
                formik.errors?.ivr_count?.[index]?.[
                  "ivr_option_destination_id"
                ]) ||
              addErrors[name]
            }
          />
        )}
        label="Select Queue"
        name={name}
      />
      {formik.touched?.ivr_count?.[index]?.["ivr_option_destination_id"] &&
      formik.errors?.ivr_count?.[index]?.["ivr_option_destination_id"] ? (
        <Alert severity="error">
          {formik.errors?.ivr_count?.[index]?.["ivr_option_destination_id"]}
        </Alert>
      ) : null}
      {addErrors[name] && (
        <Alert severity="error">{addErrors[name]?.[0]}</Alert>
      )}

      {isFetchLoading ?? <LinearProgress />}
    </>
  );
};
