import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AddBox({open,handleClose,title,children,formik,fullScreen,fullWidth,style}) {

//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

  return (
    <>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        PaperProps={{
          sx: {
            ...style
          }
        }}

      >
        <DialogTitle id="alert-dialog-title">
        <Typography
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "20px",
            }}
          >
            {title}
          </Typography>
        </DialogTitle>
        {/* (e)=>{handleFormSubmit(e)} */}
        <DialogContent>
        <form id='addBoxForm' noValidate onSubmit={formik.handleSubmit}> 
            {children}
        </form>
        </DialogContent>
        <DialogActions>
        <Button
            sx={{
              // color: "white",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "15px",
              padding: "8px 16px",
              gap: "19px",
              width: "98px",
              height: "31px",
            }}
            color={"primary"}
            variant="outlined" onClick={handleClose}>Cancel</Button>
                    <Button
            // sx={{ color: "white" }}
            color={"primary"}
            size="small"
            variant="contained"
            sx={{
              color: "white",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "15px",
              padding: "8px 16px",
              gap: "19px",
              width: "98px",
              height: "31px"
            }}
            form="addBoxForm"
               disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)} type="submit" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
