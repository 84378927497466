import React, { useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  useFetchSystemSoundMutation,
  useAddSystemSoundMutation,
  useDeleteSystemSoundMutation,
} from "../../services/systemSoundApi";
import { styled } from "@mui/material/styles";
import { useFetchMOHMutation } from "../../services/mohApi";
import ReactAudioPlayer from "react-audio-player";
import axios from "axios";
import { ReactComponent as IconEdit } from "../../images/editIcon.svg";
import { ReactComponent as IconSystemSound } from "../../images/systemsound.svg";
import { ReactComponent as IconDelete } from "../../images/deleteIcon.svg";
import { StyledTableCell } from "../call_setting/QueueList";

import { Header } from "../../components/Header";

import { isAllowed } from "../Functions";
import { useSelector } from "react-redux";

import {
  Autocomplete,
  Stack,
  Input,
  Typography,
  Button,
  TableRow,
  Alert,
  Grid,
  TextField,
  TextareaAutosize,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Tooltip,
  IconButton,
  Paper,
} from "@mui/material";
import { MyTable } from "../../components/MyTable";
import { toast } from "react-toastify";
import { useFormik, Field } from "formik";
import * as Yup from "yup";
import { StyledTableRow } from "../Style";
import AddBox from "../../components/AddBox";
import { useNavigate } from "react-router-dom";
import { useFetchClientOptionsMutation } from "../../services/clientApi";

export const SystemSound = () => {
  const isAdmin = useSelector((state) => state.company.isAdmin);

  const [fetchMOH, mohLoader] = useFetchMOHMutation();
  const [fetchClientOptions, clientLoader] = useFetchClientOptionsMutation();
  const isClientLoading = clientLoader.isLoading;
  const isMohLoading = mohLoader.isLoading;

  const [fetchSystemSound, isLoading] = useFetchSystemSoundMutation();
  const [deleteSystemSound, deleteLoader] = useDeleteSystemSoundMutation();

  const isDeleteLoading = deleteLoader.isLoading;

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    totalRecords: 0,
    fromRecord: 0,
    toRecord: 0,
    totalPages: 0,
  });

  const [body, setBody] = useState({ page: 1, search: "",client_id: "" });
  const [client, setClient] = useState({
    id: localStorage.getItem("client_id"),
    name: localStorage.getItem("client_name"),
    did_number: localStorage.getItem("master_num"),
  });
  const [clients, setClients] = useState([]);
  const [moh, setMoh] = useState([]);

  const [addSystemSound, isLoad] = useAddSystemSoundMutation();

  const addLoading = isLoad.isLoading;

  const [addAlert, setAddAlert] = useState({ status: false, msg: "" });

  const [open, setOpen] = React.useState(false);

  const [addErrors, setAddErrors] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getCategory = (id) => {
    if (id == 1) {
      return "Welcome Prompts";
    } else if (id == 2) {
      return "Callback Prompts";
    } else if (id == 3) {
      return "Voicemail";
    } else if (id == 4) {
      return "MOH";
    }
  };

  const Input = styled("input")({
    display: "none",
  });

  const fetchSS = async () => {
    try {
      const response = await fetchSystemSound(body).unwrap();

      console.log("System Sound response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result", response.systemsounds);

        setData(response.systemsounds.data);

        setPagination({
          ...pagination,
          totalPages: response.systemsounds.last_page,
          totalRecords: response.systemsounds.total,
          fromRecord: response.systemsounds.from,
          toRecord: response.systemsounds.to,
          toRecord: response.systemsounds.to,
        });

        if (
          response.systemsounds.current_page > response.systemsounds.last_page
        ) {
          setBody({ ...body, page: 1 });

          console.log("current_page > last_page");
        }
      } else {
        console.log("____false");
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };

  const createSystemSound = async (values) => {
    setAddErrors({});
    setAddAlert({ status: false, msg: "" });

    try {
      const response = await addSystemSound(values).unwrap();

      console.log("add moh response", response);

      if (response.status === true) {
        handleClose();

        fetchSS();

        toast.success("System Sound has been uploaded successfully !");

        formik.resetForm();
      } else {
        console.log("____false", response);
      }
      formik.setSubmitting(false);
    } catch (error) {
      console.log("____catch", error);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }

      formik.setSubmitting(false);
    }
  };

  const [singleMOH, setSingleMOH] = useState({ id: "", unique_name: "" });

  const initialValues = {
    name: "",
    detail: "",
    category_id: "",
    musiconhold_id: null,
    sound_file: null,
  };

  // const myRef=React.useRef(null)

  const onSubmit = (values, actions) => {
    // console.log("form values", values);

    const form_data = new FormData();

    form_data.append("musiconhold_id", formik.values.musiconhold_id);
    form_data.append("sound_file", formik.values.sound_file);
    form_data.append("category_id", formik.values.category_id);
    form_data.append("detail", formik.values.detail);
    form_data.append("name", formik.values.name);

    form_data.append("isAdmin", isAdmin);

    createSystemSound(form_data);
  };

  const checkIfFileSizeBig = (fileSize) => {
    let valid = true;
    const size = fileSize / 1024 / 1024;
    if (size > 10) {
      valid = false;
    }
    return valid;
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required !"),
    detail: Yup.string().required("Required !"),
    category_id: Yup.number().required("Required !"),
    musiconhold_id: Yup.number()
      .nullable()
      .when("category_id", {
        is: (category_id) => category_id == 4,
        then: Yup.number()
          .nullable()
          .required("Music on Hold is Required when Category Type is MOH !"),
      }),
    // sound_file:Yup.mixed().test('fileSize',"File Size is too large", value => value.size <= 10).test('fileType', "Unsupported File Format", value => [ "audio/wav","audio/gsm" ].includes(value.type) )
    sound_file: Yup.mixed()
      .required("File Required !")
      .test(
        "fileSize",
        "File should be less than 10mb",
        (sound_file) => sound_file && checkIfFileSizeBig(sound_file.size)
      )
      .test(
        "fileName",
        "Unsupported File Format, Allowed .gsm",
        (sound_file) =>
          // sound_file && sound_file?.name?.includes(".gsm")
          // console.log("ffffff",sound_file?.name?.substr(sound_file?.name?.length - 4))
          // sound_file && sound_file?.name?.substr(sound_file?.name?.length - 4).includes(".gsm")
          sound_file &&
          sound_file?.name?.substr(sound_file?.name?.length - 4) == ".gsm"
        // console.log("tttttttttttttt",sound_file && sound_file?.name?.substr(sound_file?.name?.length - 4).includes(".gsm"))
      ),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    // validateOnMount:true,
    // validate,
  });

  console.log("formik", formik);

  const handleClose = () => {
    setOpen(false);
    setAddErrors({});
    setAddAlert({ status: false, msg: "" });

    setSingleMOH({ id: "", unique_name: "" });
    formik.resetForm();

    // setAddBody({})

    // if(formik.errors.length>0){

    //  formik.setErrors({})

    // }
    // if(formik.values.length>0){

    //   formik.setValues({})
    // }
    // if(formik.touched.length>0){

    //  formik.setTouched({})
    // }
  };

  const getMOH = async () => {
    try {
      const response = await fetchMOH({ moh_pagination: "no" }).unwrap();

      console.log("moh response", response);

      if (response.status === true) {
        // setAlert(false);

        setMoh(response.musiconholds);

        console.log("result moh", response.musiconholds);
      } else {
        console.log("____false", response);
        // setData([]);
        // setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      // setData([]);
      // setAlert(true);
    }
  };

  useEffect(() => {
    fetchSS();
  }, [body.page,body.client_id]);
  useEffect(() => {
    getClients();
  }, []);
  useEffect(() => {
    getMOH();
  }, []);

  const columns = [
    "Name",
    "Category",
    "MOH Class",
    "Description",
    // "Status",
    "Size(kb)",
    "Play",
    "Action",
  ];

  const handleFileChange = (e) => {
    let file = e.target.files[0];

    console.log("sound file", file);

    formik.setFieldValue("sound_file", file);

    console.log("value file", formik.values.sound_file);
  };

  const destroySystemSound = async (id) => {
    console.log("id", id);

    if (window.confirm("are you sure you want to delete this record?")) {
      try {
        const response = await deleteSystemSound({ id: id }).unwrap();

        console.log("delete ssystem sound response", response);

        if (response.status === true) {
          fetchSS();
          toast.success("Record has been deleted successfully !");
        } else {
          console.log("____false", response);

          toast.error(response.message);
        }
      } catch (error) {
        console.log("____catch", error);

        toast.error("Oops something went wrong !");
      }
    }
  };

  const navigate = useNavigate();

  if (!isAllowed("system_sound_access")) {
    return navigate("/forbidden");
  }
  const getClients = async () => {
    try {
      const response = await fetchClientOptions({ pagination: "no" }).unwrap();

      console.log("client resposne", response);

      if (response.status === true) {
        setClients(response.clients);

        console.log("clients option", clients);
      }
    } catch (error) {
      console.log("clients catch error", error);
    }
  };

  return (
    <>
      <Header title={"System Sounds"} />
      { isAllowed("search_any_client") ? (
        <Paper spacing={2} sx={{ m: 2 }}>
          <Autocomplete
            // fullWidth={false}
            size="small"
            value={client || ""}
            onChange={(event, value) => {
              console.log("client value", value);

              setClient(value);

              setBody({ ...body, client_id: value?.id });

              // setBody({ ...body, agent_number: "" })
            }}
            disablePortal
            id="clients"
            options={clients}
            loading={isClientLoading}
            getOptionLabel={(option) => option.name + " " + option.did_number}
            sx={{}}
            renderInput={(params) => <TextField {...params} label="Client" />}
          />

          {isClientLoading ? <LinearProgress /> : null}
        </Paper>
      ) : null}
      <MyTable
        // title="SystemSounds"
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={fetchSS}
        handleClickOpen={handleClickOpen}
        addButton={
          isAllowed("system_sound_create") || isAdmin == 1 ? true : false
        }
      >
        {data?.map((item, index) => {
          return (
            <TableRow hover key={index} sx={{}}>
              <StyledTableCell>{item.file_Name}</StyledTableCell>
              <StyledTableCell>{getCategory(item.catid)}</StyledTableCell>
              <StyledTableCell>{item.moh?.name}</StyledTableCell>
              <StyledTableCell>{item.detail}</StyledTableCell>
              {/* <StyledTableCell
                sx={{ color: item.status == 0 ? "error.main" : "success.main" }}
                align="center"
              >
                {item.status == 0 ? "Not Used" : "Used"}
              </StyledTableCell> */}
              <StyledTableCell>
                {Math.floor(item.file_size / 1024)}
              </StyledTableCell>
              <StyledTableCell>
                <ReactAudioPlayer
                  // src={item.file_Path ? "https://zong-cap.com.pk:97/"+item.file_Path : ""}
                  // src={item.file_Path ? "https://zong-cap.com.pk:99/"+item.file_Path.replace(".gsm", ".mp3") : ""}
                  src={
                    item.file_Path
                      ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL +
                        item.file_Path.replace(".gsm", "mp3.mp3")
                      : ""
                  }
                  controls
                />
              </StyledTableCell>
              <StyledTableCell>
                <Tooltip title="Delete">
                  <IconButton
                    size="small"
                    // sx={{ m: 1 }}
                    color={"error"}
                    variant="contained"
                    onClick={() => destroySystemSound(item.id)}
                    disabled={!isAllowed("system_sound_delete")}
                  >
                    <IconDelete stroke="red" />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </MyTable>

      {/* ________Add Box___________________ */}
      <AddBox
        title={"Add System Sound"}
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        addFunction={createSystemSound}
        addLoading={addLoading}
        formik={formik}
        fullWidth={false}
        style={{
          width: "333px",
          boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
        }}
      >
        {addAlert.status ? (
          <Alert severity="error">{addAlert.msg}</Alert>
        ) : null}

        {addLoading && <LinearProgress />}

        <Grid container spacing={1}>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <TextField
              sx={{ width: "100%" }}
              size="small"
              error={
                (formik.touched.name && formik.errors.name) || addErrors?.name
              }
              // margin="normal"
              required
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Name
                </Typography>
              }
              id="name"
              name="name"
              inputProps={{
                style: {
                  // padding: "14px",
                  // gap: "193px",
                  // width: "100%",
                  // height: "44px",
                },
              }}
              {...formik.getFieldProps("name")}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              // onBlur={formik.handleBlur}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.name && formik.errors.name ? (
              <Alert severity="error">{formik.errors.name}</Alert>
            ) : null}
            {addErrors?.name && (
              <Alert severity="error">{addErrors?.name[0]}</Alert>
            )}
          </Grid>

          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <TextField
              error={
                (formik.touched.detail && formik.errors.detail) ||
                addErrors?.detail
              }
              sx={{ width: "100%" }}
              // margin="normal"
              required
              id="detail"
              name="detail"
              size="small"
              label={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Detail
                </Typography>
              }
              // minRows={4}
              {...formik.getFieldProps("detail")}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              // onBlur={formik.handleBlur}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.detail && formik.errors.detail ? (
              <Alert severity="error">{formik.errors.detail}</Alert>
            ) : null}
            {addErrors?.detail && (
              <Alert severity="error">{addErrors?.detail[0]}</Alert>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <FormControl
              size="small"
              fullWidth
              // margin="normal"
            >
              <InputLabel sx={{}} id="cat-select-label">
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  Category
                </Typography>
              </InputLabel>

              <Select
                sx={
                  {
                    // fontWeight: 500,
                    // fontSize: "13px",
                    // lineHeight: "16px",
                  }
                }
                labelId="cat-select-label"
                label={
                  <Typography
                    variant="span"
                    sx={{
                      fontWeight: 500,
                      fontSize: "13px",
                      lineHeight: "16px",
                    }}
                  >
                    Category
                  </Typography>
                }
                id="category_id"
                name="category_id"
                {...formik.getFieldProps("category_id")}
                error={
                  (formik.touched.category_id && formik.errors.category_id) ||
                  addErrors?.category_id
                }
              >
                <MenuItem
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                  value="1"
                >
                  Welcome Prompts
                </MenuItem>
                <MenuItem
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                  value="2"
                >
                  Callback Prompts
                </MenuItem>
                <MenuItem
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                  value="3"
                >
                  Voicemail
                </MenuItem>
                <MenuItem
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                  value="4"
                >
                  MOH
                </MenuItem>
                <MenuItem
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                  value="5"
                >
                  Main Sound (Call Originate)
                </MenuItem>
                <MenuItem
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                  value="6"
                >
                  Confirmation Sound (Call Originate)
                </MenuItem>
                <MenuItem
                  sx={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                  value="7"
                >
                  Invalid Input Sound (Call Originate)
                </MenuItem>
              </Select>
            </FormControl>
            {formik.touched.category_id && formik.errors.category_id ? (
              <Alert severity="error">{formik.errors.category_id}</Alert>
            ) : null}
            {addErrors?.category_id && (
              <Alert severity="error">{addErrors?.category_id[0]}</Alert>
            )}
          </Grid>
          {formik.values.category_id == 4 && (
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
              {/* MOH_________________________________________ */}
              <Autocomplete
                id="moh"
                options={moh}
                getOptionLabel={(option) => option.unique_name}
                // defaultValue={[moh[0]]}
                renderInput={(params) => (
                  <TextField
                    error={
                      (formik.touched.musiconhold_id &&
                        formik.errors.musiconhold_id) ||
                      addErrors?.musiconhold_id
                    }
                    {...params}
                    label="Music On Hold"
                    // placeholder="Music on Hold"
                    {...formik.getFieldProps("musiconhold_id")}
                  />
                )}
                size="small"
                // margin="normal"
                // sx={{ width: "500px" }}
                onChange={(e, value) => {
                  console.log("moh value ", value);
                  formik.setFieldValue("musiconhold_id", value?.id);
                  setSingleMOH(value);
                }}
                value={singleMOH || { id: "", unique_name: "" }}
                name="musiconhold_id"
                // {...formik.getFieldProps("musiconhold_id")}
              />
              {formik.touched.musiconhold_id && formik.errors.musiconhold_id ? (
                <Alert severity="error">{formik.errors.musiconhold_id}</Alert>
              ) : null}
              {addErrors?.musiconhold_id && (
                <Alert severity="error">{addErrors?.musiconhold_id[0]}</Alert>
              )}
            </Grid>
          )}
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <Stack direction={"row"} alignItems="center" spacing={4}>
              <label style={{ width: "100%" }} htmlFor="sound-file">
                <Input
                  // {...formik.getFieldProps("sound_file")}
                  onChange={(e) => {
                    handleFileChange(e);

                    //  console.log("formik file name",formik.values.sound_file.name)
                    // console.log(
                    //   "sound file size",
                    //   formik.values.sound_file.size / 1024 / 1024
                    // );

                    //  console.log({
                    //   fileName: formik.values.sound_file.name,
                    //   type: formik.values.sound_file.type,
                    //   size: `${formik.values.sound_file.size} bytes`
                    // })
                  }}
                  accept=".gsm"
                  name="sound_file"
                  id="sound-file"
                  type="file"
                />

                <Button
                  size="small"
                  color={formik.errors.sound_file ? "error" : "primary"}
                  component="span"
                  variant="outlined"
                  sx={{
                    // color: "white",
                    padding: "14px",
                    gap: " 5px",
                    width: "100%",

                    height: "44px",
                  }}
                >
                  <IconSystemSound />{" "}
                  {formik?.values?.sound_file?.name
                    ? formik?.values?.sound_file?.name
                    : "Upload Sound File"}
                </Button>
              </label>
            </Stack>
            {formik.errors.sound_file ? (
              <Alert severity="error">{formik.errors.sound_file}</Alert>
            ) : null}
            {addErrors?.sound_file && (
              <Alert severity="error">{addErrors?.sound_file[0]}</Alert>
            )}
          </Grid>
        </Grid>
      </AddBox>
    </>
  );
};
