import { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Typography,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Header } from "../../components/Header";
import { useCallOrignateMutation } from "../../services/callOrignateApi";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PhoneIcon from "@mui/icons-material/CallOutlined";
import { isAllowed } from "../Functions";
import { useNavigate } from "react-router-dom";

function BulkCallOrignate() {
  const [isCalling, setIsCalling] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [numbersStatus, setNumbersStatus] = useState({});
  const [numbersCallingStatus, setNumbersCallingStatus] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [key, setKey] = useState(0);

  const [callOrignate, callingLoader] = useCallOrignateMutation();
  const [terminateCalling, setTerminateCalling] = useState(false);

  function handleFileChange(event) {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = (event) => {
      const csv = event.target.result;
      const rows = csv.split("\n");

      const data = rows
        .map((row) => {
          const cells = row.split(",");
          const cell = cells.length > 0 ? cells[0].trim() : null;
          return /^3\d{9}$/.test(cell) ? cell : null;
        })
        .filter((cell) => {
          return cell !== null && cell !== "";
        });

      if (data.length === 0) {
        toast.error("No valid data found.");
        return;
      }

      const uniqueData = [...new Set(data)];

      setCsvData(uniqueData);
      setCsvFile(file);
    };
  }

  function handleDelete(index) {
    const newData = [...csvData];
    newData.splice(index, 1);
    setCsvData(newData);

    if (newData.length == 0) {
      handleClear();
    }
  }

  function handleClear() {
    setCsvData([]);
    setCsvFile(null);
    setKey(key + 1);
    setNumbersStatus({});
    setNumbersCallingStatus({});
  }

  const initiateCall = async (num) => {
    // setIsCalling(true);
   
   
    setNumbersCallingStatus((prevStatus) => ({
      ...prevStatus,
      [num]: true,
    }));

    // const body = { number: num , call_order:index};
    const body = { numbers: num };
    try {
  
  
      const res = await callOrignate(body).unwrap();
      console.log(terminateCalling);
      if(!terminateCalling){
      console.log("initiateCall resp", res);

      if (res.status === true) {
        // toast.success("call initiated successfully !");

        setNumbersStatus((prevStatus) => ({
          ...prevStatus,
          [num]: true,
        }));
        //
      } else if (res.status === false) {
        // toast.error("call not initiated successfully !");
        setNumbersStatus((prevStatus) => ({
          ...prevStatus,
          [num]: false,
        }));
      } else {
        // toast.error("there is a problem in call inititating !");
        setNumbersStatus((prevStatus) => ({
          ...prevStatus,
          [num]: false,
        }));
      }
    }
    } catch (error) {
      console.log("catch error", error);

      if (error?.status == 400) {
        toast.error("bad request !");
        setNumbersStatus((prevStatus) => ({
          ...prevStatus,
          [num]: false,
        }));
      } else if (error?.status == 403) {
        toast.error("not Allowed !");
        setNumbersStatus((prevStatus) => ({
          ...prevStatus,
          [num]: false,
        }));
      } else {
        // toast.error("there is a problem in call inititating !");
        setNumbersStatus((prevStatus) => ({
          ...prevStatus,
          [num]: false,
        }));
      }
    }

    setNumbersCallingStatus((prevStatus) => ({
      ...prevStatus,
      [num]: false,
    }));
    // setIsCalling(false);
  
  };

  const initiateAllCalls = async () => {
    setIsCalling(true);
  
    // for (const [index, num] of csvData.entries()) {
     initiateCall(csvData).then((res)=>{
      var fileInput = document.getElementById('contained-button-file');
      fileInput.value=''
      setCsvData([])});
	
      // await new Promise((resolve) => setTimeout(resolve, 1000));
    // }
  
    setIsCalling(false);
  }
  

  const navigate = useNavigate();
  if (!isAllowed("originate_call")) {
    return navigate("/forbidden");
  }

  return (
    <>
      <Header title={"Bulk Call Orignate"} />
      <Grid spacing={2} container justifyContent="center" sx={{ mt: 1 }}>
        <Grid
          sx={{
            boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.05)",
            borderRadius: "20px",
          }}
          item
          xl={4}
          lg={4}
          md={4}
          sm={8}
          xs={12}
        >
          <input
            key={key}
            type="file"
            accept=".csv"
            id="contained-button-file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" component="span">
              <Typography>
                {csvFile ? csvFile.name : "Upload CSV file"}
              </Typography>
            </Button>
          </label>

          <Button
            onClick={handleClear}
            disabled={csvData.length === 0 || !csvFile}
            variant="outlined"
            color={"secondary"}
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "15px",
              color: "#B7BBBF",
              borderColor: "#B7BBBF",
              p: 1.5,
              ml: 1,
            }}
          >
            Clear
          </Button>

          <Button
            variant="contained"
            color={"secondary"}
            sx={{
              color: "white",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "15px",
              p: 1.5,
              ml: 1,
            }}
            onClick={() => {
              if(isCalling){
              setTerminateCalling(true);
              window.location.reload(false);
              }else{
                initiateAllCalls();
                setTerminateCalling(false);
              }
              
            }}
            disabled={
              csvData.length === 0 ||
              !csvFile
            }
          >
            {isCalling?"Stop Calling":callingLoader.isLoading
              ? "Initiating Calls ..."
              : "Initiate all calls"}
          </Button>
          {csvData.length > 0 ? (
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "20px",
                mt: 1,
                // zIndex: 1,
              }}
              variant="h6"
            >
              <Box fontWeight="fontWeightBold">
                Total valid numbers found: {csvData.length}
              </Box>
            </Typography>
          ) : null}

<Box sx={{ height: '70vh', overflow: 'auto' }}>
          <List>
            {csvData.map((cell, index) => (
              <ListItem
                sx={{
                  borderBottom: "2px solid #F2F2F2",
                  padding: "5px 13px 5px 17px",
                  "&:hover": {
                    backgroundColor: "#F2F2F2",
                  },
                  backgroundColor: numbersStatus[cell]
                    ? " #90ee90"
                    : numbersCallingStatus[cell] || isCalling
                    ? "#FFF3CD"
                    : numbersStatus[cell] == false
                    ? "#f08080"
                    : "",
                }}
                key={index}
              >
                <ListItemText primary={cell} />
                <ListItemSecondaryAction>
                  {numbersStatus[cell] ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : isCalling || numbersCallingStatus[cell] ? (
                    <CircularProgress size={16} />
                  ) : (
                    <IconButton edge="end" onClick={() => initiateCall(cell)}>
                      <PhoneIcon color="success" />
                    </IconButton>
                  )}
                  <IconButton disabled={isCalling || numbersCallingStatus[cell]} edge="end" onClick={() => handleDelete(index)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default BulkCallOrignate;
