import React, { useState } from "react";

import {
  TextField,
  Button,
  Box,
  Alert,
  Stack,
  Paper,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { useResetPasswordMutation } from "../../services/userAuthApi";

import { NavLink, useNavigate, useParams } from "react-router-dom";

// import { MyCard } from "../../components/MyCard";

import { MyButton } from "../../components/MyButton";

import ZongLogo from "../../images/zonglogo.png";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const ResetPassword = () => {
  const [alert, setAlert] = useState({
    status: false,
    msg: "",
    type: "",
  });

  const { reset_token } = useParams();

  const [body, setBody] = useState({
    password: "",
    password_confirmation: "",
    reset_token,
    showPassword:false,
  });

  const [resetPassword, isLoading] = useResetPasswordMutation();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (body.password !== body.password_confirmation) {
      setAlert({
        status: true,
        msg: "Password does not matched",
        type: "error",
      });
    } else {
      // document.getElementById("reset-form").reset();

      try {
        const res = await resetPassword(body).unwrap();

        console.log("res", res);

        if (res.status === true) {
          setAlert({
            status: true,
            msg: res.message,
            type: "success",
          });

          console.log("login ressponse", res);

          document.getElementById("reset-form").reset();


          setTimeout(()=>{

            navigate("/")

          },5000)

          // navigate("/");
        } else if (res.status === false) {
          setAlert({
            status: true,
            msg: res.message,
            type: "error",
          });
        } else {
          setAlert({
            status: true,
            msg: "Something went wrong",
            type: "error",
          });
        }
      } catch (error) {
        console.log("error", error);

        setAlert({
          status: true,
          msg: error?.data?.message
            ? error?.data?.message
            : "Something went wrong !",
          type: "error",
        });
      }

      // setAlert({
      //   status: true,
      //   msg: "Password has been Changed",
      //   type: "success",
      // });

      // navigate('/')
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    setBody({ ...body, [id]: value });
  };

  const handleClickShowPassword = () => {
    setBody({ ...body, showPassword: !body.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
    <Grid container justifyContent="center" sx={{ mt: 5 }}>
      <Grid item lg={6} md={6} sm={8} xs={12}>
        <Box
          py={{ xs: 2, sm: 3, md: 5, lg: 5, xl: 5 }}
          // px={{ xs: 2, sm: 3, md: 7.4, lg: 7.4, xl: 7.4 }}
          px={{ xs: 1.4, sm: 2.4, md: 7.4, lg: 7.4, xl: 7.4 }}
          sx={{
            m: 2,
            display: "flex",
            borderRadius: "16px",
            boxShadow: 5,
            flexDirection: "column",
          }}
        >
          <Box sx={{display : "flex", justifyContent:"center"}} >
            <img src={ZongLogo}></img>
          </Box>
          <Box
            onSubmit={handleSubmit}
            component="form"
            id="reset-form"
            noValidate
            sx={{  py: 5, display: "flex", flexDirection: "column" }}
          >
            <TextField
              margin="normal"
              sx={{}}
              required
              id="password"
              name="password"
              label="New Password"
              onChange={handleChange}
              value={body?.password || ""}
              type={body.showPassword ? "text" : "password"}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {body.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              margin="normal"
              sx={{}}
              required
              id="password_confirmation"
              name="password_confirmation"
              label="Confirm New Password"
              // type="password"
              onChange={handleChange}
              value={body?.password_confirmation || ""}
              type={body.showPassword ? "text" : "password"}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {body.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Box
              textAlign="Center"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Button

                disabled={body.password==""||body.password_confirmation==""|| isLoading.isLoading}
                size="large"
                sx={{
                  mt: 3,
                  mb: 2,
                  borderRadius: "16px",
                  textTransform: "none",
                }}
                type={"submit"}
                variant="contained"
              >
                Reset Password
              </Button>

              {alert.status ? (
                <Alert severity={alert.type}>{alert.msg}</Alert>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
