import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  Grid,
  TextField,
  Alert,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
import { MyTable } from "../../components/MyTable";
import {
  useFetchMOHMutation,
  useAddMOHMutation,
  useDeleteMOHMutation,
} from "../../services/mohApi";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { StyledTableRow } from "../Style";
import AddBox from "../../components/AddBox";
import { Header } from "../../components/Header";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import {ReactComponent as IconEdit } from '../../images/editIcon.svg'
import {ReactComponent as IconDelete } from '../../images/deleteIcon.svg'
import { StyledTableCell } from "../call_setting/QueueList";

import { isAllowed } from "../Functions";
import { useNavigate } from "react-router-dom";

export const Moh = () => {
  const isAdmin=useSelector((state)=>state.company.isAdmin);


  const [fetchMOH, isLoading] = useFetchMOHMutation();
  const [deleteMOH, deleteLoader] = useDeleteMOHMutation();

  const isDeleteLoading = deleteLoader.isLoading;

  const destroyMOH = async (id) => {
    console.log("id", id);

    if (window.confirm("are you sure you want to delete this record?")) {
      try {
        const response = await deleteMOH({ id: id }).unwrap();

        console.log("delete moh response", response);

        if (response.status === true) {
          getMOH();
          toast.success("Record has been deleted successfully !");
        } else {
          console.log("____false", response);

          toast.error(response.message);
        }
      } catch (error) {
        console.log("____catch", error);

        toast.error("Oops something went wrong !");
      }
    }
  };

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    totalRecords: 0,
    fromRecord: 0,
    toRecord: 0,
    totalPages: 0,
  });

  const [body, setBody] = useState({ page: 1, search: "" });

  // const [addBody,setAddBody]=useState({})

  const [addMOH, isLoad] = useAddMOHMutation();

  const addLoading = isLoad.isLoading;

  const [addAlert, setAddAlert] = useState({ status: false, msg: "" });

  const [open, setOpen] = React.useState(false);

  const [addErrors, setAddErrors] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleAddChange=(e) => {

  //   const {id,value}=e.target

  //   setAddBody({...addBody, [id] : value})

  // };

  // const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  //   // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
  // }));

  const getMOH = async () => {
    try {
      const response = await fetchMOH(body).unwrap();

      console.log("moh response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result moh", response.musiconholds);

        setData(response.musiconholds.data);

        setPagination({
          ...pagination,
          totalPages: response.musiconholds.last_page,
          totalRecords: response.musiconholds.total,
          fromRecord: response.musiconholds.from,
          toRecord: response.musiconholds.to,
          toRecord: response.musiconholds.to,
        });

        if (
          response.musiconholds.current_page > response.musiconholds.last_page
        ) {
          setBody({ ...body, page: 1 });

          console.log("current_page > last_page");
        }
      } else {
        console.log("____false", response);
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };

  const createMOH = async (values) => {
    setAddErrors({});
    setAddAlert({ status: false, msg: "" });

    try {
      const response = await addMOH(values).unwrap();

      console.log("add moh response", response);

      if (response.status === true) {
        getMOH();

        handleClose();

        toast.success("Moh has been added successfully !");

        formik.resetForm();
      } else {
        console.log("____false", response);
      }
    } catch (error) {
      console.log("____catch", error);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const initialValues = {
    name: "",
  };

  const onSubmit = (values) => {
    // window.alert("hello")

    console.log("form data", values);

    createMOH(values);
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Required !")
      .matches(
        /^[a-z0-9_\-]+$/i,
        "The name must only contain letters, numbers, dashes and underscores."
      ),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    // validateOnMount:true,
    // validate,
  });

  const handleClose = () => {
    setOpen(false);
    // setAddBody({})
    setAddErrors({});
    setAddAlert({ status: false, msg: "" });

    formik.resetForm();

    // if(formik.errors.length>0){

    //  formik.setErrors({})

    // }
    // if(formik.values.length>0){

    //   formik.setValues({})
    // }
    // if(formik.touched.length>0){

    //  formik.setTouched({})
    // }
  };

  // console.log("formik",formik)

  useEffect(() => {
    getMOH();
  }, [body.page]);

  const columns = ["Name", "Action"];

  const navigate = useNavigate();

  if (!isAllowed("music_on_hold_access")  ) {
    return navigate("/forbidden");
  }

  return (
    <>
      <Header title={"Music On Hold"} />

      {/*__________Table ________________     */}
      <MyTable
        // title="Music On Hold"
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={getMOH}
        handleClickOpen={handleClickOpen}
        addButton={
          isAllowed("music_on_hold_create") || isAdmin == 1 ? true : false
        }
      >
        {data?.map((item, index) => {
          return (
            <TableRow hover key={index} sx={{}}>
              <StyledTableCell >{item.name}</StyledTableCell>

              <StyledTableCell >
                <Tooltip title="Delete">
                  <IconButton
                    disabled={
                      !isAllowed("music_on_hold_delete")  
                    }
                    onClick={() => destroyMOH(item.id)}
                    size="small"
                    // sx={{ m: 1 }}
                    color={"error"}
                    variant="contained"
                  >
                    <IconDelete stroke="red" />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </MyTable>

      {/* ________Add Box___________________ */}
      <AddBox
        title={"Add Music On Hold"}
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        addFunction={createMOH}
        addLoading={addLoading}
        formik={formik}
        style={{
          // width: "333px",
          boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
      }}
      >
        {addAlert.status ? (
          <Alert severity="error">{addAlert.msg}</Alert>
        ) : null}

        <Grid
          sx={{ display: "flex", flexDirection: "column" }}
          container
          spacing={1}
        >
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <TextField

              size="small"
              sx={{
                width:"100%"
              }}
              error={
                (formik.touched.name && formik.errors.name) || addErrors?.name
              }
              // margin="normal"
              // sx={{m:1}}
              required
              label={

                <Typography
                variant="span"
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
                >
                Title

                </Typography>
              }
              id="name"
              name="name"
              {...formik.getFieldProps("name")}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              // onBlur={formik.handleBlur}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.name && formik.errors.name ? (
              <Alert severity="error">{formik.errors.name}</Alert>
            ) : null}
            {addErrors?.name && (
              <Alert severity="error">{addErrors?.name[0]}</Alert>
            )}
          </Grid>
        </Grid>
      </AddBox>
    </>
  );
};
