import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company: {did_number: "",id: "",name: ""},
  user: { id: "",name:""},
  permissions:[],
  isAdmin:0,
};

export const companySlice = createSlice({
  name: "company",

  initialState,

  reducers: {
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setMustChangePassword: (state, action) => {
      state.user = {...state.user,must_change_password :action.payload};
    },
    
  },
});

export const { setCompany,setUser,setPermissions,setIsAdmin,setMustChangePassword } = companySlice.actions;

export default companySlice.reducer;
