import React, { useEffect, useState } from "react";
import { useFetchVoicemailMutation } from "../../services/voicemailApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  TextField,
  Autocomplete,
  Button,
} from "@mui/material";

import { CardActions, Card, CardHeader, CardContent,Typography } from "@mui/material";

export const VoicemailList = (props) => {

const {app,setApp,formik ,type}=props

  const [fetchVoicemail,isLoading] = useFetchVoicemailMutation();

  const loading = isLoading.isLoading;

  const [alert, setAlert] = useState(false);

  const [body, setBody] = useState([]);

  const [sounds, setSounds] = useState([]);

  const getVoicemail = async () => {
    try {
      const response = await fetchVoicemail().unwrap();

      console.log("voicemail responses", response);

      if (response.status === true) {
        setAlert(false);

        setBody(response.voicemail);

        setSounds(response.voice_sounds);

        setDestId(response?.voicemail?.id)

        // setApp({ ...app,entry_app_id : response?.voicemail?.id })

        // formik && (formik.setFieldValue('destination_type_id', response?.voicemail?.id ));

        console.log("voicemail", response.voicemail);
        console.log("voice_sounds", response.voice_sounds[0]);
        console.log("body", body);
        console.log("sounds", sounds);
      } else {
        console.log("____false", response);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setAlert(true);
    }
  };

  useEffect(() => {
    getVoicemail();
  }, []);

  const setDestId=(id)=>{


    if(formik && type=="announcement" ){
    
      (formik.setFieldValue('destination_type_id',id ) );
      // setApp({ ...app,entry_app_id : id });

    }
    else if(formik && type=="tc_matched"){

      (formik.setFieldValue('tc_matched_id',id));
      // setApp({ ...app,entry_app_id : id });


    }

    else if(formik && type=="tc_notMatched"){
    
      (formik.setFieldValue('tc_notMatched_id',id));
      // setApp({ ...app,entry_app_id : id });

    }else if(!formik){

      setApp({ ...app,entry_app_id : id });

          console.log('app',app)

    }


  }

  const isSelected=(id)=>{


    if(formik && type=="announcement" ){
    

      return formik?.values?.destination_type_id==id

    }
    else if(formik && type=="tc_matched"){


      return formik?.values?.tc_matched_id==id



    }

    else if(formik && type=="tc_notMatched"){
      
    
      return formik?.values?.tc_notMatched_id==id

    }else if(!formik){


      return app.entry_app_id==id
      

    }



  }


  return (
    <>
      <Card sx={{ m: 2 }} variant="outlined">
        <CardHeader
           title={
            <>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "18px",
                }}
              >
                Voicemail
              </Typography>
            </>
          }
          
        />
        <CardContent>

        <Grid container spacing={2} sx={{ }}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{}}>
          <Autocomplete
            disabled={true}
            fullWidth={false}
            size="small"
            onChange={(event, value) => {
              // alert('hello')
              console.log("value", value);
              setBody({
                ...body,
                destination_sound: value,
                record_message: value?.id,
              });

              console.log("body", body);
            }}
            value={body?.destination_sound || ""}
            disablePortal
            id="record_message"
            options={sounds}
            sx={{}}
            renderInput={(params) => (
              <TextField {...params} label="Destination Sound" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{}}>
          <Autocomplete
            disabled={true}
            fullWidth={false}
            size="small"
            value={body?.msg_option_sound || ""}
            onChange={(event, value) => {
              // alert('hello')
              console.log("value", value);
              setBody({
                ...body,
                msg_option_sound: value,
                record_options: value?.id,
              });

              console.log("body", body);
            }}
            disablePortal
            id="msg_option_sound"
            options={sounds}
            sx={{}}
            renderInput={(params) => (
              <TextField {...params} label="Message Option Sound" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{}}>
          <Autocomplete
            disabled={true}
            fullWidth={false}
            size="small"
            value={body?.timeout_sound || ""}
            onChange={(event, value) => {
              // alert('hello')
              console.log("value", value);
              setBody({
                ...body,
                timeout_sound: value,
                t_sound_file: value?.id,
              });

              console.log("body", body);
            }}
            disablePortal
            id="t_sound_file"
            options={sounds || []}
            sx={{}}
            renderInput={(params) => (
              <TextField {...params} label="Options Timeout Sound" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{}}>
          <Autocomplete
            disabled={true}
            fullWidth={false}
            size="small"
            value={body?.invalid_sound || ""}
            onChange={(event, value) => {
              // alert('hello')
              console.log("value", value);
              setBody({
                ...body,
                invalid_sound: value,
                i_sound_file: value?.id,
              });

              console.log("body", body);
            }}
            disablePortal
            id="i_sound_file"
            options={sounds}
            sx={{}}
            renderInput={(params) => (
              <TextField {...params} label="Invalid Option Sound" />
            )}
          />
        </Grid>
      </Grid>

        </CardContent>
        <CardActions>

 
        </CardActions>
      </Card>

    </>
  );
};
