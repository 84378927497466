import React, { useState } from "react";
import { Header } from "../../components/Header";
import {
  TextField,
  Button,
  Box,
  Alert,
  Stack,
  Paper,
  Grid,
  Typography,
  LinearProgress,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";

import { toast } from "react-toastify";

import {
  useEditProfileMutation,
  useUpdateProfileMutation,
} from "../../services/userAuthApi";
import { useEffect } from "react";

import { EditCompanyInfo } from "./EditCompanyInfo";
import { Navigate, useNavigate } from "react-router-dom";
import { isAllowed } from "../Functions";

export const EditProfile = () => {
  const [editProfile, profileEditLoader] = useEditProfileMutation();
  const [updateProfile, profileUpdateLoader] = useUpdateProfileMutation();

  const [addAlert, setAddAlert] = useState({
    status: false,
    msg: "",
    type: "success",
  });

  const [addErrors, setAddErrors] = useState({});

  const getData = async () => {
    try {
      const res = await editProfile({
        id: localStorage.getItem("user_id"),
      }).unwrap();

      console.log("edit profile data", res);

      if (res.status === true) {
        setAlert({
          status: false,
          msg: "",
          type: "success",
        });

        setBody({
          id: res.user.id,
          user_name: res.user.user_name,
          name: res.user.name,
          phone: res.user.phone,
          email: res.user.email,
          client_name: res.user.client.name,
          master_number: res.user.client.did_number,
        });
      } else if (res.status === false) {
        setAlert({
          status: true,
          msg: res.message,
          type: "error",
        });
      } else {
        setAlert({
          status: true,
          msg: "Something went wrong",
          type: "error",
        });
      }
    } catch (error) {
      console.log("error", error);

      setAlert({
        status: true,
        msg: error?.data?.message
          ? error?.data?.message
          : "Something went wrong !",
        type: "error",
      });
    }
  };

  const updateData = async (body) => {
    try {
      const res = await updateProfile(body).unwrap();

      console.log("updated profile resp", res);

      if (res.status === true) {
        setAddErrors({});
        setAddAlert({
          status: false,
          msg: "",
          type: "success",
        });
        toast.success("Profile has been updated successfully !");
        //
      } else if (res.status === false) {
        setAddAlert({
          status: true,
          msg: res.message,
          type: "error",
        });
      } else {
        setAddAlert({
          status: true,
          msg: "Something went wrong",
          type: "error",
        });
      }
    } catch (error) {
      console.log("catch error", error);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({
          status: true,
          msg: "Unauthorized action !",
          type: "error",
        });
      } else {
        setAddAlert({
          status: true,
          msg: "Oops something went wrong !",
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const Input = styled("input")({
    display: "none",
  });

  const [alert, setAlert] = useState({
    status: false,
    msg: "",
    type: "",
  });

  const [body, setBody] = useState({
    user_name: "",
    name: "",
    phone: "",
    email: "",
    client_name: "",
    master_number: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;

    setBody({ ...body, [id]: value });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    console.log("profile body", body);
  };

  const navigate = useNavigate();

  const isAdmin = useSelector((state) => state.company.isAdmin);

  // if (
  //   isAdmin != 1 && localStorage.getItem("role_id") != 3
  // ) {
  //   return navigate("/forbidden");
  // }

  if (!isAllowed("edit_profile")) {
    return navigate("/forbidden");
  }

  return (
    <>
      {/* _________________________ Header_____________________________________ */}

      <Header title={"Edit Profile"} />

      {profileEditLoader.isLoading ? <LinearProgress /> : null}

      {alert.status ? <Alert severity={alert.type}>{alert.msg}</Alert> : ""}

      <Grid container justifyContent="center" sx={{ mt: 1 }}>
        <Grid
          sx={{
            boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.05)",
            borderRadius: "20px",
          }}
          item
          xl={4}
          lg={4}
          md={4}
          sm={8}
          xs={12}
        >
          <Box
            onSubmit={handleSubmitForm}
            component="form"
            id="profile-form"
            noValidate
            sx={{
              py: 1.4,
              display: "flex",
              flexDirection: "column",
            }}
            px={{ xs: 1.4, sm: 2.4, md: 7.4, lg: 7.4 }}
          >
            <TextField
              size="small"
              error={addErrors?.client_name}
              disabled
              margin="normal"
              sx={{}}
              required
              id="client_name"
              name="client_name"
              label={
                <>
                  <Typography
                    variant="span"
                    sx={{ fontWeight: 500, fontSize: "13px" }}
                  >
                    Company Name{" "}
                  </Typography>{" "}
                </>
              }
              value={body.client_name}
              onChange={handleChange}
            />
            <TextField
              size="small"
              error={addErrors?.master_number}
              disabled
              margin="normal"
              sx={{}}
              required
              id="master_number"
              name="master_number"
              label={
                <>
                  <Typography
                    variant="span"
                    sx={{ fontWeight: 500, fontSize: "13px" }}
                  >
                    Master Number{" "}
                  </Typography>{" "}
                </>
              }
              value={body.master_number}
              onChange={handleChange}
            />

            <TextField
              size="small"
              error={addErrors?.user_name}
              disabled
              margin="normal"
              sx={{}}
              required
              id="user_name"
              name="user_name"
              label={
                <>
                  <Typography
                    variant="span"
                    sx={{ fontWeight: 500, fontSize: "13px" }}
                  >
                    User Name / Agent No{" "}
                  </Typography>{" "}
                </>
              }
              value={body.user_name}
              onChange={handleChange}
            />
            {addErrors?.user_name && (
              <Alert severity="error">{addErrors?.user_name[0]}</Alert>
            )}
            <TextField
              size="small"
              error={addErrors?.name}
              margin="normal"
              sx={{}}
              required
              id="name"
              name="name"
              label={
                <>
                  <Typography
                    variant="span"
                    sx={{ fontWeight: 500, fontSize: "13px" }}
                  >
                    Name{" "}
                  </Typography>{" "}
                </>
              }
              value={body.name}
              onChange={handleChange}
            />

            {addErrors?.name && (
              <Alert severity="error">{addErrors?.name[0]}</Alert>
            )}
            <TextField
              size="small"
              error={addErrors?.phone}
              margin="normal"
              sx={{}}
              required
              id="phone"
              name="phone"
              label={
                <>
                  <Typography
                    variant="span"
                    sx={{ fontWeight: 500, fontSize: "13px" }}
                  >
                    Phone{" "}
                  </Typography>{" "}
                </>
              }
              value={body.phone}
              onChange={handleChange}
            />
            {addErrors?.phone && (
              <Alert severity="error">{addErrors?.phone[0]}</Alert>
            )}

            <TextField
              size="small"
              error={addErrors?.email}
              margin="normal"
              sx={{}}
              required
              id="email"
              name="email"
              label={
                <>
                  <Typography
                    variant="span"
                    sx={{ fontWeight: 500, fontSize: "13px" }}
                  >
                    Email{" "}
                  </Typography>{" "}
                </>
              }
              value={body.email}
              onChange={handleChange}
            />
            {addErrors?.email && (
              <Alert severity="error">{addErrors?.email[0]}</Alert>
            )}
            <Box
              textAlign="Center"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Button
                disabled={profileUpdateLoader.isLoading || alert.status}
                onClick={() => {
                  updateData(body);
                }}
                size="large"
                sx={{
                  mt: 3,
                  mb: 2,
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
                type="submit"
                variant="contained"
              >
                Update Profile
              </Button>

              {addAlert.status ? (
                <Alert severity={addAlert.type}>{addAlert.msg}</Alert>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Grid>

        {isAllowed('update_logo') ? <EditCompanyInfo /> : null}
      </Grid>
    </>
  );
};
