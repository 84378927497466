import React, { useState } from "react";

import {
  TextField,
  Button,
  Box,
  Alert,
  Stack,
  Paper,
  Grid,
  Typography,
} from "@mui/material";


import ForgotLogo from "../../images/zonglogo.png";

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import backvector from "../../images/vector/backvector.png";
import { ReactComponent as UsernameIcon } from '../../images/username.svg'
import { ReactComponent as BackIcon } from '../../images/back.svg'


import { NavLink, useNavigate } from "react-router-dom";

import { useSendPasswordResetEmailMutation } from "../../services/userAuthApi";

// import { MyCard } from "../../components/MyCard";

import { MyButton } from "../../components/MyButton";

export const SendPasswordResetEmail = () => {
  const [alert, setAlert] = useState({
    status: false,
    msg: "",
    type: "",
  });

  const navigate = useNavigate();

  const [sendPasswordResetEmail, isLoading] =
    useSendPasswordResetEmailMutation();

  const [body, setBody] = useState({
    user_name: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;

    setBody({ ...body, [id]: value });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    try {
      const res = await sendPasswordResetEmail(body).unwrap();

      console.log("res", res);

      if (res.status === true) {
        setAlert({
          status: true,
          msg: "A password reset link has been sent, if your account exists.",
          // msg: res.message,
          type: "success",
        });

        console.log("login ressponse", res);

        // navigate("/");
      } else if (res.status === false) {
        setAlert({
          status: true,
          msg: "A password reset link has been sent, if your account exists.",
          // msg: res.message,
          type: "success",
        });
      } else {
        setAlert({
          status: true,
          msg: "Something went wrong",
          type: "error",
        });
      }
    } catch (error) {
      console.log("error", error);

      setAlert({
        status: true,
        msg: error?.data?.message
          ? error?.data?.message
          : "Something went wrong !",
        type: "error",
      });
    }
  };

  return (
    <>
      <Typography

        component={NavLink}
        to="/login"
        sx={{
          marginTop: "60px",
          marginLeft: "163px",
          marginRight: "1179px",
          marginbottom: "721px",
          width: "150px",
          height: "19px",
          fontFamily: "Plus Jakarta Sans",
          fontStyle: "normal",
          fontWeight: "500px",
          fontSize: "15px",
          // lineHeight: "19",
          color: "primary.main",
          display: "flex",
          alignItems: "center",
          flex: "none",
          order: "0",
          flexGrow: "0",
          textDecoration:"none",
        }}
      >
        <BackIcon /> <Typography sx={{fontWeight: 500,fontSize: "15px",}} variant="span">Back</Typography> 
      </Typography>
      <Grid container justifyContent="center" sx={{
        mt: 5,

        }}>
        <Grid
          // style={{
          //   position: "absolute",
          //   width: "326px",
          //   height: "292px",
          //   left: "557px",
          //   top: "254px",
          //   background: "#FFFFFF",
          //   boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.05)";
          //   borderRadius: "20",
          // }}
          sx={
            {
              // borderRadius: "16px",
              // boxShadow: 5,
              boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.05)",
              borderRadius: "20px",
            }
          }
          item
          xl={4}
          lg={4}
          md={4}
          sm={8}
          xs={12}
        >
          <Box sx={{display : "flex", justifyContent:"center"}} >
            <img src={ForgotLogo}></img>
          </Box>
          {/* <Typography
          sx={{
            // display: open ? "none" : "",
            fontWeight: "bold",
            textDecoration: "none",
            textAlign: "center",
            mt: 2,
          }}
        >
          <Typography
            variant="span"
            sx={{ color: "secondary.main", fontSize: "35px" }}
          >
            Zong
          </Typography>
          <Typography
            variant="span"
            sx={{ color: "primary.main", fontSize: "35px" }}
          >
            Cap
          </Typography>
        </Typography> */}

          {/* <Box
            // item
            // height="100%"
            // xs={12}
            // sm={12}
            // md={6}
            // lg={6}
            // xl={6}
            sx={{
              // position: 'absolute',
              width: '108px',
              height: '62px',
              left: '666px',
              top: '276px',
              background: `url(${logo})`,
              // backgroundImage: `url(${logo})`,
              // backgroundRepeat: "no-repeat",
              // backgroundSize: "cover",
              // backgroundPosition: "center",
              // mt: 2,
              // display: {
              //   xl: "flex",
              //   lg: "flex",
              //   md: "flex",
              //   sm: "none",
              //   xs: "none",
              // },
            }}
          ></Box> */}
          <Box sx={{ py: 3 }}>
            <Typography
              sx={{
                // display: open ? "none" : "",
                textDecoration: "none",
                textAlign: "center",
                fontWeight: 500, fontSize: "16px",
              }}
            >
               Send Password Reset Email
            </Typography>
          </Box>

          <Box
            onSubmit={handleSubmitForm}
            component="form"
            // id="login-form"
            noValidate
            sx={{
              // m: 2,
              py: 1.4,
              display: "flex",
              flexDirection: "column",
            }}
            px={{ xs: 1.4, sm: 2.4, md: 7.4, lg: 7.4 }}
          >
            <TextField
              margin="normal"
              sx={{}}
              required
              id="user_name"
              name="user_name"
              label={<> <UsernameIcon /> <Typography variant="span" sx={{ mb:1, fontWeight: 500, fontSize: "13px"}}>User Name / Agent No </Typography> </>}
              value={body.user_name}
              onChange={handleChange}
            />

            <Box
              textAlign="Center"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Button
                disabled={isLoading.isLoading || body.user_name === ""}
                size="large"
                sx={{ mt: 3, mb: 2, textTransform: "none",fontWeight: 600,
                fontSize: "12px", }}
                type="submit"
                variant="contained"
              >
                Submit
              </Button>

              {alert.status ? (
                <Alert severity={alert.type}>{alert.msg}</Alert>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
