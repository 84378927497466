import { createTheme } from "@mui/material/styles";

export const customTheme = createTheme({

    palette:{
        // mode:"dark",
        primary : {

            main : "#ED0080",
            // main : "#F02794",

        },
        secondary : {

            main : "#8DC63F",
        },
    },

    typography: {

        "fontFamily": `"Plus Jakarta Sans"`,
        // "textTransform" : "unset !important",

        button: {
            textTransform: 'none'
          }
       }
})