import React, { useEffect,useState } from "react";

import {

  Alert,
  Autocomplete,
  TextField,
  LinearProgress,
  Typography,

} from "@mui/material";

import { useFetchVoicemailMutation } from "../services/voicemailApi";

export const VoicemailDestination = (props) => {

  const { formik, name,addErrors } = props;

  const [fetchVoicemail, fetchLoader] = useFetchVoicemailMutation();

  const isFetchLoading=fetchLoader.isLoading


  const getVoicemail = async () => {
    try {
      const response = await fetchVoicemail().unwrap();

      console.log("voicemail destination response", response);

      if (response.status === true) {

        console.log("voice destination result", response.voicemail);

        formik.setFieldValue(name,response?.voicemail?.id);




      } else {

        console.log("____voice_destination_false");

      }
    } catch (error) {

      console.log("____catch_queue_destination", error);

    }
  };

  useEffect(()=>{
    getVoicemail();
  },[])

  return (
    <>
    
        <TextField
        sx={{

            width:"100%"
            }}
         size="small" value="Voicemail" disabled />

      {formik.touched[name] &&
      formik.errors[name] ? (
        <Alert severity="error">
          {formik.errors[name]}
        </Alert>
      ) : null}
      {addErrors[name] && (
        <Alert severity="error">
          {addErrors[name]?.[0]}
        </Alert>
      )}

      {isFetchLoading ?? ( <LinearProgress/> )}
    </>
  );
};
