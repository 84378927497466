import React, { useState } from "react";
import { Header } from "../components/Header";
import {
  TextField,
  Button,
  Box,
  Alert,
  Stack,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  Divider,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { toast } from "react-toastify";

import { useEffect } from "react";

import loginPage from "../images/manual/login.png";
import dashboardPage from "../images/manual/dashboard.png";
import callRecordPage from "../images/manual/callrecord.png";
import callSettingPage from "../images/manual/callsetting.png";
import CSDestinationPage from "../images/manual/callsettingdestination.png";
import QueuePage from "../images/manual/queue.png";
import AddQueuePage from "../images/manual/addqueue.jpg";
import IvrPage from "../images/manual/ivr.png";
import ExtensionPage from "../images/manual/extension.png";
import TimeConditionPage from "../images/manual/timecondition.png";
import AnnouncementPage from "../images/manual/announcement.png";
import MOHPage from "../images/manual/moh.png";
import SystemSoundPage from "../images/manual/systemsound.png";
import VoicemailPage from "../images/manual/voicemail.png";
import LandingPage from "../images/manual/vpbxlandingpage.png";

export const UserGuidance = () => {

  return (
    <>
      {/* _________________________ Header_____________________________________ */}

      <Header title={"Zong Cap Portal User Manual"} />

      <Grid sx={{ pt: 1 }} container spacing={1}>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <Typography sx={{ fontWeight: 700, textAlign: "center" }}>
            How to use ZongCap portal
          </Typography>
        </Grid>

        {/* Login */}

        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={LandingPage} />
        </Grid>

        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>
              {" "}
              Go to your internet browser and browse to{" "}
              <a href="https://zong-cap.com.pk">
                https://zong-cap.com.pk
              </a>
            </li>
            <li>
              Click on Sign in
            </li>
          </ul>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={loginPage} />
        </Grid>

        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>
            Enter user credentials and click “Login”.
            </li>
          </ul>
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider />
        </Grid>

        {/* Dashboard */}

        <Grid sx={{display:"flex",justifyContent:"center"}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography sx={{fontWeight:700}} >Dashboard</Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={dashboardPage} />
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>
              {" "}
              When you log in, you'll land on the dashboard indicated by the
              home icon.
            </li>
            <li>
              An overview of total calls, and incoming and outgoing calls are
              available on the dashboard.
            </li>
            <li>
              Choose your desired extension and apply the date filter
              highlighted in red rectangle to get call data for the selected
              month or week
            </li>
          </ul>
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider />
        </Grid>

        {/* Call record */}
        <Grid sx={{display:"flex",justifyContent:"center"}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography sx={{fontWeight:700}} >Call Record</Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={callRecordPage} />
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>
              if you click on the dialer icon highlighted in the black box, you
              will be able to view the call record. Call records for incoming
              and outgoing calls are available on that page.
            </li>
            <li>
              Here you can find the duration, date, and time of calls as well as
              the status of those calls..
            </li>
            <li>
              In order to get the desired information, you can filter on clients
              and agents as well as dates.
            </li>
            <li>
              The duration and DateTime fields are available for sorting call
              records. Additionally, you can export call records to your
              desktop..
            </li>
          </ul>
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider />
        </Grid>
        {/* Call setting */}
        <Grid sx={{display:"flex",justifyContent:"center"}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography sx={{fontWeight:700}} >Call Setting</Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={callSettingPage} />
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>
              To access incoming call settings, click on the setting icon
              highlighted in a box.
            </li>
          </ul>
        </Grid>
        {/* Call setting Destination */}
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={CSDestinationPage} />
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>
              You can change your setting by clicking on change destination
              shown by an arrow. You can change either to IVR, Dial list, timing
              conditions, announcement, voicemail, or hangup.
            </li>
          </ul>
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider />
        </Grid>
        {/* Extension */}
        <Grid sx={{display:"flex",justifyContent:"center"}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography sx={{fontWeight:700}} >Extension</Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={ExtensionPage} />
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>
              In extensions when you will see different number against one Master
              number, and all the number should be on zong network.
            </li>
          </ul>
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider />
        </Grid>
        {/* Queues */}
        <Grid sx={{display:"flex",justifyContent:"center"}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography sx={{fontWeight:700}} >Queues</Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={QueuePage} />
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>
              In the Queue tab, you can view all your extensions register in
              your queue.
            </li>
            <li>
              You can also add and remove extensions by clicking on the green
              box
            </li>
          </ul>
        </Grid>
        {/* Add Queue */}
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={AddQueuePage} />
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>
              You can also set the Ring strategy by clicking on Action
              highlighted in a red box..
            </li>
          </ul>
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider />
        </Grid>

        {/* Music On hold */}
        <Grid sx={{display:"flex",justifyContent:"center"}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography sx={{fontWeight:700}} >Music on Hold</Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={MOHPage} />
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>
              The music on hold option is provided whenever a calling party
              presses a number, your promo message will automatically play.
            </li>
          </ul>
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider />
        </Grid>

        {/* System Sound */}
        <Grid sx={{display:"flex",justifyContent:"center"}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography sx={{fontWeight:700}} >System Sound</Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={SystemSoundPage} />
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>We can uplod IVR according to customer desire.</li>
          </ul>
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider />
        </Grid>

        {/* Announcements */}
        <Grid sx={{display:"flex",justifyContent:"center"}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography sx={{fontWeight:700}} >Announcements</Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={AnnouncementPage} />
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>You can select announcements according to your desire in the announcement tab highlighted in red..</li>
          </ul>
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider />
        </Grid>
        {/* Ivrs */}
        <Grid sx={{display:"flex",justifyContent:"center"}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography sx={{fontWeight:700}} >Ivrs</Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={IvrPage} />
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>Highlighted in red IVRs, we can configure IVR according to your desire and we also provide the option of multiple of IVRs in main IVR.</li>
          </ul>
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider />
        </Grid>
        {/* TimeCondition */}
        <Grid sx={{display:"flex",justifyContent:"center"}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography sx={{fontWeight:700}} >Time Conditions</Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={TimeConditionPage} />
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>In timing Conditions highlighted in red, consumer can add their office timings, and distribute calls according to that..</li>
          </ul>
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider />
        </Grid>
        {/* Voicemail */}
        <Grid sx={{display:"flex",justifyContent:"center"}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography sx={{fontWeight:700}} >Voicemail</Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <img src={VoicemailPage} />
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <ul>
            <li>In voicemail settings you can set welcome messages for the voicemail that after that message, sound or a beep you can record your queries.</li>
          </ul>
        </Grid>
        <Grid sx={{}} xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider />
        </Grid>
      </Grid>
    </>
  );
};
