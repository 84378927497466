import { BrowserRouter,Routes,Route } from "react-router-dom";
// import Layout from "./layouts/Layout";
// import LoginLayout from "./layouts/LoginLayout";
// import AdminLayout from "./layouts/AdminLayout";
// import ClientLayout from "./layouts/ClientLayout";
import Setting from './pages/Setting';
import { Dashboard } from './pages/Dashboard';
import {SendPasswordResetEmail} from './pages/auth/SendPasswordResetEmail';
import {ResetPassword} from './pages/auth/ResetPassword';
import { LoginReg } from "./pages/auth/LoginReg";
import { Protected } from "./pages/Protected";
import MiniDrawer from "./components/MiniDrawer";
import { CallRecord } from './pages/call_record/CallRecord';
import { Extension } from "./pages/extension/Extension";
// import { EditExt } from "./pages/extension/EditExt";
import { Queue } from "./pages/queue/Queue";
import { Ivr } from "./pages/ivr/Ivr";
import { ToastContainer, toast } from 'react-toastify';
import { Moh } from "./pages/moh/Moh";
import { CallSetting } from "./pages/call_setting/CallSetting";
import { VoiceMail } from "./pages/voicemail/Voicemail";
import { SystemSound } from "./pages/system_sound/SystemSound";
import { Announcement } from "./pages/annoucement/Announcement";
import { TimeCondition } from "./pages/time_condition/TimeCondition";

import { MasterNumber } from "./pages/admin/master_number/MasterNumber";
import { User } from "./pages/admin/user/User";
import { Role } from "./pages/admin/role/Role";
import { Client } from "./pages/admin/client/Client";
import { RolePermission } from "./pages/admin/permission/RolePermission";

import { Forbidden  } from './pages/Forbidden'

import { ChangePassword } from "./pages/auth/ChangePassword";
import { EditProfile } from "./pages/auth/EditProfile";
import { EditCompanyInfo } from "./pages/auth/EditCompanyInfo";
import { UserGuidance } from "./pages/UserGuidance";
import { Welcome } from "./pages/Welcome";
import BulkCallOrignate from "./pages/call_orignate/BulkCallOrignate";



function App() {
  return (
    <>
      <ToastContainer />

      <BrowserRouter>

      <Routes>

        <Route path="*" element={ <h1>Page Not Found</h1> } />

        <Route path="/forbidden" element={ <Forbidden/> }/>

        <Route path="login" element={ <LoginReg/> } />
          
        <Route path="sendPasswordResetEmail" element={ <SendPasswordResetEmail /> } />

        <Route path="ResetPassword/:reset_token" element={ <ResetPassword /> } />


        <Route path="/" element={< Protected Component={MiniDrawer} />}>

          <Route  index element={ <Dashboard/> } />
          <Route path="welcome" element={ <Welcome/> } />
          <Route path="admin/setting" element={ <Setting/> } />
          <Route path="change-password" element={ <ChangePassword/> } />
          <Route path="bulk-call-orignate" element={ <BulkCallOrignate/> } />
          <Route path="edit-profile" element={ <EditProfile/> } />
          <Route path="edit-company-info" element={ <EditCompanyInfo/> } />
          <Route path="call-record" element={ <CallRecord/> } />
          <Route path="call-setting" element={ <CallSetting/> } />
          <Route path="extensions" element={ <Extension/> } />
          <Route path="queues" element={ <Queue/> } />
          <Route path="ivrs" element={ <Ivr/> } />
          <Route path="music-on-hold" element={ <Moh/> } />
          <Route path="system-sounds" element={ <SystemSound/> } />
          <Route path="announcements" element={ <Announcement/> } />
          <Route path="time-conditions" element={ <TimeCondition/> } />
          <Route path="user-guidance" element={ <UserGuidance/> } />
          <Route path="voicemail" element={ <VoiceMail/> } />
          {/* <Route replace={true} path="edit-extension/:id" element={ <EditExt/> } /> */}
          {/* Admin___________________________ */}
          <Route path="master-numbers" element={ <MasterNumber/> } />
          <Route path="users" element={ <User/> } />
          <Route path="roles" element={ <Role/> } />
          <Route path="clients" element={ <Client/> } />
          <Route replace={true} path="role-permissions/:role_id" element={ <RolePermission/> } />

        </Route>  

        
        {/* <Route path="/admin" element={< Protected Component={ MiniDrawer} />}>

            <Route path="/admin/setting" element={ <Setting/> } />
            
        </Route> */}

      </Routes>

      </BrowserRouter>

    </>
  );
}

export default App;
