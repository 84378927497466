import React, { useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ReactAudioPlayer from "react-audio-player";
import { Header } from "../../components/Header";
import { ReactComponent as IconEdit } from "../../images/editIcon.svg";
import { ReactComponent as IconDelete } from "../../images/deleteIcon.svg";
import { StyledTableCell } from "../call_setting/QueueList";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useSelector, useDispatch } from "react-redux";


import {
  useFetchAnnouncementMutation,
  useCreateAnnouncementMutation,
  useStoreAnnouncementMutation,
  useEditAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useDeleteAnnouncementMutation,
} from "../../services/announcementApi";
import { isAllowed } from "../Functions";
import { styled } from "@mui/material/styles";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Button,
  TableRow,
  Alert,
  Grid,
  Autocomplete,
  TextField,
  LinearProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import { MyTable } from "../../components/MyTable";
import { StyledTableRow } from "../Style";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddBox from "../../components/AddBox";

import { QueueList } from "../call_setting/QueueList";
import { DialList } from "../call_setting/DialList";
import { TimeConditionList } from "../call_setting/TimeConditionList";
import { IvrList } from "../call_setting/IvrList";
import { VoicemailList } from "../call_setting/VoicemailList";
import { AnnouncementList } from "../call_setting/AnnouncementList";
import { Navigate, useNavigate } from "react-router-dom";

export const Announcement = () => {
  const [fetchAnnouncement, isLoading] = useFetchAnnouncementMutation();
  const [createAnnouncement, createLoader] = useCreateAnnouncementMutation();
  const [storeAnnouncement, storeLoader] = useStoreAnnouncementMutation();
  const [editAnnouncement, editLoader] = useEditAnnouncementMutation();
  const [updateAnnouncement, updateLoader] = useUpdateAnnouncementMutation();
  const [deleteAnnouncement, deleteLoader] = useDeleteAnnouncementMutation();

  const isCreateLoading = createLoader.isLoading;
  const isStoreLoading = storeLoader.isLoading;
  const isEditLoading = editLoader.isLoading;
  const isUpdateLoading = updateLoader.isLoading;
  const isDeleteLoading = deleteLoader.isLoading;

  const [fullScreen, setFullScreen] = useState(false);

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    totalRecords: 0,
    fromRecord: 0,
    toRecord: 0,
    totalPages: 0,
  });

  const [body, setBody] = useState({ page: 1, search: "" });

  const [app, setApp] = useState({ entry_app_id: "" });

  const [addAlert, setAddAlert] = useState({ status: false, msg: "" });

  const [open, setOpen] = React.useState(false);

  const [addErrors, setAddErrors] = useState({});

  const [title, setTitle] = useState("");

  const [editMode, setEditMode] = useState(false);

  const [sounds, setSounds] = useState([]);

  const [singleSystemSound, setSingleSystemSound] = useState({
    id: "",
    file_Name: "",
  });

  const handleClickOpen = () => {
    setTitle("Add New Announcement");
    setEditMode(false);
    setOpen(true);
    // setaddFunction(()=>addQueue)
    // setaddLoading(isAddQueueLoading)
  };

  const handleEditOpen = () => {
    setTitle("Edit Announcement");
    setEditMode(true);
    setOpen(true);
  };

  const fetchAnnounce = async () => {
    try {
      const response = await fetchAnnouncement(body).unwrap();

      console.log("announcement response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result", response.announcement_sounds);

        setData(response.announcement_sounds.data);

        setPagination({
          ...pagination,
          totalPages: response.announcement_sounds.last_page,
          totalRecords: response.announcement_sounds.total,
          fromRecord: response.announcement_sounds.from,
          toRecord: response.announcement_sounds.to,
          toRecord: response.announcement_sounds.to,
        });

        if (
          response.announcement_sounds.current_page >
          response.announcement_sounds.last_page
        ) {
          setBody({ ...body, page: 1 });

          console.log("current_page > last_page");
        }
      } else {
        console.log("____false");
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);

      if (error?.status == 403) {
        //
      }

      setData([]);
      setAlert(true);
    }
  };

  const createAnnounce = async () => {
    try {
      const response = await createAnnouncement().unwrap();

      console.log("create announcement response", response);

      if (response.status === true) {
        setSounds(response.sounds);
      } else {
        console.log("____false");
      }
    } catch (error) {
      console.log("____catch", error);
    }
  };

  const storeAnnounce = async (values) => {
    try {
      const response = await storeAnnouncement(values).unwrap();

      console.log("announcement response", response);

      if (response.status === true) {
        fetchAnnounce();

        handleClose();

        toast.success("Record has been added successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);

      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const editAnnounce = async (id) => {
    try {
      const response = await editAnnouncement({ id: id }).unwrap();

      console.log("edit announcement response", response);

      if (response.status === true) {
        formik.setFieldValue("system_sound_id", response.annoucment?.id);
        formik.setFieldValue(
          "destination_type",
          response.annoucment?.Dest_After_Play
        );
        formik.setFieldValue(
          "destination_type_id",
          response.annoucment?.Dest_After_Play_ID
        );

        setApp({
          ...app,
          entry_app_id: response.annoucment?.Dest_After_Play_ID,
        });
        formik.setFieldValue("id", response.annoucment?.id);

        setSingleSystemSound(response.annoucment);

        handleEditOpen();
      } else {
        console.log("____false");
      }
    } catch (error) {
      console.log("____catch", error);
    }
  };

  const updateAnnounce = async (values) => {
    try {
      const response = await updateAnnouncement(values).unwrap();

      console.log("update announcement response", response);

      if (response.status === true) {
        fetchAnnounce();

        handleClose();

        toast.success("Record has been update successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);
      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add annonuce errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const deleteAnnounce = async (id) => {
    if (window.confirm("are you sure you want to delete this record?")) {
      try {
        const response = await deleteAnnouncement({ id: id }).unwrap();

        console.log("delete announce response", response);

        if (response.status === true) {
          fetchAnnounce();
          createAnnounce();
          toast.success("Record has been deleted successfully !");
        } else {
          console.log("____false", response);

          toast.error(response.message);
        }
      } catch (error) {
        console.log("____catch", error);

        toast.error("Oops something went wrong !");
      }
    }
  };

  useEffect(() => {
    fetchAnnounce();
  }, [body.page]);

  useEffect(() => {
    createAnnounce();
  }, []);

  const columns = ["Sound", "Name", "Destination After Play", "Action"];

  const initialValues = {
    system_sound_id: null,
    destination_type: "",
    destination_type_id: "",
  };

  const onSubmit = (values, actions) => {
    console.log("form values", values);

    if (editMode) {
      updateAnnounce(values);
    } else {
      storeAnnounce(values);
    }

    createAnnounce();
  };

  const validationSchema = Yup.object({
    system_sound_id: Yup.number().nullable().required("Required !"),
    destination_type: Yup.string().required("Required !"),
    destination_type_id: Yup.string().nullable().required("Required !"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    // validateOnMount:true,
  });

  console.log("formik", formik);

  const handleClose = () => {
    setOpen(false);
    setAddErrors({});
    setAddAlert({ status: false, msg: "" });
    setSingleSystemSound({ id: "", file_Name: "" });
    formik.resetForm();
    setApp({ ...app, entry_app_id: "" });
  };

  const destinations = [
    "Queue",
    "IVR",
    "DialList",
    "TimingCondition",
    "Announcement",
    "VoiceMail",
    "Hangup",
  ];

  const [selectedDestination, setSelectedDestination] = useState("");

  const getSelectedDestination = (name) => {
    setSelectedDestination(name);
  };

  useEffect(() => {
    if (
      formik.values.destination_type == "" ||
      formik.values.destination_type == null
    ) {
      setFullScreen(false);
    } else {
      setFullScreen(true);
    }
  }, [formik.values.destination_type]);

  const navigate = useNavigate();

  const isAdmin=useSelector((state)=>state.company.isAdmin);


  if (!isAllowed("annoucement_access")  ) {
    return navigate("/forbidden");
  }

  return (
    <>
      <Header title={"Announcements"} />

      <MyTable
        // title="Announcement"
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={fetchAnnounce}
        handleClickOpen={handleClickOpen}
        isEditLoading={isEditLoading}
        addButton={
          isAllowed("annoucement_create") || isAdmin == 1 ? true : false
        }
      >
        {data?.map((item, index) => {
          return (
            <TableRow hover key={index} sx={{}}>
              <StyledTableCell>
                <ReactAudioPlayer
                  src={
                    item.file_Path
                      ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL +
                        item.file_Path.replace(".gsm", "mp3.mp3")
                      : ""
                  }
                  controls
                />
              </StyledTableCell>
              <StyledTableCell>{item.file_Name}</StyledTableCell>
              <StyledTableCell>{item.Dest_After_Play}</StyledTableCell>
              {/* <StyledTableCell >{item.Dest_After_Play}</StyledTableCell> */}
              <StyledTableCell>
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    // sx={{ m: 1 }}
                    color={"info"}
                    variant="contained"
                    onClick={() => editAnnounce(item.id)}
                    disabled={!isAllowed("annoucement_edit")  }
                  >
                    <IconEdit />
                  </IconButton>
                </Tooltip>{" "}
                <Tooltip title="Delete">
                  <IconButton
                    disabled={!isAllowed("annoucement_delete")  }
                    size="small"
                    // sx={{ m: 1 }}
                    color={"error"}
                    variant="contained"
                    onClick={() => deleteAnnounce(item.id)}
                  >
                    <IconDelete stroke="red" />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </MyTable>

      {/* ________Add Box___________________ */}
      <AddBox
        title={title}
        open={open}
        handleClose={handleClose}
        formik={formik}
        fullWidth={true}
        style={{
          maxWidth: "100%",
          height: "100vh",
          // maxHeight: "100vh",
          boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
        }}
        // style={{
        //   width: "1242px",
        //   height: "586px",
        //   boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
        //   borderRadius: "10px",
        // }}
        // fullScreen={true}
        // fullWidth={true}
      >
        {addAlert.status ? (
          <Alert severity="error">{addAlert.msg}</Alert>
        ) : null}

        {isStoreLoading || (isUpdateLoading && <LinearProgress />)}

        <Grid sx={{}} container spacing={1}>
          <Grid
            sx={{ borderRight: "1px solid #F2F2F2" }}
            item
            xs={12}
            sm={12}
            md={4}
            lg={2}
            xl={2}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "17.64px",
                pl: 3,
              }}
            >
              Select Destination Type
            </Typography>
            <List
              dense
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
              }}
            >
              {destinations?.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                  <ListItem
                    key={value}
                    onClick={(event) => {
                      console.log("value", value);
                      formik.setFieldValue("destination_type", value);

                      formik.setFieldValue("destination_type_id", "");

                      setApp({ ...app, entry_app_id: "" });
                    }}

                    sx={{
                      borderBottom: "1px solid #F2F2F2",
                      padding: "13px 13px 13px 17px",
                      gap: "160px",
                      background:
                        formik?.values?.destination_type == value
                          ? "#FBFBFB"
                          : "",
                      color:
                        formik?.values?.destination_type == value
                          ? "black"
                          : "",
                      fontWeight:
                        formik?.values?.destination_type == value
                          ? "700"
                          : "500",
                    }}
                    secondaryAction={
                      <ChevronRightOutlinedIcon
                        sx={{
                          color:
                            formik?.values?.destination_type == value
                              ? "black"
                              : "#767676",
                        }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemText
                        id={labelId}
                        primary={
                          <>
                            <Typography
                              sx={{
                                fontWeight:
                                formik?.values?.destination_type == value ? "700" : "500",
                                fontSize: "12px",
                                lineHeight: "15px",
                                color:
                                formik?.values?.destination_type == value
                                    ? "black"
                                    : "#767676",
                              }}
                            >
                              {value}
                            </Typography>
                          </>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={10} xl={10}>
            <Grid container spacing={1}>
              {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{}}>
                <Autocomplete
                  fullWidth={true}
                  size="small"
                  value={formik.values.destination_type || ""}
                  onChange={(event, value) => {
                    console.log("value", value);
                    formik.setFieldValue("destination_type", value);

                    formik.setFieldValue("destination_type_id", "");

                    setApp({ ...app, entry_app_id: "" });
                  }}
                  // isOptionEqualToValue={formik.values.destination_type}
                  // disablePortal
                  id="destination_type"
                  options={destinations}
                  sx={{}}
                  renderInput={(params) => (
                    <TextField
                      {...formik.getFieldProps("destination_type")}
                      {...params}
                      label="Destination Type"
                      error={
                        (formik.touched.destination_type &&
                          formik.errors.destination_type) ||
                        addErrors?.destination_type
                      }
                    />
                  )}
                  margin="normal"
                  label="Destination Type"
                  name="destination_type"
                />
                {formik.touched.destination_type &&
                formik.errors.destination_type ? (
                  <Alert severity="error">
                    {formik.errors.destination_type}
                  </Alert>
                ) : null}
                {addErrors?.destination_type && (
                  <Alert severity="error">
                    {addErrors?.destination_type[0]}
                  </Alert>
                )}
              </Grid> */}
              <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
                <Autocomplete
                  id="sounds"
                  options={sounds}
                  getOptionLabel={(option) => option.file_Name}
                  // defaultValue={[moh[0]]}
                  renderInput={(params) => (
                    <TextField
                      error={
                        (formik.touched.system_sound_id &&
                          formik.errors.system_sound_id) ||
                        addErrors?.system_sound_id
                      }
                      {...params}
                      label={
                        <Typography
                          variant="span"
                          sx={{
                            fontWeight: 500,
                            fontSize: "13px",
                            lineHeight: "16px",
                          }}
                        >
                          Select System Sound
                        </Typography>
                      }
                      // placeholder="Music on Hold"
                      {...formik.getFieldProps("system_sound_id")}
                    />
                  )}
                  size="small"
                  // margin="normal"
                  // sx={{ width: "500px" }}
                  onChange={(e, value) => {
                    console.log("sound value ", value);
                    formik.setFieldValue("system_sound_id", value?.id);
                    setSingleSystemSound(value);
                  }}
                  value={singleSystemSound || { id: "", file_Name: "" }}
                  name="system_sound_id"
                  // {...formik.getFieldProps("musiconhold_id")}
                />
                {formik.touched.system_sound_id &&
                formik.errors.system_sound_id ? (
                  <Alert severity="error">
                    {formik.errors.system_sound_id}
                  </Alert>
                ) : null}
                {addErrors?.system_sound_id && (
                  <Alert severity="error">
                    {addErrors?.system_sound_id[0]}
                  </Alert>
                )}
                {isCreateLoading ? <LinearProgress /> : null}
              </Grid>

              {singleSystemSound?.file_Path ? (
                <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
                  {/* <Box sx={{fontWeight:"bolder"}} >IVR Sound</Box> */}
                  <ReactAudioPlayer
                    src={
                      singleSystemSound?.file_Path
                        ? process.env.REACT_APP_SYSTEM_SOUND_BASE_URL +
                          singleSystemSound?.file_Path.replace(
                            ".gsm",
                            "mp3.mp3"
                          )
                        : ""
                    }
                    controls
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{}}>
                {addErrors?.destination_type_id && (
                  <Alert severity="error">
                    {addErrors?.destination_type_id[0]}
                  </Alert>
                )}

                {formik.values.destination_type == "Queue" ? (
                  <QueueList
                    app_id={app.entry_app_id}
                    app={app}
                    setApp={setApp}
                    getSelectedDestination={getSelectedDestination}
                    formik={formik}
                    type="announcement"
                  />
                ) : formik.values.destination_type == "TimingCondition" ? (
                  <TimeConditionList
                    getSelectedDestination={getSelectedDestination}
                    app_id={app.entry_app_id}
                    app={app}
                    setApp={setApp}
                    formik={formik}
                    type="announcement"
                  />
                ) : formik.values.destination_type == "DialList" ? (
                  <DialList
                    getSelectedDestination={getSelectedDestination}
                    app_id={app.entry_app_id}
                    app={app}
                    setApp={setApp}
                    formik={formik}
                    type="announcement"
                  />
                ) : formik.values.destination_type == "IVR" ? (
                  <IvrList
                    getSelectedDestination={getSelectedDestination}
                    app_id={app.entry_app_id}
                    app={app}
                    setApp={setApp}
                    formik={formik}
                    type="announcement"
                  />
                ) : formik.values.destination_type == "Announcement" ? (
                  <AnnouncementList
                    getSelectedDestination={getSelectedDestination}
                    app_id={app.entry_app_id}
                    app={app}
                    setApp={setApp}
                    formik={formik}
                    type="announcement"
                  />
                ) : formik.values.destination_type == "VoiceMail" ? (
                  <VoicemailList
                    app_id={app.entry_app_id}
                    app={app}
                    setApp={setApp}
                    getSelectedDestination={getSelectedDestination}
                    formik={formik}
                    type="announcement"
                  />
                ) : formik.values.destination_type == "Hangup" ? (
                  <TextField
                    size="small"
                    label="Input Text"
                    value={formik.values.destination_type_id || ""}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "destination_type_id",
                        e.target.value
                      )
                    }
                  />
                ) : (
                  ""
                )}
                {/* </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AddBox>
    </>
  );
};
