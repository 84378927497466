import React, { useEffect,useState } from "react";

import {

LinearProgress,
  Alert,
  Autocomplete,
  TextField,
  Typography,

} from "@mui/material";

import { useFetchIvrsMutation } from "../services/ivrApi";

export const IvrDestination = (props) => {

  const { formik, name,addErrors,index } = props;

  const [fetchIvrs, fetchLoader] = useFetchIvrsMutation();

  const isFetchLoading=fetchLoader.isLoading

  const [singleIvr, setSingleIvr] = useState({ id: "", IVR_Name: "" });

  const [ivrs, setIVRs] = useState([]);

  const fetchIVR = async () => {
    try {
      const response = await fetchIvrs({pagination:"no"}).unwrap();

      console.log("IVR destination response", response);

      if (response.status === true) {

        console.log("ivr destination result", response.ivrs);

        setIVRs(response.ivrs);

        const destination_id= name=='time_destination_id' ? formik.values[name] : formik.values?.ivr_count[index]?.["ivr_option_destination_id"];


        if(destination_id){

          response.ivrs.forEach(element => {
    
            console.log("element",element)
            
            if(element["id"]==destination_id){
    
              setSingleIvr(element)
            
            }
    
          });
    
        }



      } else {

        console.log("____ivr_destination_false");

      }
    } catch (error) {

      console.log("____catch_ivr_destination", error);

    }
  };

  useEffect(()=>{
    fetchIVR();
  },[])

  return (
    <>
      <Autocomplete
        openOnFocus
        disableClearable
        loading={isFetchLoading}
        getOptionLabel={(option) => option.IVR_Name}
        fullWidth={true}
        size="small"
        value={ singleIvr || {id:"",IVR_Name:""}}
        onChange={(event, value) => {
          console.log("value", value);
          formik.setFieldValue(name, value.id);

          setSingleIvr(value);
        }}
        id={name}
        options={ivrs}
        renderInput={(params) => (
          <TextField
            {...formik.getFieldProps(name)}
            {...params}
            label={

              <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
              }}
              >
                Select IVR

              </Typography>
            }
            error={
              (formik.touched?.ivr_count?.[index]?.[
                "ivr_option_destination_id"
              ] &&
                formik.errors?.ivr_count?.[index]?.[
                  "ivr_option_destination_id"
                ]) ||
              addErrors[name]
            }
          />
        )}
        label="Select IVR"
        name={name}
      />
{formik.touched?.ivr_count?.[index]?.["ivr_option_destination_id"] &&
      formik.errors?.ivr_count?.[index]?.["ivr_option_destination_id"] ? (
        <Alert severity="error">
          {formik.errors?.ivr_count?.[index]?.["ivr_option_destination_id"]}
        </Alert>
      ) : null}
      {addErrors[name] && (
        <Alert severity="error">
          {addErrors[name]?.[0]}
        </Alert>
      )}
    
        {isFetchLoading && ( <LinearProgress/> )}

    </>
  );
};
