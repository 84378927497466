import React, { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";

import {
  setCompany,
  setUser,
  setPermissions,
  setIsAdmin,
} from "../features/company/companySlice";
import { useSelector, useDispatch } from "react-redux";

import { useLogoutUserMutation } from "../services/userAuthApi";
import { useLoginViaTokenMutation } from "../services/userAuthApi";

import { NavLink, useNavigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { isAllowed } from "../pages/Functions";

export const Header = ({ title }) => {
  const dispatch = useDispatch();

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  // const role_id= localStorage.getItem("role_id");

  // const is_superuser = localStorage.getItem("is_superuser");

  // const isAdmin = useSelector((state) => state.company.isAdmin);

  const theme = useTheme();

  const navigate = useNavigate();

  const [loginViaToken, loginLoader] = useLoginViaTokenMutation();
  const [logoutUser, logoutLoader] = useLogoutUserMutation();

  const userName = () => {
    const userName = localStorage.getItem("name");

    return userName;
  };
  const masterNumber = () => {
    const masterNumber = localStorage.getItem("master_num");

    return masterNumber;
  };

  const authToken = localStorage.getItem("token");

  const lvt = async () => {
    // localStorage.clear();

    // navigate("/login");
    try {
      const res = await loginViaToken(authToken).unwrap();

      console.log("lvak resp", res);

      if (res.status === true) {
        if (res?.user?.should_login == 1) {
          localStorage.clear();
          navigate("/login");
        } else {

          console.log("else validate token api response")
          dispatch(setCompany(res.user.client));
          dispatch(setUser(res.user));
          dispatch(setPermissions(res.permissions));
          // localStorage.setItem("token", res.token);
          localStorage.setItem("client_id", res.user.client_id);

          localStorage.setItem("user_id", res.user.id);

          localStorage.setItem(
            "must_change_password",
            res.user.must_change_password
          );

          if (res.user.is_superuser == 1) {

            dispatch(setIsAdmin(1));
            // localStorage.setItem("isAdmin", 1);
          }
          else {

            dispatch(setIsAdmin(0));
            // localStorage.setItem("isAdmin", 0);
          }

          // localStorage.setItem("is_superuser", res.user.is_superuser);

          localStorage.setItem("client_name", res.user.client.name);
          localStorage.setItem("role_id", res.user.role_id);

          localStorage.setItem("master_num", res.user.client.did_number);

          localStorage.setItem("name", res.user.name);

          if (res.user.client.logo == null) {
            localStorage.setItem("profile_photo", res.user.profile_photo_url);
          } else {
            localStorage.setItem("profile_photo", res.user.client.logo_url);
          }

          localStorage.setItem(
            "user_permissions",
            JSON.stringify(res.permissions)
          );

        }
      } else {
        localStorage.clear();
        navigate("/login");
        console.log("lvt status false");
      }
    } catch (error) {
      console.log("lvt error", error);
    }
  };

  useEffect(() => {
    window.onload = function () {
      lvt();
    };
  }, []);


  const handleLogout = async () => {
    // navigate("https://www.zong-cap.com.pk", { replace: true });
    // window.location.href = "https://www.zong-cap.com.pk";

    try {
      const res = await logoutUser(authToken).unwrap();

      console.log("logout resp", res);

      if (res.status === true) {
        // localStorage.removeItem("token");
        console.log("logout successfully");
      } else {
        console.log("cant logout status is false");
      }
    } catch (error) {
      console.log("cant logout catch error", error);
    }

    localStorage.clear();
    navigate("/login");
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <>
      <Box
        sx={{
          borderBottom: "1px solid #F2F2F2",
          display: "flex",
          justifyContent: "space-between",
          // position: "sticky",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            height: "51px",
            left: "0px",
            top: "0px",
            // background: "#FFFFFF",
            pt: 1.7,
            pl: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "20px",
              // zIndex: 1,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            pt: 1,
            // pt:0,
            // position:"fixed",
            // top:3,
            // right:1,
          }}
        >
          <Tooltip
            title={
              <>
                {" "}
                {userName()} <br /> {masterNumber()}{" "}
              </>
            }
          >
            <IconButton onClick={handleOpenUserMenu} sx={{ mt: 0, pt: 0 }}>
              <Avatar alt="photo" src={localStorage.getItem("profile_photo")} />
              {/* <Avatar alt="photo" src={"https://zong-cap.com.pk:97/HostedPBX/voiceover/faizan-test/logo.png"} /> */}
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "40px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {/* {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography text>{setting}</Typography>
                </MenuItem>
              ))} */}

            {/* <Button  style={({isActive})=> {return {backgroundColor : isActive ? "#8FC642" :" ",color : isActive ? "white" :"black"}}} component={ NavLink } to='/' sx={{textTransform: 'none' , fontWeight :'bold', color : ''}}>Dashboard</Button> */}

            {isAllowed("admin_setting") ? (
              <Button
                style={({ isActive }) => {
                  return {
                    backgroundColor: isActive ? "#8DC63F" : " ",
                    color: isActive ? "white" : "black",
                  };
                }}
                component={NavLink}
                to="/admin/setting"
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  color: "",
                  display: "block",
                }}
              >
                Setting
              </Button>
            ) : null}

            {/* {is_superuser != 1 && role_id != 5 ? ( */}
            {isAllowed("update_password") ? (
              <Button
                style={({ isActive }) => {
                  return {
                    backgroundColor: isActive ? "#8DC63F" : " ",
                    color: isActive ? "white" : "black",
                  };
                }}
                component={NavLink}
                to="/sendPasswordResetEmail"
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  color: "",
                  display: "block",
                }}
              >
                Update Password
              </Button>
            ) : null}

            {/* {is_superuser == 1 || isAdmin || role_id == 3 ? ( */}
            {isAllowed("edit_profile") ? (
              <Button
                to="/edit-profile"
                style={({ isActive }) => {
                  return {
                    backgroundColor: isActive ? "#8DC63F" : " ",
                    color: isActive ? "white" : "black",
                  };
                }}
                component={NavLink}
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  color: "",
                  display: "block",
                }}
              >
                Edit Profile
              </Button>
            ) : null}
            <Button
              to="/user-guidance"
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#8DC63F" : " ",
                  color: isActive ? "white" : "black",
                };
              }}
              component={NavLink}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                color: "",
                display: "block",
              }}
            >
              User Guide
            </Button>
            {isAllowed("originate_call") ? <Button
              to="/bulk-call-orignate"
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#8DC63F" : " ",
                  color: isActive ? "white" : "black",
                };
              }}
              component={NavLink}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                color: "",
                display: "block",
              }}
            >
              Initiate Calls
            </Button>
            :
            null
            }

            <Button
              onClick={() => {
                handleLogout();
              }}
              // style={({ isActive }) => {
              //   return {
              //     backgroundColor: isActive ? "#8DC63F" : " ",
              //     color: isActive ? "white" : "black",
              //   };
              // }}
              // to="/login"
              // component={NavLink}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                color: theme.palette.getContrastText("#FFFFFF"),
                display: "block",
              }}
            >
              Logout
            </Button>
          </Menu>
        </Box>
      </Box>
      {logoutLoader.isLoading || loginLoader.isLoading? <LinearProgress /> : null}
    </>
  );
};
