import React, { useEffect, useState } from "react";
import { useFetchAnnouncementMutation } from "../../services/announcementApi";
import { StyledTableRow } from "./QueueList";

import { TableCell, TableRow, Typography, Checkbox } from "@mui/material";
import { MyTable } from "../../components/MyTable";
import { StyledTableCell } from "./QueueList";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";

export const AnnouncementList = (props) => {
  const { getSelectedDestination, app, setApp, formik, type } = props;

  const [fetchAnnouncement, isLoading] = useFetchAnnouncementMutation();

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    totalRecords: 0,
    fromRecord: 0,
    toRecord: 0,
    totalPages: 0,
  });

  const [body, setBody] = useState({ page: 1, search: "" });

  const fetchAnnounce = async () => {
    try {
      const response = await fetchAnnouncement(body).unwrap();

      console.log("announcement response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result", response.announcement_sounds);

        setData(response.announcement_sounds.data);

        setPagination({
          ...pagination,
          totalPages: response.announcement_sounds.last_page,
          totalRecords: response.announcement_sounds.total,
          fromRecord: response.announcement_sounds.from,
          toRecord: response.announcement_sounds.to,
          toRecord: response.announcement_sounds.to,
        });

        if (
          response.announcement_sounds.current_page >
          response.announcement_sounds.last_page
        ) {
          setBody({ ...body, page: 1 });

          console.log("current_page > last_page");
        }
      } else {
        console.log("____false");
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };

  useEffect(() => {
    fetchAnnounce();
  }, [body.page]);

  const columns = ["Selected", "Name", "Destination After Play"];

  const setDestId = (id) => {
    if (formik && type == "announcement") {
      formik.setFieldValue("destination_type_id", id);
      // setApp({ ...app,entry_app_id : id });
    } else if (formik && type == "tc_matched") {
      formik.setFieldValue("tc_matched_id", id);
      // setApp({ ...app,entry_app_id : id });
    } else if (formik && type == "tc_notMatched") {
      formik.setFieldValue("tc_notMatched_id", id);
      // setApp({ ...app,entry_app_id : id });
    } else if (!formik) {
      setApp({ ...app, entry_app_id: id });
    }

    // console.log('app',app)
  };

  const isSelected = (id) => {
    if (formik && type == "announcement") {
      return formik?.values?.destination_type_id == id;
    } else if (formik && type == "tc_matched") {
      return formik?.values?.tc_matched_id == id;
    } else if (formik && type == "tc_notMatched") {
      return formik?.values?.tc_notMatched_id == id;
    } else if (!formik) {
      return app.entry_app_id == id;
    }

    // console.log('app',app)
  };

  return (
    <>
      <MyTable
        title={
          <>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              Select Announcement
            </Typography>
          </>
        }
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={fetchAnnounce}
        addButton={false}
      >
        {data?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {app.entry_app_id == item.id
                ? getSelectedDestination(item.file_Name)
                : ""}
              {/* <StyledTableRow
                onClick={() => setDestId(item.id)}
                selected={isSelected(item.id)}
                hover={!isSelected(item.id)}
                sx={{}}
              > */}
              {/* <StyledTableRow  key={index} sx={{}}> */}
              <TableRow hover>
                <StyledTableCell
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "15px",
                  }}
                  // align="center"
                >
                  <Checkbox
                    sx={{ p: 0 }}
                    icon={<RadioButtonUncheckedOutlinedIcon />}
                    checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                    onClick={() => setDestId(item.id)}
                    checked={isSelected(item.id)}
                  />
                </StyledTableCell>

                <TableCell >{item.file_Name}</TableCell>
                <TableCell >{item.Dest_After_Play}</TableCell>
                {/* <TableCell >{item.Dest_After_Play}</TableCell> */}
                {/* <TableCell >
                  <Button size="small" sx={{m:1}} color={"info"} variant="contained">Edit</Button>
                  <Button size="small" sx={{m:1}} color={"error"} variant="contained">Delete</Button>
              </TableCell> */}
              </TableRow>
            </React.Fragment>
          );
        })}
      </MyTable>
    </>
  );
};
