export const items = [
    "Itemized Bill invoice",
    "Corporate Bill invoice",
    "Age Report email",
    "connectivity complain",
    "Package activation",
    "Package deactivation",
    "Tax challan posting",
    "Tax Challan Reconciliation",
    "contract expiry alert",
    "contract renewal",
    "coverage Complain",
    "IR Complain",
    "Non Compliance email/call",
    "Discount revoking",
    "VPN/VPMN",
    "POC Change Request",
    "Signatory change request",
    "New Sale",
    "Cross sell",
    "Upsell",
    "M2M Package activation",
    "Ownership change request",
    "Post to pre request",
    "Call",
    "NTN Movement",
    "IR Bundle activation",
    "MNP Port In",
    "CBS Bundle Activation",
    "CBS Sale Pitch",
    "CBS portal creation",
    "CBS Masking",
    "CBS API",
    "DIA/DPLC/SIP Sale",
    "VPBX Package",
    "VPBX Child Numbers adding",
    "individual to Company Transfer",
    "Balance adjustment",
    "Cheque Collecting",
    "Payment recovery",
    "Payment Posting",
    "Company Registration",
    "Waiver/Sale",
    "Device/HSS issuance",
    "Bill waiver",
    "VPBX IVR Add/ Remove",
    "Corporate contract offer",
    "ROI/CMS uploading"
  ];