import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useSelector } from "react-redux";

import Pagination from "@mui/material/Pagination";
import ReactAudioPlayer from "react-audio-player";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { toast } from "react-toastify";
import { StyledTableCell } from "../call_setting/QueueList";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import Moment from "react-moment";
import { convertToCSV, downloadCSV } from "./CallRecord";

import {
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Select,
  MenuItem,
} from "@mui/material";
import {
  useFetchOutgoingCallsMutation,
  useUpdateWorkCodeMutation,
} from "../../services/outgoingCallApi";
import { isAllowed } from "../Functions";
// import { Typography } from "../Dashboard";
import { makeStyles } from "@mui/styles";
import { items } from "./options";
import RecordingField from "./RecordingField";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      "&.Mui-selected": {
        background: "#ED0080",
        color: "white",
        // borderRadius: '50%',
      },
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
}));

export const Outgoing = forwardRef((props, ref) => {
  const classes = useStyles();

  const theme = useTheme();

  const isAdmin = useSelector((state) => state.company.isAdmin);
  const client_id = localStorage.getItem("client_id");
  const isValidClient =
    client_id == 10395 ||
    client_id == 10445 ||
    client_id == 10447 ||
    client_id == 10449 ||
    client_id == 10451 ||
    client_id == 10453;
  const role_id = localStorage.getItem("role_id");
  const { body, setBody, timeConvert, secondsToHms, handleExportClick } = props;

  const [total_records, setTotalRecords] = useState(0);
  const [from_record, setFromRecord] = useState(0);
  const [to_record, setToRecord] = useState(0);
  const [total_pages, setTotalPages] = useState(null);
  const [alert, setAlert] = useState(false);
  const [wcAlert, setWcAlert] = useState(false); // work code alert
  const [wcMessage, setWcMessage] = useState(""); // work code alert
  const [workCode, setWorkCode] = useState("");

  const [data, setData] = useState([]);
  const [audio, setAudio] = useState("");
  const [showPlayer, setShowPlayer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [callId, setCallId] = useState("");
  const [fetchOutgoingCalls, isLoading] = useFetchOutgoingCallsMutation();
  const [updateWorkCode] = useUpdateWorkCodeMutation();

  // console.log("isLoading", isLoading);
  // console.log("isSuccess", isLoading.isSuccess);
  // console.log("isError", isLoading.isError);
  // console.log("data", isLoading.data);

  // console.log("testing",fetchIncomingCalls)

  const handleChangePage = (event, value) => {
    setBody({ ...body, page: value });
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useImperativeHandle(ref, () => ({
    childFunction1(body) {
      fetchCalls({ ...body, outgoing_export: 0 });
      console.log("child function 1 called");
    },
    exportCallRecords() {
      // fetchCalls({ ...body, outgoing_export: 1 });
      // console.log("outgoing export call records called !");

      // if (data.length == 0) {
      //   toast.error("No data found to export !");
      // }else{
      //   const pData= refinedData(data)
      //   const csv=convertToCSV(pData)
      //   downloadCSV(csv, "outgoing_"+from_record+"-"+to_record+"_"+total_records+".csv");
      // }

      exportCsvApi(body);
    },
  }));

  const exportCsvApi = (body) => {
    handleExportClick(true);

    toast.info(
      "Exporting... This may take a few seconds or minutes depending on the size of the data you are exporting, please be patient.",
      {
        autoClose: false,
      }
    );

    const req = { ...body, outgoing_export: 1 };

    const url = process.env.REACT_APP_BASE_URL + "search-outgoing-calls";

    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(req),
    };

    fetch(url, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "outgoing.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);

        toast.success("Data exported successfully !", {
          autoClose: false,
        });
      })
      .catch((error) => {
        toast.error("Oops there is an error in export, please try later");
        console.error("Error:", error);
      })
      .finally(() => {
        handleExportClick(false);
      });
  };

  function refinedData(tasks) {
    const columns = [
      // "Company_id",
      "Client_no",
      "Agent_no",
      "Duration",
      "Duration_In_Seconds",
      "Date",
      "Time",
      "Status",
      "Recording",
    ];

    const newData = [];

    tasks.forEach((task) => {
      const row = {};

      // row["Company_id"] = task.client_id;
      row["Client_no"] = task.destination;
      row["Agent_no"] = task.sourceclid;
      row["Duration"] = secondsToHms(task.duration);
      row["Duration_In_Seconds"] = task.duration;
      row["Date"] = task.date;
      row["Time"] = task.time;
      row["Status"] = checkCallStatus(task.recording);
      row["Recording"] =
        checkCallStatus(task.recording) === "Connected"
          ? task.recording.replace(".gsm", ".mp3")
          : "N/A";

      newData.push(row);
    });

    return newData;
  }

  const fetchCalls = async (body) => {
    try {
      const response = await fetchOutgoingCalls(body).unwrap();

      console.log("outgoing call response", response);

      if (response.status === true) {
        if (response.file) {
          window.open(response.file, "_blank");
        } else {
          setAlert(false);

          console.log("result", response.search_result);
          const agent_data = response.agent_data;
          const search_result = response.search_result.data;
          const customer_data = response?.customer_data;
          let final_data = [];
          search_result.forEach(function (data) {
            const _obj = { ...data };
            let found = false;
            let customer_found = false;

            agent_data.forEach((agent) => {
              if (data.sourceclid == agent.agent_number) {
                _obj.reporting_manager = agent.reporting_manager;
                _obj.sub_region = agent.sub_region;
                _obj.name = agent.name;
                found = true;
              }
            });
            customer_data.forEach((agent) => {
              if (data.destination == agent.customer_number) {
                _obj.company_name = agent.company_name;
                customer_found = true; // Set found to true once a match is found
              }
            });

            // If no matching agent is found, set properties to empty strings
            if (!found) {
              _obj.reporting_manager = "";
              _obj.sub_region = "";
              _obj.name = "";
            }

            if (!customer_found) {
              _obj.company_name = "";
            }

            final_data.push(_obj);
          });

          setData(final_data);
          // setData(response.search_result.data);

          setTotalPages(response.search_result.last_page);

          setTotalRecords(response.search_result.total);

          setFromRecord(response.search_result.from);

          setToRecord(response.search_result.to);

          if (
            response.search_result.current_page >
            response.search_result.last_page
          ) {
            setBody({ ...body, page: 1 });

            console.log("hello");
          }
        }
      } else {
        toast.error(response.message);
        setData([]);
        // setAlert(true);
        console.log("____", data);
      }
    } catch (error) {
      console.log("error", error);
      setData([]);
      setAlert(true);
      console.log("____", data);
    }
  };
  useEffect(() => {
    const req = { ...body, outgoing_export: 0 };
    fetchCalls(req);
  }, [body.page, body.per_page, body.order_by, workCode]);
  const checkCallStatus = (str) => {
    if (str.includes("HostedPBX") || str.includes("Answered")) {
      return "Connected";
    } else if (str.includes("BUSY")) {
      return "User busy";
    } else if (str.includes("NOANSWER")) {
      return "User Not Attended";
    } else if (str.includes("CANCEL")) {
      return "Agent Hang Up";
    } else if (str.includes("CONGESTION")) {
      return "Busy";
    } else if (str.includes("CHANUNAVAIL")) {
      return "User Unvailable";
    }
  };

  const pauseAllAudios = (e) => {
    // const current_audio = document.getElementById(e.target.id);
    // const current_audio_id = e.target.id;
    // console.log("current_audio_id",current_audio_id);
    // const audios=document.querySelectorAll('.audios:not(#1audio)')
    // console.log("audios",audios);
    // for (var i = 0; i < audios.length; i++) {
    //   audios[i].pause();
    //
    // }
  };

  // const ref = useRef(null);
  const handleWorkCodeChange = async (id, work_code) => {
    try {
      const response = await updateWorkCode({ id, work_code }).unwrap();
      if (response.status != 200) {
        setWcMessage(response.message);
        setWcAlert(true);
      } else {
        setWorkCode(work_code);
        setWcAlert(false);
      }
    } catch (error) {}
  };
  return (
    <>
      {isLoading?.isLoading ? (
        <LinearProgress />
      ) : data.length == 0 ? (
        <Box display="flex" justifyContent="center">
          <Typography sx={{ color: "error.main", fontWeight: "bold" }}>
            {alert
              ? "Oops Something went wrong,Please change fitler and try to fetch minimum days record  !"
              : "No Record Found. Change filter and then try!"}
          </Typography>
        </Box>
      ) : (
        ""
      )}
      {wcAlert && (
        <Box display="flex" justifyContent="center">
          <Typography sx={{ color: "error.main", fontWeight: "bold" }}>
            {wcMessage}
          </Typography>
        </Box>
      )}

      {/* <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          borderRadius: "16px",
          boxShadow: 5,
          p: 3,
          m: 3,
        }}
      > */}
      {/* <MyHeading>Incoming Calls</MyHeading> */}
      {/* <Box sx={{display:"flex",justifyContent:"center",mt:1}}>
        <Button size="small" variant="contained" onClick={()=>{fetchCalls()}}>Apply Filter</Button>
      </Box> */}

      <TableContainer
        // sx={{ border: 1, borderColor: "divider" }}
        // sx={{ maxHeight: "65vh" }}
        style={{ maxHeight: "calc(100vh - 210px)" }}

        // component={Paper}
      >
        <Table size="small" stickyHeader aria-label="simple table">
          <TableHead sx={{}}>
            <TableRow>
              <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "" : "#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
              >
                Client #
              </TableCell>
              {isValidClient && (
                <TableCell
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    backgroundColor:
                      theme.palette.mode === "dark" ? "" : "#F9F9F9",
                    borderWidth: "1px 0px",
                    borderStyle: "solid",
                    borderColor: "#E1E1E1",
                    // zIndex: "0",
                  }}
                >
                  Client Name
                </TableCell>
              )}
              <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "" : "#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
              >
                Agent #
              </TableCell>
              {isValidClient && (
                <TableCell
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    backgroundColor:
                      theme.palette.mode === "dark" ? "" : "#F9F9F9",
                    borderWidth: "1px 0px",
                    borderStyle: "solid",
                    borderColor: "#E1E1E1",
                    // zIndex: "0",
                  }}
                >
                  Agent Name
                </TableCell>
              )}
              {isValidClient && (
                <TableCell
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    backgroundColor:
                      theme.palette.mode === "dark" ? "" : "#F9F9F9",
                    borderWidth: "1px 0px",
                    borderStyle: "solid",
                    borderColor: "#E1E1E1",
                    // zIndex: "0",
                  }}
                >
                  Reporting Manager
                </TableCell>
              )}
              {isValidClient && (
                <TableCell
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    backgroundColor:
                      theme.palette.mode === "dark" ? "" : "#F9F9F9",
                    borderWidth: "1px 0px",
                    borderStyle: "solid",
                    borderColor: "#E1E1E1",
                    // zIndex: "0",
                  }}
                >
                  Region
                </TableCell>
              )}
              {isValidClient && (
                <TableCell
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    backgroundColor:
                      theme.palette.mode === "dark" ? "" : "#F9F9F9",
                    borderWidth: "1px 0px",
                    borderStyle: "solid",
                    borderColor: "#E1E1E1",
                    // zIndex: "0",
                  }}
                >
                  Work Codes
                </TableCell>
              )}

              <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "" : "#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
              >
                Duration
              </TableCell>
              <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "" : "#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
              >
                Date
              </TableCell>
              <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "" : "#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
              >
                Time
              </TableCell>
              <TableCell
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "15px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "" : "#F9F9F9",
                  borderWidth: "1px 0px",
                  borderStyle: "solid",
                  borderColor: "#E1E1E1",
                  // zIndex: "0",
                }}
              >
                Status
              </TableCell>
              {(isAllowed("outgoing_call_recording") || isAdmin == 1) && (
                <TableCell
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    backgroundColor:
                      theme.palette.mode === "dark" ? "" : "#F9F9F9",
                    borderWidth: "1px 0px",
                    borderStyle: "solid",
                    borderColor: "#E1E1E1",
                    // zIndex: "0",
                  }}
                >
                  Recording
                </TableCell>
              )}
              {(isAllowed("call_feedback") || isAdmin == 1) && (
                <TableCell
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    backgroundColor:
                      theme.palette.mode === "dark" ? "" : "#F9F9F9",
                    borderWidth: "1px 0px",
                    borderStyle: "solid",
                    borderColor: "#E1E1E1",
                    // zIndex: "0",
                  }}
                >
                  Feedback
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((call, index) => {
              return (
                <TableRow hover key={index} sx={{}}>
                  <StyledTableCell
                  // sx={{
                  //   fontStyle: "normal",
                  //   fontWeight: "500",
                  //   fontSize: "12px",
                  //   lineHeight: "15px",
                  // }}
                  >
                    {call?.destination}
                  </StyledTableCell>
                  {isValidClient && (
                    <StyledTableCell>{call?.company_name}</StyledTableCell>
                  )}
                  <StyledTableCell
                    sx={
                      {
                        // fontStyle: "normal",
                        // fontWeight: "500",
                        // fontSize: "12px",
                        // lineHeight: "15px",
                      }
                    }
                  >
                    {call.sourceclid}
                  </StyledTableCell>
                  {isValidClient && (
                    <StyledTableCell>{call.name}</StyledTableCell>
                  )}
                  {isValidClient && (
                    <StyledTableCell>{call.reporting_manager}</StyledTableCell>
                  )}
                  {isValidClient && (
                    <StyledTableCell>{call.sub_region}</StyledTableCell>
                  )}
                  {isValidClient && (
                    <StyledTableCell>
                      <Select
                        sx={{ width: "120px", height: "30px" }}
                        value={call.work_code}
                        // disabled={role_id == 6}
                        onChange={(e) => {
                          handleWorkCodeChange(call.id, e.target.value);
                        }}
                      >
                        {items.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledTableCell>
                  )}
                  <StyledTableCell
                    sx={
                      {
                        // fontStyle: "normal",
                        // fontWeight: "500",
                        // fontSize: "12px",
                        // lineHeight: "15px",
                      }
                    }
                  >
                    {/* {secondsToHms(call.duration)} */}
                    {call.duration}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={
                      {
                        // fontStyle: "normal",
                        // fontWeight: "500",
                        // fontSize: "12px",
                        // lineHeight: "15px",
                      }
                    }
                  >
                    {/* <Moment format="ddd,MMM D,YYYY">{call.date}</Moment> */}
                    {call.date}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={
                      {
                        // fontStyle: "normal",
                        // fontWeight: "500",
                        // fontSize: "12px",
                        // lineHeight: "15px",
                      }
                    }
                  >
                    {/* {timeConvert(call.time)} */}
                    {call.time}
                  </StyledTableCell>
                  <StyledTableCell>
                    {call.call_status == "Connected" ? (
                      <Typography
                        sx={{
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "15px",
                          backgroundColor:
                            theme.palette.mode === "dark" ? "" : "#F9F9F9",
                          color: "#678E31",
                          borderRadius: "40px",
                          px: 2,
                          py: 1,
                          textAlign: "center",
                        }}
                      >
                        {call.call_status}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "15px",
                          backgroundColor:
                            theme.palette.mode === "dark" ? "" : "#F9F9F9",
                          color: "#E43131",
                          borderRadius: "40px",
                          px: 2,
                          py: 1,
                          textAlign: "center",
                        }}
                      >
                        {call.call_status}
                      </Typography>
                    )}
                  </StyledTableCell>
                  {(isAllowed("outgoing_call_recording") || isAdmin == 1) && (
                    <StyledTableCell>
                       {call.call_status == "Connected" ? (
                        //   <ReactAudioPlayer
                        //   className="audios"
                        //   id={index + "audio"}
                        //   // onPlay={(e)=> { pauseAllAudios(e)  } }
                        //   src={call.recording?.replace(".gsm", ".mp3")}
                        //   controls
                        // /> : "N/A"
                        <audio
                          className="audios"
                          id={index + "audio"}
                          src={call.recording?.replace(".gsm", ".mp3")}
                          controls
                        />
                      ) : (
                        "N/A"
                      )}
                      {/* <RecordingField
                        call={call}
                        setWcAlert={setWcAlert}
                        setWcMessage={setWcMessage}  
                        status={'Outgoing'}                    /> */}
                    </StyledTableCell>
                  )}
                  {(isAllowed("call_feedback") || isAdmin == 1) && (
                    <StyledTableCell
                      sx={
                        {
                          // fontStyle: "normal",
                          // fontWeight: "500",
                          // fontSize: "12px",
                          // lineHeight: "15px",
                        }
                      }
                    >
                      {call.feedback}
                    </StyledTableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box container sx={{ display: "flex", justifyContent: "center" }}>
        {/* <Box item spacing={2} sx={{ m: 1 }}>
        </Box> */}
        <Box item spacing={2} sx={{ p: 1 }}>
          <Typography sx={{ mb: 1, textAlign: "center" }}>
            Showing {from_record}-{to_record} out of {total_records} records
          </Typography>

          <Pagination
            //  size="small"
            showFirstButton
            showLastButton
            boundaryCount={2}
            onChange={handleChangePage}
            count={total_pages + 0}
            defaultPage={body.page}
            color="primary"
            variant="outlined"
            shape="rounded"
            classes={{
              root: classes.ul,
            }}
          />
        </Box>
      </Box>
      {/* </Grid> */}
    </>
  );
});
