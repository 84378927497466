import React, { useEffect, useState } from "react";

import { LinearProgress, Alert, Autocomplete, TextField,Typography } from "@mui/material";

import { useFetchTimeConditionMutation } from "../services/timeConditionApi";

export const TimeConditionDestination = (props) => {

  const { formik, name, addErrors,index } = props;

  const [fetchTimeCondition, fetchLoader] = useFetchTimeConditionMutation();

  const isFetchLoading = fetchLoader.isLoading;

  const [singleTC, setSingleTC] = useState({ TC_Name:""});

  const [timeConditions, setTimeConditions] = useState([]);

  const fetchTC = async () => {
    try {
      const response = await fetchTimeCondition({ pagination: "no" }).unwrap();

      console.log("TC destination response", response);

      if (response.status === true) {

        console.log("TC destination result", response.time_conditions);

        setTimeConditions(response.time_conditions);

        const destination_id= name=='time_destination_id' ? formik.values[name] : formik.values?.ivr_count[index]?.["ivr_option_destination_id"];


        if(destination_id){

          response.time_conditions.forEach(element => {
    
            console.log("element",element)
            
            if(element["id"]==destination_id){
    
              setSingleTC(element)
    
            }
    
          });
    
        }

      } else {
        console.log("____time_condition_destination_false");
      }
    } catch (error) {
      console.log("____catch_time_condition_destination", error);
    }
  };

  useEffect(() => {
    fetchTC();
  }, []);

  return (
    <>
      <Autocomplete
        openOnFocus
        disableClearable
        loading={isFetchLoading}
        getOptionLabel={(option) => option.TC_Name}
        fullWidth={true}
        size="small"
        value={singleTC || { id: "", TC_Name: ""}}
        onChange={(event, value) => {
          console.log("value", value);
          formik.setFieldValue(name, value.id);

          setSingleTC(value);
        }}
        id={name}
        options={timeConditions}
        renderInput={(params) => (
          <TextField
            {...formik.getFieldProps(name)}
            {...params}
            label={

              <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
              }}
              >
                Select Time Condition

              </Typography>
            }
            error={
              (formik.touched?.ivr_count?.[index]?.[
                "ivr_option_destination_id"
              ] &&
                formik.errors?.ivr_count?.[index]?.[
                  "ivr_option_destination_id"
                ]) ||
              addErrors[name]
            }
          />
        )}
        label="Select Time Condition"
        name={name}
      />
      {formik.touched?.ivr_count?.[index]?.["ivr_option_destination_id"] &&
      formik.errors?.ivr_count?.[index]?.["ivr_option_destination_id"] ? (
        <Alert severity="error">
          {formik.errors?.ivr_count?.[index]?.["ivr_option_destination_id"]}
        </Alert>
      ) : null}
      {addErrors[name] && (
        <Alert severity="error">
          {addErrors[name]?.[0]}
        </Alert>
      )}

      {isFetchLoading && <LinearProgress />}
    </>
  );
};
