import React, { useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  useFetchMasterNumbersMutation,
  useCreateMasterNumbersMutation,
  useStoreMasterNumbersMutation,
  useEditMasterNumbersMutation,
  useUpdateMasterNumbersMutation,
  useDeleteMasterNumbersMutation,
} from "../../../services/admin/masterNumberApi";

import { styled } from "@mui/material/styles";
import { Header } from "../../../components/Header";
import { ReactComponent as IconEdit } from "../../../images/editIcon.svg";
import { ReactComponent as IconDelete } from "../../../images/deleteIcon.svg";
import { StyledTableCell } from "../../call_setting/QueueList";

import {
  FormControlLabel,
  FormGroup,
  FormControl,
  Autocomplete,
  Stack,
  Input,
  Typography,
  Button,
  TableCell,
  TableRow,
  Alert,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Switch,
  Tooltip,
  IconButton,
} from "@mui/material";
import { MyTable } from "../../../components/MyTable";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { StyledTableRow } from "../../Style";
import AddBox from "../../../components/AddBox";
import { isAllowed } from "../../Functions";
import { useNavigate } from "react-router-dom";

export const MasterNumber = () => {
  const [fetchMasterNumbers, fetchLoader] = useFetchMasterNumbersMutation();
  const [createMasterNumbers, createLoader] = useCreateMasterNumbersMutation();
  const [storeMasterNumbers, storeLoader] = useStoreMasterNumbersMutation();
  const [editMasterNumbers, editLoader] = useEditMasterNumbersMutation();
  const [updateMasterNumbers, updateLoader] = useUpdateMasterNumbersMutation();
  const [deleteMasterNumbers, deleteLoader] = useDeleteMasterNumbersMutation();

  const role_id=localStorage.getItem("role_id")

  const isFetchLoading = fetchLoader;
  const isCreateLoading = createLoader.isLoading;
  const isStoreLoading = storeLoader.isLoading;
  const isEditLoading = editLoader.isLoading;
  const isUpdateLoading = updateLoader.isLoading;
  const isDeleteLoading = deleteLoader.isLoading;

  const [data, setData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    totalRecords: 0,
    fromRecord: 0,
    toRecord: 0,
    totalPages: 0,
  });

  const [body, setBody] = useState({ page: 1, search: "" });

  const [addAlert, setAddAlert] = useState({ status: false, msg: "" });

  const [open, setOpen] = React.useState(false);

  const [addErrors, setAddErrors] = useState({});

  const [title, setTitle] = useState("");

  const [editMode, setEditMode] = useState(false);

  const handleClickOpen = () => {
    setTitle("Add New Master Number");
    setEditMode(false);
    setOpen(true);
  };

  const Input = styled("input")({
    display: "none",
  });

  const getMasterNumbers = async () => {
    try {
      const response = await fetchMasterNumbers(body).unwrap();

      console.log("master number response", response);

      if (response.status === true) {
        setAlert(false);

        console.log("result", response.did_numbers);

        setData(response.did_numbers.data);

        setPagination({
          ...pagination,
          totalPages: response.did_numbers.last_page,
          totalRecords: response.did_numbers.total,
          fromRecord: response.did_numbers.from,
          toRecord: response.did_numbers.to,
          toRecord: response.did_numbers.to,
        });

        if (
          response.did_numbers.current_page > response.did_numbers.last_page
        ) {
          setBody({ ...body, page: 1 });

          console.log("current_page > last_page");
        }
      } else {
        console.log("____false");
        setData([]);
        setAlert(true);
      }
    } catch (error) {
      console.log("____catch", error);
      setData([]);
      setAlert(true);
    }
  };

  useEffect(() => {
    getMasterNumbers();
  }, [body.page]);

  const storeMasterNumber = async (values) => {
    try {
      const response = await storeMasterNumbers(values).unwrap();

      console.log("store master number response", response);

      if (response.status === true) {
        getMasterNumbers();

        handleClose();

        toast.success("Record has been added successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);

      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const editMasterNumber = async (id) => {
    try {
      const response = await editMasterNumbers({ id: id }).unwrap();

      console.log("edit master number response", response);

      if (response.status === true) {
        formik.setFieldValue("id", response.didNumber?.id);
        formik.setFieldValue("did", response.didNumber.did);

        handleEditOpen();
      } else {
        console.log("____false");
      }
    } catch (error) {
      console.log("____catch", error);
    }
  };

  const handleEditOpen = () => {
    setTitle("Edit Master Number");
    setEditMode(true);
    setOpen(true);
  };

  const updateMasterNumber = async (values) => {
    try {
      const response = await updateMasterNumbers(values).unwrap();

      console.log("update master numbers response", response);

      if (response.status === true) {
        getMasterNumbers();

        handleClose();

        toast.success("Record has been update successfully !");

        formik.resetForm();

        formik.setSubmitting(false);
      } else {
        console.log("____false");
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("____catch", error);
      formik.setSubmitting(false);

      if (error?.status == 400) {
        setAddErrors(error?.data?.error);

        console.log("show errors", error?.data?.error);

        console.log("add annonuce errors", addErrors);
      } else if (error?.status == 403) {
        setAddAlert({ status: true, msg: "Unauthorized action !" });
      } else {
        setAddAlert({ status: true, msg: "Oops something went wrong !" });
      }
    }
  };

  const deleteMasterNumber = async (id) => {
    if (window.confirm("are you sure you want to delete this record?")) {
      try {
        const response = await deleteMasterNumbers({ id: id }).unwrap();

        console.log("delete Master Number response", response);

        if (response.status === true) {
          getMasterNumbers();

          toast.success("Record has been deleted successfully !");
        } else {
          console.log("____false", response);

          toast.error(response.message);
        }
      } catch (error) {
        console.log("____catch", error);

        toast.error("Oops something went wrong !");
      }
    }
  };

  const initialValues = {
    did: "",
    // logo: null,
  };

  const validationSchema = Yup.object({
    did: Yup.number().required("Required !"),
  });

  const onSubmit = (values, actions) => {
    console.log("form values", values);

    if (editMode) {
      updateMasterNumber(values);
    } else {
      storeMasterNumber(values);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  console.log("formik", formik);

  const handleClose = () => {
    setOpen(false);
    setAddErrors({});
    setAddAlert({ status: false, msg: "" });
    formik.resetForm();
  };

  const columns = ["Master Number", "Client", "Actions"];

  const handleCheck = (e, name) => {
    const { checked } = e.target;

    if (checked) {
      formik.setFieldValue(name, 1);
    } else {
      formik.setFieldValue(name, 0);
    }
  };

  const navigate = useNavigate();

  if (
    !isAllowed("master_number_access") ||
    role_id != 1
  ) {
    return navigate("/forbidden");
  }

  return (
    <>
      {/* _________________________ Header_____________________________________ */}

      <Header title={"Master Numbers"} />

      <MyTable
        // title="Master Numbers"
        columns={columns}
        data={data}
        alert={alert}
        isLoading={isFetchLoading}
        isEditLoading={isEditLoading}
        pagination={pagination}
        body={body}
        setBody={setBody}
        fetch={getMasterNumbers}
        handleClickOpen={handleClickOpen}
        addButton={
          isAllowed("master_number_create") &&
          role_id  == 1
            ? true
            : false
        }
      >
        {data?.map((item, index) => {
          return (
            <TableRow hover key={index} sx={{}}>
              <StyledTableCell >{item.did}</StyledTableCell>
              <StyledTableCell >
                {item.client?.name}
              </StyledTableCell>

              <StyledTableCell >
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    // sx={{ m: 1 }}
                    color={"info"}
                    variant="contained"
                    disabled={
                      !isAllowed("client_edit") ||
                      role_id != 1
                    }
                    onClick={() => editMasterNumber(item.id)}
                  >
                    <IconEdit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    disabled={
                      !isAllowed("client_delete") ||
                      role_id != 1
                    }
                    size="small"
                    // sx={{ m: 1 }}
                    color={"error"}
                    variant="contained"
                    onClick={() => deleteMasterNumber(item.id)}
                  >
                    <IconDelete stroke="red" />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </MyTable>

      {/* ________Add Box___________________ */}
      <AddBox
        title={title}
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        addLoading={isStoreLoading}
        formik={formik}
        fullWidth={false}
        style={{
          // width: "100%",
          boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
      }}
      >
        {addAlert.status ? (
          <Alert severity="error">{addAlert.msg}</Alert>
        ) : null}

        {(isStoreLoading || isUpdateLoading) && <LinearProgress />}

        <Grid container spacing={1}>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <TextField
              size="small"
              error={
                (formik.touched.did && formik.errors.did) || addErrors?.did
              }
              // margin="normal"
              required
              label={

                <Typography
                variant="span"
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
                >
                  Master Number

                </Typography>
              }
              id="did"
              name="did"
              {...formik.getFieldProps("did")}
              // onChange={formik.handleChange}
              // value={formik.values.name}
              // onBlur={formik.handleBlur}
              onFocus={() => {
                setAddErrors({});
              }}
            />
            {formik.touched.did && formik.errors.did ? (
              <Alert severity="error">{formik.errors.did}</Alert>
            ) : null}
            {addErrors?.did && (
              <Alert severity="error">{addErrors?.did[0]}</Alert>
            )}
          </Grid>
        </Grid>
      </AddBox>
    </>
  );
};
